import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getPaymentMethods } from '../../actions/paymentMethods';
import { AsyncSelect } from '../../components/Forms';

export class PaymentMethodsContainer extends Component {
    checkForValidValue = (value) => {
        let match = this.props.paymentMethods.find(type => type.name === value);

        return match && match.id;
    }

    updateInput = value => {
        // If coming from onChange event it's an object.
        let data = value;

        // It's a string from onInputChange. Check if this is a valid option, by finding an ID.
        if(typeof data === 'string') {
            data = {
                name: data,
                id: this.checkForValidValue(data),
            }
        }

        // onBlur for some reason triggers an onInputChange with an empty string, so skip the change if it's an empty string.
        if(!data.name) return;

        this.props.onChange(data);
    }

    getPaymentMethods = () => {
        let { paymentMethods, getPaymentMethods } = this.props;

        if(!!paymentMethods.length) return;

        getPaymentMethods();
    }

    render() {
        let {
            fetching,
            paymentMethods,
            value,
            disabled,
            id
        } = this.props;

        return(
            <AsyncSelect
                value={value}
                options={paymentMethods}
                onMenuOpen={this.getPaymentMethods}
                isLoading={fetching}
                allowCreateWhileLoading={fetching}
                onChange={this.updateInput}
                onInputChange={this.updateInput}
                onSelectResetsInput={false}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                getOptionLabel={item => item.name}
                getOptionValue={item => item.name}
                id={id}
                isDisabled={disabled}
            />
        );
    }
}

PaymentMethodsContainer.propTypes = {
    paymentMethods: PropTypes.array,
    exclude: PropTypes.array,
    fetching: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    getPaymentMethods: PropTypes.func,
    onChange: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        paymentMethods: state.paymentMethods.data,
        fetching: state.paymentMethods.fetching,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getPaymentMethods }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentMethodsContainer));