import React, { Component, Fragment } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';

import { DataTableWrap, DataTablePagination, DataTableNoData } from './components';
import { Loading } from '../../Utils';

class DataTable extends Component {
    render() {
        let {
            pages,
            page,
            columns,
            data,
            manual,
            limit,
            total,
            multiSort,
            viewingFrom,
            viewingTo,
            pageSizeOptions,
            getTdProps,
            getTheadThProps,
            onPageChange,
            onPageSizeChange,
            onSortChange,
            fetching,
            noDataText,
            noDataButtonText,
            noDataButtonLink,
            noDataButtonOnClick,
            styleType,
            loadInside,
			noCellHeight,
			rowAlign,
			noCellBorder,
			rightAlignLastCol,
        } = this.props;

        if(!data) return null;

        return(
            <Fragment>
                <DataTableWrap
                    styleType={styleType}
                    noCellHeight={noCellHeight}
					rowAlign={rowAlign}
					noCellBorder={noCellBorder}
					rightAlignLastCol={rightAlignLastCol}
                >
                    <ReactTable
                        data={data}
                        pages={pages}
                        page={page}
                        columns={columns}
                        pageSize={!!data.length ? parseInt(limit) : null}
                        pageSizeOptions={pageSizeOptions}
                        showPaginationTop={true}
                        manual={manual}
                        multiSort={multiSort}
                        getTdProps={getTdProps}
                        getTheadThProps={getTheadThProps}
                        onPageSizeChange={onPageSizeChange}
                        onPageChange={onPageChange}
                        onSortedChange={onSortChange}
                        PaginationComponent={(props) => (
                            <DataTablePagination
                                {...props}
                                total={total}
                                viewingFrom={viewingFrom}
                                viewingTo={viewingTo}
                            />
                        )}
                        NoDataComponent={(props) => (
                            <DataTableNoData
                                {...props}
                                text={noDataText}
                                buttonText={noDataButtonText}
                                buttonLink={noDataButtonLink}
                                buttonOnClick={noDataButtonOnClick}
                            />
                        )}
                    />

                    { fetching && loadInside && <Loading align={'top'} overlay={true} /> }
                </DataTableWrap>

                { fetching && !loadInside && <Loading align={'top'} overlay={true} /> }
            </Fragment>
        );
    }
}

DataTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array,
    pages: PropTypes.number,
    page: PropTypes.number,
    limit: PropTypes.number,
    total: PropTypes.number,
    viewingFrom: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    viewingTo: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    manual: PropTypes.bool,
    multiSort: PropTypes.bool,
    fetching: PropTypes.bool,
    // Loading component needs to live outside DataTableWrap for blue table
    loadInside: PropTypes.bool,
    pageSizeOptions: PropTypes.array,
    noDataText: PropTypes.string,
    noDataButtonText: PropTypes.string,
    noDataButtonLink: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
	]),
	noCellHeight: PropTypes.bool,
	noCellBorder: PropTypes.bool,
	rightAlignLastCol: PropTypes.bool,
	rowAlign: PropTypes.oneOf([
		'flex-start',
		'flex-end',
		'center',
		'stretch',
	]),
    noDataButtonOnClick: PropTypes.func,
    getTdProps: PropTypes.func,
    onPageChange: PropTypes.func,
    onPageSizeChange: PropTypes.func,
    onSortChange: PropTypes.func,
}

DataTable.defaultProps = {
    manual: true,
    multiSort: false,
    pageSizeOptions: [5, 10, 20, 40],
    loadInside: true,
}

export default DataTable;