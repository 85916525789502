import { get, post, put, remove, checkAuthentication } from '../utils/api';
import { validateParams, dispatchErrors } from '../utils/validation';

export const getCustomers = (params = {}) => {

    // Remove any invalid parameters
    let validatedParams = validateParams(['archived', 'mine_only', 'retainer', 'type', 'searchQuery', 'sort', 'sortBy', 'page', 'limit'], params);

    return (dispatch) => {

        dispatch({ type: 'FETCH_CUSTOMERS' });

        return get('/customers', validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then((data) => {
                dispatch({ type: 'FETCH_CUSTOMERS_SUCCESS', customers: data, params: validatedParams })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CUSTOMERS_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const getCustomer = (customerId) => {

    return (dispatch) => {

        dispatch({ type: 'FETCH_CUSTOMER' });

        return get(`/customers/${customerId}`, null, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'FETCH_CUSTOMER_SUCCESS', customer: data })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CUSTOMER_FAIL', status: err.status });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const addCustomer = (params) => {

    return (dispatch) => {

        let validatedParams = validateParams(['name', 'type', 'referral', 'address', 'invoice_organisation', 'invoice_address', 'retainer', 'reactive_retainer_cases_per_month', 'proactive_retainer_cases_per_month'], params);

        dispatch({ type: 'ADD_CUSTOMER' });

        return post(`/customers`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'ADD_CUSTOMER_SUCCESS', customer: data, success: 'The customer has been created.' })
            })
            .catch(err => {
                dispatch({ type: 'ADD_CUSTOMER_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const updateCustomer = (customerId, params) => {

    return (dispatch) => {

        dispatch({ type: 'UPDATE_CUSTOMER' });

        // Remove any invalid parameters
        let validatedParams = validateParams(['name', 'type', 'referral', 'address', 'invoice_organisation', 'invoice_address', 'retainer', 'source', 'reactive_retainer_cases_per_month', 'proactive_retainer_cases_per_month', 'status'], params, true);

        return put(`/customers/${customerId}`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'UPDATE_CUSTOMER_SUCCESS', customer: data, success: 'The customer has been updated.' })
            })
            .catch(err => {
                dispatch({ type: 'UPDATE_CUSTOMER_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const addCustomerDocuments = (customerId, documents) => {

    return (dispatch) => {

        dispatch({ type: 'ADD_CUSTOMER_DOCUMENTS' });

        return post(`/customers/${customerId}/documents`, documents, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'ADD_CUSTOMER_DOCUMENTS_SUCCESS', documents: data })
            })
            .catch(err => {
                dispatch({ type: 'ADD_CUSTOMER_DOCUMENTS_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const getCustomerCases = (customerId, params) => {

    let validatedParams = validateParams(['mine_only', 'status', 'searchQuery', 'sort', 'sortBy', 'page', 'limit'], params);

    return (dispatch) => {

        dispatch({ type: 'FETCH_CUSTOMER_CASES' });

        return get(`/customers/${customerId}/cases`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then((data) => {
                dispatch({ type: 'FETCH_CUSTOMER_CASES_SUCCESS', cases: data, params: validatedParams })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CUSTOMER_CASES_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }
    
}

export const getCustomerDocuments = (customerId, params) => {

    let validatedParams = validateParams(['limit', 'page', 'sort', 'sortBy'], params);

    return (dispatch) => {

        dispatch({ type: 'FETCH_CUSTOMER_DOCUMENTS' });

        return get(`/customers/${customerId}/documents`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'FETCH_CUSTOMER_DOCUMENTS_SUCCESS', documents: data, params: validatedParams })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CUSTOMER_DOCUMENTS_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const getCustomerContacts = (customerId, params) => {

    // Remove any invalid parameters
    let validatedParams = validateParams(['page', 'limit'], params);

    return (dispatch) => {

        dispatch({ type: 'FETCH_CUSTOMER_CONTACTS' });

        return get(`/customers/${customerId}/contacts`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'FETCH_CUSTOMER_CONTACTS_SUCCESS', contacts: data, params: validatedParams })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CUSTOMER_CONTACTS_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const updateCustomerContacts = (customerId, contacts) => {

    return (dispatch) => {

        dispatch({ type: 'UPDATE_CUSTOMER_CONTACTS' });

        return put(`/customers/${customerId}/contacts`, { contacts }, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'UPDATE_CUSTOMER_CONTACTS_SUCCESS', contacts: data })
            })
            .catch(err => {
                dispatch({ type: 'UPDATE_CUSTOMER_CONTACTS_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const addCustomerContact = (customerId, params) => {

    let validatedParams = validateParams(['name', 'email'], params);

    return (dispatch) => {

        dispatch({ type: 'ADD_CUSTOMER_CONTACT' });

        return post(`/customers/${customerId}/contacts`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'ADD_CUSTOMER_CONTACT_SUCCESS', contact: data, success: 'The contact has been created.' })
            })
            .catch(err => {
                dispatch({ type: 'ADD_CUSTOMER_CONTACT_FAIL' });
                return dispatchErrors(err, dispatch);
            });

    }

}

export const updateCustomerContact = (customerId, contactId, params) => {

    let validatedParams = validateParams(['name', 'email'], params);

    return (dispatch) => {

        dispatch({ type: 'UPDATE_CUSTOMER_CONTACT' });

        return put(`/customers/${customerId}/contacts/${contactId}`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'UPDATE_CUSTOMER_CONTACT_SUCCESS', contact: data, success: 'The contact has been updated.' })
            })
            .catch(err => {
                dispatch({ type: 'UPDATE_CUSTOMER_CONTACT_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const deleteCustomerContact = (customerId, id) => {

    return (dispatch) => {

        dispatch({ type: 'DELETE_CUSTOMER_CONTACT' });

        return remove(`/customers/${customerId}/contacts/${id}`, null, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then((data) => {
                dispatch({ type: 'DELETE_CUSTOMER_CONTACT_SUCCESS', id, success: 'The contact has been deleted.' })
            })
            .catch(err => {
                dispatch({ type: 'DELETE_CUSTOMER_CONTACT_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const resetCustomer = (caseId) => {

    return (dispatch) => {

        dispatch({ type: 'RESET_CUSTOMER' });

    }

}

export const resetCustomers = (caseId) => {

    return (dispatch) => {

        dispatch({ type: 'RESET_CUSTOMERS' });

    }

}