import React from 'react';
import GoogleAnalytics from 'react-ga';

import settings from './settings'

if( process.env.NODE_ENV === 'production' ) GoogleAnalytics.initialize(settings.gaCode);

const withTracker = (WrappedComponent) => {

    const trackPage = (page) => {
        if( process.env.NODE_ENV !== 'production' ) return;
        GoogleAnalytics.set({ page });
        GoogleAnalytics.pageview(page);
    };

    return (props) => {
        const page = props.location.pathname;
        trackPage(page);

        return (
            <WrappedComponent {...props} />
        );
    };

};

export default withTracker;