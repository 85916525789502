import memoizeOne from 'memoize-one';

// TODO: Validate params and return errors in one
// TODO: Convert to <forms> so enter submits (cmd+enter for add another?)
// const siteVisit = {
//     edit: ['start_date', 'user_ids', 'notes', 'address', 'end_date'],
//     complete: ['storage_location', 'mileage', 'notes', 'products_seized']
// }
const testPurchase = {
    edit: ['start_date', 'delivery_date', 'item', 'authorized_by_user_id', 'user_ids', 'type', 'purchase_status', 'website', 'notes', 'address', 'end_date', 'payment_method_id', 'cost'],
    complete: ['mileage', 'payment_method_id', 'notes', 'cost', 'received']
}
// const testPurchase = {
//     edit: [
//         {
//             key: 'start_date',
//             required: true,
//         },
//         {
//             key: 'delivery_date',
//         }, 'item', 'authorized_by_user_id', 'user_ids', 'type', 'purchase_status', 'website', 'notes', 'address', 'end_date', 'payment_method', 'cost'],
//     complete: ['mileage', 'payment_method', 'notes']
// }
const raid = {
    edit: ['start_date', 'user_ids', 'notes', 'address', 'end_date'],
    complete: ['storage_location', 'mileage', 'notes', 'products_seized']
}

export const validatePassword = (val) => {
    if( !/(?=.{8,})/gi.exec(val) ) return false; // Length
    if( !/(?=.*[a-z])/g.exec(val) ) return false; // Lower case letters
    if( !/(?=.*[A-Z])/g.exec(val) ) return false; // Higher case letters
    if( !/(?=.*[0-9])/gi.exec(val) ) return false; // Number
    return true;
}

export const validateEmail = (email) => {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.exec(email);
}

export const validateSort = (allowedParams, params) => {
    return params.filter(filter => {
        return allowedParams.find(allowed => allowed === filter.id);
    });
}

export const validateTime = (value) => {
    const regex = /\d+(:|.)\d{1,2}/;
    return regex.exec(value);
}

export const validateAddress = (address) => {
    if(typeof address !== 'string') return address;

    return address.split(/\n/gm);
}

export const validateParams = (allowedParams, params, keepEmpties) => {
    let validatedParams = {};

    if(!params) return null;

    Object.keys(params).forEach((key, val) => {
        if(allowedParams.includes(key)) {
            // Remove any empty strings
            if((typeof params[key] === 'string' && !params[key]) && !keepEmpties) return;
            if(Array.isArray(params[key]) && !params[key].length && !keepEmpties) return;
            if(typeof params[key] === 'undefined') return;

            validatedParams[key] = params[key];
        }
    });

    return validatedParams;
}

const validateLimit = (val) => {
    let allowedLimits = [5, 10, 20, 40];

    if(allowedLimits.includes(parseInt(val))) {
        return val;
    } else {
        return 10;
    }
}

export const isCaseClosed = (string) => {
    return string === 'closed' || string === 'invoiced';
}

export const validateTestPurchase = (params, complete = false) => {
    let allowed = complete ? testPurchase.complete : testPurchase.edit;

    let validatedParams = validateParams(['logged_time', 'documents', 'test_purchase'], params);

    validatedParams.test_purchase = validateParams(allowed, validatedParams.test_purchase);

    return validatedParams;
}

export const validateRaid = (params, complete = false) => {
    let allowed = complete ? raid.complete : raid.edit;

    let validatedParams = validateParams(['logged_time', 'documents', 'raid'], params);

    validatedParams.raid = validateParams(allowed, validatedParams.raid);

    return validatedParams;
}

export const hasPermission = (user, permissions) => {
    if(!user || !permissions) return null;

    return user.can && user.can.includes(permissions);
}

export const dispatchErrors = (err, dispatch) => {

    if(err.body && err.body.errors) {
        Object.keys(err.body.errors).forEach(e => {
            err.body.errors[e].forEach(error => {
                dispatch({ type: 'ERROR', error })
            });
        });
    } else {
        let error = err.body && err.body.message ? err.body.message : err.message;
        dispatch({ type: 'ERROR', error: error })
    }

}

export const allTrue = (arr) => {
    if(!arr || !arr.every) return false;

    return arr.every(item => {
        if(Array.isArray(item)) {
            return item && item.length;
        }

        return item;
    });
}

const _haveDatesChanged = (prev, { start_date, end_date }) => {
    return prev.start_date.formatted !== start_date.formatted || prev.end_date.formatted !== end_date.formatted;
}

export const haveDatesChanged = memoizeOne(_haveDatesChanged);