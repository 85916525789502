import React from 'react';
import styled from 'styled-components';
import { format, isAfter, differenceInCalendarDays } from 'date-fns';

import { BlockyDate, Link } from '../../../Text';
import { StageIcons } from '../../../Icons';
import { Button } from '../../../Buttons';
import { GreyCard } from '../../../Cards';
import theme from '../../../../config/theme';

export const StageCell = ({ original: { stages } }) => (
    <StageIcons stages={stages} />
);

export const BoldCell = ({ value }) => {
    return(
        <strong>{ value }</strong>
    );
}

export const DateCell = ({ value, warn, original }) => {
    const difference = differenceInCalendarDays(value, new Date());
    value = value ? format(value, 'DD/MM/YY') : '';

    // If the deadline is past or within a week, make it red, unless the case is archived.
    const diffStyles = {
        color: difference > -6 && original.status === 'pending' ? theme.red : 'initial'
    }

    return(
        <time style={ warn ? diffStyles : null }>{ value }</time>
    );
}

export const DateTimeCell = ({ value }) => {
    value = format(value, 'DD/MM/YY h:mmA');

    return(
        <time>{ value }</time>
    );
}

export const BlockyDateCell = ({ value }) => {
    let month = format(value, 'MMM').toUpperCase(),
        day = format(value, 'D');

    let color = isAfter(value, new Date()) ? 'orange' : null;

    return(
        <BlockyDate color={color}>
            <span>{ month }</span>
            <span>{ day }</span>
        </BlockyDate>
    );
}

export const FullCell = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: absolute;
    top: 0;
    min-width: 100%;
    height: 100%;
    cursor: pointer;
`;

const DocumentsCellWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-left: 12px solid ${({ theme }) => theme.greyLightest};
    overflow: auto;
    
    div {
        margin: 4px; 
    }
`;

export const DocumentsCell = ({ value, original, onOutcomeClick }) => {
    let { test_purchase: { id, status }  } = original;

    return(
        <DocumentsCellWrap>
            { status === 'completed' ?
                value.map((doc, i) => (
                    <GreyCard mini={true} key={`doc${doc.id}`}><a href={doc.url} target={"_blank"} style={{ fontSize: '12px' }}>{ doc.name }</a></GreyCard>
                ))
                :
                <Button 
                    onClick={() => onOutcomeClick(id)}
                    chunkyish={true}
                    green={true}
                    style={{ margin: '0 auto' }}
                >Add Test Purchase Outcome</Button>
            }
        </DocumentsCellWrap>
    );
}

export const ButtonCell = (props) => {
    return(
        <Link
            onClick={() => props.onClick(props)}
            el={'button'}
        >
            { props.value }
        </Link>
    );
}