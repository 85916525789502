import { handleActions } from 'redux-actions';

const initialState = {
    data: [],
    links: {},
    meta: {},
    page: 1,
    limit: 10,
    sort: '',
    fetching: true,
    error: false,
}

export default handleActions({

    FETCH_CASE_SOURCES: (state, action) => {
        return {
            ...state,
            fetching: true,
            error: false,
        }
    },

    FETCH_CASE_SOURCES_SUCCESS: (state, action) => {
        return {
            ...state,
            data: action.sources.data,
            links: action.sources.links,
            meta: action.sources.meta,
            fetching: false,
        }
    },

    FETCH_CASE_SOURCES_FAIL: (state, action) => {
        return {
            ...state,
            fetching: false,
            error: true
        }
    },

}, initialState)
