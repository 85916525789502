import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Strong, Small } from '../../Text';
import { media } from '../../../utils/style';

const LogEntryWrap = styled.div`
    display: flex;
    align-items: stretch;
	word-break: break-word;
    
    ${media.medium`
        flex-wrap: wrap;
        
        ${({ index, theme }) => index % 2 === 0 ?
            `background-color: ${theme.whiteish};`
        :
            `background-color: ${theme.greyLightest};`
        }
    `}
`;

const LogEntryMeta = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    width: 220px;
    padding: 10px 15px 10px 36px;
    text-align: right;

    time,
    strong {
        display: block;
        width: 100%;
    }

    button {
        margin-left: auto;
    }
    
    @media screen and (max-width: 832px) {
        width: 100%;
        padding: 20px 36px 0 36px;
        text-align: left;
        
        button {
            margin-left: 0;
        }
    }
    
    @media screen and (max-width: 479px) {
        padding: 15px 15px 0;
    }
`;

const LogEntryContent = styled.div`
    flex: 1 0 0;
    padding: 30px 33px;
    font-size: ${({ theme }) => theme.fontSize.bodyMd};
    line-height: 24px;

    ${({ index, theme }) => index % 2 === 0 ?
        `background-color: ${theme.whiteish};`
        :
        `background-color: ${theme.greyLightest};`
    };
    
    @media screen and (max-width: 479px) {
        padding: 15px;
    }
`;

const LoggedTime = styled(Strong)`
    display: block;
    font-size: ${({ theme }) => theme.fontSize.bodySm};
`;

const LogEntry = ({ attached_to, created_by, created_at, type, text, index }) => {
    // if(type !== 'App\\Cases') {
    //     After the destructuring above, only one object should be left for comment, site_visit etc. Flatten this object and move it's key to a property inside.
    //     actionInfo = flattenActionInfo(actionInfo);
    // }

    let user = created_by;
    user = user ? user.name : '';

    // We don't want "New comment: blah blah"
    if(type === 'App\\Comment') {
        text = attached_to.comment.text;
    }

    // Add together all the logged time
    let loggedTime = attached_to && attached_to.logged_time;
    if(loggedTime && loggedTime.length) {
        loggedTime = loggedTime.reduce((acc, curr) => ({
            hours: acc.hours + curr.hours,
            minutes: acc.minutes + curr.minutes
        }), { hours: 0, minutes: 0 });
    }

    // Set to null if there is no logged time
    if(!loggedTime || (!loggedTime.hours && !loggedTime.minutes)) {
        loggedTime = null;
    }

    text = text.replace(/\r?\n|\r/g, '<br/>');

    return(
        <LogEntryWrap index={index}>
            <LogEntryMeta>
                <Strong>{ user }</Strong>
                <Small is={'time'}>{ created_at }</Small>
                {/*<Link small={true} el={'button'} onClick={() => onEditClick('TODO: send ID')}>Edit</Link>*/}
            </LogEntryMeta>

            <LogEntryContent index={index}>
                <div dangerouslySetInnerHTML={{ __html: text }} />
                { loggedTime &&
                    <LoggedTime>{ `Logged time: ${loggedTime.hours} hours ${loggedTime.minutes} minutes` }</LoggedTime>
                }
            </LogEntryContent>
        </LogEntryWrap>
    );
}

LogEntry.propTypes = {
    action: PropTypes.object,
    text: PropTypes.string,
    index: PropTypes.number,
    onEditClick: PropTypes.func,
}

export default LogEntry;