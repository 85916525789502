import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'grid-styled';

const Page = styled(Flex)`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    background: ${({ theme }) => theme.blue};
    
    @media screen and (max-width: 700px) {
        align-items: flex-start;
        padding-top: 50px;
    }
`;

const ContentAreaWrap = styled(Box)`
    width: 100%;
    max-width: 420px;
    padding: 35px 50px;
    border-radius: ${({ theme }) => theme.radius.small};
    box-shadow: 0 0 3px 2px rgba(0,0,0,0.15);
    background: ${({ theme }) => theme.white};
    
    @media screen and (max-width: 700px) {
        padding: 20px;
    }
`;

const PageWithCenteredContent = ({ children }) => {
    return(
        <Page>
            <ContentAreaWrap>
                { children }
            </ContentAreaWrap>
        </Page>
    );
}

export default PageWithCenteredContent;