import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToTopOnMount extends Component {

    componentDidMount(prevProps) {
        window.scrollTo(0, 0)
    }

    shouldComponentUpdate(nextProps) {
        return this.props.onUpdateRoute && this.props.match.url !== nextProps.match.url;
    }

    componentDidUpdate(prevProps) {
        window.scrollTo(0, 0)
    }

    render() {
        return null
    }
}

ScrollToTopOnMount.defaultProps = {
    onUpdateRoute: false
}

export default withRouter(ScrollToTopOnMount);