import React from 'react';
import styled from 'styled-components';

import { defaultTabs, blueTabs } from '../../style/tabs';

const TabsWrap = styled.div`
    display: inline-flex;
    position: relative;
    
    input[type="radio"] {
        display: none;
        
        +label {
            display: block;
            cursor: pointer;
            white-space: nowrap;
        }
    }
    
    ${({ styleType, theme }) => styleType.includes('default') && defaultTabs(theme)}
    
    ${({ styleType, theme }) => styleType.includes('blue') && blueTabs(theme)}
    
    ${({ disabled, theme }) => disabled && `
        input[type="radio"] {
            +label {
                border-color: rgba(201, 201, 201, .8);
                background ${theme.greyLighter};
            }
        }
        
        * {
            cursor: default!important;
        }
    `};
`;

const Tabs = ({ name, items, styleType, disabled }) => {
    return(
        <TabsWrap
            styleType={styleType}
            disabled={disabled}
            data-testid={'tabs'}
            className={'tabs'}
        >
            { items.map((item, i) => {
                return(
                    <div key={name+i}>
                        <input
                            type="radio"
                            name={name}
                            id={item.id}
                            value={item.value}
                            onChange={item.onChange}
                            onClick={item.onClick}
                            checked={item.checked}
                            disabled={item.disabled}
                        />
                        <label htmlFor={item.id}>{item.label}</label>
                    </div>
                )
            }) }
        </TabsWrap>
    );
}

Tabs.defaultProps = {
    name: 'tabs',
    styleType: 'default',
}

export default Tabs;