import React, { Component, Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    getCaseSiteVisits,
    addCaseSiteVisit,
    updateCaseSiteVisit,
    completeCaseSiteVisit,
    deleteCaseSiteVisit,
} from '../../actions/cases';
import { removeUserFromAction } from '../../actions/users';
import { deleteDocument } from '../../actions/documents';
import { flashAlert } from '../../actions/alerts';

import {
    SiteVisitCard,
    EditSiteVisitForm,
    CompleteSiteVisitForm,
    ViewSiteVisitForm,
} from '../../components/SiteVisits';

import { FormGroup } from '../../components/Forms';
import { Grid } from '../../components/Layout';
import { CardButton } from '../../components/Cards';
import {
    Loading,
    Error,
    ErrorBoundary,
} from '../../components/Utils';
import {
    Modal,
    ModalContent,
} from '../../components/Modals';

class CaseSiteVisitsContainer extends Component {
    state = {
        showModal: false,
        editing: null,
        completing: null,
        viewing: null,
        stayOpen: false,
    }

    componentDidMount() {
        this.props.getCaseSiteVisits(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        // If we have saved successfully with no errors and 'add another' hasn't been chosen, close the modal.
        if((prevProps.saving && !this.props.saving) && !this.props.error && !this.state.stayOpen) {
            this.setState({
                modalOpen: false,
                editing: null,
                completing: null,
                viewing: null,
                stayOpen: false,
            });
        }
    }

    openEditModal = (id) => {
        this.setState({
            modalOpen: true,
            editing: this.props.siteVisits.find(item => item.site_visit.id === id),
            completing: null,
            viewing: null,
        });
    }

    openCompleteModal = (id) => {
        this.setState({
            modalOpen: true,
            editing: null,
            completing: this.props.siteVisits.find(item => item.site_visit.id === id),
            viewing: null,
        });
    }

    openNewModal = () => {
        this.setState({
            modalOpen: true,
            editing: null,
            completing: null,
            viewing: null,
        });
    }

    openViewModal = (id) => {
        this.setState({
            modalOpen: true,
            viewing: this.props.siteVisits.find(item => item.site_visit.id === id),
            completing: null,
            editing: null,
        });
    }

    closeModal = () => {
        this.setState({
            modalOpen: false
        });
    }

    saveSiteVisit = (id, params, stayOpen = false) => {
        let { match } = this.props,
            { completing, editing } = this.state;

        if(id) {
            if(completing) {
                if(window.confirm('Are you sure you want to complete this site visit? Once you do, you won\'t be able to edit it.')) {
                    this.props.completeCaseSiteVisit(match.params.id, id, params);
                }
            } else if(editing) {
                this.props.updateCaseSiteVisit(match.params.id, id, params);
            }
        } else {
            this.props.addCaseSiteVisit(match.params.id, params);
        }

        this.setState({ stayOpen });
    }

    deleteSiteVisit = (id) => {
        if(window.confirm('Are you sure you want to delete this site visit?')) {
            this.props.deleteCaseSiteVisit(this.props.match.params.id, id);
        }
    }

    renderSiteVisits = (items) => {
        return items.map(item => (
            <SiteVisitCard
                onEdit={this.openEditModal}
                onComplete={this.openCompleteModal}
                onView={this.openViewModal}
                key={`siteVisit${item.site_visit.id}`}
                {...item}
            />
        ));
    }

    render() {
        let {
            loggedIn,
            siteVisits,
            details,
            fetching,
            saving,
            error,
        } = this.props;

        let { 
            modalOpen, 
            editing, 
            completing,
            viewing,
         } = this.state;

        if(!loggedIn) return <Redirect to='/login' />

        let hide = details && !details.source.site_visits;
        if(hide) return null;

        let ModalForm = EditSiteVisitForm;
        if(completing) {
            ModalForm = CompleteSiteVisitForm;
        } else if(viewing) {
            ModalForm = ViewSiteVisitForm;
        }

        let modalTitle = 'Add Site Visit';
        if(editing) {
            modalTitle = 'Edit Site Visit';
        } else if(completing) {
            modalTitle = 'Complete Site Visit';
        } else if(viewing) {
            modalTitle = 'View Site Visit';
        }

        return(
            <Fragment>
                <FormGroup
                    title={"Site Visits"}
                    id={"site-visits"}
                    border={'top'}
                >
                    { fetching ? <Loading /> :
                        <ErrorBoundary render={() => <Error overlay={true} />}>
                            <Grid cols={[ [1, 0], [2, 480], [3, 901], [4, 1201], [5, 1380] ]}>
                                { this.renderSiteVisits(siteVisits) }
                                <CardButton
                                    label={'Add a site visit'}
                                    onClick={this.openNewModal}
                                />
                            </Grid>
                        </ErrorBoundary>
                    }
                </FormGroup>

                <Modal
                    isOpen={modalOpen}
                    onRequestClose={this.closeModal}
                >
                    <ModalContent title={modalTitle}>
                        <ErrorBoundary render={() => <Error withIcon={true} overlay={true} />}>
                            <ModalForm
                                {...editing}
                                {...completing}
                                {...viewing}
                                onSave={this.saveSiteVisit}
                                onDelete={this.deleteSiteVisit}
                                onCancel={this.closeModal}
                                onStaffRemove={(id, userId) => this.props.removeUserFromAction(this.props.match.params.id, id, userId, 'site-visits')}
                                onFileRemove={id => this.props.deleteDocument(id)}
                                handleRejectedFiles={msg => this.props.flashAlert(msg, 'error')}
                                saving={saving}
                                error={error}
                            />
                        </ErrorBoundary>
                    </ModalContent>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        details: state.case.data.details.data,
        siteVisits: state.case.data.siteVisits.data,
        fetching: state.case.data.siteVisits.fetching,
        saving: state.case.data.siteVisits.saving,
        error: state.case.data.siteVisits.error,
        // fetching: true,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ 
    getCaseSiteVisits,
    addCaseSiteVisit,
    updateCaseSiteVisit,
    completeCaseSiteVisit,
    removeUserFromAction,
    deleteDocument,
    deleteCaseSiteVisit,
    flashAlert,
 }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseSiteVisitsContainer));