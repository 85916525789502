import React from 'react';
import styled from 'styled-components';

import { IconCircle } from '../Icons';
import { SVG, Tooltip } from '../Utils';
import { toTitle } from '../../utils/textTransforms';

const StageIconsWrap = styled.div`
    display: flex;

    >div {
        +div {
            margin-left: 3px;
        }
    }
    
    ${({ size }) => size && size === 'lg' && `
        justify-content: center;
        
        >div {
            +div {
                margin-left: 50px;
            }
        }
        
        @media screen and (max-width: 549px) {
            flex-wrap: wrap;
            justify-content: center;
            
            >div {
                margin: 15px 25px;
                
                +div {
                    margin-left: 25px;
                }
            }
            
            
        }
    `}
`;

const StageIcon = styled.div`
    position: relative;
    cursor: pointer;
`;

const StageIcons = ({ stages, size }) => {
    return(
        <StageIconsWrap size={size}>
            {Object.keys(stages).map((stage, i) => (
                <Tooltip title={toTitle(stage)} key={stage+i}>
                    <StageIcon>
                        <IconCircle
                            inactive={!stages[stage]}
                            pending={stages[stage] === 'pending'}
                            size={size}
                        >
                            <SVG path={`/svg/icon-${stage}.svg`} />
                        </IconCircle>
                    </StageIcon>
                </Tooltip>
            ))}
        </StageIconsWrap>
    );
}

StageIcons.defaultProps = {
    stages: {
        created: null,
        raid: null,
        resolved: null,
        site_visit: null,
        test_purchase: null,
    }
}

export default StageIcons;