import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { SROnly, SVG } from '../Utils';

const CornerButtonWrap = styled(Link)`
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 42px;
    min-height: 42px;
    border: 0;
    border-top-left-radius: ${({ theme }) => theme.radius.medium};
    background: ${({ theme }) => theme.blueLighter};
    transition: ${({ theme }) => theme.duration.quick} background;
    cursor: pointer;
    
    svg {
        width: 13px;
        height: auto;
    }
    
    &:hover {
        background: ${({ theme }) => theme.blueLight};
    }
    
    @media screen and (max-width: 1024px) {
        position: relative;
        width: 42px;
        margin: 25px auto 0;
        border-radius: ${({ theme }) => theme.radius.medium};
    }
`;

const CornerButton = ({ label, icon, children, ...props }) => {
    return(
        <CornerButtonWrap {...props}>
            { children }
            { label && <SROnly>{ label }</SROnly> }
            { icon && <SVG path={icon} /> }
        </CornerButtonWrap>
    );
}

export default CornerButton;