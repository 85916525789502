import React, { Component } from 'react';

import { Select } from '../../../Forms';
import { PaginationControls } from '../../../Tables';

class DataTablePagination extends Component {
    constructor (props) {
        super();

        this.state = {
            page: props.page,
        }
    }

    componentWillReceiveProps (nextProps) {
        this.setState({ page: nextProps.page })
    }

    getSafePage = (page) => {
        if (Number.isNaN(page)) {
            page = this.props.page
        }
        return Math.min(Math.max(page, 0), this.props.pages - 1)
    }

    changePage = (page) => {
        page = this.getSafePage(page)
        this.setState({ page })
        if (this.props.page !== page) {
            this.props.onPageChange(page)
        }
    }

    render () {
        const {
            page,
            showPageSizeOptions,
            pageSizeOptions,
            pageSize,
            canPrevious,
            canNext,
            onPageSizeChange,
            total,
            viewingFrom,
            viewingTo
        } = this.props;

        if(!viewingFrom || !viewingTo) return null;

        return (
            <div className="rt-pagination" style={this.props.style}>
                {showPageSizeOptions ?
                    <Select
                        onChange={e => onPageSizeChange(Number(e.target.value))}
                        value={pageSize}
                        options={pageSizeOptions}
                        label={"View"}
                        size={'xs'}
                        id={`casesRowsPerPage`}
                        className={"rt-pagination__pp"}
                    />
                    :
                    null
                }

                <PaginationControls
                    canPrevious={canPrevious}
                    onPrevious={() => {
                        if (!canPrevious) return;
                        this.changePage(page - 1)
                    }}
                    previousText={this.props.previousText}
                    canNext={canNext}
                    onNext={() => {
                        if (!canNext) return;
                        this.changePage(page + 1)
                    }}
                    nextText={this.props.nextText}
                    textHtml={`<strong>${viewingFrom}</strong> - <strong>${viewingTo}</strong> of <strong>${total}</strong>`}
                />
            </div>
        )
    }
}

export default DataTablePagination;