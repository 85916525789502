import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'underscore';

import { getUsers, getUserExclusions, resetUsers } from '../../actions/users';
import { AsyncSelect } from '../../components/Forms';

export class UserDropdownContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            value: '',
            available_from: props.available_from,
            available_to: props.available_to,
        }
    }

    componentDidMount() {
        this.props.resetUsers();
    }

    static getDerivedStateFromProps(props, state) {
        let { users, exclude } = props;

        if(exclude) {
            users = users.filter(user => exclude.indexOf(user.id) === -1);
        }

        return { users };
    }

    updateInput = value => {
        this.setState({ value });

        let match = this.props.users.find(user => user.name === value);

        if(!this.props.addOnType || !match) return;

        this.props.addUser(match);
    }

    handleChange = (e) => {
        if(!e || isEmpty(e)) return;

        this.addUser(e)
    }

    getUsers = () => {
        // Check if the query parameters are what we already have in the store. If so, we don't need to fetch the users again.
        let { available_from, available_to, role_id, reset } = this.props;

        let paramsJSON = JSON.stringify({ available_from, available_to, role_id }),
            storeParamsJSON = JSON.stringify(this.props.params);

        if(paramsJSON === storeParamsJSON && !reset) return;

        this.props.getUsers({ available_from, available_to, role_id, limit: 100 });
    }

    addUser = (user) => {
        this.props.addUser(user);
    }

    render() {
        let { fetching, disabled, invalid } = this.props,
            { users } = this.state;

        let value = this.props.value || this.state.value;

        return(
            <AsyncSelect
                value={value}
                options={users}
                onMenuOpen={this.getUsers}
                isLoading={fetching}
                allowCreateWhileLoading={fetching}
                onChange={this.handleChange}
                onInputChange={this.updateInput}
                getOptionValue={item => item.name}
                getOptionLabel={item => item.name}
                isDisabled={disabled}
                invalid={invalid}
                // menuIsOpen={true}
            />
        );
    }
}

UserDropdownContainer.propTypes = {
    users: PropTypes.array,
    available_from: PropTypes.string,
    available_to: PropTypes.string,
    addOnType: PropTypes.bool,
    defaultToSelf: PropTypes.bool,
    fetching: PropTypes.bool,
    disabled: PropTypes.bool,
    reset: PropTypes.bool,
    error: PropTypes.bool,
    invalid: PropTypes.bool,
    params: PropTypes.object,
    role_id: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    addUser: PropTypes.func,
    getUsers: PropTypes.func,
    resetUsers: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        users: state.users.data,
        fetching: state.users.fetching,
        reset: state.users.reset,
        error: state.users.error,
        params: state.users.params,
        self: state.user.self,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getUsers, getUserExclusions, resetUsers }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserDropdownContainer));