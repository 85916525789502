import React from 'react';

import { UserHasPermissionContainer } from '../containers/User';
import { CustomersContainer } from '../containers/Customer';
import { PageWithMainNav } from '../components/Layout';
import { PageTitle } from '../components/Text';
import { MetaData, ScrollToTopOnMount } from '../components/Utils';
import { CornerButton } from '../components/Buttons';

const Customers = () => {
    return(
        <PageWithMainNav>
            <ScrollToTopOnMount />
            <MetaData vars={{ title: 'Customers' }} />

            <PageTitle>Customers</PageTitle>
            <CustomersContainer />

            <UserHasPermissionContainer permissions={'customers:write'}>
                <CornerButton
                    icon={'/svg/icon-plus.svg'}
                    to={'/customers/new'}
                />
            </UserHasPermissionContainer>
        </PageWithMainNav>
    );
}

export default Customers;