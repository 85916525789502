const live = 'https://wricms.com';
const local = 'http://localhost:3000';

const siteUrl = process.env.NODE_ENV === "production" ? live : local;
const serverUrl = process.env.NODE_ENV === "production" ? siteUrl : "http://localhost:25000";

const api = process.env.NODE_ENV === "production" ? "https://api.wricms.com/api" : "https://staging.api.wricms.com/api";
// const api = 'http://localhost:8888/wri-cms-api/public/api';

export default {
    url: siteUrl,
    serverUrl,
    api,
    title: 'WRi',
    gaCode: 'UA-129678681-1',
    cookieName: 'wri-usage-cookie',
    userCookieName: 'wri-user-cookie',
    userRefreshCookieName: 'wri-user-refresh-cookie',
    paymentMethods: [
        {
            label: 'Credit card',
            value: 'credit-card',
        },
        {
            label: 'Cash',
            value: 'cash'
        },
        {
            label: 'Pre-paid card',
            value: 'pre-paid-card'
        },
        {
            label: 'PayPal',
            value: 'paypal'
        },
        {
            label: 'Bank deposit',
            value: 'bank-deposit'
        },
        {
            label: 'Covert card',
            value: 'covert-card'
        },
    ],
    acceptedFileTypes: [
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/pdf',
        'application/vnd.ms-powerpoint',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/plain',
        'image/jpeg',
        'image/png',
        '.doc',
        '.docx',
        '.csv',
    ],
    maxFileSize: 13107200 // 100MB
}
