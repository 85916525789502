import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import uuid from 'uuid/v4';

import { 
	Button,
	IconButton,
 } from '../Buttons';
import {
	Input,
	Select,
	TextArea,
	ToggleSwitch,
} from '../Forms';
import { SROnly } from '../Utils';
import { DataTable } from '../Tables';
import { InvoiceFooter } from './';

const Controls = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 10px;

	input,
	select {
		margin-left: 12px;
	}

	input {
		width: 120px;
	}

	>div {
		>*+* {
			margin-left: 30px;
		}

		&:first-child {
			display: flex;

			>div {
				display: flex;
				align-items: center;
			}
		}
	}

	@media (max-width: 589px) {
		>div {
			>*+* {
				margin: 15px 0 0 0;
			}

			&:first-child {
				flex-wrap: wrap;

				>div {
					width: 100%;
				}

				label {
					width: 68px;
				}
			}
		}
	}
`;

const EditCell = styled.div`
	display: flex;
	align-items: center;
	width: 100%;

	>*+* {
		margin-left: 10px;
	}
`;

const makeEmptyRow = () => ({
	id: uuid(),
	text: '',
	quantity: 1,
	unitPrice: 0,
	vat: false,
});

class InvoiceTable extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: props.data.length ? props.data : [],
			fetching: props.fetching,
			editing: [],
			poNumber: props.poNumber || '',
			currency: 'GBP'
		}
	}

	componentDidUpdate(prevProps) {
		if(!this.state.data.length && prevProps.fetching && !this.props.fetching) {
			this.setState({
				data: this.props.data,
				fetching: false,
			});
		}
	}

	editRow = (id) => {
		this.setState(state => ({
			editing: [...state.editing, id]
		}));
	}

	saveRow = (id) => {
		this.setState(state => ({
			editing: state.editing.filter(i => i !== id)
		}));
	}

	removeRow = (id) => {
		this.setState(state => ({
			data: state.data.filter(row => row.id !== id),
			editing: state.editing.filter(i => i !== id),
		}));
	}

	addRow = () => {
		const newRow = makeEmptyRow();

		this.setState(state => ({
			data: [
				newRow,
				...state.data,
			],
			editing: [
				newRow.id,
				...state.editing
			]
		}));
	}

	handleRowFieldChange = (e, key, id) => {
		this.setState({
			data: this.state.data.map((row) => {
				if(row.id !== id) return row;

				return {
					...row,
					[key]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
				}
			})
		})
	}

	columns = [
        {
            Header: 'Description',
            accessor: 'text',
			Cell: ({ value, original }) => {
				if(this.state.editing.includes(original.id)) {
					return(
						<TextArea 
							value={value}
							onChange={(e) => this.handleRowFieldChange(e, 'text', original.id)}
						/>
					);
				}
				
				return <p style={{ whiteSpace: 'normal' }}>{ value }</p>
			},
			resizable: false,
			minWidth: 275,
			maxWidth: 375,
            sortable: true,
        },
        {
            Header: "Quantity",
            accessor: 'quantity',
			Cell: ({ value, original }) => {
				if(this.state.editing.includes(original.id)) {
					return(
						<Input 
							value={value}
							type="number"
							min={0}
							onChange={(e) => this.handleRowFieldChange(e, 'quantity', original.id)}
						/>
					);
				}
				
				return <p>{ value }</p>
			},
            maxWidth: 130,
            sortable: true,
        },
        {
            Header: "Unit price",
            accessor: 'unit_price',
			Cell: ({ value, original }) => {
				if(this.state.editing.includes(original.id)) {
					return(
						<Input 
							value={value}
							type="number"
							min={0}
							onChange={(e) => this.handleRowFieldChange(e, 'unit_price', original.id)}
						/>
					);
				}
				
				return <p>{ value ? Number(value).toFixed(2) : '' }</p>
			},
            maxWidth: 140,
            sortable: true,
        },
        {
            Header: "VAT",
			accessor: 'vat',
			Cell: ({ value, original }) => {
				return(
					<ToggleSwitch 
						label={'Toggle VAT'}
						checked={value}
						onChange={(e) => this.handleRowFieldChange(e, 'vat', original.id)}
						disabled={!this.state.editing.includes(original.id)}
						hideLabel
					/>
				)
			},
            maxWidth: 130,
            sortable: true,
        },
        {
            Header: () => (
				<p>Amount { this.state.currency }</p>
			),
			Cell: ({ original }) => {
				if(isNaN(original.unit_price)) return <p></p>;

				let amount = original.quantity * original.unit_price;
				if(original.vat) {
					amount = (original.unit_price * 1.2) * original.quantity;
				}

				return <p>{ amount.toFixed(2) }</p>
			},
            maxWidth: 130,
            sortable: true,
        },
        {
			id: 'edit',
            Header: () => null,
            accessor: () => null,
			Cell: ({ original }) => {
				const editing = this.state.editing.includes(original.id);

				return(
					<EditCell>
						<Button 
							blue={!editing}
							green={editing}
							onClick={() => {
								if(!editing) {
									this.editRow(original.id);
								} else {
									this.saveRow(original.id);
								}
							}}
						>{ editing ? 'Save' : 'Edit' }</Button>

						{ editing ? (
							<IconButton
								type={'danger'}
								onClick={() => this.removeRow(original.id)}
								aria-labelledby={`remove-row-${original.id}`}
								size="sm"
							>
								<SROnly id={`remove-row-${original.id}`}>Remove row</SROnly>
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M268 416h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12zM432 80h-82.41l-34-56.7A48 48 0 0 0 274.41 0H173.59a48 48 0 0 0-41.16 23.3L98.41 80H16A16 16 0 0 0 0 96v16a16 16 0 0 0 16 16h16v336a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128h16a16 16 0 0 0 16-16V96a16 16 0 0 0-16-16zM171.84 50.91A6 6 0 0 1 177 48h94a6 6 0 0 1 5.15 2.91L293.61 80H154.39zM368 464H80V128h288zm-212-48h24a12 12 0 0 0 12-12V188a12 12 0 0 0-12-12h-24a12 12 0 0 0-12 12v216a12 12 0 0 0 12 12z"/></svg>
							</IconButton>
						) : null }
					</EditCell>
				);
			},
            width: 150,
            sortable: true,
        },
	];
	
	render() {
		const subtotal = this.state.data.reduce((acc, curr) => {
			if(isNaN(curr.quantity) || isNaN(curr.unit_price)) return acc;

			return acc + curr.unit_price * curr.quantity;
		}, 0);
		const vat = this.state.data.reduce((acc, curr) => {
			if(!curr.vat) return acc;

			return acc + (curr.unit_price * curr.quantity) * 20 * .01;
		}, 0);

		return (
			<Fragment>
				<Controls>
					<div>
						<div>
							<label htmlFor="po-number">PO number:</label>
							<Input 
								id="po-number"
								value={this.state.poNumber}
								onChange={e => {
									this.setState({
										poNumber: e.target.value
									});
								}}
							/>
						</div>

						<div>
							<label htmlFor="currency">Currency:</label>
							<Select 
								id="currency"
								value={this.state.currency}
								options={[
									{
										label: 'GBP',
										value: 'GBP',
									},
									{
										label: 'EUR',
										value: 'EUR',
									},
								]}
								onChange={e => {
									this.setState({
										currency: e.target.value
									});
								}}
							/>
						</div>
					</div>

					<IconButton
						onClick={this.addRow}
						style={{
							marginLeft: 'auto'
						}}
					>
						<p>add invoice item</p>
						<svg width="328.91" height="328.91" viewBox="0 0 328.911 328.911" xmlns="http://www.w3.org/2000/svg"><path d="M310.2 18.71C297.736 6.242 282.651.007 264.952.007h-201c-17.703 0-32.79 6.235-45.253 18.704C6.233 31.177-.002 46.261-.002 63.96v200.99c0 17.515 6.232 32.552 18.701 45.11 12.467 12.566 27.553 18.843 45.253 18.843h201c17.699 0 32.777-6.276 45.248-18.843 12.47-12.559 18.705-27.596 18.705-45.11V63.96c0-17.699-6.245-32.783-18.712-45.25zm-17.837 246.25c0 7.614-2.673 14.089-8.001 19.414-5.324 5.332-11.799 7.994-19.41 7.994h-201c-7.614 0-14.082-2.662-19.414-7.994-5.33-5.325-7.992-11.8-7.992-19.414v-201c0-7.613 2.662-14.086 7.992-19.414 5.327-5.327 11.8-7.994 19.414-7.994h201c7.61 0 14.086 2.663 19.41 7.994 5.325 5.328 7.994 11.801 7.994 19.414v201z"/><path d="M246.68 146.19h-63.953V82.237c0-2.667-.855-4.854-2.573-6.567-1.704-1.714-3.895-2.568-6.564-2.568h-18.271c-2.667 0-4.854.854-6.567 2.568-1.714 1.713-2.568 3.903-2.568 6.567v63.954H82.23c-2.664 0-4.857.855-6.567 2.568-1.711 1.713-2.568 3.903-2.568 6.567v18.271c0 2.666.854 4.855 2.568 6.563 1.712 1.708 3.903 2.57 6.567 2.57h63.954v63.953c0 2.666.854 4.855 2.568 6.563 1.713 1.711 3.903 2.566 6.567 2.566h18.271c2.67 0 4.86-.855 6.564-2.566 1.718-1.708 2.573-3.897 2.573-6.563v-63.952h63.953c2.662 0 4.853-.862 6.563-2.57 1.712-1.708 2.563-3.897 2.563-6.563v-18.271c0-2.664-.852-4.857-2.563-6.567-1.71-1.711-3.901-2.57-6.563-2.57z"/></svg>
					</IconButton>
				</Controls>

				<DataTable
					data={this.state.data}
					columns={this.columns}
					noCellHeight={true}
					noCellBorder={true}
					rowAlign="flex-start"
					rightAlignLastCol
					fetching={this.state.fetching}
					loadInside={true}
				/>

				<InvoiceFooter 
					subtotal={subtotal.toFixed(2)}
					vat={vat.toFixed(2)}
					total={(subtotal + vat).toFixed(2)}
					onSubmit={() => this.props.onSubmit({
						po: this.state.poNumber,
						currency: this.state.currency,
						lines: this.state.data.map(({ id, ...fields }) => fields)
					})}
				/>
			</Fragment>
		);
	}
}

InvoiceTable.propTypes = {
	poNumber: PropTypes.number,
	data: PropTypes.arrayOf(
		PropTypes.shape({
			text: PropTypes.string,
			quantity: PropTypes.number,
			unit_price: PropTypes.number,
			vat: PropTypes.bool,
			amount: PropTypes.number,
		})
	),
	fetching: PropTypes.bool,
	onSubmit: PropTypes.func,
}

export default InvoiceTable;