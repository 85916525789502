import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { UserDropdownContainer } from '../../containers/User';

import {
    AssignLoggedTime,
    FormGroup,
    FormItemGroup,
    FormItem,
    DateTimeInput,
    DateTimeWrap,
    TimeSwitch,
    TextArea,
    FileUploader,
} from '../Forms';
import { ModalButtons } from '../Modals';
import { Button, ButtonListItem } from '../Buttons';
import { Link } from '../Text';
import { Grid } from '../Layout';

import {
    getEventKey,
    getEventVal,
    addStaffToState,
    removeStaffFromState,
    updateDateState,
    updateTimeState,
    addFileToState,
    removeFileFromState,
    filterDocumentsAndAddToObject,
} from '../../utils/dataMap';
import { 
    dateTimeStringNow, 
    getLoggedTime,
    convertLoggedTimeToArray,
} from '../../utils/dates';
import { haveDatesChanged } from '../../utils/validation';

class EditSiteVisitForm extends Component {
    constructor(props) {
        super(props);

        const logged_time = getLoggedTime(props.logged_time);

        this.state = {
            hasChanged: false,
            datesChanged: false,
            id: (props.site_visit && props.site_visit.id) || null,
            form: {
                start_date: {
                    date: props.site_visit ? props.site_visit.start_date.split(' ')[0] : format(new Date(), 'YYYY-MM-DD'),
                    time: props.site_visit ? props.site_visit.start_date.split(' ')[1] : '00:00:00',
                    formatted: props.site_visit ? props.site_visit.start_date : dateTimeStringNow('am')
                },
                end_date: {
                    date: props.site_visit ? props.site_visit.end_date.split(' ')[0] : format(new Date(), 'YYYY-MM-DD'),
                    time: props.site_visit ? props.site_visit.end_date.split(' ')[1] : '23:59:59',
                    formatted: props.site_visit ? props.site_visit.end_date : dateTimeStringNow('pm')
                },
                address: (props.site_visit && props.site_visit.address) || '',
                notes: (props.site_visit && props.site_visit.notes) || '',
                staff: (props.site_visit && props.site_visit.users) || [],
                documents: props.documents || [],
                ...logged_time
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // If the data was saved, with no errors, clear the form.
        if((prevProps.saving && !this.props.saving) && !this.props.error) {
            this.clearForm();
        }

        if(haveDatesChanged(prevState.form, this.state.form)) {
            this.setState({
                datesChanged: true
            });
        }
    }

    clearForm = () => {
        this.setState({
            id: null,
            datesChanged: false,
            form: {
                start_date: {
                    date: format(new Date(), 'YYYY-MM-DD'),
                    time: '00:00:00',
                    formatted: dateTimeStringNow('am')
                },
                end_date: {
                    date: format(new Date(), 'YYYY-MM-DD'),
                    time: '23:59:59',
                    formatted: dateTimeStringNow('pm')
                },
                address: '',
                notes: '',
                staff: [],
                documents: [],
                logged_time: '',
                additional_logged_time: ''
            }
        })
    }

    handleSave = (stayOpen) => {
        let {
            id,
            form,
        } = this.state;

        let data = {
            site_visit: {
                start_date: form.start_date.formatted,
                end_date: form.end_date.formatted,
                address: form.address,
                notes: form.notes,
                user_ids: form.staff.map(staff => staff.id)
            },
            logged_time: convertLoggedTimeToArray(form),
        };

        // If there are already documents assigned to the case, make sure we don't try post them again.
        data = filterDocumentsAndAddToObject(form.documents, this.props.documents, data);

        this.props.onSave(id, data, stayOpen);
    }

    handleChange = (e, k) => {
        let key = k || getEventKey(e);

        let val = k && k.includes('logged_time') ? e : getEventVal(e);

        this.setState({
            hasChanged: true,
            form: {
                ...this.state.form,
                [key]: val,
            },
        });
    }

    removeStaff = (user) => {
        this.setState(state => removeStaffFromState(state, user.id));

        // DELETE request to remove the staff if this action exists and the user is assigned to it.
        if(this.state.id && this.props.site_visit.users.find(u => u.id === user.id)) {
            this.props.onStaffRemove(this.state.id, user.id);
        }
    }

    removeFile = (index) => {
        let document = this.state.form.documents[index];

        this.setState(state => removeFileFromState(state, index));

        // If the document has an ID, then it's already been uploaded, so we need to actually delete it.
        if(document && document.id) {
            this.props.onFileRemove(document.id);
        }
    }

    render() {
        let { 
            datesChanged,
            hasChanged,
            form: {
                start_date,
                end_date,
                documents,
                notes,
                staff,
                address,
                logged_time,
                additional_logged_time,
            }
        } = this.state;

        let {
            onCancel,
            saving,
            handleRejectedFiles
        } = this.props;

        let requiredPopulated = start_date.date && start_date.time && end_date.date && start_date.time && address && staff && staff.length;

        return(
            <Fragment>
                <FormGroup center={true} saving={saving}>
                    <FormItemGroup labelWidth={"150px"} wide={true}>
                        <FormItem
                            label={"Start Date"}
                            id={'site_visit_start_date'}
                            minWidthInput={true}
                            required={true}
                        >
                            <DateTimeWrap>
                                <DateTimeInput
                                    id={"site_visit_start_date"}
                                    value={start_date.date}
                                    handleChange={val => this.setState(state => updateDateState(state, val, 'start_date'))}
                                />
                                <TimeSwitch
                                    onChange={val => this.setState(state => updateTimeState(state, val, 'start_date'))}
                                    value={start_date.time}
                                    prefix={"start"}
                                />
                            </DateTimeWrap>
                        </FormItem>

                        <FormItem
                            label={"End Date"}
                            id={'site_visit_end_date'}
                            minWidthInput={true}
                            required={true}
                        >
                            <DateTimeWrap>
                                <DateTimeInput
                                    id={"site_visit_end_date"}
                                    value={end_date.date}
                                    handleChange={val => this.setState(state => updateDateState(state, val, 'end_date'))}
                                />
                                <TimeSwitch
                                    onChange={val => this.setState(state => updateTimeState(state, val, 'end_date'))}
                                    value={end_date.time}
                                    prefix={"end"}
                                />
                            </DateTimeWrap>
                        </FormItem>

                        <FormItem
                            label={"Address"}
                            id={'address'}
                            align={'top'}
                            required={true}
                        >
                            <TextArea
                                value={address}
                                id={'address'}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem
                            label={"Staff"}
                            id={'staff'}
                            align={'top'}
                            required={true}
                        >
                            <Grid cols={[[1, 0], [2, 370], [3, 521]]} gap={'10px'}>
                                { staff.map(user => (
                                    <ButtonListItem
                                        onRemove={() => this.removeStaff(user)}
                                        showControls={true}
                                        key={`investigator${user.id}`}
                                        title={user.name}
                                    >{ user.name }</ButtonListItem>
                                )) }
                                <UserDropdownContainer
                                    addUser={staff => this.setState((state) => addStaffToState(state, staff))}
                                    exclude={staff.map(user => user.id)}
                                    available_from={start_date.formatted}
                                    available_to={end_date.formatted}
                                    invalid={datesChanged && !staff.length}
                                />
                            </Grid>
                        </FormItem>

                        <FormItem label={"Logged Time"} id={'logged_time'}>
                            <AssignLoggedTime
                                value={logged_time.value}
                                id={'logged_time'}
                                onChange={e => this.handleChange(e, 'logged_time')}
                            />
                        </FormItem>

                        <FormItem label={"Additional Logged Time"} id={'additional_logged_time'}>
                            <AssignLoggedTime
                                value={additional_logged_time.value}
                                id={'additional_logged_time'}
                                onChange={e => this.handleChange(e, 'additional_logged_time')}
                            />
                        </FormItem>

                        <FormItem label={"Notes"} id={'notes'} align={'top'}>
                            <TextArea
                                value={notes}
                                id={'notes'}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem label={"Associated files"} id={'files'} align={'top'}>
                            <FileUploader
                                files={documents}
                                handleAcceptedFiles={file => this.setState(state => addFileToState(state, file))}
                                handleRejectedFiles={handleRejectedFiles}
                                onRemove={this.removeFile}
                                cols={2}
                            />
                        </FormItem>
                    </FormItemGroup>
                </FormGroup>

                <ModalButtons pl={'150px'} wide={true}>
                    <Button
                        blue={true}
                        small={true}
                        onClick={() => this.handleSave(false)}
                        disabled={saving || !hasChanged || !requiredPopulated}
                    >Save</Button>
                    <Button
                        small={true}
                        blue={true}
                        onClick={() => this.handleSave(true)}
                        disabled={saving || !hasChanged || !requiredPopulated}
                    >Save and add another</Button>
                    { this.state.id &&
                        <Button
                            red={true}
                            small={true}
                            onClick={() => this.props.onDelete(this.state.id)}
                        >Delete</Button>
                    }
                    <Link
                        el={'button'}
                        onClick={onCancel}
                        disabled={saving}
                    >Cancel</Link>
                </ModalButtons>
            </Fragment>
        );
    }
}

EditSiteVisitForm.propTypes = {
    onSave: PropTypes.func
}

export default EditSiteVisitForm;