import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactSVG from 'react-svg';

const SVG = styled(ReactSVG)`
    display: flex;
    align-items: center;
    justify-content: center;
    
    >div {
        display: flex;
        align-items: flex-start;
    }
`;

SVG.propTypes = {
    path: PropTypes.string,
    onInjected: PropTypes.func,
    svgStyle: PropTypes.object,
}

export default SVG;