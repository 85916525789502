import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { PageTitle, Title } from '../components/Text';
import { PageWithFloatingSidebar, PageTitleWrap } from '../components/Layout';
import { FormGroup } from '../components/Forms';
import { BackButton, Button } from '../components/Buttons';
import { Error, ErrorBoundary, ScrollToTopOnMount } from '../components/Utils';

import {
    CaseMenuContainer,
    CaseDetailsContainer,
    CaseTradersContainer,
    CaseVehiclesContainer,
    CaseActivityLogContainer,
    CaseDocumentsContainer,
    CaseTestPurchasesContainer,
    CaseSiteVisitsContainer,
    CaseRaidsContainer,
    CompleteCaseContainer,
    SourcesDropdownContainer,
    CrimeTypeContainer,
} from '../containers/Case';
import { UserDropdownContainer } from '../containers/User';
import { resetCase } from '../actions/cases';

class EditCase extends Component {
	componentDidMount() {
		this.props.history.block((location) => {
			this.goingToInvoice = location.pathname === `/case/${this.props.match.params.id}/invoice`;
		})
	}

    componentWillUnmount() {
		// Clear item and reset fetching to false on unmount if going anywhere but the invoice page
		if(!this.goingToInvoice) {
			this.props.resetCase();
		}
    }

    render() {
        return(
            <PageWithFloatingSidebar render={() => (
                <Fragment>
                    <ScrollToTopOnMount />

                    <BackButton
                        label={"Back to cases"}
                        to={'/cases'}
                    />

                    <Title>Jump to case action</Title>
                    <CaseMenuContainer />
                </Fragment>
            )}>

                <PageTitleWrap>
                    <PageTitle>Edit case</PageTitle>
                    <BackButton
                        to={'/cases'}
                        small={true}
                        showLabel={true}
                        label={'Return to cases'}
                    />
                </PageTitleWrap>

                <ErrorBoundary render={() => <Error overlay={false} />}>
                    <CaseDetailsContainer
                        sourcesComponent={SourcesDropdownContainer}
                        crimeTypeComponent={CrimeTypeContainer}
                        userComponent={UserDropdownContainer}
                    />
                </ErrorBoundary>
                <CaseActivityLogContainer />
                <CaseDocumentsContainer />
                <CaseTradersContainer />
                <CaseVehiclesContainer />
                <CaseSiteVisitsContainer />
                <CaseTestPurchasesContainer />
                <CaseRaidsContainer />
                <CompleteCaseContainer 
					render={({ completed, openModal, unarchive }) => {
						return(
							<FormGroup
								id={'resolve-case'}
								border={'top'}
							>
								<Button
									chunkyish={true}
									red={true}
									type="button"
									onClick={() => {
										if(completed) {
											unarchive();
										} else {
											openModal();
										}
									}}
									style={{
										display: 'table',
										margin: '25px auto',
										width: '190px',
										float: 'none',
									}}
								>{ completed ? 'Unarchive' : 'Archive' } case</Button>
							</FormGroup>
						);
					}}
				/>
            </PageWithFloatingSidebar>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ resetCase }, dispatch);

export default withRouter(connect(
    null,
    mapDispatchToProps
)(EditCase));

// {/*<DragDropContextProvider backend={HTML5Backend}>*/}