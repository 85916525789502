import React from 'react';
import styled from 'styled-components';
import smoothScroll from 'smoothscroll';

import { Button } from '../Buttons';

import { listPlain } from '../../utils/style';

const StyledNav = styled.nav`
    ul {
        ${listPlain()}
    }
    
    li {
        +li {
            margin-top: 10px;
        }
    }
    
    button {
        width: 100%;
        text-align: left;
    }
`;

const goTo = (anchor) => {
    const target = document.querySelector(anchor);

    if(!target) return;

    smoothScroll(target);
}

const AnchorNav = ({ items }) => (
    <StyledNav>
        <ul>
            { items.map((item, index) => (
                <li key={`anchorNav${index}`}>
                    <Button onClick={() => goTo(item.id)}>{ item.label }</Button>
                </li>
            )) }
        </ul>
    </StyledNav>
);

export default AnchorNav;