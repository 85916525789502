import React from 'react';

import { DragVehiclesTarget, DragVehiclesCard } from './components';
import { CardButton } from '../../Cards';
import { DragContext, DragArea } from '../../Drag';

import { reorderArray } from '../../../utils/dataMap';

class DragVehicles extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            items: props.items,
        }
    }

    static getDerivedStateFromProps(props, state) {
        let propItemsJSON = JSON.stringify(props.items),
            stateItemsJSON = JSON.stringify(state.items);

        if((!props.items || props.items.length === state.items.length) && propItemsJSON === stateItemsJSON) return null;

        return {
            items: props.items
        }
    }

    handleMove = (id, to) => {
        let newItems = this.state.items,
            oldIndex = newItems.findIndex(item => item.id === id);

        newItems = reorderArray(oldIndex, to, newItems);

        this.setState({
            items: newItems
        });

        this.props.onMove(newItems);
    }

    render() {
        let { items } = this.state;

        return(
            <DragArea>
                { items.map((item, i) => (
                    <DragVehiclesTarget index={i} key={item.id}>
                        <DragVehiclesCard
                            {...item}
                            index={i}
                            onIndexChange={this.handleMove}
                            onDelete={this.props.onDelete}
                            onClick={this.props.onClick}
                        />
                    </DragVehiclesTarget>
                ))}
                <CardButton
                    label={'Add an associated vehicle'}
                    onClick={this.props.onAddNew}
                />
            </DragArea>
        );
    }
}

export default DragContext(DragVehicles);