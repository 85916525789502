import styled from 'styled-components';

export default styled.div`
    position: relative;
    padding: 0 43px 15px;
    background: ${({ theme }) => theme.greyLightest};
    border-bottom: ${({ theme }) => theme.border.main};

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        border-radius: ${({ theme }) => theme.radius.small};
        background: ${({ theme }) => theme.greenDark};
        z-index: 2;
    }
    
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: calc(100% - 40px);
        border-left: ${({ theme }) => theme.border.main};
        border-right: ${({ theme }) => theme.border.main};
        z-index: 1;
    }
    
    >* {
        position: relative;
        z-index: 3;
    }
`;