import React from 'react';
import system from 'system-components';
import styled from 'styled-components';

const SmallEl = system({
    is: 'small'
});

const SmallWrap = styled(SmallEl)`
    display: inline-block;
    font-weight: 300;
    font-size: ${({ theme }) => theme.fontSize.bodySm};
    line-height: 24px;
    color: ${({ theme }) => theme.darkText};
    
    strong {
        font-weight: 400;
    }
`;

const Small = ({ is, children, style }) => (
    <SmallWrap is={is} style={style}>{ children }</SmallWrap>
);

export default Small;