import styled from 'styled-components';

import { TH, TD } from './TableCell';

export default styled.tr`
    &:nth-of-type(n+2) {
        border-top: ${({ theme }) => theme.border.main};
    }
    
    thead & {
        border-bottom: ${({ theme }) => theme.border.main};
        
        ${TH} {
            &:first-of-type {
                width: 190px;
            }
        }
    }
    
    thead thead & {
        border-bottom: 0;
        
        ${TH} {
            &:first-of-type {
                width: auto;
            }
        }
    }
    
    tbody & {
        ${TD} {
            &:first-of-type {
                padding-top: 10px;
                vertical-align: top;
                background: ${({ theme }) => theme.greyLightest};
            }
        }
    }
    
    ${({ deleting, theme }) => deleting && `
        opacity: ${theme.opacity.inactive};
    `}
`;