import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import { Link } from '../Text';
import { SROnly, SVG } from '../Utils';
import { FloatingSidebar } from '../Layout';

const styles = css`
    display: ${({ small }) => small ? 'flex' : 'table'};
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    text-decoration: none;
    font-size: ${({ theme }) => theme.fontSize.bodyMd};
    
    svg {
        width: ${({ small }) => small ? '12px' : '30px'};
        height: auto;
        transform: rotate(180deg);
    }
    
    polygon {
        fill: ${({ theme }) => theme.blue};
        transition: fill ${({ theme }) => theme.duration.quick};
    }
    
    span {
        margin-left: 10px;
        
    }
    
    &:hover {
        polygon {
            fill: ${({ theme }) => theme.blueLighter};
        }
    }
    
    &:focus {
        outline: 0;
    }
    
    ${FloatingSidebar} & {
        margin-bottom: 40px;
    }
`;

const StyledLink = styled(Link)`${styles}`;
const StyledButton = styled.button`${styles}`;


const BackButton = ({ label, showLabel, withIcon, small, el, ...props }) => {

    let El = StyledLink;
    if(el === 'button') {
        El = StyledButton;
    }

    return(
        <El small={small ? 1 : 0} {...props}>
            { withIcon && <SVG path={"/svg/icon-chevron-acute.svg"} /> }
            { !showLabel ? <SROnly>{ label }</SROnly> : <span>{ label }</span> }
        </El>
    );
}

BackButton.defaultProps = {
    label: 'Back',
    showLabel: false,
    withIcon: true,
}

BackButton.propTypes = {
    small: PropTypes.bool,
    showLabel: PropTypes.bool,
    label: PropTypes.string,
}

export default BackButton;