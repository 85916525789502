import React from 'react';

import { PageWithMainNav } from '../components/Layout';
import { PageTitle } from '../components/Text';
import { CornerButton } from '../components/Buttons';
import { ScrollToTopOnMount, MetaData } from '../components/Utils';

import { UserManagementContainer, UserHasPermissionContainer } from '../containers/User';

const UserManagement = () => {
    return(
        <PageWithMainNav>
            <ScrollToTopOnMount />
            <MetaData vars={{ title: 'Users' }} />

            <PageTitle>Users</PageTitle>
            <UserManagementContainer />

            <UserHasPermissionContainer permissions={'users:write'}>
                <CornerButton
                    icon={'/svg/icon-plus.svg'}
                    to={'/users/new'}
                />
            </UserHasPermissionContainer>
        </PageWithMainNav>
    );
}

export default UserManagement;