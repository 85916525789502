import styled from 'styled-components'

const PageTitle = styled.h1`
    margin: 0 0 25px;
    text-transform: uppercase;
    color: ${({ theme }) => theme.blueLight};
    font-size: 36px;
    line-height: 42px;
    font-weight: 700;
    letter-spacing: 6px;
    
    @media screen and (max-width: 700px) {
        font-size: 28px;
        line-height: 32px;
        letter-spacing: 3px;
    }
`;

export default PageTitle;