import React, { Fragment } from 'react';
import styled from 'styled-components';

import { settings } from '../../config';
import { screenReader } from '../../utils/style';

const StyledInput = styled.input`
    ${screenReader()}
    
    +label {
        cursor: pointer;
        font-size: ${({ theme }) => theme.fontSize.bodyMd};
    }
`;

const fileTypes = settings.acceptedFileTypes.join(',');

const FileInput = ({ id, label, onChange }) => {
    return(
        <Fragment>
            <StyledInput
                type="file"
                id={`fileInput${id}`}
                onChange={onChange}
                accept={fileTypes}
                multiple
            />
            <label htmlFor={`fileInput${id}`}>{ label }</label>
        </Fragment>
    );
}

FileInput.defaultProps = {
    label: 'Attach Files',
}

export default FileInput;