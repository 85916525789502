import React from 'react';
import { format, isAfter } from 'date-fns';

import {
    CardDetails,
    GreyCard
} from '../Cards';
import { Link } from '../Text';
import { Button } from '../Buttons';
import { getLoggedTimeString } from '../../utils/textTransforms';

const SiteVisitCard = ({ site_visit: { id, booked_by, start_date, end_date, mileage, products_present, trader, status }, documents, logged_time, onEdit, onComplete, onView }) => {
    let location = trader && trader.business_address && trader.business_address.length ? trader.business_address[0] : null;

    start_date = format(start_date, 'DD/MM/YYYY HH:mm');

    const loggedTimeString = getLoggedTimeString(logged_time);

    let isCompleted = status === 'completed',
        needsCompleting = isAfter(new Date(), end_date) && !isCompleted;

    if(isCompleted) {
        onEdit = onView;
        onComplete = onView;
    }

    return(
        <GreyCard>
            <CardDetails
                title={`Site Visit ${start_date}`}
                titleStyle={'rounded blue'}
                onClick={() => onEdit(id)}
            >
                { location && <p>Location: { location }</p> }
                <p>by user: { booked_by.name }</p>
            </CardDetails>

            <CardDetails onClick={() => onEdit(id)}>
                <p>Time Logged: { loggedTimeString }</p>
                <p>Mileage Logged: { mileage ? `${mileage} miles` : 'N/A' }</p>
            </CardDetails>

            <CardDetails
                title={'Associated files:'}
                documents={true}
                noBackground={true}
                innerBackground={true}
            >
                { documents.map(doc => <GreyCard mini={true} key={`doc${doc.id}`}><a href={doc.url} target={"_blank"} style={{ fontSize: '12px', wordBreak: 'break-all' }}>{ doc.name }</a></GreyCard>) }
                { needsCompleting ?
                    <Button
                        onClick={() => onComplete(id)}
                        blueLight={true}
                        chunkyish={true}
                    >Add Site Visit Outcome</Button>
                    :
                    !isCompleted ?
                        <Link small={true} underline={true} el={'button'} onClick={() => onEdit(id)}>Edit/add details</Link>
                        :
                        <Link small={true} underline={true} el={'button'} onClick={() => onView(id)}>View details</Link>
                }
            </CardDetails>
        </GreyCard>
    );
}

export default SiteVisitCard;