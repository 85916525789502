import { remove, checkAuthentication } from '../utils/api';
import { dispatchErrors } from '../utils/validation';

export const deleteDocument = (id) => {

    return (dispatch) => {

        dispatch({ type: 'DELETE_DOCUMENT' });

        return remove(`/documents/${id}`, null, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then((data) => {
                dispatch({ type: 'DELETE_DOCUMENT_SUCCESS', id, success: 'The document has been deleted.' })
            })
            .catch(err => {
                dispatch({ type: 'DELETE_DOCUMENT_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}