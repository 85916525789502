import { get, checkAuthentication } from '../utils/api';
import { validateParams, dispatchErrors } from '../utils/validation';

export const getTraders = (params) => {

    // Remove any invalid parameters
    let validatedParams = validateParams(['searchQuery', 'sort', 'sortBy', 'page', 'limit'], params);

    return (dispatch) => {

        dispatch({ type: 'FETCH_TRADERS' });

        return get('/traders', validatedParams, true)
        // .catch(checkAuthentication.bind(null, dispatch))
            .catch(checkAuthentication.bind(null, dispatch))
            .then((data) => {
                dispatch({ type: 'FETCH_TRADERS_SUCCESS', traders: data, params: validatedParams })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_TRADERS_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const resetTraders = () => {

    return (dispatch) => {

        dispatch({ type: 'RESET_TRADERS' });

    }

}