import React from 'react';
import styled, { css } from 'styled-components';

const StyledDiv = styled.div``;

const activeStyles = css`
    background: ${({ theme }) => theme.red};
    color: white!important;
`;

const excludedStyles = css`
    color: white!important;
    // ${({ active, theme }) => active ? `background: ${theme.redAlt}` : `background: ${theme.blueLight}` };
    ${({ active, theme }) => active ? `background: ${theme.red}` : `background: ${theme.redAlt}` };
    
    &:hover {    
        background: ${({ theme }) => theme.red};
    }
`;

const Half = styled.button`
    width: 50%;
    height: 80px;
    padding: 0;
    border: 0;
    color: ${({ theme }) => theme.white};
    transition: ${({ theme }) => theme.duration.quick};
    cursor: pointer;
    
    &:hover {
        background: ${({ theme }) => theme.red};
    }
    
    ${({ active, excluded }) => active && !excluded && activeStyles};
    ${({ excluded }) => excluded && excludedStyles};
    
    &[disabled] {
        background: ${({ theme }) => theme.greyLighter};
        color: white!important;
        cursor: pointer;
    }
`;

const SplitDayButton = ({ activeAm, excludedAm, disabledAm, activePm, excludedPm, disabledPm, onClick, onMouseEnter, onMouseLeave }) => {
    return(
        <StyledDiv>
            <Half
                onClick={() => onClick('am', excludedAm)}
                onMouseEnter={() => onMouseEnter('am')}
                onMouseLeave={onMouseLeave}
                active={activeAm}
                excluded={excludedAm}
                disabled={disabledAm}
            >AM</Half>
            <Half
                onClick={() => onClick('pm', excludedPm)}
                onMouseEnter={() => onMouseEnter('pm')}
                onMouseLeave={onMouseLeave}
                active={activePm}
                excluded={excludedPm}
                disabled={disabledPm}
            >PM</Half>
        </StyledDiv>
    );
}

export default SplitDayButton;