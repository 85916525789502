import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { PageTitle } from '../components/Text';

const StyledDiv = styled.div`
    max-width: 500px;
    margin: 0 auto;
    padding: 75px 15px 0;
    text-align: center;
    
    h1 {
        margin: 0 0 15px;
    }
    
    p {
        font-size: 16px;
    }
`;

const NotFound = () => (
    <StyledDiv>
        <PageTitle>Page not found</PageTitle>
        <p><Link to={'/cases'}>Return to cases</Link>?</p>
    </StyledDiv>
);

export default NotFound;