import React, { Component, Fragment } from 'react';

import { UserDropdownContainer } from '../../containers/User';
import { PaymentMethodsContainer } from '../../containers/Case';
import {
    FormGroup,
    FormItemGroup,
    FormItem,
    Input,
    DateTimeInput,
    DateTimeWrap,
    TimeInput,
    Tabs,
    TimeSwitch,
    TextArea,
    FileUploader,
} from '../Forms';
import { Grid } from '../Layout';
import { Link } from '../Text';
import { ModalButtons } from '../Modals';
import { ButtonListItem, Button } from '../Buttons';

import { getLoggedTime } from '../../utils/dates';

class ViewTestPurchaseForm extends Component {
    renderInPersonFields = () => {
        let { test_purchase: { address } } = this.props;

        return(
            <Fragment>
                <FormItem label={"Address"} id={'address'} align={'top'}>
                    <TextArea
                        defaultValue={address || ''}
                        onChange={this.handleChange}
                        disabled={true}
                        id={'address'}
                    />
                </FormItem>
            </Fragment>
        );
    }

    renderOnlineFields = () => {
        let {
            test_purchase: {
                website,
                purchase_status,
                delivery_date,
                payment_method,
            }
        } = this.props;

        return(
            <Fragment>
                <FormItem label={"Website Address"} id={'website'}>
                    <Input
                        value={website || ''}
                        id={'website'}
                        type={"text"}
                        disabled={true}
                        minWidth={true}
                    />
                </FormItem>

                <FormItem label={"Purchase Status"} id={'purchase_status'}>
                    <Tabs
                        id={'purchase_status'}
                        name={'purchase_status'}
                        styleType={"blue"}
                        disabled={true}
                        items={[
                            {
                                value: 'out-of-stock',
                                label: 'Out of stock',
                                checked: purchase_status === 'out-of-stock',
                                onChange: this.handleChange,
                                id: 'out-of-stock',
                                disabled: true
                            },
                            {
                                value: 'ordered',
                                label: 'Ordered',
                                checked: purchase_status === 'ordered',
                                onChange: this.handleChange,
                                id: 'ordered',
                                disabled: true
                            },
                        ]}
                    />
                </FormItem>

                { purchase_status === 'ordered' &&
                    <Fragment>
                        <FormItem label={"Test Purchase Delivery"} id={'delivery_date'}>
                            <DateTimeInput
                                value={delivery_date}
                                id={'delivery_date'}
                                handleChange={ value => this.handleChange({ target: { id: 'delivery_date', value } }) }
                                minWidth={true}
                                disabled={true}
                            />
                        </FormItem>

                        <FormItem label={"Payment Method"} id={'payment_method'} minWidthInput={true}>
                            <PaymentMethodsContainer
                                onChange={p => this.handleChange(p, 'payment_method')}
                                addOnType={true}
                                value={payment_method}
                                disabled={true}
                            />
                        </FormItem>
                    </Fragment>
                }
            </Fragment>
        );
    }

    render() {
        let {
            onCancel,
            onFileRemove,
            documents,
            logged_time,
            test_purchase: {
                start_date,
                end_date,
                type,
                users,
                authorized_by_user,
                item,
                cost,
            }
        } = this.props;

        // Separate additional_logged_time from logged_time
        logged_time = getLoggedTime(logged_time);

        return(
            <Fragment>
                <FormGroup center={true}>
                    <FormItemGroup labelWidth={"150px"} wide={true}>
                        <FormItem label={"Purchase Type"} id={'type'}>
                            <Tabs
                                id={'type'}
                                name={'type'}
                                items={[
                                    {
                                        value: 'online',
                                        label: 'Online',
                                        checked: type === 'online',
                                        onChange: this.handleChange,
                                        id: 'online',
                                        disabled: true
                                    },
                                    {
                                        value: 'in-person',
                                        label: 'In person',
                                        checked: type === 'in-person',
                                        onChange: this.handleChange,
                                        id: 'in-person',
                                        disabled: true
                                    }
                                ]}
                                styleType={"blue"}
                                disabled={true}
                            />
                        </FormItem>

                        { type &&
                            <FormItem label={"Start Date"} id={'test_purchase_start_date'} minWidthInput={true}>
                                <DateTimeWrap>
                                    <DateTimeInput
                                        id={"test_purchase_start_date"}
                                        value={start_date.split(' ')[0]}
                                        disabled={true}
                                    />
                                    <TimeSwitch
                                        value={start_date.split(' ')[1]}
                                        prefix={"start"}
                                        disabled={true}
                                    />
                                </DateTimeWrap>
                            </FormItem>
                        }

                        { type === 'in-person' &&
                            <FormItem label={"End Date"} id={'test_purchase_end_date'} minWidthInput={true}>
                                <DateTimeWrap>
                                    <DateTimeInput
                                        id={"test_purchase_end_date"}
                                        value={end_date.split(' ')[0]}
                                        disabled={true}
                                    />
                                    <TimeSwitch
                                        value={end_date.split(' ')[1]}
                                        prefix={"end"}
                                        disabled={true}
                                    />
                                </DateTimeWrap>
                            </FormItem>
                        }

                        { type &&
                            <Fragment>
                                <FormItem label={"Staff"} id={'staff'} align={'top'}>
                                    <Grid cols={[[1, 0], [2, 370], [3, 521]]} gap={'10px'}>
                                        { users.map(user => (
                                            <ButtonListItem
                                                showControls={false}
                                                key={`investigator${user.id}`}
                                                title={user.name}
                                                disabled={true}
                                            >{ user.name }</ButtonListItem>
                                        )) }
                                    </Grid>
                                </FormItem>

                                <FormItem
                                    label={"Authorised By"}
                                    id={'authorized_by_user'}
                                    minWidthInput={true}
                                >
                                    <UserDropdownContainer
                                        addOnType={true}
                                        value={authorized_by_user}
                                        role_id={2}
                                        disabled={true}
                                    />
                                </FormItem>

                                <FormItem label={"Logged Time"} id={'logged_time'}>
                                    <TimeInput
                                        value={logged_time.logged_time.value}
                                        id={'logged_time'}
                                        minWidth={true}
                                        disabled={true}
                                    />
                                </FormItem>

                                <FormItem label={"Additional Logged Time"} id={'additional_logged_time'}>
                                    <TimeInput
                                        value={logged_time.additional_logged_time.value}
                                        id={'additional_logged_time'}
                                        minWidth={true}
                                        disabled={true}
                                    />
                                </FormItem>
                                <FormItem label={"Item"} id={'item'}>
                                    <Input
                                        value={item || ''}
                                        id={'item'}
                                        minWidth={true}
                                        disabled={true}
                                    />
                                </FormItem>

                                <FormItem label={"Cost"} id={'cost'}>
                                    <Input
                                        value={cost || ''}
                                        id={'cost'}
                                        minWidth={true}
                                        disabled={true}
                                    />
                                </FormItem>
                            </Fragment>
                        }

                        { type === 'online' ? this.renderOnlineFields() : type === 'in-person' ? this.renderInPersonFields() : null }

                        { documents && !!documents.length &&
                            <FormItem label={"Associated files"} id={'files'} align={'top'}>
                                <FileUploader
                                    files={documents}
                                    readOnly={true}
                                    cols={2}
                                    onRemove={onFileRemove}
                                    canRemove={false}
                                />
                            </FormItem>
                        }
                    </FormItemGroup>
                </FormGroup>

                <ModalButtons pl={'150px'} wide={true}>
                    { this.props.test_purchase.id &&
                        <Button
                            red={true}
                            small={true}
                            onClick={() => this.props.onDelete(this.props.test_purchase.id)}
                        >Delete</Button>
                    }
                    <Link el={'button'} onClick={onCancel}>Cancel</Link>
                </ModalButtons>
            </Fragment>
        );
    }
}

export default ViewTestPurchaseForm;