import styled from 'styled-components';

import { Grid } from '../Layout';

const DragArea = styled(Grid)`
    padding: 15px;
    border: ${({ theme }) => theme.border.main};
    
    >div {
        &:not(:first-of-type) {
            >div div {
                width: 100%;
            }
        }
    }
`;

DragArea.defaultProps = {
    cols: [[2, 480], [3, 768], [4, 1025], [5, 1301]]
}

export default DragArea;