import React, { Component } from 'react';
import styled from 'styled-components';

import theme from '../../config/theme';
import { SVG, SROnly } from './';

const Alert = styled.div`
    position: relative;
    padding: 15px 34px 15px 22px;
    border-radius: ${theme.radius.small};
    color: ${theme.white};
    background: ${({ type }) => type === 'success' ? theme.green : type === 'danger' ? theme.red : theme.blue};
    
    button {
        position: absolute;
        width: 15px;
        top: 50%;
        right: 15px;
        padding: 3px;
        border: 0;
        transform: translateY(-50%);
        cursor: pointer;

        span {
            +div {
                width: 9px;
                
                div {
                    width: 100%;
                }
            }
        }
    }
`;

class AlertWrap extends Component {
    render () {
        const { style, message: { message, type }, close } = this.props;

        return (
            <Alert type={type} style={style}>
                {message}
                <button onClick={close}>
                    <SROnly>Close alert</SROnly>
                    <SVG path={'/svg/icon-close.svg'} />
                </button>
            </Alert>
        );
    }
}

export default AlertWrap;