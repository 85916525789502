import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { SROnly } from '../Utils';
import { input, minWidthInput } from '../../utils/style';

const InputWrap = styled.div`
    input {
        ${input()};
    }
    
    ${({ chunky, theme }) => chunky && `
        input {
            height: 55px;
            padding: 5px 19px;
            border-radius: ${theme.radius.smaller};
            border: ${theme.border.faint};
            background: ${theme.greyLighter};
            color: ${theme.lightTextAlt};
            font-size: ${theme.fontSize.bodyLg};
            font-weight: 400;
            line-height: 18px;
        }
        
        +* {
            margin-top: 14px;
        }
        
        +small {
            margin-top: 8px;
            line-height: 18px;
        }
    `}
    
    ${({ styleType }) => styleType && styleType.includes('time') && `
        input {
            width: 75px;
        }
    `}
    
    ${({ invalid, theme }) => invalid && `
        border: 1px solid ${theme.error};
    `}
    
    ${({ minWidth }) => minWidth && minWidthInput()}
`;

const Input = React.forwardRef(({ srLabel, chunky, minWidth, id, inputProps, invalid, ...props }, ref) => (
    <InputWrap minWidth={minWidth} chunky={chunky} invalid={invalid}>
        { srLabel && <SROnly is={"label"} htmlFor={id}>{ srLabel }</SROnly> }
        <input id={id} {...props} {...inputProps} ref={ref} />
    </InputWrap>
));

Input.propTypes = {
    minWidth: PropTypes.bool,
    chunky: PropTypes.bool,
    srLabel: PropTypes.string,
    id: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    onChange: PropTypes.func,

    // Used for when this component is used with InputSuggest
    inputProps: PropTypes.object,
}

Input.defaultProps = {
    value: '',
    type: 'text',
}

export default Input;