import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format } from 'date-fns';

import { Link, Strong, Small } from '../../../Text';

const FileWrap = styled.div`
    //width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    border: ${({ theme }) => theme.border.main};
    border-radius: ${({ theme }) => theme.radius.small};
    background: ${({ theme }) => theme.whiteish};
    opacity: ${({ removing }) => removing ? '0.6' : '1'};
    
    >* {
        width: 100%;
    }
`;

const FileInfo = styled.div`
    padding: 8px 14px;
    
    >* {
        display: block;
    }
    
    ${Strong} { 
        margin-bottom: 5px;
        line-height: 21px;
        word-break: break-word;
        
        ~* {
            line-height: 20px;
        }
    }
`;

const FileControls = styled.div`
    padding: 8px 14px;
    align-self: flex-end;
    background: ${({ theme }) => theme.greyLightest};
    
    * {
        margin-right: 10px;
    }
`;

const File = ({ id, name, created_at, uploaded_by, url, index, onRemove, removing, canRemove }) => (
    <FileWrap
        removing={removing}
        data-testid={'file'}
    >
        <FileInfo>
            <Strong>{ name }</Strong>
            { uploaded_by && <Small>Uploaded by: <strong>{ uploaded_by.name }</strong></Small> }
            { created_at && <Small is={"time"}>{ format(created_at.date, 'DD/MM/YYYY HH:mm') }</Small> }
        </FileInfo>
        <FileControls>
            { url && <Link href={url} small={true} el={'anchor'} target={"_blank"}>View</Link> }
            { onRemove && canRemove && <Link small={true} red={true} el={'button'} onClick={() => onRemove(index, id)}>Remove</Link> }
        </FileControls>
    </FileWrap>
);

File.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    name: PropTypes.string,
    uploaded_by: PropTypes.object,
    created_at: PropTypes.object,
    url: PropTypes.string,
    index: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onRemove: PropTypes.func,
    removing: PropTypes.bool,
    canRemove: PropTypes.bool,
}

export default File;