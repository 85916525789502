import React from 'react';
import styled from 'styled-components';
import { Box } from 'grid-styled';

const ContentAreaWrap = styled(Box)`
    padding: 35px 20px 20px;
    //overflow-x: scroll;
    
    @media screen and (max-width: 1024px) {
        width: 100%;
        padding-bottom: 87px;
        flex: none;
    }
    
    >div {
        max-width: 1280px;
        margin: 0 auto;
    }
`;

const ContentArea = ({ children }) => {
    return(
        <ContentAreaWrap flex={'1 0 0'}>
            <div>{ children }</div>
        </ContentAreaWrap>
    );
}

export default ContentArea;