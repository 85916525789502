import React from "react";
import ReactSelect from "react-select";
import styled from 'styled-components';

import { input, option, optionDropdown, optionDropdownOuter } from '../../utils/style';
import { SVG } from '../Utils';

const StyledOption = styled.div`
    ${option()};
    background: ${({ focused, theme }) => focused ? theme.greyLighter : 'none'};
`;

const Option = ({ innerProps, ...props }) => {
    return(
        <StyledOption {...innerProps} focused={props.isFocused}>{ props.label }</StyledOption>
    );
}

const StyledMenu = styled.div`
    ${optionDropdownOuter()};
`;

const Menu = ({ innerProps, ...props }) => {
    return <StyledMenu {...innerProps}>{ props.children }</StyledMenu>
}

const StyledMenuList = styled.div`
    ${optionDropdown()};
`;

const MenuList = ({ innerProps, ...props }) => {
    return <StyledMenuList {...innerProps}>{ props.children }</StyledMenuList>
}

const StyledSelectContainer = styled.div`
    position: relative;
    
    >div {
        position: relative;
        
        &:first-of-type {
            ${input()};
            height: 33px;
            font-weight: 400;
            cursor: pointer;
            background: ${({ isDisabled, theme }) => isDisabled ? theme.greyLighter : theme.whiteish};
            
            ${({ invalid, theme }) => invalid && `border: 1px solid ${theme.red}`}
        }
    }
    
    * {
        color: ${({ theme }) => theme.darkText}!important;
    }
    
    span {
        display: none;
    }
`;

const SelectContainer = ({ innerProps, isDisabled, className, invalid, ...props }) => {
    return(
        <StyledSelectContainer
            className={className}
            isDisabled={isDisabled}
            invalid={props.selectProps.invalid}
            {...innerProps}
        >
            { props.children }
        </StyledSelectContainer>
    );
}

const StyledValueContainer = styled.div`
    position: absolute;
    top: 0;
    width: calc(100% - 24px);
    padding: 0;
    margin: 0;
    overflow: hidden;
    color: ${({ theme }) => theme.darkText};
`;

const ValueContainer = ({ innerProps, ...props }) => (
    <StyledValueContainer
        {...innerProps}
        data-testid="selectValue"
    >{ props.children }</StyledValueContainer>
)

const StyledDropdownIndicator = styled.button`
    position: absolute;
    top: 0;
    right: 12px;
    width: 10px;
    height: 100%;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    
    svg {
        width: 10px;
        transform: rotate(90deg);
    }
    
    polygon {
        fill: ${({ theme }) => theme.blueLight};
    }
`;

const DropdownIndicator = ({ innerProps, ...props }) => (
    <StyledDropdownIndicator {...innerProps}>
        <SVG path={'/svg/icon-chevron-acute.svg'} />
        { props.children }
    </StyledDropdownIndicator>
);

// export default Select;
export default (props) => {
    return(
        <ReactSelect
            {...props}
            components={{ Option, Menu, MenuList, SelectContainer, ValueContainer, DropdownIndicator }}
            className={'input-dropdown'}
        />
    );
}