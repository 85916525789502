import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { input } from '../../utils/style';

const SelectWrap = styled.div`
    label {
        margin-right: 10px;
        font-weight: 500;
    }
    
    select {
        ${({ size }) => size === 'xs' && `
            display: inline-block;
            width: auto;
            min-width: 50px;
            height: 22px;
            padding: 0 20px 0 5px;
            text-align: center;
        `}
        
        ${({ size }) => size !== 'xs' && `
            ${input()}
        `}
        
        ${({ disabled, theme }) => disabled && `
            background-color: ${theme.greyLighter}!important;
        `}
    }
    
`;

const Select = ({ options, label, blankText, size, onChange, id, defaultValue, value, className, disabled }) => {
    if(value && typeof value === 'object') {
        value = value.value;
    }

    return(
        <SelectWrap size={size} className={className} disabled={disabled}>
            { label && <label htmlFor={id}>{label}</label> }
            <select id={id} onChange={onChange} value={value} defaultValue={defaultValue} disabled={disabled}>
                { blankText && <option value="">{blankText}</option> }
                { options.map((opt, i) => {
                    let label = opt,
                        val = opt;

                    if(typeof opt === 'object') {
                        label = opt.label;
                        val = opt.value;
                    }

                    return(
                        <option value={val} key={val+i}>{label}</option>
                    )
                }) }
            </select>
        </SelectWrap>
    );
}

Select.propTypes = {
    id: PropTypes.string.isRequired
}

export default Select;