import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { IconLabel } from '../Icons';
import { dragCard, greyCard } from '../../utils/style';

const CardButtonWrap = styled.button`
    ${greyCard()};
    ${dragCard()};
    border: ${({ theme }) => theme.border.main};
    background: ${({ theme }) => theme.whiteish};
    transition: background ${({ theme }) => theme.duration.quick};
    cursor: pointer;
    
    &:hover {
        background: ${({ theme }) => theme.greyLightest};
    }
    
    >div {
        width: 100%;
    }
`;

const CardButton = ({ label, onClick }) => {
    return(
        <CardButtonWrap onClick={onClick}>
            <IconLabel label={label} />
        </CardButtonWrap>
    );
}

CardButton.propTypes = {
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
}

CardButton.defaultProps = {
    label: 'Add',
}

export default CardButton;