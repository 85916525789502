import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
    AssignLoggedTime,
    FormGroup,
    FormItemGroup,
    FormItem,
    Input,
    TextArea,
    FileUploader,
    ProductForm,
} from '../Forms';
import { PopupContent, ModalButtons } from '../Modals';
import { Button, ButtonListItem } from '../Buttons';
import { CardButton } from '../Cards';
import { Link } from '../Text';
import { Grid } from '../Layout';

import {
    getEventKey,
    getEventVal,
    addFileToState,
    removeFileFromState,
    filterDocumentsAndAddToObject,
} from '../../utils/dataMap';
import {
    convertLoggedTimeToArray,
    getLoggedTime
} from '../../utils/dates';
import { allTrue } from '../../utils/validation';

class CompleteRaidForm extends Component {
    constructor(props) {
        super(props);

        const logged_time = getLoggedTime(props.logged_time);

        this.state = {
            id: (props.raid && props.raid.id) || null,
            showProductForm: false,
            form: {
                mileage: (props.raid && props.raid.mileage) || '',
                notes: (props.raid && props.raid.notes) || '',
                products_seized: (props.raid && props.raid.products_seized) || [],
                documents: props.documents || [],
                ...logged_time
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // If the data was saved, with no errors, clear the form.
        if((prevProps.saving && !this.props.saving) && !this.props.error) {
            this.clearForm();
        }
    }

    clearForm = () => {
        this.setState({
            id: null,
            showProductForm: false,
            form: {
                mileage: '',
                notes: '',
                documents: [],
                products_present: false,
                logged_time: '',
                additional_logged_time: '',
            },
        })
    }

    handleSave = (stayOpen) => {
        let {
            id,
            form,
        } = this.state;

        let data = {
            raid: {
                notes: form.notes,
                mileage: form.mileage,
                products_seized: form.products_seized,
                storage_location: form.storage_location,
            },
            logged_time: convertLoggedTimeToArray(form),
        };

        // If there are already documents assigned to the case, make sure we don't try post them again.
        data = filterDocumentsAndAddToObject(form.documents, this.props.documents, data);

        this.props.onSave(id, data, stayOpen);
    }

    handleChange = (e, k) => {
        let key = k || getEventKey(e);

        let val = k && k.includes('logged_time') ? e : getEventVal(e);

        this.setState({
            form: {
                ...this.state.form,
                [key]: val
            }
        });
    }

    removeProduct = (bag_no) => {
        let products_seized = this.state.form.products_seized.filter(prod => prod.bag_no !== bag_no);

        this.setState({
            form: {
                ...this.state.form,
                products_seized
            }
        });
    }

    addProduct = (data) => {
        this.setState({
            showProductForm: false,
            form: {
                ...this.state.form,
                products_seized: [...this.state.form.products_seized, data]
            }
        });
    }

    removeFile = (index) => {
        let document = this.state.form.documents[index];

        this.setState(state => removeFileFromState(state, index));

        // If the document has an ID, then it's already been uploaded, so we need to actually delete it.
        if(document && document.id) {
            this.props.onFileRemove(document.id);
        }
    }

    render() {
        let {
            showProductForm,
            form: {
                documents,
                notes,
                mileage,
                products_seized,
                storage_location,
                logged_time,
                additional_logged_time,
            }
        } = this.state;

        let {
            onCancel,
            saving,
            deleting,
            handleRejectedFiles,
        } = this.props;

        let requiredPopulated = allTrue([mileage]);

        return(
            <Fragment>
                <FormGroup center={true}>
                    <FormItemGroup labelWidth={"150px"} wide={true}>
                        <FormItem label={"Products Seized"} id={'products_seized'}>
                            <Grid cols={[ [1, 0], [2, 370] ]} gap={'10px'} position={"relative"}>
                                { products_seized.map(({ bag_no, product, quantity }) => (
                                    <ButtonListItem
                                        showControls={true}
                                        key={`seizedProduct${bag_no}`}
                                        onRemove={() => this.removeProduct(bag_no)}
                                        title={product}
                                        large={true}
                                        noWrap={false}
                                    >
                                        <strong>{ product }</strong>
                                        <div>
                                            { quantity && <span>QTY: { quantity }</span> }
                                            { bag_no && <span>Bag no: { bag_no }</span> }
                                        </div>
                                    </ButtonListItem>
                                )) }
                                <CardButton label={'Add a product'} onClick={() => this.setState({ showProductForm: true })} />
                                { showProductForm &&
                                    <PopupContent onRequestClose={() => this.setState({ showProductForm: false })}>
                                        <ProductForm
                                            onSave={this.addProduct}
                                            onCancel={() => this.setState({ showProductForm: false })}
                                            focusOnMount={true}
                                        />
                                    </PopupContent>
                                }
                            </Grid>
                        </FormItem>

                        <FormItem
                            label={"Storage Location"}
                            id={'storage_location'}
                        >
                            <Input
                                value={storage_location}
                                id={'storage_location'}
                                onChange={this.handleChange}
                                minWidth={true}
                                type={'text'}
                            />
                        </FormItem>

                        <FormItem label={"Logged Time"} id={'logged_time'}>
                            <AssignLoggedTime
                                value={logged_time.value}
                                id={'logged_time'}
                                onChange={e => this.handleChange(e, 'logged_time')}
                            />
                        </FormItem>

                        <FormItem label={"Additional Logged Time"} id={'additional_logged_time'}>
                            <AssignLoggedTime
                                value={additional_logged_time.value}
                                id={'additional_logged_time'}
                                onChange={e => this.handleChange(e, 'additional_logged_time')}
                            />
                        </FormItem>

                        <FormItem
                            label={"Logged Mileage"}
                            id={'mileage'}
                            required={true}
                        >
                            <Input
                                value={mileage}
                                id={'mileage'}
                                onChange={e => this.handleChange(e, 'mileage')}
                                minWidth={true}
                                type={'number'}
                            />
                        </FormItem>

                        <FormItem label={"Notes"} id={'notes'} align={'top'}>
                            <TextArea
                                value={notes}
                                id={'notes'}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem label={"Associated files"} id={'files'} align={'top'}>
                            <FileUploader
                                files={documents}
                                handleAcceptedFiles={file => this.setState(state => addFileToState(state, file))}
                                handleRejectedFiles={handleRejectedFiles}
                                onRemove={this.removeFile}
                                deleting={deleting}
                                cols={2}
                            />
                        </FormItem>
                    </FormItemGroup>
                </FormGroup>

                <ModalButtons pl={'150px'} wide={true}>
                    <Button
                        blue={true}
                        small={true}
                        onClick={() => this.handleSave(false)}
                        disabled={saving || !requiredPopulated}
                    >Save</Button>
                    <Button
                        small={true}
                        blue={true}
                        onClick={() => this.handleSave(true)}
                        disabled={saving || !requiredPopulated}
                    >Save and add another</Button>
                    { this.state.id &&
                        <Button
                            red={true}
                            small={true}
                            onClick={() => this.props.onDelete(this.state.id)}
                        >Delete</Button>
                    }
                    <Link el={'button'} onClick={onCancel}>Cancel</Link>
                </ModalButtons>
            </Fragment>
        );
    }
}

CompleteRaidForm.propTypes = {
    onSave: PropTypes.func
}

export default CompleteRaidForm;