import styled from 'styled-components';
import system from 'system-components';

import { FloatingSidebar } from '../Layout';

const TitleEl = system({
    is: 'h3'
});

const Title = styled(TitleEl)`
    margin: 0;
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize.titleSm};
    
    ${FloatingSidebar} & {
        margin-bottom: 20px;
    }
`;

export default Title;