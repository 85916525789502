import React from 'react';
import { connect } from 'react-redux';

import { hasPermission } from '../../utils/validation';

const UserHasPermissionContainer = ({ self, permissions, children, render }) => {
    const userHasPermission = hasPermission(self, permissions);

    if(render) {
        return render(self);
    }

    if(!userHasPermission) return null;

    return children;
}

const mapStateToProps = state => ({
    self: state.user.self,
    fetching: state.user.fetching,
});

export default connect(
    mapStateToProps
)(UserHasPermissionContainer);