import styled from 'styled-components';

export default styled.time`
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    max-width: 30px;
    
    >span {
        width: 100%;
        font-weight: 300;
        text-align: center;
        color: ${({ theme, color }) => color ? theme[color] : 'initial'};
        
        &:first-of-type {
            font-size: 16px;
        }
        &:nth-of-type(2) {
            font-size: 22px;
            font-weight: 400;
        }
    }
`;