import React, { Component, Fragment } from 'react';

import settings from '../../config/settings';
import { PaymentMethodsContainer } from '../../containers/Case';
import {
    AssignLoggedTime,
    FormGroup,
    FormItemGroup,
    FormItem,
    Input,
    TextArea,
    ToggleSwitch,
    FileUploader,
} from '../Forms';
import { ModalButtons } from '../Modals';
import { Button } from '../Buttons';
import { Link } from '../Text';

import {
    getEventKey,
    getEventVal,
    addFileToState,
    removeFileFromState,
    filterDocumentsAndAddToObject,
} from '../../utils/dataMap';
import {
    convertLoggedTimeToArray,
    getLoggedTime
} from '../../utils/dates';
import { allTrue } from '../../utils/validation';

class CompleteTestPurchaseForm extends Component {
    constructor(props) {
        super(props);

        const logged_time = getLoggedTime(props.logged_time);

        this.state = {
            id: props.test_purchase ? props.test_purchase.id : null,
            form: {
                mileage: props.test_purchase && props.test_purchase.mileage ? props.test_purchase.mileage : '',
                cost: props.test_purchase && props.test_purchase.cost ? props.test_purchase.cost : '',
                notes: props.test_purchase && props.test_purchase.notes ? props.test_purchase.notes : '',
                received: props.test_purchase && props.test_purchase.received || true,
                documents: props.documents || [],
                payment_method: props.test_purchase && props.test_purchase.payment_method ? props.test_purchase.payment_method : '',
                ...logged_time
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // If the data was saved, with no errors, clear the form.
        if((prevProps.saving && !this.props.saving) && !this.props.error) {
            this.clearForm();
        }
    }

    clearForm = () => {
        this.setState({
            id: null,
            form: {
                mileage: '',
                notes: '',
                received: true,
                documents: [],
                payment_method: '',
                logged_time: '',
                additional_logged_time: '',
            }
        })
    }

    handleSave = (stayOpen) => {
        let {
            id,
            form,
        } = this.state;

        let data = {
            test_purchase: {
                notes: form.notes,
                received: form.received,
                mileage: form.mileage,
                cost: form.cost.replace('£', ''),
                payment_method_id: form.payment_method && form.payment_method.id,
            },
            logged_time: convertLoggedTimeToArray(form),
        };

        // If there are already documents assigned to the case, make sure we don't try post them again.
        data = filterDocumentsAndAddToObject(form.documents, this.props.documents, data);

        this.props.onSave(id, data, stayOpen);
    }

    handleChange = (e, k) => {
        let key = k || getEventKey(e);

        let val = k && k.includes('logged_time') ? e : getEventVal(e);

        this.setState({
            form: {
                ...this.state.form,
                [key]: val
            }
        });
    }

    removeFile = (index) => {
        let document = this.state.form.documents[index];

        this.setState(state => removeFileFromState(state, index));

        // If the document has an ID, then it's already been uploaded, so we need to actually delete it.
        if(document && document.id) {
            this.props.onFileRemove(document.id);
        }
    }

    render() {
        let {
            form: {
                documents,
                notes,
                mileage,
                cost,
                received,
                logged_time,
                additional_logged_time,
                payment_method,
            }
        } = this.state;

        let {
            test_purchase,
            onCancel,
            saving,
            handleRejectedFiles
        } = this.props;

        let requiredPopulated = allTrue([cost]);
        if(requiredPopulated && test_purchase.type !== 'online') {
            requiredPopulated = mileage;
        }

        return(
            <Fragment>
                <FormGroup center={true}>
                    <FormItemGroup labelWidth={"150px"} wide={true}>
                        <FormItem label={"Payment Method"} id={'payment_method'} minWidthInput={true}>
                            <PaymentMethodsContainer
                                onChange={p => this.handleChange(p, 'payment_method')}
                                addOnType={true}
                                value={payment_method}
                            />
                        </FormItem>

                        <FormItem
                            label={"Product Received"}
                            id={'received'}
                        >
                            <ToggleSwitch
                                checked={received}
                                id={'received'}
                                onChange={e => this.handleChange(e, 'received')}
                            />
                        </FormItem>

                        <FormItem
                            label={"Cost"}
                            id={'cost'}
                            required={true}
                        >
                            <Input
                                value={cost}
                                id={'cost'}
                                onChange={this.handleChange}
                                minWidth={true}
                                type={'text'}
                            />
                        </FormItem>

                        <FormItem label={"Logged Time"} id={'logged_time'}>
                            <AssignLoggedTime
                                value={logged_time.value}
                                id={'logged_time'}
                                onChange={e => this.handleChange(e, 'logged_time')}
                            />
                        </FormItem>

                        <FormItem label={"Additional Logged Time"} id={'additional_logged_time'}>
                            <AssignLoggedTime
                                value={additional_logged_time.value}
                                id={'additional_logged_time'}
                                onChange={e => this.handleChange(e, 'additional_logged_time')}
                            />
                        </FormItem>

                        <FormItem
                            label={"Logged Mileage"}
                            id={'mileage'}
                            required={test_purchase.type !== 'online'}
                        >
                            <Input
                                value={mileage}
                                id={'mileage'}
                                onChange={e => this.handleChange(e, 'mileage')}
                                minWidth={true}
                                type={'number'}
                            />
                        </FormItem>

                        <FormItem label={"Notes"} id={'notes'} align={'top'}>
                            <TextArea
                                value={notes}
                                id={'notes'}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem label={"Associated files"} id={'files'} align={'top'}>
                            <FileUploader
                                files={documents}
                                handleAcceptedFiles={file => this.setState(state => addFileToState(state, file))}
                                handleRejectedFiles={handleRejectedFiles}
                                onRemove={this.removeFile}
                                cols={2}
                            />
                        </FormItem>
                    </FormItemGroup>
                </FormGroup>

                <ModalButtons pl={'150px'} wide={true}>
                    <Button
                        blue={true}
                        small={true}
                        onClick={() => this.handleSave(false)}
                        disabled={saving || !requiredPopulated}
                    >Save</Button>
                    <Button
                        small={true}
                        blue={true}
                        onClick={() => this.handleSave(true)}
                        disabled={saving || !requiredPopulated}
                    >Save and add another</Button>
                    { this.state.id &&
                        <Button
                            red={true}
                            small={true}
                            onClick={() => this.props.onDelete(this.state.id)}
                        >Delete</Button>
                    }
                    <Link el={'button'} onClick={onCancel}>Cancel</Link>
                </ModalButtons>
            </Fragment>
        );
    }
}

export default CompleteTestPurchaseForm;