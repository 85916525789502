export const defaultTabs = (theme) => {
    return `
        margin-right: 35px;
        border-bottom: 1px solid #ddd;
        font-size: ${theme.fontSize.bodyLg};
        
        input[type="radio"] {
            +label {
                padding: 5px 7px 12px 2px;
                transition: ${theme.duration.quick} color;
                color: ${theme.lightText};
                
                &:hover {
                    color: ${theme.greyLight};
                }
            }
            
            &:checked {
                +label {
                    position: relative;
                    font-weight: 500;
                    color: ${theme.blueLight};
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        box-shadow: 0px 1px 2px 1px rgb(0,153,224,0.5);
                        background: #0099e0;
                    }    
                }
            }
        }
        
        >div {
            +div {
                margin-left: 12px;
                
                input[type="radio"] {
                    +label {
                        padding-left: 7px;
                    }
                }
            }
        }
    `;
}

export const blueTabs = (theme) => {
    return`
        border: 0;
        
        >div {
            +div {
                input[type="radio"] {
                    +label {
                        border-left: 0;
                    }
                }
            }
            
            &:first-of-type {
                input[type="radio"] {
                    +label {
                        border-top-left-radius: 3px;
                        border-bottom-left-radius: 3px;
                    }
                }
            }
            
            &:last-of-type {
                input[type="radio"] {
                    +label {
                        border-top-right-radius: 3px;
                        border-bottom-right-radius: 3px;
                    }
                }
            }
        }
        
        input[type="radio"] {
            +label {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 15px;
                height: 33px;
                border: ${theme.border.main};
                font-size: 14px;
                color: ${theme.darkText};
                background: ${theme.whiteish};
            }
            
            &:checked {
                +label {
                    background: ${theme.blueLighter};
                    border-color: ${theme.blueLighter};
                    color: ${theme.white};
                }
            }
        }
    `;
}