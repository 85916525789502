import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends Component {
    state = {
        error: null,
        errorInfo: null,
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo });
        Sentry.captureException(error);
    }

    render() {
        if(this.state.errorInfo) {
            if(this.props.render) {
                return this.props.render();
            }

            return <div>Oops, an error has occured.</div>
        }

        return this.props.children;
    }
}

export default ErrorBoundary;