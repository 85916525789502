import React from 'react';
import styled from 'styled-components';

import { SVG, SROnly } from '../../../Utils';

const WeekControls = ({ date, prevLabel, nextLabel, onPrevClick, onNextClick, canPrev, canNext }) => {
    return(
        <StyledDiv>
            <StyledButton
                onClick={onPrevClick}
                data-testid="ctrl-prev"
                disabled={!canPrev}
            >
                <SVG path={'/svg/icon-chevron-acute.svg'} />
                <SROnly>{ prevLabel }</SROnly>
            </StyledButton>
            <div>{ date }</div>
            <StyledButton
                onClick={onNextClick}
                data-testid="ctrl-next"
                disabled={!canNext}
            >
                <SVG path={'/svg/icon-chevron-acute.svg'} />
                <SROnly>{ nextLabel }</SROnly>
            </StyledButton>
        </StyledDiv>
    );
}

WeekControls.defaultProps = {
    prevLabel: 'Previous week',
    nextLabel: 'Next week',
    canPrev: true,
    canNext: true,
}

const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    
    div {
        width: 115px;
        margin: 0 10px;
    }
`;

const StyledButton = styled.button`
    padding: 5px;
    border: 0;
    background: none;
    cursor: pointer;
    
    &:first-of-type {
        transform: rotate(180deg);
        transform-origin: 50%;
    }
    
    svg {
        width: 8px;
        
        polygon {
            fill: ${({ theme, disabled }) => disabled ? theme.greyLight : theme.lightTextAlt};
            cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
        }
    }
`;

export default WeekControls;