import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
    FormGroup,
    FormItemGroup,
    FormItem,
    DateTimeInput,
    DateTimeWrap,
    Input,
    TimeSwitch,
    TextArea,
    FileUploader,
    ToggleSwitch,
    TimeInput,
} from '../Forms';
import { ModalButtons } from '../Modals';
import { ButtonListItem, Button } from '../Buttons';
import { Link } from '../Text';
import { Grid } from '../Layout';

import { getLoggedTime } from '../../utils/dates';

class ViewSiteVisitForm extends Component {
    render() {
        let {
            onCancel,
            saving,
            logged_time,
            documents,
            site_visit: {
                start_date,
                end_date,
                address,
                users,
                notes,
                mileage,
                products_present,
            }
        } = this.props;

        // Separate additional_logged_time from logged_time
        logged_time = getLoggedTime(logged_time);

        return(
            <Fragment>
                <FormGroup center={true} saving={saving}>
                    <FormItemGroup labelWidth={"150px"} wide={true}>
                        <FormItem label={"Products Present"} id={'products_present'}>
                            <ToggleSwitch
                                checked={products_present}
                                id={'products_present'}
                                disabled={true}
                            />
                        </FormItem>

                        <FormItem label={"Start Date"} id={'site_visit_start_date'} minWidthInput={true}>
                            <DateTimeWrap>
                                <DateTimeInput
                                    id={"site_visit_start_date"}
                                    value={start_date.split(' ')[0]}
                                    disabled={true}
                                />
                                <TimeSwitch
                                    value={start_date.split(' ')[1]}
                                    prefix={"start"}
                                    selectedByDefault={true}
                                    disabled={true}
                                />
                            </DateTimeWrap>
                        </FormItem>

                        <FormItem label={"End Date"} id={'site_visit_end_date'} minWidthInput={true}>
                            <DateTimeWrap>
                                <DateTimeInput
                                    id={"site_visit_end_date"}
                                    value={end_date.split(' ')[0]}
                                    disabled={true}
                                />
                                <TimeSwitch
                                    value={end_date.split(' ')[1]}
                                    prefix={"end"}
                                    disabled={true}
                                />
                            </DateTimeWrap>
                        </FormItem>

                        <FormItem label={"Address"} id={'address'} align={'top'}>
                            <TextArea
                                value={address || ''}
                                id={'address'}
                                disabled={true}
                            />
                        </FormItem>

                        <FormItem label={"Staff"} id={'staff'} align={'top'}>
                            <Grid cols={[[1, 0], [2, 370], [3, 521]]} gap={'10px'}>
                                { users.map(user => (
                                    <ButtonListItem
                                        showControls={false}
                                        key={`investigator${user.id}`}
                                        title={user.name}
                                        disabled={true}
                                    >{ user.name }</ButtonListItem>
                                )) }
                            </Grid>
                        </FormItem>

                        <FormItem label={"Logged Time"} id={'logged_time'}>
                            <TimeInput
                                value={logged_time.logged_time.value}
                                id={'logged_time'}
                                minWidth={true}
                                disabled={true}
                            />
                        </FormItem>

                        <FormItem label={"Additional Logged Time"} id={'additional_logged_time'}>
                            <TimeInput
                                value={logged_time.additional_logged_time.value}
                                id={'additional_logged_time'}
                                minWidth={true}
                                disabled={true}
                            />
                        </FormItem>

                        <FormItem label={"Mileage"} id={'mileage'}>
                            <Input
                                value={mileage}
                                id={'mileage'}
                                disabled={true}
                                minWidth={true}
                                type={"text"}
                            />
                        </FormItem>

                        <FormItem label={"Notes"} id={'notes'} align={'top'}>
                            <TextArea
                                value={notes || ''}
                                id={'notes'}
                                disabled={true}
                            />
                        </FormItem>

                        { documents && !!documents.length &&
                            <FormItem label={"Associated files"} id={'files'} align={'top'}>
                                <FileUploader
                                    files={documents}
                                    cols={2}
                                    readOnly={true}
                                    canRemove={false}
                                />
                            </FormItem>
                        }
                    </FormItemGroup>
                </FormGroup>

                <ModalButtons pl={'150px'} wide={true}>
                    { this.props.site_visit.id &&
                        <Button
                            red={true}
                            small={true}
                            onClick={() => this.props.onDelete(this.props.site_visit.id)}
                        >Delete</Button>
                    }
                    <Link
                        el={'button'}
                        onClick={onCancel}
                        disabled={saving}
                    >Cancel</Link>
                </ModalButtons>
            </Fragment>
        );
    }
}

ViewSiteVisitForm.propTypes = {
    onSave: PropTypes.func
}

export default ViewSiteVisitForm;