import styled from 'styled-components';

export default styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 65px;
    border: ${({ theme }) => theme.border.faint};
    cursor: pointer;
`;