import React from 'react';
import styled from 'styled-components';

const CardDetailsWrap = styled.div`
    border: ${({ theme, titleStyle }) => titleStyle && titleStyle.includes('rounded') ? theme.border.main : '0'};
    border-radius: ${({ theme }) => theme.radius.small};
    overflow: hidden;
    text-align: center;
    background: ${({ theme, noBackground }) => noBackground ? 'none' : theme.whiteish};
    
    +* {
        margin-top: 10px;
    }
`;

const CardDetailsTitle = styled.div`
    padding: 7px 15px;
    color: ${({ titleStyle }) => titleStyle && titleStyle.includes('rounded') ? 'white' : 'inherit'};
    background: ${({ titleStyle, theme }) => titleStyle && titleStyle.includes('blue') ? theme.blue : titleStyle && titleStyle.includes('orange') ? theme.orange : titleStyle.includes('green') ? theme.greenDark : 'none'};
    font-size: ${({ titleStyle, theme }) => titleStyle && titleStyle.includes('rounded') ? theme.fontSize.bodyMd : theme.fontSize.bodySm};
    line-height: ${({ titleStyle, theme }) => titleStyle && titleStyle.includes('rounded') ? 'inherit' : theme.fontSize.bodySm};
`;

const CardDetailsInner = styled.div`
    padding: 10px 15px;
    background: ${({ innerBackground, theme }) => innerBackground ? theme.whiteish : 'none'};
    
    p {
        margin: 0;
        font-size: ${({ theme }) => theme.fontSize.bodyMd};
        
        +* {
            margin-top: 5px;
        }
    }

    div {
        display: table;
        margin-left: auto;
        margin-right: auto;
        
        +* {
            margin-top: 10px;
        }
    }

    button {
        display: table;
        margin-left: auto;
        margin-right: auto;
    }
    
    ${({ theme, titleStyle }) => titleStyle.includes('rounded') ? 
        `
            border-bottom-left-radius: ${theme.radius.small};
            border-bottom-right-radius: ${theme.radius.small};
            border-bottom: ${theme.border.main};
            border-left: ${theme.border.main};
            border-right: ${theme.border.main};
        `
    :
        `
            border-radius: ${theme.radius.small};
            border: ${theme.border.main};
        `
    }
    
    ${({ documents }) => documents && `
        padding: 5px 0;
        
        div {
            display: inline-block;
            margin: 5px;
            
            +div {
                margin: 5px;
            }
        }
        
        button {
            margin: 10px auto;
        }
    `};
`;

const CardDetails = ({ title, titleStyle, documents, children, onClick, noBackground, innerBackground }) => {
    return(
        <CardDetailsWrap
            onClick={onClick}
            noBackground={noBackground}
        >
            { title && <CardDetailsTitle titleStyle={titleStyle}>{ title }</CardDetailsTitle> }
            <CardDetailsInner
                titleStyle={titleStyle}
                documents={documents}
                innerBackground={innerBackground}
            >
                { children }
            </CardDetailsInner>
        </CardDetailsWrap>
    );
}

CardDetails.defaultProps = {
    titleStyle: ''
}

export default CardDetails;