import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const ReactModalAdapter = ({ className, ...props }) => {
    const contentClassName = `${className}__content`;
    const overlayClassName = `${className}__overlay`;

    return (
        <Modal
            portalClassName={className}
            className={contentClassName}
            overlayClassName={overlayClassName}
            {...props}
        />
    )
}

const StyledModal = styled(ReactModalAdapter)`
    &__overlay {
        display: flex;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: ${({ translucent }) => translucent ? 'rgba(255, 255, 255, .8)' : 'rgba(255, 255, 255, 1)'};
        z-index: 99;
    }
    
    &__content {
        position: absolute;
        width: calc(100% - 20px);
        max-width: ${({ narrow }) => narrow ? '450px' : '760px'};
        max-height: calc(100vh - 100px);
        top: 50px;
        padding: 0;
        border: ${({ borderless, theme }) => borderless ? '0' : theme.border.main};
        border-radius: ${({ borderless, theme }) => borderless ? '0' : theme.radius.small};
        box-shadow: ${({ borderless, theme }) => borderless ? 'none' : theme.boxShadow.main};
        overflow: ${({ overflow }) => overflow ? 'visible' : 'auto'};
        -webkit-overflow-scrolling: touch;
        background: ${({ borderless, theme }) => borderless ? 'none' : theme.white};
        outline: none;
    }
`;

export default StyledModal;