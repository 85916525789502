import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getSources } from '../../actions/cases';
import { AsyncSelect } from '../../components/Forms';

export class SourcesDropdownContainer extends Component {
    state = {
        sources: [],
        fetchingTimeout: 0
    }

    static getDerivedStateFromProps(props, state) {
        if(!!state.sources.length && !props.exclude) return null;
        if(!props.sources.length) return null;

        let { sources, exclude } = props;

        if(exclude) {
            sources = sources.filter(trader => exclude.indexOf(trader.id) === -1);
        }

        sources = sources.map(source => ({
            ...source,
            label: source.title + (source.type ? ` (${source.type})` : '')
        }));

        return { sources }
    }

    updateInput = value => {
        // If coming from onChange event it's an object.
        let data = value;

        // It's a string from onInputChange. Check if this is a valid option, by finding an ID.
        if(typeof data === 'string') {
            data = {
                title: data
            }
        }

        // onBlur for some reason triggers an onInputChange with an empty string, so skip the change if it's an empty string.
        if(!data || !data.title) return;

        this.props.onChange(data);
    }

    getSources = () => {
        let { sources, getSources } = this.props;

        if(!!sources.length) return;

        if(this.state.fetchingTimeout) {
            clearTimeout(this.state.fetchingTimeout);
        }

        this.setState({
            fetchingTimeout: setTimeout(() => getSources({ limit: 50 }), 300)
        })
    }

    render() {
        let {
            fetching,
            value,
            disabled,
            id,
        } = this.props;

        let { sources } = this.state;

        return(
            <AsyncSelect
                value={value}
                options={sources}
                placeholder={value}
                onMenuOpen={this.getSources}
                isLoading={fetching}
                allowCreateWhileLoading={fetching}
                isDisabled={disabled}
                onChange={this.updateInput}
                onInputChange={this.updateInput}
                onSelectResetsInput={false}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                getOptionLabel={item => item.label || item.title}
                getOptionValue={item => item.title}
                id={id}
            />
        );
    }
}

SourcesDropdownContainer.defaultProps = {
    sources: [],
}

SourcesDropdownContainer.propTypes = {
    sources: PropTypes.array,
    exclude: PropTypes.array,
    fetching: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.object,
    getSources: PropTypes.func,
    onChange: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        sources: state.sources.data,
        fetching: state.sources.fetching,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getSources }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SourcesDropdownContainer));