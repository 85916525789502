import styled, { css } from 'styled-components';

const styles = css`
    position: relative;
    padding: ${({ noPadding }) => noPadding ? '0' : '3px'};
    
    ${({ theme, size }) => size === 'sm' && `
        padding: 0;
        font-size: 12px;
    `}
    
    ${({ align }) => align && `vertical-align: ${align}`};
    
    &:nth-of-type(n+2) {
        border-left: ${({ theme }) => theme.border.main};
    }
`;

export const TH = styled.th`
    ${styles};
    
    text-transform: uppercase;
    background: ${({ theme }) => theme.greyLightest};
    font-weight: 500;
    
    * {
        color: ${({ theme, lightText }) => lightText && theme.lightTextAlt};
    }
`;

export const TD = styled.td`
    ${styles};
    
    &:nth-last-of-type(2),
    &:last-of-type {
        background: ${({ theme }) => theme.greyLight};
        
        button {
            color: ${({ theme }) => theme.greyLight};
            
            &:hover {
                color: ${({ theme }) => theme.white};
            }
        }
    }
`;