import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'underscore';

import { getUser, updateUser } from '../../actions/user';
import { blurAllFields } from '../../utils/interaction';
import { getEventVal, convertNullValues } from '../../utils/dataMap';

// TODO: Close password modal on succesful save.

import {
    LockableFormGroup,
    FormItemGroup,
    FormItem,
    Input,
    PasswordForm,
} from '../../components/Forms';
import {
    Loading,
    Error,
    ErrorBoundary,
} from '../../components/Utils';
import {
    Modal,
    ModalContent,
} from '../../components/Modals';

import { Link } from '../../components/Text';

class SettingsContainer extends Component {
    state = {
        form: {},
        locked: true,
        modalOpen: false,
    }

    componentDidMount() {
        if(!this.props.self) {
            this.props.getUser();
        }
    }

    static getDerivedStateFromProps(props, state) {
        if(!isEmpty(state.form) || !props.self) return null;

        let {
            name,
            email,
        } = props.self;


        return {
            form: {
                name: name || '',
                email: email || '',
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if((prevProps.saving && !this.props.saving) && !this.props.error) {
            this.setState({
                locked: true,
                modalOpen: false,
            });
        }

        if(prevState.locked === this.state.locked) return;

        if(this.state.locked) {
            blurAllFields();
        }
    }

    saveAndLock = () => {
        this.props.updateUser(this.props.self.id, this.state.form, true);

        this.setState({
            locked: true
        });
    }

    handleChange = (e, id) => {
        let targetId = id;

        if(!targetId) {
            targetId = e.target.id;
        }

        this.setState({
            form: {
                ...this.state.form,
                [targetId]: id ? e : getEventVal(e)
            }
        });
    }

    toggleModal = (open = true) => {
        this.setState({
            modalOpen: open
        });
    }

    savePassword = (form) => {
        this.props.updateUser(this.props.self.id, form, true);
    }

    render() {
        let {
            loggedIn,
            self,
            fetching,
            saving,
        } = this.props;

        let {
            locked,
            modalOpen,
            form: {
                name,
                email,
            }
        } = this.state;

        if(!loggedIn) return <Redirect to='/login' />

        if(fetching || !self) return <Loading />

        return(
            <Fragment>
                <LockableFormGroup
                    title={"Your details"}
                    background={'all'}
                    border={'all'}
                    locked={locked}
                    padChildren={true}
                    padBottom={true}
                    toggleLock={() => this.setState({ locked: !locked })}
                    saveAndLock={this.saveAndLock}
                    saving={saving}
                    unlockable={true}
                >
                    <ErrorBoundary render={() => <Error overlay={true} />}>
                        <FormItemGroup labelWidth={"70px"}>
                            <FormItem label={"Name"} id={'name'}>
                                <Input
                                    value={locked && !saving ? convertNullValues(self.name) : name}
                                    id={'name'}
                                    type={"text"}
                                    onChange={this.handleChange}
                                />
                            </FormItem>

                            <FormItem label={"Email"} id={'email'}>
                                <Input
                                    value={locked && !saving ? convertNullValues(self.email) : email}
                                    id={'email'}
                                    type={"email"}
                                    onChange={this.handleChange}
                                    disabled={true}
                                />
                            </FormItem>

                            <FormItem label={"Password"} id={'password'}>
                                <Link 
                                    el="button" 
                                    style={{ textAlign: 'left' }}
                                    onClick={this.toggleModal}
                                    disabled={locked}
                                >Change password</Link>
                            </FormItem>
                        </FormItemGroup>
                    </ErrorBoundary>
                </LockableFormGroup>

                <Modal
                    isOpen={!!modalOpen}
                    onRequestClose={() => this.toggleModal(false)}
                >
                    <ModalContent title={'Change Password'}>
                        <ErrorBoundary render={() => <Error overlay={true} />}>
                            <PasswordForm
                                onSave={this.savePassword}
                                onCancel={() => this.toggleModal(false)}
                            />
                        </ErrorBoundary>
                    </ModalContent>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        self: state.user.self,
        fetching: state.user.fetching,
        saving: state.user.saving,
        error: state.user.error,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getUser, updateUser }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsContainer);