import React, { Component, Fragment } from 'react';
import { format } from 'date-fns';

import { UserDropdownContainer } from '../../containers/User';

import {
    AssignLoggedTime,
    FormGroup,
    FormItemGroup,
    FormItem,
    DateTimeInput,
    DateTimeWrap,
    TimeInput,
    TimeSwitch,
    TextArea,
    FileUploader,
} from '../Forms';
import { Link } from '../Text';
import { Grid } from '../Layout';
import { ModalButtons } from '../Modals';
import { Button, ButtonListItem } from '../Buttons';

import {
    getEventKey,
    getEventVal,
    addStaffToState,
    removeStaffFromState,
    updateDateState,
    updateTimeState,
    addFileToState,
    removeFileFromState,
    filterDocumentsAndAddToObject,
} from '../../utils/dataMap';
import {
    convertLoggedTimeToArray,
    dateTimeStringNow,
    getLoggedTime,
} from '../../utils/dates';
import {
    allTrue,
    haveDatesChanged
} from '../../utils/validation';

class EditRaidForm extends Component {
    constructor(props) {
        super(props);

        const logged_time = getLoggedTime(props.logged_time);

        this.state = {
            hasChanged: false,
            datesChanged: false,
            id: props.raid && props.raid.id,
            form: {
                start_date: {
                    date: props.raid ? props.raid.start_date.split(' ')[0] : format(new Date(), 'YYYY-MM-DD'),
                    time: props.raid ? props.raid.start_date.split(' ')[1] : '00:00:00',
                    formatted: props.raid ? props.raid.start_date : dateTimeStringNow('am')
                },
                end_date: {
                    date: props.raid ? props.raid.end_date.split(' ')[0] : format(new Date(), 'YYYY-MM-DD'),
                    time: props.raid ? props.raid.end_date.split(' ')[1] : '23:59:59',
                    formatted: props.raid ? props.raid.start_date : dateTimeStringNow('pm')
                },
                address: (props.raid && props.raid.address) || '',
                notes: (props.raid && props.raid.notes) || '',
                staff: (props.raid && props.raid.users) || [],
                documents: props.documents || [],
                ...logged_time
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // If the data was saved, with no errors, clear the form.
        if((prevProps.saving && !this.props.saving) && !this.props.error) {
            this.clearForm();
        }

        if(haveDatesChanged(prevState.form, this.state.form)) {
            this.setState({
                datesChanged: true
            });
        }
    }

    clearForm = () => {
        this.setState({
            id: null,
            hasChanged: false,
            form: {
                type: '',
                start_date: {
                    date: format(new Date(), 'YYYY-MM-DD'),
                    time: '00:00:00',
                    formatted: dateTimeStringNow('am')
                },
                end_date: {
                    date: format(new Date(), 'YYYY-MM-DD'),
                    time: '23:59:59',
                    formatted: dateTimeStringNow('pm')
                },
                address: '',
                staff: [],
                documents: [],
                notes: '',
                logged_time: '',
                additional_logged_time: ''
            }
        });
    }

    handleChange = (e, k) => {
        let key = k || getEventKey(e);

        let val = k && k.includes('logged_time') ? e : getEventVal(e);

        this.setState({
            hasChanged: true,
            form: {
                ...this.state.form,
                [key]: val
            },
        });
    }

    removeStaff = (user) => {
        this.setState(state => removeStaffFromState(state, user.id));

        // DELETE request to remove the staff if this action exists and the user is assigned to it.
        if(this.state.id && this.props.raid.users.find(u => u.id === user.id)) {
            this.props.onStaffRemove(this.state.id, user.id);
        }
    }

    removeFile = (index) => {
        let document = this.state.form.documents[index];

        this.setState(state => removeFileFromState(state, index));

        // If the document has an ID, then it's already been uploaded, so we need to actually delete it.
        if(document && document.id) {
            this.props.onFileRemove(document.id);
        }
    }

    handleSave = (stayOpen) => {
        let {
            id,
            form,
        } = this.state;

        let data = {
            raid: {
                start_date: form.start_date.formatted,
                end_date: form.end_date.formatted,
                address: form.address,
                notes: form.notes,
                user_ids: form.staff.map(staff => staff.id)
            },
            logged_time: convertLoggedTimeToArray(form),
        };

        // If there are already documents assigned to the case, make sure we don't try post them again.
        data = filterDocumentsAndAddToObject(form.documents, this.props.documents, data);

        this.props.onSave(id, data, stayOpen);
    }

    render() {
        let {
            datesChanged,
            hasChanged,
            form: {
                start_date,
                end_date,
                documents,
                notes,
                staff,
                address,
                logged_time,
                additional_logged_time,
            }
        } = this.state;

        let {
            onCancel,
            onDelete,
            saving,
            handleRejectedFiles
        } = this.props;

        let requiredPopulated = allTrue([start_date, end_date, staff, address]);

        return(
            <Fragment>
                <FormGroup center={true}>
                    <FormItemGroup
                        labelWidth={"150px"}
                        wide={true}
                    >
                        <FormItem
                            label={"Raid Start Date"}
                            id={'raid_start_date'}
                            minWidthInput={true}
                            required={true}
                        >
                            <DateTimeWrap>
                                <DateTimeInput
                                    id={"raid_start_date"}
                                    value={start_date.date}
                                    handleChange={val => this.setState(state => updateDateState(state, val, 'start_date'))}
                                />
                                <TimeSwitch
                                    onChange={val => this.setState(state => updateTimeState(state, val, 'start_date'))}
                                    value={start_date.time}
                                    prefix={"start"}
                                />
                            </DateTimeWrap>
                        </FormItem>

                        <FormItem
                            label={"Raid End Date"}
                            id={'raid_end_date'}
                            minWidthInput={true}
                            required={true}
                        >
                            <DateTimeWrap>
                                <DateTimeInput
                                    id={"raid_end_date"}
                                    value={end_date.date}
                                    handleChange={val => this.setState(state => updateDateState(state, val, 'end_date'))}
                                />
                                <TimeSwitch
                                    onChange={val => this.setState(state => updateTimeState(state, val, 'end_date'))}
                                    value={end_date.time}
                                    prefix={"end"}
                                />
                            </DateTimeWrap>
                        </FormItem>

                        <FormItem
                            label={"Address"}
                            id={'address'}
                            align={'top'}
                            required={true}
                        >
                            <TextArea
                                value={address}
                                id={'address'}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem
                            label={"Raid Staff"}
                            id={'staff'}
                            align={'top'}
                            required={true}
                        >
                            <Grid cols={[[1, 0], [2, 370], [3, 521]]} gap={'10px'}>
                                { staff.map(user => (
                                    <ButtonListItem
                                        onRemove={() => this.removeStaff(user)}
                                        showControls={true}
                                        key={`investigator${user.id}`}
                                        title={user.name}
                                    >{ user.name }</ButtonListItem>
                                )) }
                                <UserDropdownContainer
                                    addUser={staff => this.setState((state) => addStaffToState(state, staff))}
                                    exclude={staff.map(user => user.id)}
                                    available_from={start_date.formatted}
                                    available_to={end_date.formatted}
                                    invalid={datesChanged && !staff.length}
                                />
                            </Grid>
                        </FormItem>

                        <FormItem label={"Logged Time"} id={'logged_time'}>
                            <AssignLoggedTime
                                value={logged_time.value}
                                id={'logged_time'}
                                onChange={e => this.handleChange(e, 'logged_time')}
                            />
                        </FormItem>

                        <FormItem label={"Additional Logged Time"} id={'additional_logged_time'}>
                            <AssignLoggedTime
                                value={additional_logged_time.value}
                                id={'additional_logged_time'}
                                onChange={e => this.handleChange(e, 'additional_logged_time')}
                            />
                        </FormItem>

                        <FormItem label={"Notes"} id={'notes'} align={'top'}>
                            <TextArea
                                value={notes}
                                id={'notes'}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem label={"Associated files"} id={'files'} align={'top'}>
                            <FileUploader
                                files={documents}
                                handleAcceptedFiles={file => this.setState(state => addFileToState(state, file))}
                                handleRejectedFiles={handleRejectedFiles}
                                onRemove={this.removeFile}
                                deleting={this.props.deleting}
                                cols={2}
                            />
                        </FormItem>
                    </FormItemGroup>
                </FormGroup>

                <ModalButtons pl={'100px'} wide={true}>
                    <Button
                        blue={true}
                        small={true}
                        onClick={() => this.handleSave(false)}
                        disabled={saving || !hasChanged || !requiredPopulated}
                    >Save</Button>
                    <Button
                        small={true}
                        blue={true}
                        onClick={() => this.handleSave(true)}
                        disabled={saving || !hasChanged || !requiredPopulated}
                    >Save and add another</Button>

                    { this.state.id &&
                        <Button
                            red={true}
                            small={true}
                            onClick={() => onDelete(this.state.id)}
                        >Delete</Button>
                    }
                    <Link el={'button'} onClick={onCancel}>Cancel</Link>
                </ModalButtons>
            </Fragment>
        );
    }
}

export default EditRaidForm;