import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from '../Buttons';

const Footer = styled.footer`
	margin-top: 40px;
	padding-top: 17px;
	border-top: ${({ theme }) => theme.border.main};

	div {
		max-width: 275px;
		margin-left: auto;
	}

	button {
		width: 100%;
		margin-top: 45px;
	}
`;

const Row = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	p {
		margin: 0;
	}

	+* {
		margin-top: 10px;
	}
	
	&:last-of-type {
		padding-top: 15px;
		border-top: ${({ theme }) => theme.border.main};
		font-weight: 500;
		text-transform: uppercase;
	}
`;

const InvoiceFooter = ({ subtotal, vat, total, onSubmit }) => {
	return (
		<Footer>
			<div>
				<Row>
					<p>Subtotal</p>
					<p>{ subtotal }</p>
				</Row>
				<Row>
					<p>VAT</p>
					<p>{ vat }</p>
				</Row>
				<Row>
					<p>Total (GBP)</p>
					<p>{ total }</p>
				</Row>

				<Button
					chunkyish
					blueLight
					onClick={onSubmit}
				>Create invoice and resolve case</Button>
			</div>
		</Footer>
	)
}

InvoiceFooter.propTypes = {
	subtotal: PropTypes.string,
	vat: PropTypes.string,
	total: PropTypes.string,
	onSubmit: PropTypes.func,
}

export default InvoiceFooter
