import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// TODO: Find better solution for this.

const StyledLinkEl = styled(Link)`
    display: inline-block;
    color: ${({ theme }) => theme.blue};
    text-decoration: none;
    
    ${({ theme, small }) => small && `
        font-size: ${theme.fontSize.bodySm};
    `};
    
    ${({ theme, plain }) => plain && `
        color: ${theme.darkText};
        font-size: ${theme.fontSize.bodySm};
    `};
`;

const StyledAnchorEl = styled.a`
    display: inline-block;
    color: ${({ theme }) => theme.blue};
    text-decoration: none;
    
    ${({ theme, small }) => small && `
        font-size: ${theme.fontSize.bodySm};
    `};
    
    ${({ theme, plain }) => plain && `
        color: ${theme.darkText};
        font-size: ${theme.fontSize.bodySm};
    `};
`;

const StyledButtonEl = styled.button`
    display: inline-block;
    padding: 0;
    border: 0;
    margin-top: 0;
    margin-bottom: 0;
    background: none;
    color: ${({ disabled, theme }) => disabled ? theme.greyLight : theme.blue};
    font-size: ${({ theme }) => theme.fontSize.bodyMd};
    transition: color ${({ theme }) => theme.duration.quick};
    text-decoration: none;
    cursor: ${({ disabled }) => disabled ? 'initial' : 'pointer'};
    
    &:hover {
        color: ${({ disabled, theme }) => disabled ? theme.greyLight : theme.blueLight}};
    }
    
    ${({ theme, small }) => small && `
        font-size: ${theme.fontSize.bodySm};
    `};
    
    ${({ theme, plain }) => plain && `
        color: ${theme.darkText};
        font-size: ${theme.fontSize.bodySm};
    `};
    
    ${({ theme, underline }) => underline && `
        position: relative;
        color: ${theme.blue};
        padding-bottom: 3px;
        cursor: pointer;
        
        &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background: ${theme.blueFaint}
        }
    `};
    
    ${({ red, theme }) => red && `
        color: ${theme.red};
        
        &:hover {
            color: ${theme.redLight};
        }
    `};
`;

const StyledLink = ({ el, children, ...props }) => {
    if(el === 'button') {
        return <StyledButtonEl {...props}>{ children }</StyledButtonEl>
    }

    if(el === 'anchor') {
        return <StyledAnchorEl {...props}>{ children }</StyledAnchorEl>
    }

    return <StyledLinkEl {...props}>{ children }</StyledLinkEl>
}

export default StyledLink;