import React from 'react';
import styled from 'styled-components';

import { Button } from '../Buttons';
import { listPlain } from '../../utils/style';

const FloatingNavWrap = styled.nav`
    position: fixed;
    width: 185px;
    padding-left: 20px;
    
    ul {
        ${listPlain()}
    }
    
    li {
        +li {
            margin-top: 14px;
        }
    }
    
    ${Button} {
        width: 100%;
        text-align: left;
    }
    
    +* {
        width: calc(100% - 185px);
        margin-left: 185px;
    }
`;

const ButtonNav = ({ title, items }) => {
    return(
        <FloatingNavWrap>
            <ul>
                { items.map((item, i) => (
                    <li key={item.label+i}>
                        <Button>{item.label}</Button>
                    </li>
                )) }
            </ul>
        </FloatingNavWrap>
    );
}

ButtonNav.defaultProps = {
    items: [
        {
            label: 'Menu item'
        },
        {
            label: 'Menu item'
        },
        {
            label: 'Menu item'
        },
        {
            label: 'Menu item'
        },
        {
            label: 'Menu item'
        },

    ]
}

export default ButtonNav;