import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { TraderDropdownContainer } from '../../containers/Case';

import {
    FormGroup,
    FormItemGroup,
    FormItem,
    Input,
    TextArea,
} from '../Forms';
import { ModalButtons } from '../Modals';
import { Button } from '../Buttons';
import { Link } from '../Text';

import { getEventKey, getEventVal } from '../../utils/dataMap';
import { lineBreaksFromArray } from '../../utils/textTransforms';

const updateTraderState = (data) => {
    return {
        business_address: lineBreaksFromArray(data['business_address']),
        business_name: data['business_name'] || '',
        contact_address: lineBreaksFromArray(data['contact_address']),
        contact_name: data['contact_name'] || '',
        notes: data['notes'] || '',
        email: data['email'] || '',
        phone: data['phone'] || '',
    }
}

class TraderForm extends Component {
    constructor(props) {
        super(props);

        let {
            id,
            onSave,
            onCancel,
            traderIds,
            ...fields
        } = props;

        this.state = {
            hasChanged: false,
            id: props.id || null,
            editing: !props.id,
            form: updateTraderState(fields),
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // If the data was saved, with no errors, clear the form.
        if((prevProps.saving && !this.props.saving) && !this.props.error) {
            this.clearForm();
        }
    }

    handleChange = (e, k) => {
        let key = k || getEventKey(e);

        this.setState({
            hasChanged: true,
            form: {
                ...this.state.form,
                [key]: getEventVal(e)
            },
        });
    }

    handleBusinessChange = (e) => {
        let {
            id,
            ...fields
        } = e;

        this.setState({
            id,
            hasChanged: true,
            editing: false,
            form: updateTraderState(fields)
        });
    }

    handleSave = (stayOpen) => {
        let { id, form } = this.state;

        this.props.onSave(id, form, stayOpen);
    }

    clearForm = () => {
        this.setState({
            hasChanged: false,
            id: null,
            form: {
                business_address: '',
                business_name: '',
                contact_address: '',
                contact_name: '',
                notes: '',
                email: '',
                phone: '',
            }
        });
    }

    render() {
        let {
            id,
            hasChanged,
            editing,
            form: {
                business_name,
                business_address,
                contact_name,
                contact_address,
                email,
                phone,
                notes,
            }
        } = this.state;

        let {
            onCancel,
            exclude,
            saving,
        } = this.props;

        let requiredPopulated = contact_name;

        return(
            <Fragment>
                <FormGroup center={true}>
                    <FormItemGroup labelWidth={"120px"} wide={true}>
                        <FormItem
                            label={"Contact Name"}
                            id={'contact_name'}
                            minWidthInput={true}
                            required={true}
                        >
                            <TraderDropdownContainer
                                value={contact_name}
                                id={'contact_name'}
                                onChange={this.handleBusinessChange}
                                disabled={!!this.props.id}
                                exclude={exclude}
                            />
                            { this.props.id && !editing &&
                                <Link
                                    el={'button'}
                                    onClick={() => this.setState({ editing: true })}
                                    style={{
                                        marginLeft: '10px'
                                    }}
                                >Edit</Link>
                            }
                        </FormItem>

                        <FormItem label={"Contact Address"} id={'contact_address'} align={'top'}>
                            <TextArea
                                value={contact_address}
                                id={'contact_address'}
                                type={"text"}
                                onChange={this.handleChange}
                                disabled={!!(!editing && id)}
                            />
                        </FormItem>

                        <FormItem label={"Email"} id={'email'} minWidthInput={true}>
                            <Input
                                value={email}
                                id={'email'}
                                type={"email"}
                                onChange={this.handleChange}
                                disabled={!!(!editing && id)}
                            />
                        </FormItem>

                        <FormItem label={"Phone"} id={'phone'} minWidthInput={true}>
                            <Input
                                value={phone}
                                id={'phone'}
                                type={"tel"}
                                onChange={this.handleChange}
                                disabled={!!(!editing && id)}
                            />
                        </FormItem>

                        <FormItem
                            label={"Business Name"}
                            id={'business_name'}
                            minWidthInput={true}
                        >
                            <Input
                                value={business_name}
                                id={'business_name'}
                                type={"text"}
                                onChange={this.handleChange}
                                disabled={!!(!editing && id)}
                            />
                        </FormItem>

                        <FormItem label={"Business Address"} id={'business_address'} align={'top'}>
                            <TextArea
                                value={business_address}
                                id={'business_address'}
                                type={"text"}
                                onChange={this.handleChange}
                                disabled={!!(!editing && id)}
                            />
                        </FormItem>

                        <FormItem
                            label={"Notes"}
                            id={'notes'}
                            align={'top'}
                        >
                            <TextArea
                                value={notes}
                                id={'notes'}
                                type={"text"}
                                onChange={this.handleChange}
                                disabled={!!(!editing && id)}
                            />
                        </FormItem>
                    </FormItemGroup>
                </FormGroup>

                <ModalButtons pl={'120px'} wide={true}>
                    <Button
                        small={true}
                        blue={true}
                        onClick={() => this.handleSave(false)}
                        disabled={saving || !hasChanged || !requiredPopulated}
                    >Save</Button>
                    <Button
                        small={true}
                        blue={true}
                        onClick={() => this.handleSave(true)}
                        disabled={saving || !hasChanged || !requiredPopulated}
                    >Save and add another</Button>
                    <Link el={'button'} onClick={onCancel}>Cancel</Link>
                </ModalButtons>
            </Fragment>
        );
    }
}

TraderForm.propTypes = {
    id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    traderIds: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    ),
    exclude: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    ),
    saving: PropTypes.bool,
}

export default TraderForm;