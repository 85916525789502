import React from 'react';
import { format, isAfter } from 'date-fns';

import { CardDetails, GreyCard } from '../Cards';
import { Link, Small } from '../Text';
import { Button } from '../Buttons';
import { getLoggedTimeString } from '../../utils/textTransforms';
// TODO: Refetch raids when opening modal
// const RaidCard = ({ raid: { id, booked_by, start_date, end_date, mileage, products_seized, trader, status }, documents, logged_time, onEdit, onComplete, onView }) => {
const RaidCard = ({ raid: { id, address, booked_by, start_date, end_date, mileage, products_seized, trader, status }, documents, logged_time, onEdit, onComplete, onView }) => {
    start_date = format(start_date, 'DD/MM/YYYY HH:mm');

    let isCompleted = status === 'completed',
        needsCompleting = isAfter(new Date(), end_date) && !isCompleted;

    const loggedTimeString = getLoggedTimeString(logged_time);

    if(isCompleted) {
        onEdit = onView;
        onComplete = onView;
    }

    return(
        <GreyCard>
            <CardDetails
                title={`Raid Date ${start_date}`}
                titleStyle={'rounded orange'}
                onClick={() => onEdit(id)}
            >
                { address && <p>Location: { address }</p> }
                <p>by user: { booked_by.name }</p>
                <p>Product(s) seized:</p>
                { products_seized ? products_seized.map(prod => <Small style={{ display: 'block', lineHeight: '18px', fontWeight: '400' }} key={`raidProd${prod.bag_no}`}>{prod.product} <i>({prod.quantity}) (bag {prod.bag_no})</i></Small>) : <p>N/A</p> }
            </CardDetails>

            <CardDetails onClick={() => onEdit(id)}>
                <p>Time Logged: { loggedTimeString }</p>
                <p>Mileage Logged: { mileage ? `${mileage} miles` : 'N/A' }</p>
            </CardDetails>

            <CardDetails
                title={'Associated files:'}
                documents={true}
                noBackground={true}
                innerBackground={true}
            >
                { documents.map(doc => <GreyCard mini={true} key={`doc${doc.id}`}><a href={doc.url} target={"_blank"} style={{ fontSize: '12px', wordBreak: 'break-all' }}>{ doc.name }</a></GreyCard>) }
                { needsCompleting ?
                    <Button
                        onClick={() => onComplete(id)}
                        blueLight={true}
                        chunkyish={true}
                    >Add Raid Outcome</Button>
                    :
                    !isCompleted ?
                        <Link small={true} underline={true} el={'button'} onClick={() => onEdit(id)}>Edit/add details</Link>
                        :
                        <Link small={true} underline={true} el={'button'} onClick={() => onView(id)}>View details</Link>
                }
            </CardDetails>
        </GreyCard>
    );
}

export default RaidCard;