import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import createHistory from 'history/createBrowserHistory';
import { createLogger } from 'redux-logger';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from "redux-sentry-middleware";

import { version } from '../../package.json';
import reducers from '../reducers';

if( process.env.NODE_ENV === 'production' ) {
    Sentry.init({
        dsn: 'https://2bfd183c24cf4652bfaafea64038a704@sentry.io/1288764',
        attachStackTrace: true,
        release: version,
        beforeBreadcrumb(breadcrumb, hint) {
            if(breadcrumb.category === 'ui.click') {
                if(hint && hint.event && hint.event.target) {
                    if(hint.event.target.id) {
                        breadcrumb.message += ` - clicked element: #${hint.event.target.id}`;
                    }
                }
            }

            return breadcrumb;
        },
        beforeSend(event, hint) {
            // Check if it is an exception, and if so, show the report dialog
            if (event.exception) {
                Sentry.showReportDialog({ eventId: event.event_id });
            }
            return event;
        }
    });
}

export const history = createHistory();

const logger = createLogger({
    collapsed: true
});

let initialState = {};
const enhancers = [];
const middleware = [
    thunk,
    routerMiddleware(history),
]

if( process.env.NODE_ENV === 'production' ) {
    middleware.push(createSentryMiddleware(Sentry));
}

if( 'REDUX_DATA' in window ) {
    initialState = window.REDUX_DATA;
    delete window.REDUX_DATA;
}

if( process.env.NODE_ENV === 'development' ) {
    const devToolsExtension = window.devToolsExtension;

    if (typeof devToolsExtension === 'function') {
        enhancers.push(devToolsExtension())
    }

    middleware.push(logger);
}

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

const store = createStore(
    reducers,
    initialState,
    composedEnhancers
);

export default store;