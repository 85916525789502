import React, { Component, Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    getCaseVehicles,
    addCaseVehicleAndAssign,
    unassignCaseVehicle,
    assignCaseVehicle,
    updateCaseVehicles,
    updateCaseVehicle,
} from '../../actions/cases';

import { FormGroup } from '../../components/Forms';
import {
    DragVehicles,
    VehicleForm,
} from '../../components/Vehicles';
import {
    Modal,
    ModalContent,
} from '../../components/Modals';
import {
    Error,
    ErrorBoundary,
    Loading,
} from '../../components/Utils';

class CaseVehiclesContainer extends Component {
    state = {
        modalOpen: false,
        editing: null,
        stayOpen: false,
    }

    componentDidMount() {
        this.props.getCaseVehicles(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        // If we have saved successfully with no errors and 'add another' hasn't been chosen, close the modal.
        if((prevProps.saving && !this.props.saving) && !this.props.error && !this.state.stayOpen) {
            this.setState({
                modalOpen: false,
                editing: null,
                stayOpen: false,
            });
        }
    }

    updateVehicles = (newItems) => {
        this.props.updateCaseVehicles(this.props.match.params.id, newItems.map(item => item.id));
    }

    removeVehicle = (id) => {
        if(window.confirm('Are you sure you want to remove this vehicle?')) {
            this.props.unassignCaseVehicle(this.props.match.params.id, id);
        }
    }

    saveVehicle = (id, params, stayOpen = false) => {
        let { match } = this.props;

        if(id) {
            let vehicleIsPresent = this.props.vehicles.find(vehicle => vehicle.id === id);

            // Trader already exists, so warn the user before updating.
            if(vehicleIsPresent) {
                let confirmation = window.confirm('Changes made to this vehicle will affect all instances. Are you sure you want to do this?');

                if(confirmation) {
                    this.props.updateCaseVehicle(id, params);
                }
            } else {
                // Trader exists, but needs to be assigned to this case.
                params.id = id;
                this.props.assignCaseVehicle(match.params.id, params);
            }
        } else {
            // Trader needs to be created and then assigned to the case.
            this.props.addCaseVehicleAndAssign(match.params.id, params);
        }

        this.setState({ stayOpen });
    }

    openEditVehicleModal = (id) => {
        this.setState({
            modalOpen: true,
            editing: this.props.vehicles.find(item => item.id === id)
        });
    }

    openNewVehicleModal = () => {
        this.setState({
            modalOpen: true,
            editing: null
        });
    }

    closeModal = () => {
        this.setState({
            modalOpen: false
        });
    }

    render() {
        let {
            loggedIn,
            vehicles,
            fetching,
            saving,
            error,
        } = this.props;

        let vehicleIds = vehicles.map(vehicle => vehicle.id);

        let { modalOpen, editing } = this.state;

        if(!loggedIn) return <Redirect to='/login' />

        return(
            <Fragment>
                <FormGroup
                    title={"Associated Vehicles"}
                    id={"case-vehicles"}
                    saving={saving}
                    border={'top'}
                    noMinHeight={true}
                >
                    { fetching ? <Loading /> :
                        <ErrorBoundary render={() => <Error overlay={true} />}>
                            <DragVehicles
                                items={vehicles}
                                onClick={this.openEditVehicleModal}
                                onAddNew={this.openNewVehicleModal}
                                onMove={this.updateVehicles}
                                onDelete={this.removeVehicle}
                            />
                        </ErrorBoundary>
                    }
                </FormGroup>

                <Modal
                    isOpen={modalOpen}
                    onRequestClose={this.closeModal}
                >
                    <ModalContent title={`${editing ? 'Edit' : 'Add'} Associated Vehicle`}>
                        <ErrorBoundary render={() => <Error overlay={true} />}>
                            <VehicleForm
                                {...editing}
                                saving={saving}
                                error={error}
                                exclude={vehicleIds}
                                onSave={this.saveVehicle}
                                onCancel={this.closeModal}
                            />
                        </ErrorBoundary>
                    </ModalContent>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        vehicles: state.case.data.vehicles.data,
        fetching: state.case.data.vehicles.fetching,
        saving: state.case.data.vehicles.saving,
        error: state.case.data.vehicles.error,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getCaseVehicles, updateCaseVehicles, updateCaseVehicle, addCaseVehicleAndAssign, assignCaseVehicle, unassignCaseVehicle }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseVehiclesContainer));