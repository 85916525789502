import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { hasPermission } from '../../utils/validation';
import { getCaseDocuments } from '../../actions/cases';
import { deleteDocument } from '../../actions/documents';

import {
    FormGroup,
    FileUploader,
} from '../../components/Forms';

import {
    Loading,
    Error,
    ErrorBoundary,
} from '../../components/Utils';

class CaseDocumentsContainer extends Component {
    componentDidMount() {
        this.props.getCaseDocuments(this.props.match.params.id, {
            limit: 100
        });
    }

    deleteFile = (index, id) => {
        this.props.deleteDocument(id);
    }

    render() {
        let {
            self,
            loggedIn,
            documents,
            fetching,
            deleting,
        } = this.props;

        const userHasPermission = hasPermission(self, 'documents:read');

        if(!loggedIn) return <Redirect to='/login' />
        if(!documents || !documents.length || !userHasPermission) return null;

        return(
            <FormGroup
                title={"Related Documents"}
                id={"related-documents"}
                border={'top'}
            >
                { fetching ? <Loading /> :
                    <ErrorBoundary render={() => <Error overlay={true} />}>
                        <FileUploader
                            files={documents}
                            readOnly={true}
                            onRemove={this.deleteFile}
                            deleting={deleting}
                        />
                    </ErrorBoundary>
                }
            </FormGroup>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        self: state.user.self,
        documents: state.case.data.documents.data,
        fetching: state.case.data.documents.fetching,
        deleting: state.case.data.documents.deleting,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getCaseDocuments, deleteDocument }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseDocumentsContainer));