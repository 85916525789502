import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Title } from '../Text';
import { Loading } from '../Utils';
import { media } from '../../utils/style';

const FormGroupWrap = styled.fieldset`
    position: relative;
    padding: 0;
    margin: 0;
    border-radius: ${({ theme }) => theme.radius.small};
    background: ${({ background, theme }) => background === 'all' ? theme.greyLightest : 'none'};
    
    ${({ border, theme }) => border === 'top' ? 
        `
            border: 0;
            border-top: ${theme.border.main};
        `
    :
        border === 'all' ?
            `border: ${theme.border.main};`
        :
            `border: none;`
    }
    
    h3 {
        padding: 26px 36px;
        background: ${({ background, theme }) => background === 'title' ? theme.greyLightest : 'none'};
    }
    
    >div {
        padding: ${({ padChildren }) => padChildren ? '0 36px 40px' : '0'};
       
        >button:not([type="button"]) {
            position: absolute;
            bottom: 30px;
            right: 36px;
        }
        
        ${({ padBottom }) => padBottom && `
            @media screen and (max-width: 649px) {
                padding-bottom: 80px;
            }
        `};
        
        @media screen and (max-width: 479px) {
            padding: ${({ padChildren }) => padChildren ? '0 15px 20px' : '0'};
            
            >button:not([type="button"]) {
                bottom: 20px;
                right: 15px;
            }
            
            ${({ padBottom }) => padBottom && `
                padding-bottom: 67px;
            `};
        }
    }
    
    + fieldset,
    + * {
        margin-top: 30px;
    }
`;

const FormGroupInner = styled.div`
    position: relative;
    min-height: ${({ noMinHeight }) => noMinHeight ? '0' : '200px'};
    
    ${({ center }) => center && `
        display: flex;
        justify-content: center;
    `}
`;

class FormGroup extends Component {
    render() {
        let { title, children, center, saving, noMinHeight, ...props } = this.props;

        return(
           <FormGroupWrap {...props}>
               { title && <Title>{ title }</Title> }
               <FormGroupInner center={center} noMinHeight={noMinHeight}>
                   { children }
               </FormGroupInner>
               { saving && <Loading overlay={true} /> }
           </FormGroupWrap>
        );
    }
}

FormGroup.defaultProps = {
    titleInside: false,
    noMinHeight: true,
}

FormGroup.propTypes = {
    border: PropTypes.oneOf(['all', 'top']),
    background: PropTypes.oneOf(['all', 'title']),
    padChildren: PropTypes.bool,
    focused: PropTypes.bool,
    children: PropTypes.node,
}

export default FormGroup;