import React from 'react';

import { Page, ContentArea } from './';
import { MainNav } from '../Nav';
import { ReactComponent as Cases } from '../../svg/icon-cases.svg';
import { ReactComponent as Customers } from '../../svg/icon-customers.svg';
import { ReactComponent as Reporting } from '../../svg/icon-reporting.svg';
import { ReactComponent as Users } from '../../svg/icon-users.svg';
import { ReactComponent as Settings } from '../../svg/icon-settings.svg';

const menu = [
    {
        path: '/cases',
        label: 'Cases',
        Icon: Cases
    },
    {
        path: '/customers',
        label: 'Customers',
        Icon: Customers
    },
    {
        path: '/reports',
        label: 'Reports',
        Icon: Reporting,
        permissions: 'users:write',
    },
    {
        path: '/users',
        label: 'User Management',
        Icon: Users,
    },
    {
        path: '/settings',
        label: 'Settings',
        Icon: Settings
    },
];

const PageWithMainNav = ({ children }) => {
    return(
        <Page>
            <MainNav items={menu} />
            <ContentArea>
                { children }
            </ContentArea>
        </Page>
    );
}

export default PageWithMainNav;