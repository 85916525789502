// This is a HOC for sharing 'lock' functionality. This merely provides CSS and event listeners.
// Everything else should be handled by a parent component/container.
import React, { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Button } from '../components/Buttons';
import { Grid } from '../components/Layout';
import { Link } from '../components/Text';
import { darkAfter } from '../utils/style';
import { checkIfClickedInside, checkIfAncestorsHaveClass } from '../utils/interaction';

const lockable = (Component) => {
    const LockedOuter = styled.div`
        ${({ locked }) => !locked && darkAfter()}
        
        + fieldset {
            margin-top: 30px;
        }
    `;

    const LockedWrap = styled.div`
        position: relative;
        
        >button {
            position: absolute;
            bottom: 30px;
            right: 36px;
            z-index: 3;
            
            &:first-of-type:not(:last-of-type) {
                right: 92px;
                
                @media screen and (max-width: 479px) {
                    bottom: 22px;
                    right: 73px;
                }
            }
            
            &:last-of-type:not(:first-of-type) {
                bottom: 34px;
                
                @media screen and (max-width: 479px) {
                    bottom: 26px;
                    right: 17px;
                }
            }
            
            @media screen and (max-width: 479px) {
                bottom: 20px;
                right: 15px;
            }
        }
    `;

    const LockedInner = styled.div`
        position: relative;
        pointer-events: ${({ locked }) => locked ? 'none' : 'all'};
        
        ${({ locked, theme }) => !locked ? `
            z-index: 3;
        ` : `
            input,
            select,
            textarea,
            .input-dropdown *,
            .investigators-field >div,
            input[type="radio"] +label {
                background: ${theme.greyLighter}!important;
                border-color: rgba(201, 201, 201, .8)!important;
                color: ${theme.darkText}!important;
            }
        `}
    `;

    class LockedComponent extends React.Component {
        constructor(props) {
            super(props);

            this.inner = React.createRef();
            this.button = React.createRef();
        }

        componentDidMount() {
            window.addEventListener('keydown', this.handleKeyDown);
        }

        componentWillUnmount() {
            window.removeEventListener('keydown', this.handleKeyDown);
        }

        handleKeyDown = (e) => {
            // Disable editing if escape key is pressed.
            if(e.which === 27 && !this.props.locked) {
                this.props.toggleLock();
            }

            // Detect tab presses to handle focuses outside the locked component
            if(e.which === 9 && !this.props.locked) {
                this.handleTabs(e.target);
            }
        }

        handleTabs = (node) => {
            // TODO: Handle tabs
        }

        handleClick = (e) => {
            // Detect clicks outside of lockable fieldset, which will disable editing.
            if(this.props.locked) return;

            const clickedInside = checkIfClickedInside(e.target, this.inner.current);
            // Ignore if the user has clicked the save button
            const clickedOnButton = e.target === this.button.current;
            // Ignore if clicked inside modal. Used for date input mobile view.
            const clickedInModal = e.target.classList && Array.from(e.target.classList).includes('ReactModal__Overlay');
            const nestedInModal = !!checkIfAncestorsHaveClass(e.target, 'ReactModal__Overlay');

            if(!clickedInside && !clickedOnButton && !clickedInModal && !nestedInModal) {
                this.props.toggleLock();
            }
        }

        handleButtonClick = () => {
            let { locked } = this.props;

            if(locked) {
                this.props.toggleLock();
            } else {
                this.props.saveAndLock();
            }
        }

        render() {
            let {
                locked,
                saving,
                fetching,
                saveDisabled,
                id,
                unlockable,
                ...props
            } = this.props;

            return(
                <LockedOuter
                    locked={locked}
                    onClick={this.handleClick}
                >
                    <LockedWrap>
                        <LockedInner locked={locked} {...props} innerRef={this.inner}>
                            <Component {...this.props} />
                        </LockedInner>
                        { unlockable &&
                            <Fragment>
                                <Button
                                    small={true}
                                    blue={true}
                                    onClick={this.handleButtonClick}
                                    disabled={saving || fetching || (saveDisabled && !locked)}
                                    innerRef={this.button}
                                >{ locked ? 'Edit' : 'Save' }</Button>

                                { !locked && (
                                    <Link
                                        el={'button'}
                                        onClick={this.props.toggleLock}
                                        data-testid={'unlock'}
                                    >Cancel</Link>
                                ) }
                            </Fragment>
                        }
                    </LockedWrap>
                </LockedOuter>
            );
        }
    }

    LockedComponent.propTypes = {
        locked: PropTypes.bool,
        saving: PropTypes.bool,
        unlockable: PropTypes.bool,
        toggleLock: PropTypes.func,
        saveAndLock: PropTypes.func,
    }

    return LockedComponent;
}

lockable.propTypes = {
    Component: PropTypes.element
}

export default lockable;