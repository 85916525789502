import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getVehicles } from '../../actions/vehicles';
import { AsyncSelect } from '../../components/Forms';

class VehicleDropdownContainer extends Component {
    state = {
        vehicles: []
    }

    static getDerivedStateFromProps(props, state) {
        if(!!state.vehicles.length && !props.exclude) return null;
        if(!props.vehicles.length) return null;

        let { vehicles, exclude } = props;

        if(exclude) {
            vehicles = vehicles.filter(vehicle => exclude.indexOf(vehicle.id) === -1);
        }

        vehicles = vehicles.filter(v => v.number_plate);

        return { vehicles }
    }

    updateInput = value => {
        // If coming from onChange event it's an object.
        let data = value;

        // It's a string from onInputChange. Check if this is a valid option, by finding an ID.
        if(typeof data === 'string') {
            data = {
                number_plate: data
            }
        }

        // onBlur for some reason triggers an onInputChange with an empty string, so skip the change if it's an empty string.
        if(!data || !data.number_plate) return;

        this.props.onChange(data);
    }

    getVehicles = () => {
        let { vehicles, getVehicles } = this.props;

        if(!!vehicles.length) return;

        getVehicles({ limit: 300 });
    }

    render() {
        let { fetching, value, disabled } = this.props;

        let { vehicles } = this.state;

        return(
            <AsyncSelect
                value={value}
                options={vehicles}
                placeholder={value}
                onMenuOpen={this.getVehicles}
                isLoading={fetching}
                allowCreateWhileLoading={fetching}
                isDisabled={disabled}
                onChange={this.updateInput}
                onInputChange={this.updateInput}
                onSelectResetsInput={false}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                getOptionLabel={item => item.number_plate}
                getOptionValue={item => item.number_plate}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        vehicles: state.vehicles.data,
        fetching: state.vehicles.fetching,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getVehicles }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(VehicleDropdownContainer));