import { combineReducers } from 'redux';

import { default as singleCase } from './case';
import cases from './cases';
import customer from './customer';
import customers from './customers';
import user from './user';
import users from './users';
import traders from './traders';
import vehicles from './vehicles';
import crimeTypes from './crimeTypes';
import sources from './sources';
import userRoles from './userRoles';
import paymentMethods from './paymentMethods';
import alerts from './alerts';

export default combineReducers({
    case: singleCase,
    cases,
    customer,
    customers,
    user,
    users,
    crimeTypes,
    traders,
    vehicles,
    sources,
    userRoles,
    paymentMethods,
    alerts,
});