import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'underscore';
import { format } from 'date-fns';

import { getCase, updateCase } from '../../actions/cases';

import { Grid } from '../../components/Layout';
import { StageIcons } from '../../components/Icons';
import { ButtonListItem } from '../../components/Buttons';

import Input from '../../components/Forms/Input';
import FormItem from '../../components/Forms/FormItem';
import FormItemGroup from '../../components/Forms/FormItemGroup';
import FormGroup from '../../components/Forms/FormGroup';
import LockableFormGroup from '../../components/Forms/LockableFormGroup';
import ToggleSwitch from '../../components/Forms/ToggleSwitch';
import DateTimeInput from '../../components/Forms/DateTimeInput';
import DateTimeWithReason from '../../components/Forms/DateTimeWithReason';

import {
    Loading,
    Error,
    ErrorBoundary,
    MetaData,
} from '../../components/Utils';

import { getEventVal, convertNullValues } from '../../utils/dataMap';
import { blurAllFields } from '../../utils/interaction';
import { hasPermission } from '../../utils/validation';

const StageIconsWrap = styled.div`
    width: 100%;
    padding: 20px 36px;
    border-radius: 3px;
    border: ${({ theme }) => theme.border.main};
    
    @media screen and (max-width: 549px) {
        padding: 10px 0;
    }
`;

export class CaseDetailsContainer extends Component {
    state = {
        hasChanged: false,
        form: {},
        locked: true,
    }

    static getDerivedStateFromProps(props, state) {
        if(!isEmpty(state.form) || !props.details) return null;

        let {
            title,
            customer,
            deadline,
            deadline_reason,
            crime_type,
            customer_reference,
            reference,
            source,
            is_retainer,
            assigned_users,
            status,
        } = props.details;

        source = source ? {
            ...source,
            label: source.title + ` (${source.type})`
        } : null;

        return {
            form: {
                customer: customer.name || '',
                title: title || '',
                customer_reference: customer_reference || '',
                reference: reference || '',
                source,
                assigned_users: assigned_users || [],
                is_retainer: is_retainer || false,
                is_potential: (status && status === 'potential') || false,
                crime_type: {
                    id: (crime_type && crime_type.id) || null,
                    name: (crime_type && crime_type.name) || '',
                },
                deadline: deadline || '',
                deadline_reason: deadline_reason || '',
            }
        }
    }

    componentDidMount() {
        this.props.getCase(this.props.match.params.id);
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.error && this.props.error === 'notFound') {
            this.props.history.push('/not-found');
        }

        if(prevProps.saving && !this.props.saving) {
            this.setState({
                locked: true
            });
        }

        if(prevState.form.source && prevState.form.source.type !== this.state.form.source.type) {
            this.setState({
                form: {
                    ...this.state.form,
                    is_retainer: false
                }
            });
        }

        if(prevState.locked === this.state.locked) return;

        if(this.state.locked) {
            blurAllFields();
        }
    }

    removeInvestigator = (id) => {
        let assigned_users = this.state.form.assigned_users.filter(user => user.id !== id);

        this.setState({
            hasChanged: true,
            form: {
                ...this.state.form,
                assigned_users
            }
        });
    }

    addInvestigator = (investigator) => {
        this.setState({
            hasChanged: true,
            form: {
                ...this.state.form,
                assigned_users: [...this.state.form.assigned_users, investigator]
            }
        });
    }

    changeDeadline = date => {
        this.setState({
            hasChanged: true,
            form: {
                ...this.state.form,
                deadline: format(date, 'YYYY-MM-DD HH:mm:ss')
            }
        });
    }

    saveAndLock = () => {
        let params = Object.assign({}, this.state.form);

        params.assigned_users = params.assigned_users.map(user => user.id);
        params.crime_type_id = params.crime_type.id;
        params.source_id = params.source.id;

        this.props.updateCase(this.props.match.params.id, params);
    }

    handleChange = (e, id) => {
        let targetId = id;

        if(!targetId) {
            targetId = e.target.id;
        }

        this.setState({
            hasChanged: true,
            form: {
                ...this.state.form,
                [targetId]: id ? e : getEventVal(e)
            }
        });
    }

    getRetainerInfo = () => {
        let { details } = this.props;
        let { form, locked } = this.state;

        let sourceType = locked ? details && details.source.type : form.source.type,
            isRetainer = locked ? details && details.is_retainer : form.is_retainer,
            customerIsRetainer = details ? details.customer.proactive_retainer || details.customer.reactive_retainer : false,
            typeNode = details ? sourceType === 'proactive' ? details.customer.proactive_retainer : details.customer.reactive_retainer : {},
            remaining = (typeNode && typeNode.cases_remaining) || '0',
            total = (typeNode && typeNode.cases_per_month) || '0';

        // If the form is unlocked, increment/decrement the remaining amount based on difference with props.
        if(!locked && customerIsRetainer) {
            isRetainer = form.is_retainer;

            // TODO: Rewrite this
            // If the type(pro/reactive) hasn't changed, just add/remove if the state is false/true
            if(details.source.type === form.source.type) {
                if(!!isRetainer !== !!details.is_retainer) {
                    if(isRetainer) {
                        remaining = parseInt(remaining - 1);
                    } else if(!isRetainer && remaining < total) {
                        remaining = parseInt(remaining + 1);
                    }
                }
            } else {
                // The type has changed, so it will be off by default. Only decrement if the toggle is switched on.
                if(isRetainer) {
                    remaining = parseInt(remaining - 1);
                }
            }
        }

        return {
            isRetainer,
            customerIsRetainer,
            retainerString: `${remaining} remaining of ${total}`,
            casesRemaining: remaining > 0
        }
    }

    renderDetails = () => {
        if(this.props.fetching) return <Loading delay={false} overlay={false} />

        let {
            locked,
            form: {
                title,
                customer_reference,
                reference,
                source,
                assigned_users,
                crime_type,
                deadline,
                deadline_reason,
                is_potential,
            }
        } = this.state;

        let {
            details,
            saving,
            sourcesComponent,
            userComponent,
            crimeTypeComponent,
            self,
        } = this.props;

        // Get nested containers from props so that we can pass un-connected components in tests. Kinda gross. TODO?
        let SourcesDropdown = sourcesComponent,
            UserDropdown = userComponent,
            CrimeTypeDropdown = crimeTypeComponent;

        let { customerIsRetainer, isRetainer, retainerString, casesRemaining } = this.getRetainerInfo();

        // Don't show the potential toggle if the case is closed
        let showPotentialToggle = details ? details.status === 'potential' || details.status === 'pending' : false;

        // Only show state values while editing.
        assigned_users = details ? locked && !saving ? details.assigned_users : assigned_users : [];

        let sourcesProp = {}

        if(details) {
            sourcesProp = {
                ...details.source,
                label: details.source.title + (details.source.type ? ` (${details.source.type})` : '')
            }
        }

        const disableDeadline = !hasPermission(self, 'cases:deadline:write');

        return(
            <Fragment>
                <FormItemGroup labelWidth={"135px"}>
                    <FormItem label={"Customer"} id={'customer'}>
                        <Input
                            value={details ? details.customer.name : ''}
                            id={'customer'}
                            type={"text"}
                            disabled={true}
                        />
                    </FormItem>

                    <FormItem label={"Case Title"} id={'title'} required={true}>
                        <Input
                            value={locked && !saving && details ? convertNullValues(details.title) : title}
                            id={'title'}
                            type={"text"}
                            onChange={this.handleChange}
                        />
                    </FormItem>

                    <FormItem label={"Reference"} id={'customer_reference'}>
                        <Grid cols={[[1, 0], [2, 426]]} gap={'10px'}>
                            <Input
                                value={locked && !saving && details ? convertNullValues(details.customer_reference) : customer_reference}
                                id={'customer_reference'}
                                type={"text"}
                                onChange={this.handleChange}
                            />
                            <Input
                                value={details ? convertNullValues(details.reference) : null}
                                id={'reference'}
                                type={"text"}
                                disabled={true}
                            />
                        </Grid>
                    </FormItem>

                    { sourcesComponent &&
                        <FormItem
                            label={"Source"}
                            id={'source'}
                            required={true}
                        >
                            <SourcesDropdown
                                value={locked && !saving ? sourcesProp : source}
                                id={'source'}
                                onChange={e => this.handleChange(e, 'source')}
                            />
                        </FormItem>
                    }

                    <FormItem
                        label={"Investigators"}
                        id={'assigned_users'}
                        align={'top'}
                        required={true}
                    >
                        <Grid
                            cols={[[1, 0], [2, 426]]}
                            gap={'10px'}
                            className={"investigators-field"}
                        >
                            { assigned_users.map(user => (
                                <ButtonListItem
                                    onRemove={() => this.removeInvestigator(user.id)}
                                    showControls={!locked}
                                    key={`investigator${user.id}`}
                                    title={user.name}
                                    data-testid={'user'}
                                >{ user.name }</ButtonListItem>
                            )) }
                            { !locked && userComponent &&
                                <UserDropdown
                                    addUser={this.addInvestigator}
                                    exclude={assigned_users.map(user => user.id)}
                                    addOnType={false}
                                />
                            }
                        </Grid>
                    </FormItem>

                    { crimeTypeComponent &&
                        <FormItem
                            label={"Crime Type"}
                            id={'crime_type'}
                            required={true}
                        >
                            <CrimeTypeDropdown
                                value={locked && !saving && details ? details.crime_type : crime_type}
                                onChange={data => this.handleChange(data, 'crime_type')}
                                id={'crime_type'}
                            />
                        </FormItem>
                    }

                    <FormItem label={"Next Deadline"} id={'deadline'}>
                        <DateTimeWithReason>
                            <DateTimeInput
                                id={'deadline'}
                                value={locked && !saving && details ? details.deadline : deadline}
                                disabled={disableDeadline}
                                handleChange={this.changeDeadline}
                            />
                            <Input
                                type={"text"}
                                id={'deadline_reason'}
                                value={locked && !saving && details ? convertNullValues(details.deadline_reason) : deadline_reason}
                                disabled={disableDeadline}
                                onChange={this.handleChange}
                            />
                        </DateTimeWithReason>
                    </FormItem>

                    { customerIsRetainer &&
                        <FormItem label={"Retainer Case"} id={'is_retainer'}>
                            <ToggleSwitch
                                checked={isRetainer}
                                id={"is_retainer"}
                                labelAfter={true}
                                label={retainerString}
                                onChange={this.handleChange}
                                disabled={!casesRemaining && !isRetainer}
                            />
                        </FormItem>
                    }

                    { showPotentialToggle &&
                        <FormItem label={"Potential Case"} id={'is_potential'}>
                            <ToggleSwitch
                                checked={locked && !saving && details ? details.status === 'potential' : is_potential}
                                id={"is_potential"}
                                onChange={this.handleChange}
                            />
                        </FormItem>
                    }
                </FormItemGroup>
            </Fragment>
        );
    }

    renderStages = () => {
        let stages = {
            created: null,
            raid: null,
            resolved: null,
            site_visit: null,
            test_purchase: null,
        }

        if(this.props.details && !this.props.fetching) {
            stages = this.props.details.stages;
        }

        return(
            <StageIconsWrap>
                <StageIcons stages={stages} size={'lg'} />
            </StageIconsWrap>
        );
    }

    render() {
        let {
            loggedIn,
            saving,
            fetching,
            self
        } = this.props,
        {
            locked,
            hasChanged,
        } = this.state;

        if(!loggedIn) return <Redirect to='/login' />

        let metaTitle = null;
        if(this.props.details) {
            metaTitle = this.props.details.title || this.props.details.customer.name;
        }

        return(
            <Fragment>
                <MetaData vars={{ title: metaTitle, type: 'Case' }} />

                <LockableFormGroup
                    title={"Case details"}
                    border="all"
                    background="all"
                    padChildren={true}
                    id={"case-details"}
                    locked={locked}
                    toggleLock={() => this.setState({ locked: !locked })}
                    saveAndLock={this.saveAndLock}
                    fetching={fetching}
                    saving={saving}
                    saveDisabled={!hasChanged}
                    noMinHeight={true}
                    padBottom={true}
                    unlockable={hasPermission(self, 'cases:write')}
                >
                    <ErrorBoundary render={() => <Error overlay={false} />}>
                        { this.renderDetails() }
                    </ErrorBoundary>
                </LockableFormGroup>

                <FormGroup
                    title={"Case Status"}
                    id={"case-status"}
                    noMinHeight={true}
                >
                    { this.renderStages() }
                </FormGroup>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        self: state.user.self,
        loggedIn: state.user.loggedIn,
        details: state.case.data.details.data,
        fetching: state.case.data.details.fetching,
        saving: state.case.data.details.saving,
        error: state.case.data.details.error,
        // fetching: true,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getCase, updateCase }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseDetailsContainer));