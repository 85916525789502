import React, { Component, Fragment } from 'react';
import ReactTimeout from 'react-timeout';
import PropTypes from 'prop-types';

import {
    FormGroup,
    FormItemGroup,
    FormItem,
    Input,
} from '../Forms';
import { ModalButtons } from '../Modals';
import { Button } from '../Buttons';
import { Link } from '../Text';

import {
    getEventKey,
    getEventVal,
} from '../../utils/dataMap';

class ProductForm extends Component {
    constructor(props) {
        super(props);

        this.nameInput = React.createRef();

        this.state = {
            form: {
                product: '',
                bag_no: '',
                quantity: 1,
            }
        }
    }

    componentDidMount() {
        if(this.props.focusOnMount) {
            this.setTimeout();
        }
    }

    setTimeout = () => this.props.setTimeout(this.focusInput, 250);

    focusInput = () => {
        if(!this.nameInput.current) return;

        this.nameInput.current.focus();
    }

    handleChange = (e, k) => {
        let key = k || getEventKey(e);

        this.setState({
            form: {
                ...this.state.form,
                [key]: getEventVal(e)
            }
        });
    }

    handleSave = () => {
        this.props.onSave(this.state.form);
    }

    render() {
        let {
            form: {
                product,
                quantity,
                bag_no,
            }
        } = this.state;

        const disabled = !product || !bag_no || quantity < 1;

        return(
            <Fragment>
                <FormGroup center={true}>
                    <FormItemGroup labelWidth={"93px"}>
                        <FormItem label={"Product Name"} id={'product_name'}>
                            <Input
                                value={product}
                                id={'product'}
                                onChange={this.handleChange}
                                minWidth={true}
                                type={'text'}
                                ref={this.nameInput}
                            />
                        </FormItem>

                        <FormItem label={"Bag No."} id={'bag_no'}>
                            <Input
                                value={bag_no}
                                id={'bag_no'}
                                onChange={this.handleChange}
                                minWidth={true}
                                type={'text'}
                            />
                        </FormItem>

                        <FormItem label={"Quantity"} id={'quantity'}>
                            <Input
                                value={quantity}
                                id={'quantity'}
                                onChange={this.handleChange}
                                minWidth={true}
                                type={'number'}
                            />
                        </FormItem>
                    </FormItemGroup>
                </FormGroup>

                <ModalButtons pl={'0'} alignRight={true}>
                    <Button
                        blue={true}
                        small={true}
                        onClick={this.handleSave}
                        disabled={disabled}
                    >Save</Button>
                    <Link el={'button'} onClick={this.props.onCancel}>Cancel</Link>
                </ModalButtons>
            </Fragment>
        );
    }
}

ProductForm.propTypes = {
    focusOnMount: PropTypes.bool,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
}

export default ReactTimeout(ProductForm);