import styled from 'styled-components';
import PropTypes from 'prop-types';

const IconButton = styled.button`
	display: flex;
	align-items: center;
	min-width: 42px;
	padding: 9px 0;
	border: 0;
	background: none;
	color: ${({ type, theme }) => {
		if(type === 'danger') return theme.redLight;
		
		return theme.blue;
	}};
	font-size: ${({ theme }) => theme.fontSize.titleSm};
	cursor: pointer;
	white-space: nowrap;

	svg {
		display: block;
		width: ${props => {
			if(props.size === 'sm') return '12px';

			return '15px';
		}};
		fill: currentColor;
	}

	>* {
		color: inherit;

		+* {
			margin-left: 10px;
		}
	}
`;

IconButton.propTypes = {
	type: PropTypes.oneOf([
		'danger',
	]),
	size: PropTypes.oneOf([
		'sm',
		'lg',
	]),
}

export default IconButton;
