import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';

import { SVG } from '../Utils';

const ArrowWrap = styled.button`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: 0;
    background: none;
    z-index: 2;
    opacity: ${({ theme, disabled }) => disabled ? theme.opacity.inactive : '1'};
    cursor: ${({ theme, disabled }) => disabled ? 'initial' : 'pointer'};
    
    ${({ prev }) => prev ? `
        left: 10px;
        transform: translateY(-50%) rotate(180deg);
    ` : `right: 10px;`};

    svg {
        width: 13px;
    }
    
    polygon {
        fill: ${({ theme }) => theme.grey}
    }
`;

const Arrow = ({ onClick, style, prev, disabled }) => (
    <ArrowWrap
        prev={prev}
        style={style}
        onClick={onClick}
        disabled={disabled}
    >
        <SVG path={'/svg/icon-chevron-acute.svg'} />
    </ArrowWrap>
);

const SliderWrap = styled.div`
    position: relative;
    
    .slick-slider {
        width: calc(100vw - 40px);
    }
`;

const Pagination = styled.div`
    position: relative;
    text-align: center;
    
    >div {
        margin-top: 20px;
    }
`;

class CardSlider extends Component {
    constructor(props) {
        super(props);

        this.slider = React.createRef();

        this.state = {
            current: 0
        }
    }

    settings = {
        slidesToShow: 1,
        infinite: false,
        autoplay: false,
        arrows: false,
        afterChange: i => this.goToSlide(i)
    }

    goToSlide = (slide) => {
        if(slide < 0) return;
        if(slide > this.props.total) return;

        this.slider.current.slickGoTo(slide);

        this.setState({
            current: slide
        });
    }

    render() {
        let {
            children,
            pagination,
            total,
            emptyText,
        } = this.props;
        let { current } = this.state;

        return(
            <SliderWrap>
                <Slider
                    ref={this.slider}
                    {...this.settings}
                >{ children }</Slider>

                { pagination &&
                    <Pagination>
                        { !!total && <div>{ current + 1 }/{ total }</div> }

                        { emptyText && !total &&
                            <div>{ emptyText }</div>
                        }

                        { !!total &&
                            <Fragment>
                                <Arrow
                                    onClick={() => this.goToSlide(current - 1)}
                                    disabled={current === 0}
                                    prev={true}
                                />
                                <Arrow
                                    onClick={() => this.goToSlide(current + 1)}
                                    disabled={current === total - 1}
                                />
                            </Fragment>
                        }
                    </Pagination>
                }
            </SliderWrap>
        );
    }
}

CardSlider.defaultProps = {
    pagination: true
}

export default CardSlider;