import React, { Component, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format, addMonths, isWeekend, eachDay, startOfMonth, endOfMonth, isFuture } from 'date-fns';
import { saveAs } from 'file-saver';

import { getUsers } from '../../actions/users';
import { flashAlert } from '../../actions/alerts';

import { Button } from '../../components/Buttons';
import {
    SearchBar,
} from '../../components/Forms';
import {
    Loading,
    Error,
    ErrorBoundary,
    SVG,
} from '../../components/Utils';
import {
    DataTable,
    Filters,
} from '../../components/Tables';
import { Strong } from '../../components/Text';
import { Modal, ModalContent } from '../../components/Modals';
import { ButtonCell } from '../../components/Tables/DataTable/components/DataTableCells';
import { WeekControls } from '../../components/Calendars/WeekCalendar/components';
import { BasicTable } from '../../components/Tables';

import { validateSort, hasPermission } from '../../utils/validation';
import { getDownload } from '../../utils/api';
import { addToLoggedTimeString, getStringFromTimeObject, getTimeObjectFromArray, getActivityTimes, buildActivityLoggedTime } from '../../utils/dates';

class LoggedTimeContainer extends Component {
    constructor(props) {
        super(props);

        let now = new Date();

        this.state = {
            users: null,
            modalOpen: false,
            activeCell: {},
            now,
            columns: [],
        }
    }

    componentDidMount() {
        this.getUsers({
            include_time: true
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.fetching && !this.props.fetching) {
            let users = this.mapTimeToUsers(this.props.users);

            this.setState({ users });
        }

        if((!prevState.users && this.state.users) || (prevState.now !== this.state.now)) {
            this.setState({
                columns: this.generateColumns()
            });
        }
    }

    mapTimeToUsers = (users) => {
        return users
            .filter(({ role }) => role === 'Team Member' || role === 'Admin')
            .map(({ monthly_time, ...fields }) => {
                let months = {}

                for(let year in monthly_time) {
                    for(let month in monthly_time[year]) {
                        let prettyMonth = format(`${year}-${month}`, 'YYYY-MM');

                        // Build object like { '2019-08': { '01': {}, 'total': {} } }
                        months[prettyMonth] = {
                            total: {
                                cases: [],
                                total: {
                                    total: null,
                                    activities: {}
                                },
                            }
                        }

                        for(let _case of monthly_time[year][month]) {
                            for(let time of _case.loggedTime) {
                                let day = format(time.created_at, 'DD');
                                // console.log('time', time)

                                if(!months[prettyMonth][day]) {
                                    months[prettyMonth][day] = {
                                        cases: [],
                                        total: {
                                            total: null,
                                            activities: {}
                                        },
                                    }
                                }

                                months[prettyMonth][day]['total'] = {
                                    total: addToLoggedTimeString(months[prettyMonth][day]['total']['total'], time),
                                    activities: buildActivityLoggedTime(months[prettyMonth][day]['total']['activities'], time),
                                }

                                months[prettyMonth]['total']['total'] = {
                                    total: addToLoggedTimeString(months[prettyMonth]['total']['total']['total'], time),
                                    activities: buildActivityLoggedTime(months[prettyMonth]['total']['total']['activities'], time),
                                }

                                if(!months[prettyMonth][day]['cases'].find(c => c.case.id === _case.case.id)) {
                                    months[prettyMonth][day]['cases'].push(_case);
                                }

                                if(!months[prettyMonth]['total']['cases'].find(c => c.case.id === _case.case.id)) {
                                    months[prettyMonth]['total']['cases'].push(_case);
                                }
                            }
                        }

                    }
                }

                // console.log('m', months)

                return {
                    ...fields,
                    months,
                }
            });
    }

    openModal = ({ original, column: { id } }) => {
        let { now } = this.state;

        let month = format(now, 'YYYY-MM'),
            key = id === 'total' ? 'total' : id.substring(8, id.length),
            date = id === 'total' ? format(now, 'MMMM YYYY') : format(id, 'DD MMMM YYYY');

        let activeCell = {
            ...original.months[month][key],
            user: original.name,
            date,
        }

        this.setState({
            modalOpen: true,
            activeCell,
        });
    }

    changeMonth = (modifier) => {
        this.setState(prev => ({
            now: addMonths(prev.now, modifier)
        }));
    }

    downloadFile = () => {
        let start_date = startOfMonth(this.state.now);
        let end_date = endOfMonth(this.state.now);

        if(isFuture(end_date)) {
            end_date = this.state.now;
        }

        start_date = format(start_date, 'YYYY-MM-DD');
        end_date = format(end_date, 'YYYY-MM-DD');

        getDownload('/user-time/export', {
            start_date,
            end_date,
        }, true)
            .then(blob => saveAs(blob, 'logged_time_report-'+start_date+'-'+end_date))
            .catch(e => {
                console.error(e);
                this.props.flashAlert('There was a problem downloading this file.', 'error');
            });
    }

    generateColumns = () => {
        let now = this.state ? this.state.now : new Date(),
            month = eachDay(startOfMonth(now), endOfMonth(now)),
            prettyMonth = format(now, 'YYYY-MM'),
            prettierMonth = format(now, 'MMMM YYYY');

        let columns = [
            {
                Header: '',
                columns: [
                    {
                        Header: 'Name',
                        accessor: 'name',
                        resizable: false,
                        width: 150,
                    }
                ],
            },
            {
                Header: () => (
                    <WeekControls
                        date={prettierMonth}
                        prevLabel={'Previous month'}
                        nextLabel={'Next month'}
                        onPrevClick={() => this.changeMonth(-1)}
                        onNextClick={() => this.changeMonth(1)}
                        canPrev={prettierMonth === format(new Date, 'MMMM YYYY')}
                        canNext={prettierMonth !== format(new Date, 'MMMM YYYY')}
                    />
                ),
                columns: []
            }
        ];

        for(let _day of month) {
            let day = format(_day, 'DD'),
                prettyDay = format(_day, 'ddd D'),
                dayId = format(_day, 'YYYY-MM-DD'),
                weekend = isWeekend(_day),
                isMonday = format(_day, 'd') === 0;

            if(!weekend) {
                columns[1]['columns'].push({
                    Header: prettyDay,
                    id: dayId,
                    accessor: d => {
                        let time = d['months'];

                        if(time[prettyMonth] && time[prettyMonth] && time[prettyMonth][day]) {
                            return time[prettyMonth][day]['total']['total'];
                        } else {
                            return '';
                        }
                    },
                    Cell: props => <ButtonCell {...props} onClick={this.openModal} style={{ borderLeft: isMonday ? '1px solid red' : '0' }} />
                });
            }
        }

        columns[1]['columns'].push({
            Header: 'Total',
            id: 'total',
            accessor: t => t.months[prettyMonth] ? t.months[prettyMonth]['total']['total']['total'] : '',
            Cell: props => <ButtonCell {...props} onClick={this.openModal} />
        });

        return columns;
    }

    getUsers = (newParam = {}) => {
        this.props.getUsers({
            limit: 100,
            ...this.props,
            ...newParam
        });
    }

    filterUsers = (search) => {
        let users = this.props.users.filter(u => u.name.includes(search));
        users = this.mapTimeToUsers(users);

        this.setState({
            users
        });
    }

    handleSortChange = (sortArray) => {
        // Only allow sorting by the following keys.
        let sortParams = validateSort(['name', 'created_at'], sortArray);

        if(!sortParams.length) return;

        // Query the server with the provided sort params and reset to page 1.
        this.getCustomers({
            sortBy: sortParams[0]['id'],
            sort: sortParams[0]['desc'] ? 'DESC' : 'ASC',
            page: 1
        });
    }
    
    renderLoggedTimeTable = () => {
        let { activeCell: { cases, total, user, date } } = this.state;

        if(!cases) return null;

        return(
            <Fragment>
                <div style={{
                    marginBottom: '25px'
                }}>
                    <p><Strong>Name:</Strong> { user }</p>
                    <p><Strong>Date:</Strong> { date }</p>
                </div>

                <BasicTable>
                    <thead>
                    <tr>
                        <th>Case</th>
                        <th>Logged time</th>
                    </tr>
                    </thead>

                    <tbody>
                        { cases.map((c) => {
                            let loggedTime = c.loggedTime.filter(({ created_at }) => {
                                // If total cell date will be like June 2019
                                if(isNaN(date.charAt(0))) {
                                    return true;
                                } else {
                                    return format(created_at, 'DD MMMM YYYY') === date
                                }
                            });

                            let activityTimes = getActivityTimes(loggedTime);

                            loggedTime = getTimeObjectFromArray(loggedTime);
                            loggedTime = getStringFromTimeObject(loggedTime);

                            return(
                                <Fragment key={c.case.id}>
                                    <tr>
                                        <td>
                                            <Strong>{ c.case.title }</Strong>
                                        </td>
                                        <td>
                                            <Strong>{ loggedTime }</Strong>
                                        </td>
                                    </tr>

                                    { Object.keys(activityTimes).map(key => {
                                        return(
                                            <tr
                                                className={'small-row'}
                                                key={c.case.id + '-' + key}
                                            >
                                                <td>{ key }</td>
                                                <td>{ activityTimes[key] }</td>
                                            </tr>
                                        );
                                    }) }
                                </Fragment>
                            );
                        }) }

                        <tr>
                            <td><strong>Total</strong></td>
                            <td><strong>{ total.total }</strong></td>
                        </tr>

                        { Object.keys(total.activities).map(key => {
                            return(
                                <tr
                                    className={'small-row'}
                                    key={`total${key}`}
                                >
                                    <td>{ key }</td>
                                    <td>{ total.activities[key] }</td>
                                </tr>
                            );
                        }) }
                    </tbody>
                </BasicTable>
            </Fragment>
        );
    }

    render() {
        let {
            fetching,
            loggedIn,
            self,
        } = this.props;

        let {
            users,
            modalOpen,
            columns,
        } = this.state;

        const userHasPermission = hasPermission(self, 'users:write');

        if(!loggedIn) return <Redirect to='/login' />

        if(!userHasPermission && !fetching) return <Redirect to='/not-found' />

        if(fetching) return <Loading />

        return(
            <Fragment>
                <Filters>
                    <ErrorBoundary render={() => <Error overlay={true} />}>
                        <div className="filters__bottom">
                            <SearchBar
                                placeholder={"search users"}
                                onSubmit={val => this.filterUsers(val)}
                            />

                            <div className={'filters__download'}>
                                <Button
                                    blue
                                    onClick={this.downloadFile}
                                >
                                    Download
                                    <SVG path={'/svg/icon-download.svg'} />
                                </Button>
                            </div>
                        </div>
                    </ErrorBoundary>
                </Filters>

                <DataTable
                    data={users}
                    columns={columns}
                    manual={false}
                    noCellHeight={true}
                    styleType={'weekCalendarHeader'}
                    total={users ? users.length : 0}
                    limit={users ? users.length : 0}
                />

                <Modal
                    isOpen={modalOpen}
                    onRequestClose={() => this.setState({ modalOpen: false })}
                >
                    <ModalContent title={`Logged time`}>
                        { this.renderLoggedTimeTable() }
                    </ModalContent>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        users: state.users.data,
        self: state.user.self,
        exclusions: state.users.exclusions,
        fetching: state.users.fetching,
        deleting: state.users.deleting,
        reset: state.users.reset,
        error: state.users.error,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getUsers, flashAlert }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoggedTimeContainer);