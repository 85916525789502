import React, { Component, Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCaseRaids, addCaseRaid, updateCaseRaid, completeCaseRaid, deleteCaseRaid } from '../../actions/cases';
import { removeUserFromAction } from '../../actions/users';
import { deleteDocument } from '../../actions/documents';
import { flashAlert } from '../../actions/alerts';

import {
    RaidCard,
    EditRaidForm,
    CompleteRaidForm,
    ViewRaidForm,
} from '../../components/Raids';
import { Grid } from '../../components/Layout';
import { CardButton } from '../../components/Cards';
import { FormGroup } from '../../components/Forms';
import { Modal, ModalContent } from '../../components/Modals';
import { Loading, Error, ErrorBoundary } from '../../components/Utils';

class CaseRaidsContainer extends Component {
    state = {
        showModal: false,
        editing: null,
        completing: null,
        viewing: null,
        stayOpen: false,
    }

    componentDidMount() {
        this.props.getCaseRaids(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        // If we have saved successfully with no errors and 'add another' hasn't been chosen, close the modal.
        if((prevProps.saving && !this.props.saving) && !this.props.error && !this.state.stayOpen) {
            this.setState({
                modalOpen: false,
                editing: null,
                completing: null,
                viewing: null,
                stayOpen: false,
            });
        }
    }

    openEditModal = (id) => {
        this.setState({
            modalOpen: true,
            editing: this.props.raids.find(item => item.raid.id === id),
            completing: null,
            viewing: null,
        });
    }

    openCompleteModal = (id) => {
        this.setState({
            modalOpen: true,
            editing: null,
            completing: this.props.raids.find(item => item.raid.id === id),
            viewing: null,
        });
    }

    openNewModal = () => {
        this.setState({
            modalOpen: true,
            editing: null,
            completing: null,
            viewing: null,
        });
    }

    openViewModal = (id) => {
        this.setState({
            modalOpen: true,
            viewing: this.props.raids.find(item => item.raid.id === id),
            completing: null,
            editing: null,
        });
    }

    closeModal = () => {
        this.setState({
            modalOpen: false
        });
    }

    saveRaid = (id, params, stayOpen = false) => {
        let { match } = this.props,
            { completing, editing } = this.state;

        if(id) {
            if(completing) {
                if(window.confirm('Are you sure you want to complete this raid? Once you do, you won\'t be able to edit it.')) {
                    this.props.completeCaseRaid(match.params.id, id, params);
                }
            } else if(editing) {
                this.props.updateCaseRaid(match.params.id, id, params);
            }
        } else {
            this.props.addCaseRaid(match.params.id, params);
        }

        this.setState({ stayOpen });
    }

    deleteRaid = (id) => {
        if(window.confirm('Are you sure you want to delete this raid?')) {
            this.props.deleteCaseRaid(this.props.match.params.id, id);
        }
    }

    renderRaids = (items) => {
        return items.map(item => (
            <RaidCard
                onEdit={this.openEditModal}
                onComplete={this.openCompleteModal}
                onView={this.openViewModal}
                key={`raid${item.raid.id}`}
                {...item}
            />
        ));
    }

    render() {
        let {
            loggedIn,
            raids,
            details,
            fetching,
            saving,
            deleting,
            error,
        } = this.props;

        let {
            modalOpen,
            editing,
            completing,
            viewing,
        } = this.state;

        let hide = details && !details.source.raids;
        if(hide) return null;

        let ModalForm = EditRaidForm;
        if(completing) {
            ModalForm = CompleteRaidForm;
        } else if(viewing) {
            ModalForm = ViewRaidForm;
        }

        let modalTitle = 'Add Raid';
        if(editing) {
            modalTitle = 'Edit Raid';
        } else if(completing) {
            modalTitle = 'Complete Raid';
        } else if(viewing) {
            modalTitle = 'View Raid';
        }

        if(!loggedIn) return <Redirect to='/login' />

        return(
            <Fragment>
                <FormGroup
                    title={"Raids"}
                    id={"raids"}
                    border={'top'}
                >
                    { fetching ? <Loading /> :
                        <ErrorBoundary render={() => <Error overlay={true} />}>
                            <Grid cols={[ [1, 0], [2, 480], [3, 901], [4, 1201], [5, 1380] ]}>
                                { this.renderRaids(raids) }
                                <CardButton
                                    label={'Add a raid'}
                                    onClick={this.openNewModal}
                                />
                            </Grid>
                        </ErrorBoundary>
                    }
                </FormGroup>

                <Modal
                    isOpen={modalOpen}
                    onRequestClose={this.closeModal}
                >
                    <ModalContent title={modalTitle}>
                        <ErrorBoundary render={() => <Error overlay={true} />}>
                            <ModalForm
                                {...editing}
                                {...viewing}
                                {...completing}
                                onSave={this.saveRaid}
                                onDelete={this.deleteRaid}
                                onCancel={this.closeModal}
                                onStaffRemove={(id, userId) => this.props.removeUserFromAction(this.props.match.params.id, id, userId, 'raids')}
                                onFileRemove={id => this.props.deleteDocument(id)}
                                handleRejectedFiles={msg => this.props.flashAlert(msg, 'error')}
                                saving={saving}
                                error={error}
                                deleting={deleting}
                            />
                        </ErrorBoundary>
                    </ModalContent>
                </Modal>
            </Fragment>
        );
    }
}
// TODO: Front-end validation for rejected files
const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        raids: state.case.data.raids.data,
        details: state.case.data.details.data,
        fetching: state.case.data.raids.fetching,
        saving: state.case.data.raids.saving,
        error: state.case.data.raids.error,
        deleting: state.case.data.documents.deleting,
        // fetching: true,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getCaseRaids, addCaseRaid, updateCaseRaid, completeCaseRaid, deleteCaseRaid, removeUserFromAction, deleteDocument, flashAlert }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseRaidsContainer));