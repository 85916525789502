import React, { Component, Fragment } from 'react';
import MediaQuery from 'react-responsive';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCustomers } from '../../actions/customers';

import {
    Tabs,
    ToggleSwitch,
    SearchBar,
} from '../../components/Forms';
import {
    Loading,
    Error,
    ErrorBoundary,
} from '../../components/Utils';
import {
    DataTable,
    Filters,
    TableCard
} from '../../components/Tables';
import { CardSlider } from '../../components/Cards';
import { DateTimeCell } from '../../components/Tables/DataTable/components/DataTableCells';

import { validateSort, isCaseClosed, hasPermission } from '../../utils/validation';
import { getInlineAddress } from '../../utils/textTransforms';
import theme from '../../config/theme';

class CustomersContainer extends Component {
    state = {
        search: ''
    }

    componentDidMount() {
        this.getCustomers();
    }

    columns = [
        {
            Header: 'Customer',
            accessor: 'name',
            Cell: props => <Link to={`/customers/${props.original.id}`}>{ props.value }</Link>,
            MobileCell: props => <Link to={`/customers/${props.original.id}`}>{ props.value }</Link>,
        },
        {
            Header: "Address",
            accessor: 'address',
            Cell: props => (
                <Link to={`/customers/${props.original.id}`} style={{ fontWeight: '400' }}>{ getInlineAddress(props.value) }</Link>
            ),
            MobileCell: props => (
                <Link to={`/customers/${props.original.id}`} style={{ fontWeight: '400' }}>{ getInlineAddress(props.value) }</Link>
            ),
            sortable: false,
        },
        {
            Header: "Cases per month",
            accessor: 'cases_per_month',
            maxWidth: 160,
            Cell: props => <Link to={`/customers/${props.original.id}`} style={{ fontWeight: '400' }}>{ props.value }</Link>,
            MobileCell: props => <Link to={`/customers/${props.original.id}`} style={{ fontWeight: '400' }}>{ props.value }</Link>,
            sortable: false,
        },
        {
            Header: "Last Edited",
            accessor: 'created_at',
            Cell: props => <Link to={`/customers/${props.original.id}`} style={{ fontWeight: '400' }}><DateTimeCell value={props.value} /></Link>,
            MobileCell: props => <Link to={`/customers/${props.original.id}`} style={{ fontWeight: '400' }}><DateTimeCell value={props.value} /></Link>,
            maxWidth: 190,
        },
    ];

    getCustomers = (newParam = {}) => {
        this.props.getCustomers({
            ...this.props,
            ...newParam
        });
    }

    handleSortChange = (sortArray) => {
        // Only allow sorting by the following keys.
        let sortParams = validateSort(['name', 'created_at'], sortArray);

        if(!sortParams.length) return;

        // Query the server with the provided sort params and reset to page 1.
        this.getCustomers({
            sortBy: sortParams[0]['id'],
            sort: sortParams[0]['desc'] ? 'DESC' : 'ASC',
            page: 1
        });
    }

    render() {
        let {
            customers,
            archived,
            retainer,
            type,
            page,
            fetching,
            error,
            meta,
            loggedIn,
        } = this.props;

        if(!loggedIn) return <Redirect to='/login' />

        let primary = [
            {
                label: 'All',
                id: 'customers-all',
                checked: !type || type === 'all',
                onChange: e => this.getCustomers({ type: 'all', page: 1 })
            },
            {
                label: 'Customers',
                id: 'customers-customers',
                checked: type === 'customer',
                onChange: e => this.getCustomers({ type: 'customer', page: 1 })
            },
            {
                label: 'Leads',
                id: 'customers-leads',
                checked: type === 'lead',
                onChange:  e => this.getCustomers({ type: 'lead', page: 1 })
            }
        ]

        return(
            <ErrorBoundary render={() => <Error />}>
                <Filters>
                    <SearchBar
                        placeholder={"search customers"}
                        onSubmit={val => this.getCustomers({ searchQuery: val, page: 1 })}
                        onChange={val => this.setState({ search: val })}
                        value={this.state.search}
                    />
                    <div className="filters__bottom">
                        <Tabs
                            name={`customersPrimaryFilters`}
                            items={primary}
                            handleChange={this.getCustomers}
                        />
                        <div className="filters__toggles">
                            <ToggleSwitch
                                label={'Show retainer'}
                                id={'show-retainer'}
                                onChange={e => this.getCustomers({ retainer: e.target.checked, page: 1 })}
                                checked={retainer}
                                key={`show-retainer`}
                            />
                            <ToggleSwitch
                                label={'Show archived'}
                                id={'show-archived'}
                                onChange={e => this.getCustomers({ archived: e.target.checked, page: 1 })}
                                checked={archived}
                                key={`show-archived`}
                            />
                        </div>
                    </div>
                </Filters>

                { fetching && !customers.length ? <Loading /> :
                    error ?
                        <Error message={error} />
                        :
                        <Fragment>
                            <MediaQuery query={'(max-width: 700px)'}>
                                <CardSlider
                                    total={customers.length}
                                    emptyText={"There are no customers."}
                                >
                                    { customers.map(c => (
                                        <TableCard
                                            data={c}
                                            columns={this.columns}
                                            key={`tableCard${c.id}`}
                                            firstColTitle={true}
                                            labelWidth={'120px'}
                                        />
                                    )) }
                                </CardSlider>
                            </MediaQuery>

                            <MediaQuery query={'(min-width: 701px)'}>
                                <DataTable
                                    data={customers}
                                    columns={this.columns}
                                    total={meta.total}
                                    pages={meta.last_page}
                                    page={page - 1}
                                    limit={Number(meta.per_page)}
                                    viewingFrom={meta.from}
                                    viewingTo={meta.to}
                                    manual={true}
                                    getTdProps={(state, rowInfo) => {
                                        if(!rowInfo) return {}

                                        return{
                                            style: {
                                                opacity: isCaseClosed(rowInfo.original.status) ? theme.opacity.inactive : '1'
                                            }
                                        }
                                    }}
                                    onPageChange={page => this.getCustomers({ page: page + 1 })}
                                    onPageSizeChange={size => this.getCustomers({ limit: size, page: 1 })}
                                    onSortChange={this.handleSortChange}
                                    fetching={fetching}
                                    noDataText={'There are no customers.'}
                                    noDataButtonText={'add a new customer'}
                                    noDataButtonLink={hasPermission(this.props.self, 'customers:write') ? '/customers/new' : null}
                                />
                            </MediaQuery>
                        </Fragment>
                }
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        self: state.user.self,
        customers: state.customers.data,
        links: state.customers.links,
        meta: state.customers.meta,
        page: state.customers.page,
        limit: state.customers.limit,
        sort: state.customers.sort,
        sortBy: state.customers.sortBy,
        archived: state.customers.archived,
        retainer: state.customers.retainer,
        type: state.customers.type,
        status: state.customers.status,
        searchQuery: state.customers.searchQuery,
        fetching: state.customers.fetching,
        error: state.customers.error,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getCustomers }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomersContainer);