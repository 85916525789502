import React, { Component, Fragment } from 'react';
import { format } from 'date-fns';

import { UserDropdownContainer } from '../../containers/User';
import { PaymentMethodsContainer } from '../../containers/Case';

import {
    AssignLoggedTime,
    FormGroup,
    FormItemGroup,
    FormItem,
    Input,
    DateTimeInput,
    DateTimeWrap,
    TimeInput,
    Tabs,
    TimeSwitch,
    TextArea,
    FileUploader,
} from '../Forms';
import { Grid } from '../Layout';
import { Link } from '../Text';
import { ModalButtons } from '../Modals';
import { Button, ButtonListItem } from '../Buttons';

import {
    getEventKey,
    getEventVal,
    addStaffToState,
    removeStaffFromState,
    updateDateState,
    updateTimeState,
    addFileToState,
    removeFileFromState,
    filterDocumentsAndAddToObject,
} from '../../utils/dataMap';

import {
    dateTimeStringNow,
    getLoggedTime,
    convertLoggedTimeToArray,
} from '../../utils/dates';
import { haveDatesChanged, allTrue } from '../../utils/validation';

class EditTestPurchaseForm extends Component {
    constructor(props) {
        super(props);

        const logged_time = getLoggedTime(props.logged_time);

        this.state = {
            datesChanged: false,
            hasChanged: false,
            id: (props.test_purchase && props.test_purchase.id) || null,
            form: {
                type: (props.test_purchase && props.test_purchase.type) || '',
                start_date: {
                    date: props.test_purchase ? props.test_purchase.start_date.split(' ')[0] : format(new Date(), 'YYYY-MM-DD'),
                    time: props.test_purchase ? props.test_purchase.start_date.split(' ')[1] : '00:00:00',
                    formatted: props.test_purchase ? props.test_purchase.start_date : dateTimeStringNow('am')
                },
                end_date: {
                    date: props.test_purchase ? props.test_purchase.end_date.split(' ')[0] : format(new Date(), 'YYYY-MM-DD'),
                    time: props.test_purchase ? props.test_purchase.end_date.split(' ')[1] : '23:59:59',
                    formatted: props.test_purchase ? props.test_purchase.end_date : dateTimeStringNow('pm')
                },
                authorized_by_user: props.test_purchase ? props.test_purchase.authorized_by_user : null,
                // In person
                address: (props.test_purchase && props.test_purchase.address) || '',
                staff: (props.test_purchase && props.test_purchase.users) || [],
                item: (props.test_purchase && props.test_purchase.item) || '',
                cost: (props.test_purchase && props.test_purchase.cost) || '',
                // Online
                website: (props.test_purchase && props.test_purchase.website) || '',
                purchase_status: (props.test_purchase && props.test_purchase.purchase_status) || '',
                delivery_date: (props.test_purchase && props.test_purchase.delivery_date) || '',
                payment_method: (props.test_purchase && props.test_purchase.payment_method) || '',
                documents: (props.documents && props.documents.length && props.documents) || [],
                ...logged_time
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // If the data was saved, with no errors, clear the form.
        if((prevProps.saving && !this.props.saving) && !this.props.error) {
            this.clearForm();
        }

        if(haveDatesChanged(prevState.form, this.state.form)) {
            this.setState({
                datesChanged: true
            });
        }
    }

    clearForm = () => {
        this.setState({
            datesChanged: false,
            id: null,
            form: {
                type: '',
                start_date: {
                    date: format(new Date(), 'YYYY-MM-DD'),
                    time: '00:00:00',
                    formatted: dateTimeStringNow('am')
                },
                end_date: {
                    date: format(new Date(), 'YYYY-MM-DD'),
                    time: '23:59:59',
                    formatted: dateTimeStringNow('pm')
                },
                authorized_by_user: null,
                // In person
                address: '',
                staff: [],
                item: '',
                cost: '',
                // Online
                website: '',
                purchase_status: '',
                delivery_date: '',
                payment_method: '',
                documents: [],
                logged_time: '',
                additional_logged_time: ''
            }
        });
    }

    handleSave = (stayOpen) => {
        let {
            id,
            form: {
                type,
                start_date,
                end_date,
                address,
                staff,
                website,
                purchase_status,
                authorized_by_user,
                delivery_date,
                payment_method,
                logged_time,
                additional_logged_time,
                documents,
                item,
                cost,
            }
        } = this.state;

        let data = {
            type,
            item,
            cost: cost.replace('£', ''),
            payment_method_id: payment_method ? payment_method.id : null,
            start_date: start_date.formatted,
            user_ids: staff.map(staff => staff.id),
            authorized_by_user_id: authorized_by_user && authorized_by_user.id,
        };

        if(type === 'in-person') {
            data = {
                ...data,
                address,
                end_date: end_date.formatted,
            }
        }

        if(type === 'online') {
            data = {
                ...data,
                website,
                purchase_status,
                delivery_date,
                end_date: start_date.formatted,
            }
        }

        data = {
            logged_time: convertLoggedTimeToArray({ logged_time, additional_logged_time }),
            test_purchase: {
                ...data
            }
        }

        // If there are already documents assigned to the case, make sure we don't try post them again.
        data = filterDocumentsAndAddToObject(documents, this.props.documents, data);

        this.props.onSave(id, data, stayOpen);
    }

    handleChange = (e, k) => {
        let key = k || getEventKey(e);

        let val = k && k.includes('logged_time') ? e : getEventVal(e);

        this.setState({
            hasChanged: true,
            form: {
                ...this.state.form,
                [key]: val
            },
        });
    }

    removeStaff = (user) => {
        this.setState(state => removeStaffFromState(state, user.id));

        // DELETE request to remove the staff if this action exists and the user is assigned to it.
        if(this.state.id && this.props.test_purchase.users.find(u => u.id === user.id)) {
            this.props.onStaffRemove(this.state.id, user.id);
        }
    }

    removeFile = (index) => {
        let document = this.state.form.documents[index];

        this.setState(state => removeFileFromState(state, index));

        // If the document has an ID, then it's already been uploaded, so we need to actually delete it.
        if(document && document.id) {
            this.props.onFileRemove(document.id);
        }
    }

    renderInPersonFields = () => {
        let { form: { address } } = this.state;

        return(
            <Fragment>
                <FormItem label={"Address"} id={'address'} align={'top'}>
                    <TextArea
                        value={address}
                        id={'address'}
                        onChange={this.handleChange}
                    />
                </FormItem>
            </Fragment>
        );
    }

    renderOnlineFields = () => {
        let { form: {
            website,
            purchase_status,
            delivery_date,
        } } = this.state;

        return(
            <Fragment>
                <FormItem label={"Website Address"} id={'website'} minWidthInput={true}>
                    <Input
                        value={website}
                        id={'website'}
                        type={"text"}
                        onChange={this.handleChange}
                    />
                </FormItem>

                <FormItem
                    label={"Purchase Status"}
                    id={'purchase_status'}
                    required={true}
                >
                    <Tabs
                        id={'purchase_status'}
                        name={'purchase_status'}
                        items={[
                            {
                                value: 'out-of-stock',
                                label: 'Out of stock',
                                checked: purchase_status === 'out-of-stock',
                                onChange: this.handleChange,
                                id: 'out-of-stock'
                            },
                            {
                                value: 'ordered',
                                label: 'Ordered',
                                checked: purchase_status === 'ordered',
                                onChange: this.handleChange,
                                id: 'ordered'
                            },
                        ]}
                        styleType={"blue"}
                    />
                </FormItem>

                { purchase_status === 'ordered' &&
                    <Fragment>
                        <FormItem label={"Test Purchase Delivery"} id={'delivery_date'}>
                            <DateTimeInput
                                value={delivery_date}
                                id={'delivery_date'}
                                handleChange={ value => this.handleChange({ target: { id: 'delivery_date', value: format(value, 'YYYY-MM-DD') } }) }
                                minWidth={true}
                            />
                        </FormItem>
                    </Fragment>
                }
            </Fragment>
        );
    }

    render() {
        let {
            hasChanged,
            datesChanged,
            form: {
                type,
                start_date,
                end_date,
                documents,
                staff,
                logged_time,
                additional_logged_time,
                authorized_by_user,
                payment_method,
                purchase_status,
                item,
                cost,
            }
        } = this.state;

        let {
            onCancel,
            saving,
            handleRejectedFiles
        } = this.props;

        // payment_method = getPaymentMethodInput(payment_method);

        let requiredPopulated = [type, start_date, end_date, staff, authorized_by_user, item];
        if(type === 'online') {
            requiredPopulated.push(purchase_status);
        }

        requiredPopulated = allTrue(requiredPopulated);

        return(
            <Fragment>
                <FormGroup center={true}>
                    <FormItemGroup labelWidth={"150px"} wide={true}>
                        <FormItem
                            label={"Purchase Type"}
                            id={'type'}
                            required={true}
                        >
                            <Tabs
                                id={'type'}
                                name={'type'}
                                items={[
                                    {
                                        value: 'online',
                                        label: 'Online',
                                        checked: type === 'online',
                                        onChange: this.handleChange,
                                        id: 'online'
                                    },
                                    {
                                        value: 'in-person',
                                        label: 'In person',
                                        checked: type === 'in-person',
                                        onChange: this.handleChange,
                                        id: 'in-person'
                                    }
                                ]}
                                styleType={"blue"}
                            />
                        </FormItem>

                        { type &&
                            <FormItem
                                label={"Start Date"}
                                id={'test_purchase_start_date'}
                                minWidthInput={true}
                                required={true}
                            >
                                <DateTimeWrap>
                                    <DateTimeInput
                                        id={"test_purchase_start_date"}
                                        value={start_date.date}
                                        handleChange={val => this.setState(state => updateDateState(state, val, 'start_date', type === 'online'))}
                                    />
                                    <TimeSwitch
                                        onChange={val => this.setState(state => updateTimeState(state, val, 'start_date'))}
                                        value={start_date.time}
                                        prefix={"start"}
                                    />
                                </DateTimeWrap>
                            </FormItem>
                        }

                        { type === 'in-person' &&
                            <FormItem
                                label={"End Date"}
                                id={'test_purchase_end_date'}
                                minWidthInput={true}
                                required={true}
                            >
                                <DateTimeWrap>
                                    <DateTimeInput
                                        id={"test_purchase_end_date"}
                                        value={end_date.date}
                                        handleChange={val => this.setState(state => updateDateState(state, val, 'end_date', type === 'online'))}
                                    />
                                    <TimeSwitch
                                        onChange={val => this.setState(state => updateTimeState(state, val, 'end_date'))}
                                        value={end_date.time}
                                        prefix={"end"}
                                    />
                                </DateTimeWrap>
                            </FormItem>
                        }

                        { type &&
                            <Fragment>
                                <FormItem
                                    label={"Staff"}
                                    id={'staff'}
                                    align={'top'}
                                    required={true}
                                >
                                    <Grid cols={[[1, 0], [2, 370], [3, 521]]} gap={'10px'}>
                                        { staff.map(user => (
                                            <ButtonListItem
                                                onRemove={() => this.removeStaff(user)}
                                                showControls={true}
                                                key={`investigator${user.id}`}
                                                title={user.name}
                                            >{ user.name }</ButtonListItem>
                                        )) }
                                        <UserDropdownContainer
                                            addUser={staff => this.setState((state) => addStaffToState(state, staff))}
                                            exclude={staff.map(user => user.id)}
                                            available_from={start_date.formatted}
                                            available_to={end_date.formatted}
                                            invalid={datesChanged && !staff.length}
                                        />
                                    </Grid>
                                </FormItem>

                                <FormItem
                                    label={"Authorised By"}
                                    id={'authorized_by_user'}
                                    minWidthInput={true}
                                    required={true}
                                >
                                    <UserDropdownContainer
                                        addUser={e => this.handleChange(e, 'authorized_by_user')}
                                        addOnType={true}
                                        value={authorized_by_user}
                                        // role_id={1}
                                    />
                                </FormItem>

                                <FormItem label={"Logged Time"} id={'logged_time'}>
                                    <AssignLoggedTime
                                        value={logged_time.value}
                                        id={'logged_time'}
                                        onChange={e => this.handleChange(e, 'logged_time')}
                                    />
                                </FormItem>

                                <FormItem label={"Additional Logged Time"} id={'additional_logged_time'}>
                                    <AssignLoggedTime
                                        value={additional_logged_time.value}
                                        id={'additional_logged_time'}
                                        onChange={e => this.handleChange(e, 'additional_logged_time')}
                                    />
                                </FormItem>

                                <FormItem
                                    label={"Item"}
                                    id={'item'}
                                    required={true}
                                >
                                    <Input
                                        value={item}
                                        id={'item'}
                                        onChange={this.handleChange}
                                        minWidth={true}
                                    />
                                </FormItem>

                                <FormItem label={"Cost"} id={'cost'}>
                                    <Input
                                        value={cost}
                                        id={'cost'}
                                        onChange={this.handleChange}
                                        minWidth={true}
                                    />
                                </FormItem>

                                <FormItem
                                    label={"Payment Method"}
                                    id={'payment_method'}
                                    minWidthInput={true}
                                    required={true}
                                >
                                    <PaymentMethodsContainer
                                        onChange={p => this.handleChange(p, 'payment_method')}
                                        addOnType={true}
                                        value={payment_method}
                                    />
                                </FormItem>
                            </Fragment>
                        }

                        { type === 'online' ? this.renderOnlineFields() : type === 'in-person' ? this.renderInPersonFields() : null }

                        <FormItem label={"Associated files"} id={'files'} align={'top'}>
                            <FileUploader
                                files={documents}
                                handleAcceptedFiles={file => this.setState(state => addFileToState(state, file))}
                                handleRejectedFiles={handleRejectedFiles}
                                onRemove={this.removeFile}
                                cols={2}
                            />
                        </FormItem>
                    </FormItemGroup>
                </FormGroup>

                <ModalButtons pl={'150px'} wide={true}>
                    <Button
                        blue={true}
                        small={true}
                        onClick={() => this.handleSave(false)}
                        disabled={saving || !hasChanged || !requiredPopulated}
                    >Save</Button>
                    <Button
                        small={true}
                        blue={true}
                        onClick={() => this.handleSave(true)}
                        disabled={saving || !hasChanged || !requiredPopulated}
                    >Save and add another</Button>
                    { this.state.id &&
                        <Button
                            red={true}
                            small={true}
                            onClick={() => this.props.onDelete(this.state.id)}
                        >Delete</Button>
                    }
                    <Link el={'button'} onClick={onCancel}>Cancel</Link>
                </ModalButtons>
            </Fragment>
        );
    }
}

export default EditTestPurchaseForm;