import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { IconLabel } from '../../../Icons';
import { Link } from '../../../Text';

const NoDataWrap = styled.div`
    margin-top: 35px;
    text-align: center;
    
    * {
        font-size: ${({ theme }) => theme.fontSize.titleSm}!important;
    }
    
    >p {
        margin-bottom: 15px;
        color: ${({ theme }) => theme.lightText};
    }
`;

const NoData = ({ text, buttonText, buttonLink, buttonOnClick }) => {
    let linkProps = buttonLink ? {
        to: buttonLink
    } : {
        el: 'button',
        onClick: buttonOnClick
    }

    return(
        <NoDataWrap>
            <p>{ text }</p>
            { buttonLink && <Link {...linkProps}><IconLabel size={'lg'} color={'blueLighter'} label={buttonText} /></Link> }
        </NoDataWrap>
    );
}

NoData.propTypes = {
    text: PropTypes.string,
    buttonText: PropTypes.string,
    noDataButtonLink: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    buttonOnClick: PropTypes.func,
}

NoData.defaultProps = {
    text: 'No data',
    buttonText: 'add a new item'
}

export default NoData;