import React from 'react';
import styled from 'styled-components';

import { SVG } from './';
import { overlayStyle } from '../../utils/style';

const ErrorWrap = styled.div`
    padding: 12px 14px;
    background: ${({ theme }) => theme.error};
    color: white;
    
    p {
        margin: 0;
    }
    
    ${({ overlay }) => overlay && overlayStyle()}
    
    ${({ withIcon, theme }) => withIcon && `
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 25px 15px;
        background: ${theme.white};
        
        svg {
            width: 30px;
            height: auto;
            margin-bottom: 10px;
        }
        
        path {
            fill: ${theme.blue};
        }
    `}
`;

const ErrorInner = styled.div`
    max-width: 200px;
    text-align: center;
`;

const Error = ({ message, overlay, withIcon }) => {
    return(
        <ErrorWrap overlay={overlay} withIcon={withIcon}>
            <ErrorInner>
                { withIcon && <SVG path={"/svg/icon-broken.svg"} /> }
                <p>{ message }</p>
            </ErrorInner>
        </ErrorWrap>
    );
}

Error.defaultProps = {
    message: 'Something has gone wrong.',
    withIcon: true,
}

export default Error;