import React, { Component } from 'react';

import { PageWithMainNav, PageTitleWrap } from '../components/Layout';
import { PageTitle } from '../components/Text';
import { BackButton } from '../components/Buttons';
import { ScrollToTopOnMount, MetaData } from '../components/Utils';

import { NewCustomerContainer } from '../containers/Customer';

class NewCustomer extends Component {
    render() {
        return(
            <PageWithMainNav>
                <ScrollToTopOnMount />
                <MetaData vars={{ title: 'New Customer' }} />

                <PageTitleWrap>
                    <PageTitle>New customer</PageTitle>
                    <BackButton
                        to={'/customers'}
                        small={true}
                        showLabel={true}
                        label={'Discard and return to customers'}
                    />
                </PageTitleWrap>

                <NewCustomerContainer />
            </PageWithMainNav>
        );
    }
}

export default NewCustomer;