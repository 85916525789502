import styled from 'styled-components';
import PropTypes from 'prop-types';

const FormItemGroup = styled.div`
    width: 100%;
    max-width: ${({ wide }) => wide ? '530px' : '410px'};
    
    >div {
        >label {
            width: ${({ labelWidth }) => labelWidth};
            text-align: right;

            @media screen and (max-width: 649px) {
                width: 100%;
                text-align: left;
            }
        }
    }
    
    .input-dropdown >div,
    input,
    select {
        //max-width: ${({ wide }) => wide ? '240px' : '100%'};
        //width: 100%;
        //width: 100%;
    }
`;

FormItemGroup.propTypes = {
    // Width of the largest label. Needed for centering form, while keeping labels right aligned.
    labelWidth: PropTypes.string.isRequired
}

FormItemGroup.defaultProps = {
    labelWidth: '150px'
}

export default FormItemGroup;