import styled from 'styled-components';

const ChunkyForm = styled.form`
    button {
        &:last-child {
            margin-top: 25px;
            
            @media screen and (max-width: 700px) {
                margin-top: 15px;
            }
        }
    }
    
    p {
        margin: 0 0 25px;
    }
`;

export default ChunkyForm;