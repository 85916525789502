import { format, isWithinRange, isBefore, isAfter } from 'date-fns';

import { validateTime } from './validation';

export const getTimeObjectFromString = (string, notNull) => {
    if(!string || !validateTime(string)) {
        if(notNull) {
            return {
                hours: 0,
                minutes: 0,
            }
        }

        return null;
    }

    return {
        hours: parseInt(string.split(/\s*[:./]\s*/)[0]),
        minutes: parseInt(string.split(/\s*[:./]\s*/)[1])
    }
}

export const prettyDate = (date) => {
    return format(date, 'Do MMMM');
}

export const getAMPM = (date) => {
    if(!date) return null;

    return format(date, 'A');
}

export const timeFromAMPM = (val, key) => {
    if(key === 'start_date') {
        return val === 'AM' ? '00:00:00' : '12:00:00';
    }

    return val === 'AM' ? '11:59:59' : '23:59:59';
}

export const dateTimeStringNow = (timeOfDay) => {
    return format(new Date(), 'YYYY-MM-DD') + ' ' + (timeOfDay === 'am' ? '00:00:00' : '23:59:59');
}

export const fancyMinutes = (minutes) => {
    if(typeof minutes === 'object') {
        let withHours = minutes.split(':');

        minutes = withHours[1] < 9 ? '0' + withHours[1] : withHours[1];

        return withHours[0] + ':' + minutes;
    }

    return minutes < 9 ? "0" + minutes : minutes;
}

export const getStringFromTimeObject = (time) => {
    if(!time) return '';

    if(!time.hours && !time.minutes) return '';

    return `${time.hours}:${fancyMinutes(time.minutes)}`;
}

export const getLoggedTime = (time) => {

    let timeObject = {
        logged_time: {
            value: '',
            valid: true,
        },
        additional_logged_time: {
            value: '',
            valid: true,
        },
    }


    if(time && time.length) {
        time = [...time];

        timeObject.logged_time.value = getStringFromTimeObject(time[0]);
        timeObject.logged_time.user_id = time[0].user.id;
        timeObject.logged_time.created_at = time[0].created_at;

        time.shift();

        // If there are still time objects left, add them up and convert them to a string
        if(time.length) {
            let additionalTime = time.reduce((acc, curr) => {
                return {
                    ...acc,
                    hours: acc.hours + curr.hours,
                    minutes: acc.minutes + curr.minutes,
                    user_id: curr.user.id,
                    created_at: curr.created_at,
                }
            }, { hours: 0, minutes: 0 });

            timeObject.additional_logged_time.value = getStringFromTimeObject(additionalTime);
            timeObject.additional_logged_time.user_id = time[0].user.id;
            timeObject.additional_logged_time.created_at = time[0].created_at;
        }
    }

    return timeObject;
}

export const convertLoggedTimeToArray = ({ logged_time, additional_logged_time }) => {
    let time = getTimeObjectFromString(logged_time.value, true);

    let timeObject = {
        ...time
    }

    if(logged_time.created_at) {
        timeObject.created_at = logged_time.created_at;
    }

    if(logged_time.user_id) {
        timeObject.user_id = logged_time.user_id;
    }

    time = [timeObject];

    // If valid additional time has been added, tack it on to the array
    if(additional_logged_time) {
        let moreTime = getTimeObjectFromString(additional_logged_time.value);

        let moreTimeObject = {
            ...moreTime
        }

        if(additional_logged_time.created_at) {
            moreTimeObject.created_at = additional_logged_time.created_at;
        }

        if(additional_logged_time.user_id) {
            moreTimeObject.user_id = additional_logged_time.user_id;
        }

        if(moreTime) {
            time = [...time, moreTimeObject];
        }
    }

    return time;
}

export const getAvailability = (day, exclusions) => {
    let AMInRange = exclusions.find(exclusion => isWithinRange(day + ' 00:00:00', exclusion.start_date, exclusion.end_date)),
        PMInRange = exclusions.find(exclusion => isWithinRange(day + ' 12:00:00', exclusion.start_date, exclusion.end_date));

    return {
        am: AMInRange ? isBefore(AMInRange.start_date, day + ' ' + '12:00:01') : false,
        pm: PMInRange ? isAfter(PMInRange.end_date, day + ' ' + '11:59:59') : false,
    }
}

export const getTimeObjectFromArray = (arr, key = null) => {
    return arr.reduce((acc, curr) => {
        curr = key ? curr[key] : curr;

        return {
            hours: acc.hours + curr.hours,
            minutes: acc.minutes + curr.minutes,
        }
    }, { hours: 0, minutes: 0 });
}

export const addToLoggedTimeString = (string, obj) => {
    if(!string) {
        return `${obj.hours}:${fancyMinutes(obj.minutes)}`;
    }

    let [hours, minutes] = string.split(':');

    hours = parseInt(hours) + obj.hours;
    minutes = parseInt(minutes) + obj.minutes;

    if( minutes >= 60 ) {
        hours += Math.floor(minutes / 60);
        minutes = minutes - 60;
    }

    return `${hours}:${fancyMinutes(minutes)}`;
}

export const getActivityTimes = (time) => {
    if(!time) return null;

    return time.reduce((acc, curr) => {
        let key = prettyActivity(curr.action_type);

        acc[key] = addToLoggedTimeString(acc[key], curr);

        return acc;
    }, {});
}

const prettyActivity = (str) => {
    return str
        .replace('App\\', '')
        .replace('SiteVisit', 'Site Visit')
        .replace('TestPurchase', 'Test Purchase');
}

export const buildActivityLoggedTime = (obj, time) => {
    let key = prettyActivity(time.action_type);

    obj[key] = addToLoggedTimeString(obj[key], time);

    return obj;
}