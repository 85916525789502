import styled from 'styled-components';

const FloatingSidebar = styled.div`
    position: fixed;
    width: 185px;
    padding: 30px 0 20px 20px;
    
    +* {
        width: calc(100% - 185px);
        margin-left: 185px;
    }
    
    @media screen and (max-width: 829px) {
        display: none;
        
        +* {
            width: 100%;
            margin: 0;
        }
    }
`;

export default FloatingSidebar;