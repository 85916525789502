import { get, checkAuthentication } from '../utils/api';
import { validateParams, dispatchErrors } from '../utils/validation';

export const getCrimeTypes = (params = {}) => {

    // Remove any invalid parameters
    let validatedParams = validateParams(['limit', 'page', 'sort'], params);

    return (dispatch) => {

        dispatch({ type: 'FETCH_CRIMETYPES' });

        return get('/crime-types', validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then((data) => {
                dispatch({ type: 'FETCH_CRIMETYPES_SUCCESS', crimeTypes: data, params: validatedParams })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CRIMETYPES_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}