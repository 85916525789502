import React from 'react';
import styled from 'styled-components';

const FormItemWrap = styled.div`
    display: flex;
    align-items: ${({ align }) => align === 'top' ? 'flex-start' : 'center'};
    font-size: ${({ theme }) => theme.fontSize.bodyMd};
    
    >label {
        position: relative;
        top: ${({ align }) => align === 'top' && '4px'};
        
        +* {
            margin-left: 18px;
            flex: ${({ minWidthInput }) => minWidthInput ? 'none' : '1 0 0'};
            
            ${({ minWidthInput }) => minWidthInput && `
                width: 240px;
            `};
        }
        
        ${({ required, theme }) => required && `
            &:after {
                position: absolute;
                right: -7px;
                display: inline-block;
                font-size: 11px;
                content: '*';
                color: ${theme.red};
                
                @media screen and (max-width: 649px) {
                    position: relative;
                    right: -3px;
                }
            }
        `}
    }
    
    +* {
        margin-top: 15px;
    }
    
    @media screen and (max-width: 649px) {
        flex-wrap: wrap;
        
        >label {
            top: auto;
            
            +* {
                margin: 5px 0 0;
            }
        }
    }
`;

const FormItem = ({ label, id, children, align, minWidthInput, required }) => {
    return(
        <FormItemWrap
            align={align}
            required={required}
            minWidthInput={minWidthInput}
        >
            { label && <label htmlFor={id}>{ label }</label> }
            { children }
        </FormItemWrap>
    );
}

FormItem.defaultProps = {
    align: 'center',
}

export default FormItem;