import React, { Component, Fragment } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCases } from '../../actions/cases';
import { getCustomerCases } from '../../actions/customers';

import CasesTable from '../../components/Tables/CasesTable';
import Filters from '../../components/Tables/Filters';

import {
    Tabs,
    ToggleSwitch,
    SearchBar,
} from '../../components/Forms';

import {
    Loading,
    Error,
    ErrorBoundary
} from '../../components/Utils';

import {
    StageCell,
    BoldCell,
    DateCell,
    DateTimeCell,
} from '../../components/Tables/DataTable/components/DataTableCells';

import { validateSort, hasPermission } from '../../utils/validation';

export class CasesContainer extends Component {
    constructor(props) {
        super(props);

        let columns = [
            {
                Header: 'Customer',
                accessor: c => c.customer.name,
                id: 'customer',
                Cell: props => {
                    return(
                        <Link
                            to={`/customers/${props.original.customer.id}`}
                            data-testid={`caseCustomer`}
                        >{ props.value }</Link>
                    )
                },
                MobileCell: props => {
                    return(
                        <Link
                            to={`/customers/${props.original.customer.id}`}
                            data-testid={`caseCustomer`}
                        >{ props.value }</Link>
                    )
                },
                width: 160,
            },
            {
                Header: "Case",
                accessor: 'title',
                Cell: props => <Link to={`/cases/${props.original.id}`}><BoldCell value={props.value} /></Link>,
                MobileCell: props => <Link to={`/cases/${props.original.id}`}><BoldCell value={props.value} /></Link>
            },
            {
                Header: "Case ID",
                accessor: 'customer_reference',
                width: 160,
                sortable: false,
                MobileCell: props => <Link to={`/cases/${props.original.id}`}><BoldCell value={props.value} /></Link>
            },
            {
                Header: "Stage",
                accessor: 'stages',
                Cell: StageCell,
                width: 160,
                sortable: false,
                MobileCell: StageCell
            },
            {
                Header: "Last Edited",
                accessor: 'created_at',
                Cell: DateTimeCell,
                maxWidth: 190,
                MobileCell: props => <Link to={`/cases/${props.original.id}`}><DateTimeCell value={props.value} /></Link>
            },
        ];

        if(!this.props.excludeDeadlineColumns) {
            columns = [
                ...columns,
                {
                    Header: "Deadline",
                    accessor: 'deadline',
                    Cell: props => <DateCell {...props} value={props.value} warn />,
                    maxWidth: 100,
                    MobileCell: props => <Link to={`/cases/${props.original.id}`}><DateCell {...props} value={props.value} warn /></Link>
                },
                {
                    Header: "Deadline Reason",
                    accessor: 'deadline_reason',
                    sortable: false,
                    width: 140,
                },
            ]
        }

        this.columns = columns;

        this.state = {
            search: '',
            status: props.fixedStatus ? props.fixedStatus : ['pending'],
        }
    }

    componentDidMount() {
        this.getCases();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevState.status.join(',') !== this.state.status.join(',')) {
            this.getCases({ page: 1 });
        }
    }

    getCases = (newParam = {}) => {
        let params = {
            ...this.props,
            status: this.state.status.join(','),
            searchQuery: this.props.fixedSearch ? this.props.fixedSearch+` ${this.state.search}` : this.state.search,
            ...newParam,
        }

        if('fixedMineOnly' in this.props) {
            params.mine_only = this.props.fixedMineOnly;
        }

        // Fetch customer cases with customer params instead
        if(this.props.isCustomer) {
            this.props.getCustomerCases(this.props.match.params.id, {
                ...this.props.customerCases,
                ...newParam,
                status: this.state.status.join(','),
                searchQuery: this.props.fixedSearch ? this.props.fixedSearch+` ${this.state.search}` : this.state.search
            });
        } else {
            this.props.getCases(params);
        }
    }

    handleSortChange = (sortArray) => {
        // Only allow sorting by the following keys.
        let sortParams = validateSort(['title', 'created_at', 'deadline', 'customer'], sortArray);

        if(!sortParams.length) return;

        // Query the server with the provided sort params and reset to page 1.
        this.getCases({
            sortBy: sortParams[0]['id'],
            sort: sortParams[0]['desc'] ? 'DESC' : 'ASC',
            page: 1
        });
    }

    changeSearchStatus = (type, add, exclude = []) => {
		let status = [...this.state.status];
		
		if(exclude.length) {
			if(add) {
				status = status.filter(status => !exclude.find(st => st === status));
			} else {
				status = [
					...status,
					...exclude,
				]
			}
		}

        if(!add) {
            status = status.filter(t => type !== t);
        } else if(!status.includes(type)) {
            status.push(type);
        }

        this.setState({ status });
    }

    renderTabs = () => {
        let {
            mine_only,
            isCustomer,
            customerCases,
        } = this.props;

        if(isCustomer) {
            mine_only = customerCases.mine_only;
        }

        if('fixedMineOnly' in this.props) {
            mine_only = this.props.fixedMineOnly;
        }

        let primary = [
            {
                label: 'All',
                id: 'cases-all',
                checked: !mine_only,
                onChange: e => this.getCases({ mine_only: false, page: 1 })
            },
            {
                label: 'My Cases',
                id: 'cases-mine',
                checked: mine_only,
                onChange:  e => this.getCases({ mine_only: true, page: 1 })
            }
        ];

        return(
            <Tabs
                name={`casesPrimaryFilters`}
                items={primary}
                handleChange={this.getCases}
            />
        );
    }

    render() {
        let {
            cases,
            archived,
            potential,
            page,
            fetching,
            match,
            meta,
            loggedIn,
            customerCases,
            customerDetails,
            isCustomer,
        } = this.props;

        let addCaseAction = hasPermission(this.props.self, 'cases:write') ? '/cases/new' : null;

        if(!loggedIn) return <Redirect to='/login' />

        if(isCustomer) {
            archived = customerCases.archived;
            fetching = customerCases.fetching;
            potential = customerCases.potential;
            cases = customerCases.data;
            meta = customerCases.meta;

            // Send the customer name and ID to /cases/new to pre-populate the customer field
            addCaseAction = hasPermission(this.props.self, 'cases:write') ? {
                pathname: '/cases/new',
                state: {
                    customer: {
                        id: match.params.id,
                        name: customerDetails.data ? customerDetails.data.name : ''
                    }
                }
            } : null;
        }

        return(
            <Fragment>
                <Filters>
                    <ErrorBoundary render={() => <Error overlay={true} />}>
                        <SearchBar
                            placeholder={"search cases"}
                            onSubmit={val => this.getCases({
                                searchQuery: this.props.fixedSearch ? this.props.fixedSearch+` ${val}` : val,
                                page: 1
                            })}
                            onChange={val => this.setState({ search: val })}
                            value={this.state.search}
                        />
                    </ErrorBoundary>

                    { this.props.fixedStatus ? null : (
                        <div className="filters__bottom">
                            { this.renderTabs() }

                            <div className="filters__toggles">
                                <ToggleSwitch
                                    label={'Show archived'}
                                    id={'show-archived'}
                                    onChange={e => this.changeSearchStatus('closed,invoiced', e.target.checked)}
                                    checked={archived}
                                    key={`show-archived`}
                                />

                                <ToggleSwitch
                                    label={'Show only potential cases'}
                                    id={'show-potential'}
                                    onChange={e => this.changeSearchStatus('potential', e.target.checked, ['pending'])}
                                    checked={potential}
                                    key={`show-potential`}
                                />
                            </div>
                        </div>
                    ) }
                </Filters>

                { fetching && !cases.length ? <Loading /> :
                    <CasesTable
                        cases={cases}
                        columns={this.columns}
                        meta={meta}
                        page={page}
                        noDataButtonLink={addCaseAction}
                        fetching={fetching}
                        onPageChange={page => this.getCases({ page: page + 1 })}
                        onPageSizeChange={size => this.getCases({ limit: size, page: 1 })}
                        onSortChange={this.handleSortChange}
                    />
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        self: state.user.self,
        cases: state.cases.data,
        links: state.cases.links,
        meta: state.cases.meta,
        page: state.cases.page,
        limit: state.cases.limit,
        sort: state.cases.sort,
        sortBy: state.cases.sortBy,
        archived: state.cases.archived,
        potential: state.cases.potential,
        mine_only: state.cases.mine_only,
        fetching: state.cases.fetching,
        error: state.cases.error,
        customerCases: state.customer.data.cases,
        customerDetails: state.customer.data.details,
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ getCases, getCustomerCases }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CasesContainer));