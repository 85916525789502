import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { SROnly } from '../Utils';

const TextAreaWrap = styled.div`
    width: 100%;
    
    textarea {
        display: block;
        width: 100%;
        height: 75px;
        padding: 8px 12px;
        border: ${({ borderless, theme }) => borderless ? '0' : theme.border.main};
        line-height: 140%;
        background: ${({ theme }) => theme.whiteish};
        font-weight: 400;
        font-size: ${({ theme }) => theme.fontSize.bodyMd};
        resize: none;
    }
`;

const TextArea = ({ srLabel, borderless, id, ...props }) => {
    return(
        <TextAreaWrap borderless={borderless}>
            { srLabel && <SROnly is={"label"} htmlFor={id}>{ srLabel }</SROnly> }
            <textarea {...props} id={id} />
        </TextAreaWrap>
    );
}

TextArea.propTypes = {
    styleType: PropTypes.string,
    srLabel: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
}

export default TextArea;