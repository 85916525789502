import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { UserHasPermissionContainer } from '../User';
import { getCustomerDocuments, addCustomerDocuments } from '../../actions/customers';
import { deleteDocument } from '../../actions/documents';
import { flashAlert } from '../../actions/alerts';

import {
    FormGroup,
    FileUploader,
} from '../../components/Forms';

import {
    Loading,
    Error,
    ErrorBoundary,
} from '../../components/Utils';

class CustomerDocumentsContainer extends Component {
    state = {
        files: [],
        expect: null,
    }

    componentDidMount() {
        this.props.getCustomerDocuments(this.props.match.params.id, {
            limit: 50
        });
    }

    addFilesToState = ({ file, expect }) => {
        let { files } = this.state;

        files = [...files, file];

        this.setState({ files, expect });

        if(files.length === expect) {
            this.props.addCustomerDocuments(this.props.match.params.id, { documents: files });

            this.setState({
                files: [],
                expect: null
            })
        }
    }

    deleteFile = (index, id) => {
        this.props.deleteDocument(id);
    }

    render() {
        let {
            loggedIn,
            documents,
            fetching,
            saving,
            deleting,
            self,
        } = this.props;

        if(!loggedIn) return <Redirect to='/login' />

        return(
            <UserHasPermissionContainer
                self={self}
                permissions={'customers:write'}
            >
                <FormGroup
                    title={"Related Documents"}
                    id={"related-documents"}
                >
                    { fetching ? <Loading /> :
                        <ErrorBoundary render={() => <Error overlay={true} />}>
                            <FileUploader
                                files={documents}
                                handleAcceptedFiles={this.addFilesToState}
                                handleRejectedFiles={msg => this.props.flashAlert(msg, 'error')}
                                onRemove={this.deleteFile}
                                uploadOnAccept={true}
                                deleting={deleting}
                                saving={saving}
                            />
                        </ErrorBoundary>
                    }
                </FormGroup>
            </UserHasPermissionContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        self: state.user.self,
        loggedIn: state.user.loggedIn,
        documents: state.customer.data.documents.data,
        fetching: state.customer.data.documents.fetching,
        saving: state.customer.data.documents.saving,
        deleting: state.customer.data.documents.deleting,
        // fetching: true,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getCustomerDocuments, addCustomerDocuments, deleteDocument, flashAlert }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerDocumentsContainer));