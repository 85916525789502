import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCase, resetCase, getInvoice } from '../../actions/cases';

import { CompleteCaseContainer } from '../Case';

import { PageHeader } from '../../components/Layout';
import { PageTitle } from '../../components/Text';
import { Button } from '../../components/Buttons';
import { InvoiceTable } from '../../components/Invoice';
import { Loading } from '../../components/Utils';

class InvoiceContainer extends Component {
	componentDidMount() {
		this.props.history.block((location) => {
			this.goingToCase = location.pathname === `/case/${this.props.match.params.id}`;
		});

		// If there's no case details in the store fetch the case
		if(this.props.match.path !== '/cases/:id' && !this.props.details) {
			this.props.getCase(this.props.match.params.id);
		}

		this.props.getInvoice(this.props.id);
	}

	componentWillUnmount() {
		// Clear item and reset fetching to false on unmount if going anywhere but the case page for this invoice
		if(!this.goingToCase) {
			this.props.resetCase();
		}
	}

	render() {
		const reason = this.props.location.state && this.props.location.state.reason;

		return (
			<CompleteCaseContainer
				reason={reason}
				render={({ completed, openModal, complete }) => {
					if(completed) return <Redirect to={`/cases/${this.props.match.params.id}`} />
					
					return(
						<Fragment>
							<PageHeader>
								<PageTitle>Client Invoice</PageTitle>
			
								<Button
									chunkyish
									blueLight
									hollow
									onClick={() => {
										if(reason) {
											complete();
										} else {
											openModal();
										}
									}}
								>Resolve case without invoice</Button>
							</PageHeader>
			
							{ this.props.fetching ? <Loading /> : (
								<InvoiceTable 
									data={this.props.data}
									fetching={this.props.fetching}
									poNumber={this.props.details && this.props.details.customer_reference}
									onSubmit={(invoice) => {
										if(reason) {
											complete(invoice);
										} else {
											openModal(invoice);
										}
									}}
								/>
							) }
						</Fragment>
					);
				}}
			/>
		)
	}
}

InvoiceContainer.propTypes = {
	id: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string,
	]),
}

const mapStateToProps = (state) => ({
	data: state.case.data.invoice.data,
	details: state.case.data.details.data,
	fetching: state.case.data.invoice.fetching,
	saving: state.case.data.invoice.saving,
	error: state.case.data.invoice.error,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
	getCase,
	getInvoice,
	resetCase,
}, dispatch);

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps,
)(InvoiceContainer));