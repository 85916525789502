import React, { Component } from 'react';

import { ModalButtons } from '../Modals';
import { Link } from '../Text';
import { Button } from '../Buttons';
import {
    FormGroup,
    FormItemGroup,
    FormItem,
    Input,
} from '../Forms';

class PasswordForm extends Component {
    state = {
        old_password: '',
        new_password: '',
        new_password_confirmation: '',
    }

    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    handleSave = (e) => {
        e.preventDefault();

        this.props.onSave(this.state);

        return false;
    }

    render() {
        let { onCancel } = this.props;
        let { old_password, new_password, new_password_confirmation } = this.state;

        return(
            <form onSubmit={this.handleSave}>
                <FormGroup center={true}>
                    <FormItemGroup labelWidth={"150px"} wide={true}>
                        <FormItem label={"Old Password"} id={'old_password'}>
                            <Input
                                value={old_password}
                                id={'old_password'}
                                type={"password"}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem label={"New Password"} id={'new_password'}>
                            <Input
                                value={new_password}
                                id={'new_password'}
                                type={"password"}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem label={"Repeat New Password"} id={'new_password_confirmation'}>
                            <Input
                                value={new_password_confirmation}
                                id={'new_password_confirmation'}
                                type={"password"}
                                onChange={this.handleChange}
                            />
                        </FormItem>
                    </FormItemGroup>
                </FormGroup>
        
                <ModalButtons pl={'91px'}>
                    <Button
                        small={true}
                        blue={true}
                        type={"submit"}
                    >Change Password</Button>
                    <Link el={'button'} onClick={onCancel}>Cancel</Link>
                </ModalButtons>
            </form>
        );
    }
}

export default PasswordForm;