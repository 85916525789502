import { handleActions } from 'redux-actions';

const initialState = {
    data: [],
    item: {
        details: {
            data: null,
            fetching: true,
            saving: false,
        },
        activityLog: {
            data: null,
            fetching: true,
        },
        traders: {
            data: null,
            fetching: true,
        },
    },
    links: {},
    meta: {},
    page: 1,
    limit: 10,
    sort: '',
    sortBy: '',
    mine_only: true,
    archived: false,
    searchQuery: '',
    fetching: true,
    error: false,
}

export default handleActions({

    FETCH_CRIMETYPES: (state, action) => {
        return {
            ...state,
            fetching: true,
            error: false,
        }
    },

    FETCH_CRIMETYPES_SUCCESS: (state, action) => {
        return {
            ...state,
            data: action.crimeTypes.data,
            links: action.crimeTypes.links,
            meta: action.crimeTypes.meta,
            fetching: false,
        }
    },

    FETCH_CRIMETYPES_FAIL: (state, action) => {
        return {
            ...state,
            fetching: false,
            error: true
        }
    },

}, initialState)
