import React from 'react';

import { PageWithMainNav, PageTitleWrap } from '../components/Layout';
import { PageTitle } from '../components/Text';
import { BackButton } from '../components/Buttons';
import { ScrollToTopOnMount, MetaData } from '../components/Utils';

import { NewUserContainer } from '../containers/User';

const NewUser = () => {
    return(
        <PageWithMainNav>
            <ScrollToTopOnMount />
            <MetaData vars={{ title: 'New User' }} />

            <PageTitleWrap>
                <PageTitle>New User</PageTitle>
                <BackButton
                    to={'/users'}
                    small={true}
                    showLabel={true}
                    label={'Discard and return to users'}
                />
            </PageTitleWrap>

            <NewUserContainer />
        </PageWithMainNav>
    );
}

export default NewUser;