import { handleActions } from 'redux-actions';

const initialState = {
    data: null,
    loggedIn: false,
    loggingIn: false,
    fetching: true,
    saving: false,
    error: false,
    self: null,
}

export default handleActions({

    FETCH_SELF_SUCCESS: (state, action) => {
        return {
            ...state,
            self: action.user,
            loggedIn: true,
            fetching: false,
        }
    },

    FETCH_SELF_FAIL: (state, action) => {
        return {
            ...state,
            self: null,
            loggedIn: false,
            fetching: false,
            error: true,
        }
    },

    LOGIN_USER: (state, action) => {
        return {
            ...state,
            loggedIn: false,
            loggingIn: true,
            error: false,
        }
    },

    LOGIN_USER_SUCCESS: (state, action) => {
        return {
            ...state,
            loggedIn: true,
            loggingIn: false,
        }
    },

    LOGIN_USER_FAIL: (state, action) => {
        return {
            ...state,
            loggedIn: false,
            loggingIn: false,
            error: true,
        }
    },

    REGISTER_USER: (state, action) => {
        return {
            ...state,
            loggingIn: true,
            loggedIn: false,
            error: false,
        }
    },

    REGISTER_USER_SUCCESS: (state, action) => {
        return {
            ...state,
            loggingIn: false,
            loggedIn: true,
        }
    },

    REGISTER_USER_FAIL: (state, action) => {
        return {
            ...state,
            loggingIn: false,
            loggedIn: false,
            error: true
        }
    },

    UPDATE_USER: (state, action) => {
        return {
            ...state,
            saving: true,
            error: false,
        }
    },

    UPDATE_USER_SUCCESS: (state, action) => {
        let data = {
            ...state,
            saving: false,
        }

        if(action.user) {
            data.data = action.user;
        } else if(action.self) {
            data.self = action.self;
        }

        return data;
    },

    UPDATE_USER_FAIL: (state, action) => {
        return {
            ...state,
            saving: false,
            error: true,
        }
    },

    FETCH_TOKEN_FAIL: (state, action) => {
        return {
            ...state,
            fetching: false,
            loggedIn: false,
            self: null,
            error: true,
        }
    },

    LOGOUT_USER_SUCCESS: (state, action) => {
        return {
            ...state,
            data: null,
            fetching: false,
            loggedIn: false,
        }
    },

    LOGOUT_USER_FAIL: (state, action) => {
        return {
            ...state,
            data: null,
            fetching: false,
            loggedIn: false,
            error: true,
        }
    },

    RESET_PASSWORD: (state, action) => {
        return {
            ...state,
            saving: true,
            error: false,
        }
    },

    RESET_PASSWORD_SUCCESS: (state, action) => {
        return {
            ...state,
            saving: false,
        }
    },

    RESET_PASSWORD_FAIL: (state, action) => {
        return {
            ...state,
            saving: false,
            error: true,
        }
    },

    UPDATE_PASSWORD: (state, action) => {
        return {
            ...state,
            error: false,
            saving: true,
        }
    },

    UPDATE_PASSWORD_SUCCESS: (state, action) => {
        return {
            ...state,
            saving: false,
        }
    },

    UPDATE_PASSWORD_FAIL: (state, action) => {
        return {
            ...state,
            saving: false,
            error: true
        }
    },

}, initialState);