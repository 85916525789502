import styled from 'styled-components';

export default styled.div`
    position: relative;
    overflow-x: auto;
    
    @media screen and (max-width: 767px) {
        table {
            width: 800px;
        }
    }
`;