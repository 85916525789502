import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Header = styled.header`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-bottom: 25px;

	>* {
		width: auto;
		
		&:first-child {
			margin: 0 auto 0 0;
		}
	}
`;

const PageHeader = ({ children }) => {
	return (
		<Header>
			{ children }
		</Header>
	)
}

PageHeader.propTypes = {

}

export default PageHeader
