if (window.Element && !Element.prototype.closest) {
    Element.prototype.closest =
    function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i,
            el = this;
        do {
        i = matches.length;
        while (--i >= 0 && matches.item(i) !== el) {};
        } while ((i < 0) && (el = el.parentElement));
        return el;
    };
}

export const blurAllFields = () => {
    if(document.activeElement) {
        document.activeElement.blur();
    }
}

export const checkIfClickedInside = ( node, ancestor ) => {
    var child = node;
    while (child !== null) {
        if (child === ancestor) return true;
        child = child.parentNode;
    }
    return false;
}

export const checkIfAncestorsHaveClass = (element, className) => {
    return element.closest(`.${className}`);
}