import { format, isAfter, isBefore } from 'date-fns';

import { settings } from '../config';
// import { validateTime } from './validation';
import { timeFromAMPM } from './dates';
// import { toSlug } from './textTransforms';

export const filterData = (data, filter, reverse) => {
    let { key, val } = filter;

    return filterByProperty(data, key, val, reverse);
}

export const filterByProperty = (data, key, val, reverse) => {
    return data.filter(item => {
        // If no key is provided, just return all the data.
        if(!key) return item;

        // If no value is provided, just return all items where the key is truthy
        if(!val) {
            if(reverse) {
                return !item[key];
            } else {
                return item[key];
            }
        }

        // Return any items that match the provided key/val
        if(reverse) {
            return item[key] !== val;
        } else {
            return item[key] === val;
        }
    });
}

export const compareJSONStrings = (a, b) => {
    return JSON.stringify(a) === JSON.stringify(b);
}

export const reorderArray = (from, to, originalArray) => {
    const movedItem = originalArray[from];
    const remainingItems = originalArray.filter((item, index) => index !== from);

    const reorderedItems = [
        ...remainingItems.slice(0, to),
        movedItem,
        ...remainingItems.slice(to)
    ];

    return reorderedItems;
}

export const getEventKey = e => {
    if(e.target.type === 'radio') {
        return e.target.name;
    }

    return e.target.id;
}

// TODO: Nice alerts/confirms
// TODO: Mobile tables
// TODO: Handle 422 errors
export const getEventVal = e => {
    if(!e) return null;

    if(typeof e === 'string') return e;

    // Used by components like TimeInput, when we only need the value property.
    if('value' in e) return e.value;

    // If the id property is a direct child, that means the value is an object, not a DOM event.
    if(e.id || e.value) {
        return e;
    }

    if(e.target.type === 'checkbox') {
        return e.target.checked;
    }

    return e.target.value;
}

export const convertNullValues = (val) => {
    return val ? val : '';
}

export const findByIdAndUpdate = (src, updated, key) => {
    if(key) {
        return src.map(item => item[key].id === updated[key].id ? updated : item);
    }

    return src.map(item => item.id === updated.id ? updated : item);
}

export const updateItem = (state, key, vals) => {
    return {
        ...state,
        data: {
            ...state.data,
            [key]: {
                ...state.data[key],
                ...vals
            }
        },
    }
}

export const denestUsers = (users, useAvailId) => {
    if(useAvailId) {
        return users.map(({ user: { id, ...userFields }, id: availId, ...fields }) => ({
            id: availId,
            ...userFields
        }));
    }

    return users.map(({ user, ...fields }) => ({
        ...user
    }));
}

export const addStaffToState = (state, staff) => {
    return {
        datesChanged: false,
        hasChanged: true,
        form: {
            ...state.form,
            staff: [...state.form.staff, staff]
        }
    }
}

export const removeStaffFromState = (state, id) => {
    let staff = state.form.staff.filter(user => user.id !== id);

    return {
        hasChanged: true,
        form: {
            ...state.form,
            staff
        }
    };
}

export const updateDateState = (state, val, key, both) => {
    let date = format(val, 'YYYY-MM-DD');

    // Use this to also set end_date when setting a future start date, vice versa for end date in past.
    let startDateInFuture = isAfter(date, new Date()) && key === 'start_date',
        endDateInPast = isBefore(date, new Date()) && key === 'end_date';

    let data = {
        ...state.form[key],
        date,
        formatted: date + ' ' + state.form[key].time,
    }

    // Change both dates if both is true, used for online test purchases.
    // However, we still want to make end_date time PM
    if(both || startDateInFuture) {
        data = {
            start_date: data,
            end_date: {
                ...data,
                time: '23:59:59',
                formatted: `${data.date} 23:59:59`,
            },
        }
    } else if(endDateInPast) {
        data = {
            start_date: {
                ...data,
                time: '00:00:00',
                formatted: `${data.date} 00:00:00`,
            },
            end_date: data
        }
    } else {
        data = {
            [key]: data
        }
    }

    return {
        hasChanged: true,
        form: {
            ...state.form,
            ...data,
            staff: [],
        }
    };
}

export const updateTimeState = (state, val, key) => {
    let time = timeFromAMPM(val, key);

    return {
        hasChanged: true,
        form: {
            ...state.form,
            staff: [],
            [key]: {
                ...state.form[key],
                time,
                formatted: state.form[key].date + ' ' + time,
            }
        }
    };
}

export const addFileToState = (state, file) => {
    return {
        hasChanged: true,
        form: {
            ...state.form,
            documents: [...state.form.documents, file]
        }
    }
}

export const removeFileFromState = (state, index) => {
    let documents = [...state.form.documents];
    documents.splice(index, 1);

    return {
        hasChanged: true,
        form: {
            ...state.form,
            documents
        }
    }
}

export const filterDocumentsAndAddToObject = (stateDocs, propDocs, obj) => {
    let documents = stateDocs;

    if(propDocs && propDocs.length) {
        documents = stateDocs.filter(doc => !propDocs.find(pDoc => pDoc.id === doc.id));
    }

    if(documents.length) {
        obj.documents = documents;
    }

    return obj;
}

export const updatePaymentState = (state, val) => {
    return {
        ...state.form,
        // payment_method: toSlug(val),
    }
}

// export const handleChange = (e, k, state) => {
//     let key = k || getEventKey(e);
//
//     return {
//         hasChanged: true,
//         form: {
//             ...state.form,
//             [key]: getEventVal(e)
//         },
//     };
// }

export const getPaymentMethodInput = (payment_method) => {
    let matchingMethod = settings.paymentMethods.find(m => m.value === payment_method);

    return {
        value: payment_method || '',
        label: matchingMethod && matchingMethod.label
    }
}

const makePathArray = (obj) => {
    return flattenDeep(obj)
        .join('.')
        .replace(/\[/g, '.')
        .replace(/\]/g, '')
        .split('.')
}

const flattenDeep = (arr, newArr = []) => {
    if (!Array.isArray(arr)) {
        newArr.push(arr)
    } else {
        for (let i = 0; i < arr.length; i += 1) {
            flattenDeep(arr[i], newArr)
        }
    }
    return newArr
}

// Copied from react-tables package.
export const getValue = (obj, path, def) => {
    if (!path) {
        return obj
    }
    const pathObj = makePathArray(path)
    let val
    try {
        val = pathObj.reduce((current, pathPart) => current[pathPart], obj)
    } catch (e) {
        // continue regardless of error
    }
    return typeof val !== 'undefined' ? val : def
}