import React from 'react';

import { PageWithMainNav, PageTitleWrap } from '../components/Layout';
import { PageTitle } from '../components/Text';
import { BackButton } from '../components/Buttons';
import { ScrollToTopOnMount, MetaData } from '../components/Utils';

import { LogoutContainer } from '../containers/Auth';
import { SettingsContainer } from '../containers/User';

const Settings = () => {
    return(
        <PageWithMainNav>
            <ScrollToTopOnMount />
            <MetaData vars={{ title: 'Settings' }} />

            <PageTitleWrap>
                <PageTitle>Settings</PageTitle>
                <LogoutContainer render={(logoutUser) => (
                    <BackButton
                        onClick={logoutUser}
                        withIcon={false}
                        small={true}
                        el={'button'}
                        showLabel={true}
                        label={'Logout'}
                    />
                )} />
            </PageTitleWrap>

            <SettingsContainer />
        </PageWithMainNav>
    );
}

export default Settings;