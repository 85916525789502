import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Box } from 'grid-styled';

import { UserHasPermissionContainer } from '../../containers/User';

import { SROnly } from '../Utils';
import { IconCircle } from '../Icons';
import { Button } from '../Buttons';

import { listPlain } from '../../utils/style';

const MainNav = ({ items, location: { pathname } }) => {

    const isHome = pathname === '/';

    return (
        <MainNavWrap is={'nav'}>
            <ul>
                { items.map((item, i) => {
                    let El = item.disabled ? Button : Link;

                    let { Icon } = item;

                    // Set active state for cases icon on home page.
                    let active = isHome ? item.path === '/cases' : pathname.includes(item.path);

                    return(
                        <UserHasPermissionContainer
                            key={item.label+i}
                            render={({ can }) => {
                                if(!item.permissions || (item.permissions && can.includes(item.permissions))) {
                                    return(
                                        <li>
                                            <El
                                                to={item.disabled ? '' : item.path}
                                                title={item.label}
                                                disabled={item.disabled}
                                                style={{
                                                    padding: '0',
                                                    border: '0',
                                                    background: 'none',
                                                }}
                                            >
                                                <IconCircle
                                                    inactive={!active}
                                                    disabled={item.disabled}
                                                    styleType={"b"}
                                                    size={'lg'}
                                                >
                                                    <Icon />
                                                </IconCircle>
                                                <SROnly>{item.label}</SROnly>
                                            </El>
                                        </li>
                                    );
                                } else {
                                    return null;
                                }
                            }}
                        />
                    );
                })}
            </ul>
        </MainNavWrap>
    );
}

const MainNavWrap = styled(Box)`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-width: 110px;
    z-index: 99;
    background: ${({ theme }) => theme.blue};
    
    ul {
        ${listPlain()};
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100vh;
        min-height: 500px;
        z-index: 100;
    }
    
    li {
        +li {
            margin-top: 60px;
        }
    }
    
    svg {
        width: 22px;
        height: auto;
    }
    
    @media screen and (max-width: 1024px) {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 10px 0;
        
        ul {
            position: relative;
            width: 100%;
            min-height: 0;
            height: auto;
            bottom: 0;
            flex-direction: row;
        }
        
        li {
            +li {
                margin: 0 0 0 25px;
            }
        }
    }
`;

export default withRouter(MainNav);