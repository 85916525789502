import React from 'react';
import styled from 'styled-components';

import { SROnly, SVG } from '../Utils';

const PaginationControlsWrap = styled.div`
    .prev {
        transform: rotate(90deg);
    }
    .next {
        transform: rotate(-90deg);
        margin-left: 16px;
    }
    
    button {
        padding: 0;
        border: 0;
        cursor: pointer;
    }
    
    >span {
        margin: 0 0 0 16px;
    }
    
    strong {
        font-weight: 500;
    }
    
    svg {
        width: 10px;
    }
`;

const PaginationControls = ({ canPrevious, onPrevious, previousText, canNext, onNext, nextText, textHtml }) => {
    return(
        <PaginationControlsWrap>
            { canPrevious &&
                <button className={"prev"} onClick={onPrevious}>
                    <SROnly>Go to previous page</SROnly>
                    <SVG path={'/svg/icon-chevron.svg'} />
                </button>
            }

            { textHtml &&
                <span
                    dangerouslySetInnerHTML={{ __html: textHtml }}
                    data-testid="table-pagination-count"
                />
            }

            { canNext &&
                <button className={"next"} onClick={onNext}>
                    <SROnly>Go to next page</SROnly>
                    <SVG path={'/svg/icon-chevron.svg'} />
                </button>
            }
        </PaginationControlsWrap>
    );
}

export default PaginationControls;