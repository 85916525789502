import React from 'react';
import { Tooltip as TippyTip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';

const Tooltip = ({ title, children }) => (
    <TippyTip
        title={title}
        position={'top'}
        theme={"light"}
        arrow={true}
    >
        {children}
    </TippyTip>
);

export default Tooltip;