import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { Provider as AlertProvider } from 'react-alert';

import App from './App';
import { 
    withAnalytics,
    alertOptions } from './config';
import registerServiceWorker, { unregister } from './config/serviceWorker'
import store, { history } from './config/store';
import { Alert } from './components/Utils';
require('es6-promise').polyfill();
require('isomorphic-fetch');

ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <AlertProvider template={Alert} {...alertOptions}>
                <Route component={withAnalytics(App)} />
            </AlertProvider>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);

if( process.env.NODE_ENV === 'production' ) {
    registerServiceWorker();
} else {
    unregister();
}