import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider, injectGlobal } from 'styled-components';

import withAlert from './hoc/withAlert';
import { routes, theme } from './config';
import {
    normalize,
    base,
    misc,
    forms,
    text,
} from './style';

injectGlobal`
	${normalize}
	${base}
	${misc}
	${forms}
	${text}
`;

class App extends Component {
    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown);
        console.log(process.env.NODE_ENV);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    handleKeyDown = e => {
        if(e.keyCode===9){
            document.body.classList.add('user-is-tabbing');
            window.removeEventListener('keydown', this.handleKeyDown);
        }
    }

    render() {
        return(
            <ThemeProvider theme={theme}>
                <Switch>
                    {routes.map((route, index) => (
                        <Route
                            key={index}
                            path={route.path}
                            exact={route.exact}
                            component={route.component}
                        />
                    ))}
                </Switch>
            </ThemeProvider>
        );
    }
}

export default withAlert(App);