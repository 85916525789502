import { handleActions } from 'redux-actions';

const initialState = {
    data: [],
    page: 1,
    limit: 10,
    sort: '',
}

export default handleActions({

    FETCH_USER_ROLES: (state, action) => {
        return {
            ...state,
            fetching: true,
            error: false,
        }
    },

    FETCH_USER_ROLES_SUCCESS: (state, action) => {
        return {
            ...state,
            data: action.userRoles.data,
            fetching: false,
        }
    },

    FETCH_USER_ROLES_FAIL: (state, action) => {
        return {
            ...state,
            fetching: false,
            error: true
        }
    },

}, initialState)
