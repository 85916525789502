import React, { Component } from 'react';

import { Tabs } from './';
import { getAMPM } from '../../utils/dates';

class TimeSwitch extends Component {
    handleChange = e => {
        if(this.props.disabled) return;

        this.props.onChange(e.target.value);
    }

    render() {
        let { value, prefix, disabled } = this.props;

        // Format doesn't work with only a time provided, so provide an arbitrary date.
        let timeOfDay = value ? getAMPM(`1993-08-26 ${value}`) : null;

        const tabs = [
            {
                id: prefix + 'AM',
                checked: timeOfDay === 'AM',
                label: 'AM',
                value: 'AM',
                onChange: this.handleChange,
                disabled: disabled,
                // onClick: (e) => !value && onChange ? onChange(e.target.value) : null
            },
            {
                id: prefix + 'PM',
                checked: timeOfDay === 'PM',
                label: 'PM',
                value: 'PM',
                onChange: this.handleChange,
                disabled: disabled,
                // onClick: (e) => !value && onChange ? onChange(e.target.value) : null
            },
        ];

        return(
            <Tabs
                name={prefix+'timeSwitch'}
                items={tabs}
                styleType={'blue'}
                disabled={disabled}
            />
        );
    }
}

TimeSwitch.defaultProp = {
    prefix: ''
}

export default TimeSwitch;