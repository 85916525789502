import React from 'react';

import { Page, ContentArea, FloatingSidebar } from './';

const PageWithFloatingSidebar = ({ children, render }) => {
    return(
        <Page>
            <FloatingSidebar>
                { render() }
            </FloatingSidebar>
            <ContentArea>
                { children }
            </ContentArea>
        </Page>
    );
}

export default PageWithFloatingSidebar;