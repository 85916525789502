import styled, { css } from 'styled-components';

const colStyles = (val) => {
    if(!val) return null;

    if(typeof val === 'string' || !isNaN(val)) {
        return css`
            grid-template-columns: repeat(${val}, 1fr);
        `;
    }

    return val.map(([ cols, breakpoint ]) => css`
        @media screen and (min-width: ${breakpoint}px) {
            grid-template-columns: repeat(${cols}, 1fr);
        }
    `);
}

export default styled.div`
    position: ${({ position }) => position || 'static'};
    display: grid;
    grid-gap: ${({ gap }) => gap || '15px'};
    ${({ cols }) => colStyles(cols)}
`;