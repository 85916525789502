import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Page as _Page, ContentArea } from './';
import { BackButton } from '../Buttons';
import { MainNav } from '../Nav';

const Page = styled(_Page)`
	padding-top: 45px;

	>a {
		align-self: flex-start;
		margin-left: 20px;
	}
`;

const PageWithBackButton = ({ link, children }) => {
    return(
		<Page 
			flexDirection="column"
			justifyContent="flex-start"
			alignItems="stretch"
		>
            <BackButton 
				label={'Back to case'}
				showLabel={true}
				withIcon={true}
				to={link}
				small
			/>
            <ContentArea>
                { children }
            </ContentArea>
        </Page>
    );
}

export default PageWithBackButton;