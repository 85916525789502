import React, { PureComponent } from 'react';

import { SplitDayButton } from './';
import { TD } from './TableCell';

class UserDay extends PureComponent {
    render() {
        let {
            user,
            day,
            unavailable,
            clicked,
            beforeClicked,
            betweenClickedHovered,
        } = this.props;

        return(
            <TD
                noPadding={true}
                align={'top'}
                data-testid={user.id+day}
                data-activeam={!!clicked.am || !!betweenClickedHovered.am}
                data-activepm={!!clicked.pm || !!betweenClickedHovered.pm}
                data-excludedam={!!unavailable.am}
                data-excludedpm={!!unavailable.pm}
                data-disabledam={!!beforeClicked.am}
                data-disabledpm={!!beforeClicked.pm}
            >
                <SplitDayButton
                    excludedAm={!!unavailable.am}
                    excludedPm={!!unavailable.pm}
                    activeAm={clicked.am || betweenClickedHovered.am}
                    activePm={clicked.pm || betweenClickedHovered.pm}
                    disabledAm={beforeClicked.am}
                    disabledPm={beforeClicked.pm}
                    onClick={(time, unavailable) => this.props.onTimeSlotClick({
                        date: `${day} ${time}`,
                        userId: user.id,
                        deleting: unavailable
                    })}
                    onMouseEnter={(time) => this.props.onTimeSlotHover({
                        date: `${day} ${time}`,
                        userId: user.id
                    })}
                    onMouseLeave={() => this.props.onTimeSlotHover({
                        date: null,
                        userId: null
                    })}
                />
            </TD>
        );
    }
}

export default UserDay;