import { get, post, remove, checkAuthentication } from '../utils/api';
import { validateParams, dispatchErrors } from '../utils/validation';
import { prettyDate } from '../utils/dates';

export const getUsers = (params) => {

    // Remove any invalid parameters
    let validatedParams = validateParams(['available_from', 'available_to', 'role_id', 'limit', 'page', 'sort', 'sortBy', 'searchQuery', 'include_time'], params);

    return (dispatch) => {

        dispatch({ type: 'FETCH_USERS' });

        return get(`/users`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                // data = data.filter(({ name }) => name === 'Pete Davies');
                dispatch({ type: 'FETCH_USERS_SUCCESS', users: data, params: validatedParams })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_USERS_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const getUserExclusions = (params) => {

    // Remove any invalid parameters
    let validatedParams = validateParams(['from', 'to', 'limit', 'page', 'sort'], params);

    return (dispatch) => {

        if(!params.from || !params.to) {
            dispatch({ type: 'ERROR', error: 'Please provide a start and end date.' })
        }

        dispatch({ type: 'FETCH_USER_EXCLUSIONS' });

        return get(`/user-exclusions`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then((data) => {
                dispatch({ type: 'FETCH_USER_EXCLUSIONS_SUCCESS', exclusions: data })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_USER_EXCLUSIONS_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const addUserExclusion = (params) => {

    // Remove any invalid parameters
    let validatedParams = validateParams(['user_id', 'start_date', 'end_date'], params);

    return (dispatch) => {

        dispatch({ type: 'ADD_USER_EXCLUSION' });

        return post(`/user-exclusions`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'ADD_USER_EXCLUSION_SUCCESS', exclusion: data, success: `Booking between ${prettyDate(data.start_date)} and ${prettyDate(data.end_date)} has been added off for ${data.user.name}` })
            })
            .catch(err => {
                dispatch({ type: 'ADD_USER_EXCLUSION_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const deleteUserExclusion = (userId, params) => {

    // Remove any invalid parameters
    let validatedParams = validateParams(['from', 'to'], params);

    return (dispatch) => {

        dispatch({ type: 'DELETE_USER_EXCLUSION' });

        return remove(`/users/${userId}/user-exclusions`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then((data) => {
                dispatch({ type: 'DELETE_USER_EXCLUSION_SUCCESS', success: `Bookings between ${prettyDate(params.from)} to ${prettyDate(params.to)} have been deleted` })
            })
            .catch(err => {
                dispatch({ type: 'DELETE_USER_EXCLUSION_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const removeUserFromAction = (caseId, typeId, userId, type) => {

    return (dispatch) => {

        dispatch({ type: 'REMOVE_USER_FROM_ACTION' });

        return remove(`/cases/${caseId}/${type}/${typeId}/users/${userId}`, null, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then((data) => {
                dispatch({ type: 'REMOVE_USER_FROM_ACTION_SUCCESS', users: data })
            })
            .catch(err => {
                dispatch({ type: 'REMOVE_USER_FROM_ACTION_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const addUser = (params) => {

    // Remove any invalid parameters
    let validatedParams = validateParams(['email', 'role_id'], params);

    return (dispatch) => {

        dispatch({ type: 'ADD_USER' });

        return post(`/users`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(() => {
                dispatch({ type: 'ADD_USER_SUCCESS', user: validatedParams, success: `The user account has been created.` })
            })
            .catch(err => {
                dispatch({ type: 'ADD_USER_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const deleteUser = (userId) => {

    return (dispatch) => {

        dispatch({ type: 'DELETE_USER' });

        return remove(`/users/${userId}`, null, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(() => {
                dispatch({ type: 'DELETE_USER_SUCCESS', success: `The user account has been deleted` })
            })
            .catch(err => {
                dispatch({ type: 'DELETE_USER_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const getUserRoles = (params = {}) => {

    // Remove any invalid parameters
    let validatedParams = validateParams(['limit', 'page', 'sort'], params);

    return (dispatch) => {

        dispatch({ type: 'FETCH_USER_ROLES' });

        return get('/roles', validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then((data) => {
                dispatch({ type: 'FETCH_USER_ROLES_SUCCESS', userRoles: data, params: validatedParams })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_USER_ROLES_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const resetUsers = () => {

    return (dispatch) => {

        dispatch({ type: 'RESET_USERS' });

    }

}