import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTimeout from 'react-timeout';

import { checkIfClickedInside } from '../../utils/interaction';

const PopupContentWrap = styled.div`
    position: absolute;
    top: 100%;
    padding-top: 15px;
    
    >div {
        position: relative;
        padding: 35px 15px;
        border: ${({ theme }) => theme.border.main};
        border-radius: ${({ theme }) => theme.radius.small};
        z-index: 2;
        background: ${({ theme }) => theme.white};
    }
    
    &:before {
        position: absolute;
        top: 8px;
        left: 25%;
        content: '';
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 8px 5px;
        border-color: transparent transparent ${({ theme }) => theme.white} transparent;
        z-index: 3;
    }
    
    &:after {
        content: '';
        display: block;
        position: fixed;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.6);
    }
`;

class PopupContent extends Component {
    constructor(props) {
        super(props);

        this.popupNode = React.createRef();
    }

    componentDidMount() {
        this.setTimeout();
    }

    componentWillUnmount() {
        window.removeEventListener('click', this.handleClick);
        this.props.clearTimeout(this.setTimeout);
    }

    setTimeout = () => {
        // We need to delay this, otherwise the click to open this popup is picked up by this listener.
        this.props.setTimeout(() => {
            window.addEventListener('click', this.handleClick);
        }, 100);
    }

    handleClick = (e) => {
        // Check if the user clicked outside the popup - close it if so
        let clickedOutside = !checkIfClickedInside(e.target, this.popupNode.current);

        if(clickedOutside) {
            this.props.onRequestClose();
        }
    }

    render() {
        let { children } = this.props;

        return(
            <PopupContentWrap>
                <div ref={this.popupNode}>
                    { children }
                </div>
            </PopupContentWrap>
        );
    }
}

PopupContent.propTypes = {
    onRequestClose: PropTypes.func,
    children: PropTypes.any
}

export default ReactTimeout(PopupContent);