import uuid from 'uuid/v4';

import { get, post, put, remove, checkAuthentication } from '../utils/api';
import {
    validateParams,
    validateTestPurchase,
    validateRaid,
    dispatchErrors,
} from '../utils/validation';

export const getCases = (params) => {

    return (dispatch) => {

        dispatch({ type: 'FETCH_CASES' });

        // Remove any invalid parameters
        let validatedParams = validateParams(['mine_only', 'status', 'searchQuery', 'sort', 'sortBy', 'page', 'limit'], params);

        return get('/cases', validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then((data) => {
                dispatch({ type: 'FETCH_CASES_SUCCESS', cases: data, params: validatedParams })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CASES_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const getCase = (caseId) => {

    return (dispatch) => {

        dispatch({ type: 'FETCH_CASE' });

        return get(`/cases/${caseId}`, null, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'FETCH_CASE_SUCCESS', case: data })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CASE_FAIL', status: err.status });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const getCaseLog = (caseId, refetching = false) => {

    return (dispatch) => {

        dispatch({ type: 'FETCH_CASE_LOG', refetching });

        return get(`/cases/${caseId}/activity-log`, { sort: 'DESC' }, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'FETCH_CASE_LOG_SUCCESS', activityLog: data })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CASE_LOG_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const addCaseComment = (caseId, params) => {

    let validatedParams = validateParams(['documents', 'logged_time', 'comment'], params);

    return (dispatch) => {

        dispatch({ type: 'ADD_CASE_COMMENT' });

        return post(`/cases/${caseId}/comments`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                // Match the shape of the real data, so we can prepend it to the other log entries.
                let item = { attached_to: { ...data } };

                dispatch({ type: 'ADD_CASE_COMMENT_SUCCESS' })

                // Refetch log and case documents
                dispatch(getCaseLog(caseId, true));
                if(validatedParams.documents) {
                    dispatch(getCaseDocuments(caseId, { limit: 100 }));
                }
            })

            .catch(err => {
                dispatch({ type: 'ADD_CASE_COMMENT_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const getCaseTraders = (caseId) => {

    return (dispatch) => {

        dispatch({ type: 'FETCH_CASE_TRADERS' });

        return get(`/cases/${caseId}/traders`, { sortBy: 'priority' }, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'FETCH_CASE_TRADERS_SUCCESS', traders: data })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CASE_TRADERS_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const assignCaseTrader = (caseId, trader) => {

    return (dispatch) => {

        dispatch({ type: 'ASSIGN_CASE_TRADER' });

        return post(`/cases/${caseId}/traders/${trader.id}`, null, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'ASSIGN_CASE_TRADER_SUCCESS', trader, success: 'The trader has been added to the case.' })
            })
            .catch(err => {
                dispatch({ type: 'ASSIGN_CASE_TRADER_FAIL' });
                return dispatchErrors(err, dispatch);
            });

    }

}

export const addCaseTraderAndAssign = (caseId, params) => {

    let validatedParams = validateParams(['business_name', 'business_address', 'contact_name', 'contact_address', 'email', 'phone', 'notes'], params);

    return (dispatch) => {

        dispatch({ type: 'ADD_CASE_TRADER' });

        return post(`/traders`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch(assignCaseTrader(caseId, data));
            })
            .catch(err => {
                dispatch({ type: 'ADD_CASE_TRADER_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const unassignCaseTrader = (caseId, traderId) => {

    return (dispatch) => {

        dispatch({ type: 'UNASSIGN_CASE_TRADER' });

        return remove(`/cases/${caseId}/traders/${traderId}`, null, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(() => {
                dispatch({ type: 'UNASSIGN_CASE_TRADER_SUCCESS', id: traderId, success: 'The trader has been removed from the case.' })
            })
            .catch(err => {
                dispatch({ type: 'UNASSIGN_CASE_TRADER_FAIL' });
                return dispatchErrors(err, dispatch);
            });

    }

}

export const updateCaseTraders = (caseId, traders) => {

    return (dispatch) => {

        dispatch({ type: 'UPDATE_CASE_TRADERS' });

        return put(`/cases/${caseId}/traders`, { traders }, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'UPDATE_CASE_TRADERS_SUCCESS', traders: data })
            })
            .catch(err => {
                dispatch({ type: 'UPDATE_CASE_TRADERS_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const updateCaseTrader = (traderId, params) => {

    let validatedParams = validateParams(['business_name', 'business_address', 'contact_name', 'contact_address', 'email', 'phone', 'notes'], params);

    return (dispatch) => {

        dispatch({ type: 'UPDATE_CASE_TRADER' });

        return put(`/traders/${traderId}`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'UPDATE_CASE_TRADER_SUCCESS', trader: data, success: 'The trader has been updated.' })
            })
            .catch(err => {
                dispatch({ type: 'UPDATE_CASE_TRADER_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const getCaseVehicles = (caseId) => {

    return (dispatch) => {

        dispatch({ type: 'FETCH_CASE_VEHICLES' });

        return get(`/cases/${caseId}/vehicles`, { sortBy: 'priority' }, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'FETCH_CASE_VEHICLES_SUCCESS', vehicles: data })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CASE_VEHICLES_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const assignCaseVehicle = (caseId, vehicle) => {

    return (dispatch) => {

        dispatch({ type: 'ASSIGN_CASE_VEHICLE' });

        return post(`/cases/${caseId}/vehicles/${vehicle.id}`, null, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'ASSIGN_CASE_VEHICLE_SUCCESS', vehicle, success: 'The vehicle has been added to the case.' })

                // Refetch log and case documents
                dispatch(getCaseLog(caseId, true));
            })
            .catch(err => {
                dispatch({ type: 'ASSIGN_CASE_VEHICLE_FAIL' });
                return dispatchErrors(err, dispatch);
            });

    }

}

export const addCaseVehicleAndAssign = (caseId, params) => {

    let validatedParams = validateParams(['number_plate', 'make', 'model', 'colour', 'vnc_check', 'notes'], params);

    return (dispatch) => {

        dispatch({ type: 'ADD_CASE_VEHICLE' });

        return post(`/vehicles`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch(assignCaseVehicle(caseId, data));
            })
            .catch(err => {
                dispatch({ type: 'ADD_CASE_VEHICLE_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const unassignCaseVehicle = (caseId, vehicleId) => {

    return (dispatch) => {

        dispatch({ type: 'UNASSIGN_CASE_VEHICLE' });

        return remove(`/cases/${caseId}/vehicles/${vehicleId}`, null, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(() => {
                dispatch({ type: 'UNASSIGN_CASE_VEHICLE_SUCCESS', id: vehicleId, success: 'The vehicle has been removed from the case.' })
            })
            .catch(err => {
                dispatch({ type: 'UNASSIGN_CASE_VEHICLE_FAIL' });
                return dispatchErrors(err, dispatch);
            });

    }

}

export const updateCaseVehicles = (caseId, vehicles) => {

    return (dispatch) => {

        dispatch({ type: 'UPDATE_CASE_VEHICLES' });

        return put(`/cases/${caseId}/vehicles`, { vehicles }, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'UPDATE_CASE_VEHICLES_SUCCESS', vehicles: data })
            })
            .catch(err => {
                dispatch({ type: 'UPDATE_CASE_VEHICLES_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const updateCaseVehicle = (vehicleId, params) => {

    let validatedParams = validateParams(['number_plate', 'make', 'model', 'colour', 'vnc_check', 'notes'], params);

    return (dispatch) => {

        dispatch({ type: 'UPDATE_CASE_VEHICLE' });

        return put(`/vehicles/${vehicleId}`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'UPDATE_CASE_VEHICLE_SUCCESS', vehicle: data, success: 'The vehicle has been updated.' })
            })
            .catch(err => {
                dispatch({ type: 'UPDATE_CASE_VEHICLE_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const getCaseSiteVisits = (caseId, params) => {

    let validatedParams = validateParams(['limit', 'page', 'sort'], params);

    return (dispatch) => {

        dispatch({ type: 'FETCH_CASE_SITE_VISITS' });

        return get(`/cases/${caseId}/site-visits`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'FETCH_CASE_SITE_VISITS_SUCCESS', siteVisits: data })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CASE_SITE_VISITS_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const getCaseRaids = (caseId, params) => {

    let validatedParams = validateParams(['limit', 'page', 'sort'], params);

    return (dispatch) => {

        dispatch({ type: 'FETCH_CASE_RAIDS' });

        return get(`/cases/${caseId}/raids`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'FETCH_CASE_RAIDS_SUCCESS', raids: data })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CASE_RAIDS_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const addCaseRaid = (caseId, params) => {

    let validatedParams = validateRaid(params);

    return (dispatch) => {

        dispatch({ type: 'ADD_CASE_RAID' });

        let { raid } = validatedParams;

        // if(!raid.address || !raid.address.length) {
        //     return dispatch({ type: 'ADD_CASE_RAID_FAIL', error: 'Please enter an address.' })
        // }
        // if(!raid.user_ids || !raid.user_ids.length) {
        //     return dispatch({ type: 'ADD_CASE_RAID_FAIL', error: 'Please assign staff.' })
        // }

        return post(`/cases/${caseId}/raids`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'ADD_CASE_RAID_SUCCESS', raid: data, success: 'The raid has been created.' })

                // Refetch log and case documents
                dispatch(getCaseLog(caseId, true))

                if(validatedParams.documents) {
                    dispatch(getCaseDocuments(caseId))
                }
            })
            .catch(err => {
                dispatch({ type: 'ADD_CASE_RAID_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const updateCaseRaid = (caseId, raidId, params) => {

    let validatedParams = validateRaid(params);

    return (dispatch) => {

        dispatch({ type: 'UPDATE_CASE_RAID' });

        return put(`/cases/${caseId}/raids/${raidId}`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'UPDATE_CASE_RAID_SUCCESS', raid: data, success: 'The raid has been updated.' })

                // Refetch log and case documents
                dispatch(getCaseLog(caseId, true))

                if(validatedParams.documents) {
                    dispatch(getCaseDocuments(caseId))
                }
            })
            .catch(err => {
                dispatch({ type: 'UPDATE_CASE_RAID_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const completeCaseRaid = (caseId, raidId, params) => {

    let validatedParams = validateRaid(params, true);

    return (dispatch) => {

        dispatch({ type: 'COMPLETE_CASE_RAID' });

        return post(`/cases/${caseId}/raids/${raidId}/complete`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'COMPLETE_CASE_RAID_SUCCESS', raid: data, success: 'The raid has been completed.' })
            })
            .catch(err => {
                dispatch({ type: 'COMPLETE_CASE_RAID_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const deleteCaseRaid = (caseId, raidId) => {

    return (dispatch) => {

        dispatch({ type: 'DELETE_CASE_RAID' });

        return remove(`/cases/${caseId}/raids/${raidId}`, null, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(() => {
                dispatch({ type: 'DELETE_CASE_RAID_SUCCESS', id: raidId, success: 'The raid has been deleted.' })
            })
            .catch(err => {
                dispatch({ type: 'DELETE_CASE_RAID_FAIL' });
                return dispatchErrors(err, dispatch);
            });

    }

}

export const getCaseTestPurchases = (caseId, params) => {

    let validatedParams = validateParams(['limit', 'page', 'sort'], params);

    return (dispatch) => {

        dispatch({ type: 'FETCH_CASE_TEST_PURCHASES' });

        return get(`/cases/${caseId}/test-purchases`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then((data) => {
                dispatch({ type: 'FETCH_CASE_TEST_PURCHASES_SUCCESS', testPurchases: data, params: validatedParams })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CASE_TEST_PURCHASES_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const addCaseTestPurchase = (caseId, params) => {

    let validatedParams = validateTestPurchase(params);

    return (dispatch) => {

        dispatch({ type: 'ADD_CASE_TEST_PURCHASE' });

        let { test_purchase } = validatedParams;

        if(!test_purchase.item || !test_purchase.item.length) {
            // return dispatch({ type: 'ADD_CASE_TEST_PURCHASE_FAIL', error: 'Please enter an item.' })
        }
        // if(!test_purchase.user_ids || !test_purchase.user_ids.length) {
        //     return dispatch({ type: 'ADD_CASE_TEST_PURCHASE_FAIL', error: 'Please assign staff.' })
        // }
        // if(!test_purchase.authorized_by_user_id) {
        //     return dispatch({ type: 'ADD_CASE_TEST_PURCHASE_FAIL', error: 'Please select the staff member who has authorized this.' })
        // }
        // if(test_purchase.type === 'online' && !test_purchase.purchase_status) {
        //     return dispatch({ type: 'ADD_CASE_TEST_PURCHASE_FAIL', error: 'Please select a purchase status.' })
        // }

        return post(`/cases/${caseId}/test-purchases`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {

                dispatch({ type: 'ADD_CASE_TEST_PURCHASE_SUCCESS', testPurchase: data, success: 'The test purchase has been created.' })

                // Refetch log and case documents
                dispatch(getCaseLog(caseId, true))

                if(validatedParams.documents) {
                    dispatch(getCaseDocuments(caseId))
                }
            })
            .catch(err => {
                dispatch({ type: 'ADD_CASE_TEST_PURCHASE_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const getCaseDocuments = (caseId, params) => {

    let validatedParams = validateParams(['limit', 'page', 'sort', 'sortBy'], params);

    return (dispatch) => {

        dispatch({ type: 'FETCH_CASE_DOCUMENTS' });

        return get(`/cases/${caseId}/documents`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'FETCH_CASE_DOCUMENTS_SUCCESS', documents: data, params: validatedParams })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CASE_DOCUMENTS_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const addCaseSiteVisit = (caseId, params) => {

    let validatedParams = validateParams(['documents', 'logged_time', 'site_visit'], params);

    return (dispatch) => {

        let { site_visit } = validatedParams;

        if(!site_visit.address || !site_visit.address.length) {
            return dispatch({ type: 'ADD_SITE_VISIT_FAIL', error: 'Please enter an address.' })
        }
        if(!site_visit.user_ids || !site_visit.user_ids.length) {
            return dispatch({ type: 'ADD_SITE_VISIT_FAIL', error: 'Please assign staff.' })
        }

        dispatch({ type: 'ADD_SITE_VISIT' });

        return post(`/cases/${caseId}/site-visits`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'ADD_SITE_VISIT_SUCCESS', siteVisit: data, success: 'The site visit has been created.' })

                // Refetch log and case documents
                dispatch(getCaseLog(caseId, true))

                if(validatedParams.documents) {
                    dispatch(getCaseDocuments(caseId))
                }
            })
            .catch(err => {
                dispatch({ type: 'ADD_SITE_VISIT_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const updateCaseSiteVisit = (caseId, siteVisitId, params) => {

    let validatedParams = validateParams(['documents', 'logged_time', 'site_visit'], params);

    return (dispatch) => {

        dispatch({ type: 'UPDATE_CASE_SITE_VISIT' });

        return put(`/cases/${caseId}/site-visits/${siteVisitId}`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'UPDATE_CASE_SITE_VISIT_SUCCESS', siteVisit: data, success: 'The site visit has been updated.' })

                // Refetch log and case documents
                dispatch(getCaseLog(caseId, true))

                if(validatedParams.documents) {
                    dispatch(getCaseDocuments(caseId))
                }
            })
            .catch(err => {
                dispatch({ type: 'UPDATE_CASE_SITE_VISIT_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const completeCaseSiteVisit = (caseId, siteVisitId, params) => {

    let validatedParams = validateParams(['documents', 'logged_time', 'site_visit'], params);

    return (dispatch) => {

        dispatch({ type: 'COMPLETE_CASE_SITE_VISIT' });

        return post(`/cases/${caseId}/site-visits/${siteVisitId}/complete`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'COMPLETE_CASE_SITE_VISIT_SUCCESS', siteVisit: data, success: 'The site visit has been completed.' })
            })
            .catch(err => {
                dispatch({ type: 'COMPLETE_CASE_SITE_VISIT_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const deleteCaseSiteVisit = (caseId, siteVisitId) => {

    return (dispatch) => {

        dispatch({ type: 'DELETE_CASE_SITE_VISIT' });

        return remove(`/cases/${caseId}/site-visits/${siteVisitId}`, null, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(() => {
                dispatch({ type: 'DELETE_CASE_SITE_VISIT_SUCCESS', id: siteVisitId, success: 'The site visit has been deleted.' })
            })
            .catch(err => {
                dispatch({ type: 'DELETE_CASE_SITE_VISIT_FAIL' });
                return dispatchErrors(err, dispatch);
            });

    }

}

export const updateCaseTestPurchase = (caseId, testPurchaseId, params) => {

    let validatedParams = validateTestPurchase(params);

    return (dispatch) => {

        dispatch({ type: 'UPDATE_CASE_TEST_PURCHASE' });

        return put(`/cases/${caseId}/test-purchases/${testPurchaseId}`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'UPDATE_CASE_TEST_PURCHASE_SUCCESS', testPurchase: data, success: 'The test purchase has been updated.' })

                // Refetch log and case documents
                dispatch(getCaseLog(caseId, true))

                if(validatedParams.documents) {
                    dispatch(getCaseDocuments(caseId))
                }
            })
            .catch(err => {
                dispatch({ type: 'UPDATE_CASE_TEST_PURCHASE_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const completeCaseTestPurchase = (caseId, testPurchaseId, params) => {

    let validatedParams = validateTestPurchase(params, true);

    return (dispatch) => {

        dispatch({ type: 'COMPLETE_CASE_TEST_PURCHASE' });

        return post(`/cases/${caseId}/test-purchases/${testPurchaseId}/complete`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'COMPLETE_CASE_TEST_PURCHASE_SUCCESS', testPurchase: data, success: 'The test purchase has been completed.' })
            })
            .catch(err => {
                dispatch({ type: 'COMPLETE_CASE_TEST_PURCHASE_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const deleteCaseTestPurchase = (caseId, testPurchaseId) => {

    return (dispatch) => {

        dispatch({ type: 'DELETE_CASE_TEST_PURCHASE' });

        return remove(`/cases/${caseId}/test-purchases/${testPurchaseId}`, null, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(() => {
                dispatch({ type: 'DELETE_CASE_TEST_PURCHASE_SUCCESS', id: testPurchaseId, success: 'The test purchase has been deleted.' })
            })
            .catch(err => {
                dispatch({ type: 'DELETE_CASE_TEST_PURCHASE_FAIL' });
                return dispatchErrors(err, dispatch);
            });

    }

}

export const addCase = (customerId, params) => {

    // Remove any invalid parameters
    let validatedParams = validateParams(['customer_id', 'title', 'source_id', 'brand_authorized', 'is_counterfeit', 'description', 'is_potential'], params);

    return (dispatch) => {

        dispatch({ type: 'ADD_CASE' });

        return post(`/customers/${customerId}/cases`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'ADD_CASE_SUCCESS', case: data })
            })
            .catch(err => {
                dispatch({ type: 'ADD_CASE_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const updateCase = (caseId, params) => {

    return (dispatch) => {

        dispatch({ type: 'UPDATE_CASE' });

        let {
            title,
            assigned_users,
        } = params;

        if(!title) {
            dispatch({ type: 'UPDATE_CASE_FAIL', error: 'Please enter a title.', saving: false }); return;
        }

        if(!assigned_users || !assigned_users.length) {
            dispatch({ type: 'UPDATE_CASE_FAIL', error: 'Please assign at least one investigator.', saving: false }); return;
        }

        // Remove any invalid parameters
        let validatedParams = validateParams(['title', 'customer_reference', 'is_retainer', 'deadline', 'deadline_reason', 'assigned_users', 'trading_standards_authority', 'crime_type_id', 'source_id', 'is_potential'], params);

        return put(`/cases/${caseId}`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'UPDATE_CASE_SUCCESS', case: data, success: 'The case has been updated.' })
            })
            .catch(err => {
                dispatch({ type: 'UPDATE_CASE_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const completeCase = (caseId, params) => {

    let validatedParams = validateParams(['outcome', 'invoice'], params);

    return (dispatch) => {

        dispatch({ type: 'COMPLETE_CASE' });

        return post(`/cases/${caseId}/complete`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ type: 'COMPLETE_CASE_SUCCESS', case: data, success: 'The case has been completed.' })
            })
            .catch(err => {
                dispatch({ type: 'COMPLETE_CASE_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const unarchiveCase = (caseId) => {

    return (dispatch) => {

        dispatch({ type: 'UNARCHIVE_CASE' });

        return post(`/cases/${caseId}/unarchive`, null, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(() => {
                dispatch({ type: 'UNARCHIVE_CASE_SUCCESS', success: 'The case has been re-opened.' })
            })
            .catch(err => {
                dispatch({ type: 'UNARCHIVE_CASE_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const getSources = (params) => {

    // Remove any invalid parameters
    let validatedParams = validateParams(['sort', 'page', 'limit'], params);

    return (dispatch) => {

        dispatch({ type: 'FETCH_CASE_SOURCES' });

        return get('/case-sources', validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(( data ) => {
                dispatch({ type: 'FETCH_CASE_SOURCES_SUCCESS', sources: data })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_CASE_SOURCES_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const getInvoice = (caseId) => {

    return (dispatch) => {

		dispatch({ type: 'FETCH_INVOICE' });
		
        return get(`/cases/${caseId}/invoice`, {}, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                dispatch({ 
					type: 'FETCH_INVOICE_SUCCESS', 
					invoice: data.map(obj => ({
						id: uuid(),
						...obj
					}))
				});
            })
            .catch(err => {
                dispatch({ type: 'FETCH_INVOICE_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}

export const resetCase = () => {

    return (dispatch) => {

        dispatch({ type: 'RESET_CASE' });

    }

}

/*
* DOCS stuff
* mine param is different
* archived not working
* */