import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { SROnly } from '../Utils';

export const SearchBarWrap = styled.form`
    display: flex;
    width: 100%;
    max-width: 345px;
    
    input {
        height: 25px;
        flex: 1 0 0;
        padding: 1px 6px;
        border-right: 0;
        appearance: none;
    }
    
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border: 0;
        background: ${({ theme }) => theme.blue} url('/svg/icon-search.svg') no-repeat center/14px;
        transition: ${({ theme }) => theme.duration.quick} background;
        cursor: pointer;
        
        &:hover {
            background-color: ${({ theme }) => theme.blueLight};
        }
    }
    
    +* {
        margin-top: 20px;
    }
`;

class SearchBar extends Component {
    handleSubmit = (e) => {
        e.preventDefault();

        if(e.target.search.value === '') return false;

        this.props.onSubmit(e.target.search.value);

        return false;
    }

    handleChange = (e) => {
        let { value } = e.target;

        this.props.onChange(value);

        // If the default browser 'x' is clicked, treat this as a reset.
        if(value === '' || this.props.submitOnChange) {
            this.props.onSubmit(value);
        }
    }

    render() {
        let {
            id,
            placeholder,
            value,
        } = this.props;

        if(value === 'lemontest') {
            throw new Error('Big Lemon error test');
        }

        return(
            <SearchBarWrap
                onSubmit={this.handleSubmit}
                data-testid={'form'}
            >
                <label htmlFor={id}>
                    <SROnly>{placeholder}</SROnly>
                </label>
                <input
                    type="search"
                    name={"search"}
                    placeholder={placeholder}
                    value={value}
                    onChange={this.handleChange}
                    data-testid="input"
                />
                <button type={"submit"}>
                    <SROnly>Search</SROnly>
                </button>
            </SearchBarWrap>
        );
    }
}

SearchBar.propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    submitOnChange: PropTypes.bool,
}

SearchBar.defaultProps = {
    placeholder: 'search',
    submitOnChange: false,
    onChange: () => null,
    onSubmit: () => null,
}

export default SearchBar;