import theme from '../config/theme';

export default `
    select {
        width: 100%;
        padding: 2px 5px;
        border: 1px solid ${theme.greyBorder};
        border-radius: 3px;
        appearance: none;
        font-size: ${theme.fontSize.body};
        background: ${theme.whiteish} url(/svg/icon-chevron.svg) no-repeat right 6px center/10px!important;
    }
    
    input {
        width: 100%;
        padding: 5px 12px;
        border: 1px solid ${theme.greyBorder};
    }
    
    input[type="checkbox"],
    input[type="radio"],
    input[type="submit"],
    input[type="file"] {
        width: auto;
        padding: 0;
        border: 0;
    }
    
    ::placeholder {
        color: ${theme.lightText};
        font-weight: 300;
    }
    
    input,
    select,
    textarea {
        &[disabled] {
            background: ${theme.greyLighter};
        }
    }
    
    .DayPicker.DayPicker {
        display: block;
        margin: 0 auto;
        max-width: 380px;
        background: white;
        z-index: 10;
        border: ${theme.border.main};
        
        &:not(.DayPicker--interactionDisabled) {
            .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
                &:hover {
                    background: ${theme.blueLighter};
                    color: white;
                }
            }
        }
    }
    
    .DayPicker-Month.DayPicker-Month {
        margin: 18px 20px 0;
        border-collapse: initial;
        border-spacing: 14px 0;
    }
    
    .DayPicker-Day.DayPicker-Day {
        height: 32px;
        padding: 0 0.5rem;
        transition: color, background ${theme.duration.quick};
        font-size: ${theme.fontSize.bodySm};
        color: ${theme.blueText};
    
        &--today {
            color: ${theme.blueLighter};
        }
        
        +.DayPicker-Day {
            margin-left: 5px;
        }
    }
    
    .DayPicker-Caption.DayPicker-Caption {
        padding: 0 21px;
        
        >div {
            color: ${theme.blueText};
            font-size: ${theme.fontSize.bodySm};
            font-weight: 400;
        }
    }
    
    .DayPicker-Weekday {
        color: ${theme.darkText};
        font-size: ${theme.fontSize.bodySm};
        font-weight: 500;
    }
    
    .DayPicker-NavButton.DayPicker-NavButton {
        right: 37px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-size: 6px;
        background-color: ${theme.greyLightest};
    }
`;