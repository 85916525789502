import React, { Component, Fragment } from 'react';

import {
    FormGroup,
    FormItemGroup,
    FormItem,
    DateTimeWrap,
    DateTimeInput,
    TimeSwitch,
    TimeInput,
    TextArea,
    FileUploader,
} from '../Forms';
import { Link } from '../Text';
import { Grid } from '../Layout';
import { ModalButtons } from '../Modals';
import { ButtonListItem, Button } from '../Buttons';
import { getLoggedTime } from '../../utils/dates';

class ViewRaidForm extends Component {
    render() {
        let {
            documents,
            logged_time,
            raid: {
                start_date,
                end_date,
                notes,
                users,
                address,
            },
        } = this.props;

        // Separate additional_logged_time from logged_time
        logged_time = getLoggedTime(logged_time);

        return(
            <Fragment>
                <FormGroup center={true}>
                    <FormItemGroup labelWidth={"100px"} wide={true}>
                        <FormItem label={"Raid Start Date"} id={'raid_start_date'} minWidthInput={true}>
                            <DateTimeWrap>
                                <DateTimeInput
                                    id={"raid_start_date"}
                                    value={start_date.split(' ')[0]}
                                    disabled={true}
                                />
                                <TimeSwitch
                                    value={start_date.split(' ')[1]}
                                    prefix={"start"}
                                    disabled={true}
                                />
                            </DateTimeWrap>
                        </FormItem>

                        <FormItem label={"Raid End Date"} id={'raid_end_date'} minWidthInput={true}>
                            <DateTimeWrap>
                                <DateTimeInput
                                    id={"raid_end_date"}
                                    value={end_date.split(' ')[0]}
                                    disabled={true}
                                />
                                <TimeSwitch
                                    value={end_date.split(' ')[1]}
                                    prefix={"end"}
                                    disabled={true}
                                />
                            </DateTimeWrap>
                        </FormItem>

                        <FormItem label={"Address"} id={'address'} align={'top'}>
                            <TextArea
                                value={address || ''}
                                id={'address'}
                                disabled={true}
                            />
                        </FormItem>

                        <FormItem label={"Raid Staff"} id={'staff'} align={'top'}>
                            <Grid cols={[[1, 0], [2, 370], [3, 521]]} gap={'10px'}>
                                { users.map(user => (
                                    <ButtonListItem
                                        showControls={false}
                                        key={`investigator${user.id}`}
                                        title={user.name}
                                    >{ user.name }</ButtonListItem>
                                )) }
                            </Grid>
                        </FormItem>

                        <FormItem label={"Logged Time"} id={'logged_time'}>
                            <TimeInput
                                value={logged_time.logged_time.value}
                                id={'logged_time'}
                                minWidth={true}
                                disabled={true}
                            />
                        </FormItem>

                        <FormItem label={"Additional Logged Time"} id={'additional_logged_time'}>
                            <TimeInput
                                value={logged_time.additional_logged_time.value}
                                id={'additional_logged_time'}
                                minWidth={true}
                                disabled={true}
                            />
                        </FormItem>

                        <FormItem label={"Notes"} id={'notes'} align={'top'}>
                            <TextArea
                                value={notes || ''}
                                id={'notes'}
                                disabled={true}
                            />
                        </FormItem>

                        { documents && !!documents.length &&
                            <FormItem label={"Associated files"} id={'files'} align={'top'}>
                                <FileUploader
                                    files={documents}
                                    readOnly={true}
                                    cols={2}
                                />
                            </FormItem>
                        }
                    </FormItemGroup>
                </FormGroup>

                <ModalButtons pl={'100px'} wide={true}>
                    { this.props.raid.id &&
                        <Button
                            red={true}
                            small={true}
                            onClick={() => this.props.onDelete(this.props.raid.id)}
                        >Delete</Button>
                    }
                    <Link el={'button'} onClick={this.props.onCancel}>Cancel</Link>
                </ModalButtons>
            </Fragment>
        );
    }
}

export default ViewRaidForm;