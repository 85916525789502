import React, { Component, Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isEmpty } from 'underscore';

import { getCustomer, updateCustomer, resetCustomer } from '../../actions/customers';

import {
    Input,
    FormItem,
    FormItemGroup,
    LockableFormGroup,
    ToggleSwitch,
    TextArea,
    Tabs,
} from '../../components/Forms';
import {
    Loading,
    Error,
    ErrorBoundary,
} from '../../components/Utils';

import { getEventKey, getEventVal } from '../../utils/dataMap';
import { blurAllFields } from '../../utils/interaction';
import { hasPermission } from '../../utils/validation';

class CustomerDetailsContainer extends Component {
    state = {
        form: {},
        hasChanged: false,
        locked: true,
    }

    componentDidMount() {
        this.props.getCustomer(this.props.match.params.id);
    }

    componentWillUnmount() {
        this.props.resetCustomer();
    }

    static getDerivedStateFromProps(props, state) {
        if(!isEmpty(state.form) || !props.details) return null;

        let {
            name,
            status,
            type,
            referral,
            address,
            invoice_organisation,
            invoice_address,
            reactive_retainer,
            proactive_retainer,
        } = props.details;

        return {
            form: {
                name: name || '',
                status: status || '',
                type: type || '',
                referral: referral || '',
                address: address || '',
                invoice_organisation: invoice_organisation || '',
                invoice_address: invoice_address || '',
                reactive_retainer_cases_per_month: (reactive_retainer && reactive_retainer.cases_per_month) || '0',
                proactive_retainer_cases_per_month: (proactive_retainer && proactive_retainer.cases_per_month) || '0',
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(!prevProps.error && this.props.error === 'notFound') {
            this.props.history.push('/not-found');
        }

        if(prevProps.saving && !this.props.saving) {
            this.setState({
                locked: true
            });
        }

        if(prevState.locked === this.state.locked) return;

        if(this.state.locked) {
            blurAllFields();
        }
    }

    saveAndLock = () => {
        this.props.updateCustomer(this.props.match.params.id, this.state.form);
    }

    handleChange = (e, k) => {
        let key = k || getEventKey(e);

        this.setState({
            hasChanged: true,
            form: {
                ...this.state.form,
                [key]: getEventVal(e)
            },
        });
    }

    render() {
        let {
            locked,
            hasChanged,
            form: {
                name,
                address,
                invoice_organisation,
                invoice_address,
                reactive_retainer_cases_per_month,
                proactive_retainer_cases_per_month,
                referral,
                status,
                type,
            }
        } = this.state;

        let {
            details,
            fetching,
            saving,
            loggedIn,
            self
        } = this.props;

        if(!loggedIn) return <Redirect login={'/login'} />

        if(fetching) return <Loading />

        let propAddress = (details && details.address) || '',
            propInvoiceOrganisation = (details && details.invoice_organisation) || '',
            propInvoiceAddress = (details && details.invoice_address) || '',
            propReferral = (details && details.referral) || '';

        type = locked && !saving ? details.type : type;
        status = locked && !saving ? details.status : status;

        let showRetainerFields = locked && !saving ? details.type !== 'lead' : type !== 'lead';

        return(
            <LockableFormGroup
                title={"Customer details"}
                border="all"
                background="all"
                padChildren={true}
                padBottom={true}
                id={"customer-details"}
                locked={locked}
                toggleLock={() => this.setState({ locked: !locked })}
                saveAndLock={this.saveAndLock}
                saveDisabled={!hasChanged}
                saving={saving}
                unlockable={hasPermission(self, 'customers:write')}
            >
                <ErrorBoundary render={() => <Error overlay={true} />}>
                    <FormItemGroup labelWidth={"170px"}>
                        <FormItem label={"Name"} id={'name'} required={true}>
                            <Input
                                value={locked && !saving ? details.name : name}
                                id={'name'}
                                type={"text"}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem label={"Address"} id={'address'} align={'top'}>
                            <TextArea
                                value={locked && !saving ? propAddress : address}
                                id={'address'}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem label={"Invoice Organisation"} id={'invoice_organisation'}>
                            <Input
                                value={locked && !saving ? propInvoiceOrganisation : invoice_organisation}
                                id={'invoice_organisation'}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem label={"Invoice Address"} id={'invoice_address'} align={'top'}>
                            <TextArea
                                value={locked && !saving ? propInvoiceAddress : invoice_address}
                                id={'invoice_address'}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem label={"Status"} id={'status'} required={true}>
                            <Tabs
                                styleType={"blue"}
                                name={"customer-status"}
                                items={[
                                    {
                                        label: 'Customer',
                                        id: 'customer',
                                        checked: type === 'customer',
                                        onChange: () => this.handleChange('customer', 'type')
                                    },
                                    {
                                        label: 'Lead',
                                        id: 'lead',
                                        checked: type === 'lead',
                                        onChange: () => this.handleChange('lead', 'type')
                                    },
                                ]}
                            />
                        </FormItem>

                        <FormItem label={"Referral"} id={'referral'}>
                            <Input
                                value={locked && !saving ? propReferral : referral}
                                id={'referral'}
                                type={"text"}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        { showRetainerFields &&
                            <Fragment>
                                <FormItem label={"Reactive Cases Per Month"} id={'reactive_retainer_cases_per_month'}>
                                    <Input
                                        value={locked && !saving ? ((details.reactive_retainer && details.reactive_retainer.cases_per_month) || 0) : reactive_retainer_cases_per_month}
                                        id={'reactive_retainer_cases_per_month'}
                                        minWidth={true}
                                        onChange={this.handleChange}
                                    />
                                </FormItem>

                                <FormItem label={"Proactive Cases Per Month"} id={'proactive_retainer_cases_per_month'}>
                                    <Input
                                        value={locked && !saving ? ((details.proactive_retainer && details.proactive_retainer.cases_per_month) || 0) : proactive_retainer_cases_per_month}
                                        id={'proactive_retainer_cases_per_month'}
                                        minWidth={true}
                                        onChange={this.handleChange}
                                    />
                                </FormItem>
                            </Fragment>
                        }

                        <FormItem label={"Archived"} id={'status'}>
                            <ToggleSwitch
                                checked={status === 'closed'}
                                id={"status"}
                                onChange={() => {
                                    let val = status === 'closed' ? 'open' : 'closed';
                                    this.handleChange(val, 'status')
                                }}
                                // disabled={details.customer.cases_per_month < 1}
                            />
                        </FormItem>
                    </FormItemGroup>

                </ErrorBoundary>
            </LockableFormGroup>
        );
    }
}

const mapStateToProps = state => {
    return {
        self: state.user.self,
        loggedIn: state.user.loggedIn,
        details: state.customer.data.details.data,
        fetching: state.customer.data.details.fetching,
        saving: state.customer.data.details.saving,
        error: state.customer.data.details.error,
        // fetching: true,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getCustomer, updateCustomer, resetCustomer }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerDetailsContainer));