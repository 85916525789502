import React, { Component, Fragment } from 'react';
import MediaQuery from 'react-responsive';
import styled from 'styled-components';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { format } from 'date-fns';

import { Input } from './';
import { SROnly } from '../Utils';
import {
    Modal
} from '../../components/Modals';
import { checkIfClickedInside, checkIfAncestorsHaveClass } from '../../utils/interaction';
import { minWidthInput } from '../../utils/style';

const DateTimeInputWrap = styled.div`
    position: relative;
    max-width: 130px;
    
    >button {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        width: 27px;
        height: 100%;
        border: 0;
        border-left: ${({ theme }) => theme.border.main};
        font-size: ${({ theme }) => theme.fontSize.body};
        color: ${({ theme }) => theme.darkText};
        background: none;
        cursor: pointer;
    }
    
    .DayPicker.DayPicker {
        position: absolute;
        display: inline-block;
        top: 100%;
        left: 0;
        max-width: none;
        margin: 0;
    }
    
    input {
        width: 100%;
    } 
    
    ${({ minWidth }) => minWidth && minWidthInput()}
`;

class DateTimeInput extends Component {
    constructor(props) {
        super(props);

        this.calendarRef = React.createRef();

        this.state = {
            pickerIsOpen: false,
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('click', this.handleClick);
    }

    handleKeyDown = e => {
        /*
        * Close picker if ESC or tab is pressed. TODO: Support tabbing through calendar
        * */
        if((e.which === 27 || e.which === 9) && this.state.pickerIsOpen) {
            this.togglePicker();
        }
    }

    handleClick = e => {
        if(!this.state.pickerIsOpen) return;

        const clickedInside = checkIfClickedInside(e.target, this.calendarRef.current);
        const nestedInModal = !!checkIfAncestorsHaveClass(e.target, 'ReactModal__Overlay');

        if(!clickedInside && !nestedInModal && this.state.pickerIsOpen) {
            this.togglePicker();
        }
    }

    togglePicker = () => {
        this.setState({
            pickerIsOpen: !this.state.pickerIsOpen
        });
    }

    handleDayClick = date => {
        this.props.handleChange(date);
        this.togglePicker();
    }

    render() {
        let { value, minWidth, disabled, id } = this.props,
            { pickerIsOpen } = this.state;

        let formatted = value ? format(value, 'DD/MM/YYYY') : '';

        return(
            <DateTimeInputWrap
                innerRef={this.calendarRef}
                minWidth={minWidth}
            >
                <Input
                    value={formatted}
                    onClick={this.togglePicker}
                    readOnly={true}
                    disabled={disabled}
                    id={id}
                />
                <button onClick={this.togglePicker}>
                    <SROnly>Open date picker</SROnly>
                    <i className="icon-calendar" />
                </button>
                { pickerIsOpen && !disabled &&
                    <Fragment>
                        <MediaQuery query={'(max-width: 1024px)'}>
                            <Modal
                                isOpen={pickerIsOpen}
                                onRequestClose={() => this.setState({
                                    pickerIsOpen: false,
                                })}
                                borderless={true}
                                translucent={true}
                            >
                                <DayPicker
                                    onDayClick={this.handleDayClick}
                                />
                            </Modal>
                        </MediaQuery>
                        <MediaQuery query={'(min-width: 1025px)'}>
                            <DayPicker
                                onDayClick={this.handleDayClick}
                            />
                        </MediaQuery>
                    </Fragment>
                }
            </DateTimeInputWrap>
        );
    }
}

export default DateTimeInput;