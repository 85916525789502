import React, { Component, Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addCustomer } from '../../actions/customers';

import {
    Input,
    FormItem,
    FormItemGroup,
    FormGroup,
    ToggleSwitch,
    Tabs,
    TextArea,
} from '../../components/Forms';
import {
    Loading,
    Error,
    ErrorBoundary,
} from '../../components/Utils';
import { Button } from '../../components/Buttons';

import { getEventVal } from '../../utils/dataMap';

class NewCustomerContainer extends Component {
    state = {
        form: {
            name: '',
            type: '',
            referral: '',
            address: '',
            invoice_address: '',
            retainer: false,
            reactive_retainer_cases_per_month: 0,
            proactive_retainer_cases_per_month: 0,
        },
    }

    componentDidUpdate(prevProps, prevState) {
        let { details, history } = this.props;

        if(details && details.id) {
            history.push(`/customers/${details.id}`);
        }
    }

    handleSubmit = e => {
        let { form } = this.state;

        e.preventDefault();

        this.props.addCustomer(form);

        return false;
    }

    handleChange = (e, id) => {
        let targetId = id;

        if(!targetId) {
            targetId = e.target.id;
        }

        this.setState({
            form: {
                ...this.state.form,
                [targetId]: id ? e : getEventVal(e)
            }
        });
    }

    handleRadioChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    render() {
        let {
            loggedIn,
            fetching,
            saving,
        } = this.props;

        let {
            form: {
                name,
                type,
                referral,
                address,
                invoice_address,
                retainer,
                reactive_retainer_cases_per_month,
                proactive_retainer_cases_per_month,
            },
        } = this.state;

        let requiredPopulated = name && type;

        if(!loggedIn) return <Redirect to='/login' />

        return(
            <form onSubmit={this.handleSubmit}>
                <FormGroup
                    title={"Customer details"}
                    border="all"
                    background="all"
                    padChildren={true}
                    padBottom={true}
                    id={"customer-details"}
                >
                    <ErrorBoundary render={() => <Error overlay={true} />}>
                        <FormItemGroup labelWidth={"170px"}>
                            <FormItem label={"Name"} id={'name'} required={true}>
                                <Input
                                    value={name}
                                    id={'name'}
                                    type={"text"}
                                    onChange={this.handleChange}
                                />
                            </FormItem>

                            <FormItem label={"Address"} id={'address'} align={'top'}>
                                <TextArea
                                    value={address}
                                    id={'address'}
                                    onChange={this.handleChange}
                                />
                            </FormItem>

                            <FormItem label={"Invoice Address"} id={'invoice_address'} align={'top'}>
                                <TextArea
                                    value={invoice_address}
                                    id={'invoice_address'}
                                    onChange={this.handleChange}
                                />
                            </FormItem>

                            <FormItem label={"Status"} id={'type'} required={true}>
                                <Tabs
                                    styleType={"blue"}
                                    name={"type"}
                                    items={[
                                        {
                                            label: 'Customer',
                                            value: 'customer',
                                            checked: type === 'customer',
                                            onChange: this.handleRadioChange,
                                            id: 'customer',
                                        },
                                        {
                                            label: 'Lead',
                                            value: 'lead',
                                            checked: type === 'lead',
                                            onChange: this.handleRadioChange,
                                            id: 'lead',
                                        },
                                    ]}
                                />
                            </FormItem>

                            <FormItem label={"Referral"} id={'referral'}>
                                <Input
                                    value={referral}
                                    id={'referral'}
                                    type={"text"}
                                    onChange={this.handleChange}
                                />
                            </FormItem>

                            { type === 'customer' &&
                                <FormItem label={"Retainer"} id={'retainer'}>
                                    <ToggleSwitch
                                        checked={retainer}
                                        id={"retainer"}
                                        onChange={this.handleChange}
                                    />
                                </FormItem>
                            }

                            { retainer &&
                                <Fragment>
                                    <FormItem label={"Reactive Cases Per Month"} id={'reactive_retainer_cases_per_month'}>
                                        <Input
                                            value={reactive_retainer_cases_per_month}
                                            id={'reactive_retainer_cases_per_month'}
                                            type={"number"}
                                            minWidth={true}
                                            onChange={this.handleChange}
                                        />
                                    </FormItem>

                                    <FormItem label={"Proactive Cases Per Month"} id={'proactive_retainer_cases_per_month'}>
                                        <Input
                                            value={proactive_retainer_cases_per_month}
                                            id={'proactive_retainer_cases_per_month'}
                                            type={"number"}
                                            minWidth={true}
                                            onChange={this.handleChange}
                                        />
                                    </FormItem>
                                </Fragment>
                            }
                        </FormItemGroup>

                        <Button
                            small={true}
                            blue={true}
                            disabled={saving || fetching || !requiredPopulated}
                        >Save</Button>

                        { saving && <Loading overlay={true} /> }
                    </ErrorBoundary>
                </FormGroup>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        fetching: state.customer.fetching,
        details: state.customer.data.details.data,
        saving: state.customer.saving,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ addCustomer }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NewCustomerContainer));