import React, { Component, Fragment } from 'react';

import { VehicleDropdownContainer } from '../../containers/Case';

import {
    FormGroup,
    FormItemGroup,
    FormItem,
    Input,
    TextArea,
    ToggleSwitch,
} from '../Forms';
import { ModalButtons } from '../Modals';
import { Button } from '../Buttons';
import { Link } from '../Text';

import { getEventKey, getEventVal } from '../../utils/dataMap';

const updateVehicleState = (data) => {
    return {
        number_plate: data['number_plate'] || '',
        make: data['make'] || '',
        model: data['model'] || '',
        colour: data['colour'] || '',
        vnc_check: data['vnc_check'],
        notes: data['notes'] || '',
    }
}

class VehicleForm extends Component {
    constructor(props) {
        super(props);

        let {
            id,
            onSave,
            onCancel,
            vehicleIds,
            ...fields
        } = props;

        this.state = {
            hasChanged: false,
            id: props.id || null,
            editing: !props.id,
            form: updateVehicleState(fields),
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // If the data was saved, with no errors, clear the form.
        if((prevProps.saving && !this.props.saving) && !this.props.error) {
            this.clearForm();
        }
    }

    handleChange = (e, k) => {
        let key = k || getEventKey(e);

        this.setState({
            hasChanged: true,
            form: {
                ...this.state.form,
                [key]: getEventVal(e)
            },
        });
    }

    handleVehicleChange = (e) => {
        let {
            id,
            ...fields
        } = e;

        this.setState({
            id,
            hasChanged: true,
            editing: false,
            form: updateVehicleState(fields)
        });
    }

    handleSave = (stayOpen) => {
        let { id, form } = this.state;

        this.props.onSave(id, form, stayOpen);
    }

    clearForm = () => {
        this.setState({
            id: null,
            hasChanged: true,
            form: updateVehicleState({})
        });
    }

    render() {
        let {
            id,
            hasChanged,
            editing,
            form: {
                number_plate,
                make,
                model,
                colour,
                vnc_check,
                notes,
            }
        } = this.state;

        let {
            onCancel,
            exclude,
            saving,
        } = this.props;

        let requiredPopulated = number_plate;

        return(
            <Fragment>
                <FormGroup center={true}>
                    <FormItemGroup labelWidth={"120px"} wide={true}>
                        <FormItem
                            label={"Number Plate"}
                            id={'number_plate'}
                            minWidthInput={true}
                            required={true}
                        >
                            <VehicleDropdownContainer
                                value={number_plate}
                                id={'number_plate'}
                                onChange={this.handleVehicleChange}
                                disabled={!!this.props.id}
                                exclude={exclude}
                            />
                            { this.props.id && !editing &&
                                <Link
                                    el={'button'}
                                    onClick={() => this.setState({ editing: true })}
                                    style={{
                                        marginLeft: '10px'
                                    }}
                                >Edit</Link>
                            }
                        </FormItem>

                        <FormItem label={"Make"} id={'make'}>
                            <Input
                                value={make}
                                id={'make'}
                                type={"text"}
                                onChange={this.handleChange}
                                disabled={!!(!editing && id)}
                            />
                        </FormItem>

                        <FormItem label={"Model"} id={'model'}>
                            <Input
                                value={model}
                                id={'model'}
                                type={"text"}
                                onChange={this.handleChange}
                                disabled={!!(!editing && id)}
                            />
                        </FormItem>

                        <FormItem label={"Colour"} id={'colour'}>
                            <Input
                                value={colour}
                                id={'colour'}
                                type={"text"}
                                onChange={this.handleChange}
                                disabled={!!(!editing && id)}
                            />
                        </FormItem>

                        <FormItem label={"Notes"} id={'notes'}>
                            <TextArea
                                value={notes}
                                id={'notes'}
                                onChange={this.handleChange}
                                disabled={!!(!editing && id)}
                            />
                        </FormItem>

                        <FormItem label={"VNC Check"} id={'vnc_check'}>
                            <ToggleSwitch
                                checked={vnc_check}
                                id={'vnc_check'}
                                onChange={this.handleChange}
                                disabled={!!(!editing && id)}
                            />
                        </FormItem>
                    </FormItemGroup>
                </FormGroup>

                <ModalButtons pl={'120px'} wide={true}>
                    <Button
                        small={true}
                        blue={true}
                        onClick={() => this.handleSave(false)}
                        disabled={saving || !hasChanged || !requiredPopulated}
                    >Save</Button>
                    <Button
                        small={true}
                        blue={true}
                        onClick={() => this.handleSave(true)}
                        disabled={saving || !hasChanged || !requiredPopulated}
                    >Save and add another</Button>
                    <Link el={'button'} onClick={onCancel}>Cancel</Link>
                </ModalButtons>
            </Fragment>
        );
    }
}

export default VehicleForm;