import React from 'react';
import styled from 'styled-components';
import Toggle from 'react-toggle';
import "react-toggle/style.css";

const ToggleSwitchWrap = styled.div`
    display: inline-flex;
    align-items: center;
    
    label {
        margin: ${({ labelAfter }) => labelAfter ? '0 0 0 10px' : '0 10px 0 0'};
        font-weight: 300;
        color: ${({ theme }) => theme.lightText};
        cursor: ${({ disabled }) => disabled ? 'default' : 'pointer'};
    }

    .react-toggle {
        order: ${({ labelAfter }) => labelAfter ? '-1' : 'initial'};

        &--checked {
            .react-toggle-track {
                background: ${({ theme }) => theme.green};
            }
            
            .react-toggle-thumb {
                left: 16px;
                border-color: #e5e5e5;
            }
            
            &:hover {
                &:not(.react-toggle--disabled) {
                    .react-toggle-track {
                        background: ${({ theme }) => theme.green};
                    }
                }
            }
        }
        
        &:hover {
            &:not(.react-toggle--disabled):not(.react-toggle--checked) {
                .react-toggle-track {
                    background: ${({ theme }) => theme.greyLighter};
                }
            }
        }
    }
    
    .react-toggle-track {
        width: 37px;
        height: 22px;
        border: 1px solid #E0E0E0;
        border-radius: 15px;     
        background: #ddd;   
    }
    
    .react-toggle-thumb {
        width: 21px;
        height: 21px;
        border: .5px solid ${({ theme }) => theme.greyLighter};
        box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1), 0 2px 2px 0 rgba(0,0,0,0.3);
    }
    
    body:not(.user-is-tabbing) & {    
        *,
        *:focus {
            outline: 0;
        }
        
        .react-toggle--focus {
            .react-toggle-thumb {
                box-shadow: 0 5px 10px 0 rgba(0,0,0,0.1), 0 2px 2px 0 rgba(0,0,0,0.3)
            }
        }
    }
    
    +* {
        margin-left: 35px;
    }
    
    @media screen and (max-width: 700px) {
        +* {
            margin-left: 15px;
        }
    }
`;

const ToggleSwitch = ({ onChange, checked, label, labelAfter, hideLabel, id, disabled, index }) => {
    return(
        <ToggleSwitchWrap labelAfter={labelAfter} disabled={disabled}>
            { label ? (
				<label 
					htmlFor={id} 
					className={hideLabel ? 'sr-only' : ''}
				>{label}</label>
			) : null }
            <Toggle
                icons={false}
                checked={!!checked}
                onChange={onChange}
                id={id}
                disabled={disabled}
				data-index={index}
            />
        </ToggleSwitchWrap>
    );
}

ToggleSwitch.defaultProps = {
    checked: false,
    disabled: false,
}

export default ToggleSwitch;