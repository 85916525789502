import React from 'react';
import styled from 'styled-components';

const CheckboxWrap = styled.div`
    display: flex;
    align-items: center;
    
    input {
        margin-right: 8px;
    }
    
    label {
        color: ${({ theme }) => theme.lightText};
    }
`;

const Checkbox = ({ label, id, checked, onChange }) => {
    return(
        <CheckboxWrap>
            <input type="checkbox" id={id} checked={checked} onChange={onChange} />
            <label htmlFor={id}>{ label }</label>
        </CheckboxWrap>
    );
}

Checkbox.propTypes = {

}

export default Checkbox;