import React, { Component } from 'react';

import { CasesContainer } from '../containers/Case';
import { LoggedTimeContainer } from '../containers/Report';

import { PageWithMainNav } from '../components/Layout';
import { Tabs } from '../components/Forms';
import { PageTitle } from '../components/Text';
import { Filters } from '../components/Tables';
import { MetaData, ScrollToTopOnMount } from '../components/Utils';

class Customers extends Component {
    state = {
        viewing: 'logged_time',
    }

    render() {
        return(
            <PageWithMainNav>
                <ScrollToTopOnMount />
                <MetaData vars={{ title: 'Reports' }} />

                <PageTitle>Reports</PageTitle>

                <Filters>
                    <div className="filters__bottom">
                        <Tabs
                            name={'current-report'}
                            items={[
                                {
                                    label: 'Logged Time',
                                    id: 'report-logged_time',
                                    checked: this.state.viewing === 'logged_time',
                                    onChange: e => this.setState({ viewing: 'logged_time' })
                                },
                                {
                                    label: 'Closed Potential Cases',
                                    id: 'cases-closed_nfa',
                                    checked: this.state.viewing === 'closed_nfa',
                                    onChange: e => this.setState({ viewing: 'closed_nfa' })
                                }
                            ]}
                        />
                    </div>
                </Filters>

                { this.state.viewing === 'closed_nfa' ? (
                    <CasesContainer
                        fixedStatus={['closed']}
                        fixedMineOnly={false}
                        fixedSearch={'no_further_action'}
                        excludeDeadlineColumns={true}
                    />
                ) : this.state.viewing === 'logged_time' ? (
                    <LoggedTimeContainer />
                ) : null }
            </PageWithMainNav>
        );
    }
}

export default Customers;