import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UserRolesContainer from './UserRolesContainer';
import { addUser } from '../../actions/users';

import {
    Input,
    FormItem,
    FormItemGroup,
    FormGroup,
} from '../../components/Forms';
import {
    Loading,
    Error,
    ErrorBoundary,
} from '../../components/Utils';
import { Button } from '../../components/Buttons';

import { getEventVal } from '../../utils/dataMap';

class NewUserContainer extends Component {
    state = {
        form: {
            email: '',
            role: {
                name: null,
                id: null
            }
        },
    }

    componentDidUpdate(prevProps, prevState) {
        let {
            saving,
            error,
            history
        } = this.props;

        if(prevProps.saving && !saving && !error) {
            history.push(`/users`);
        }
    }

    handleSubmit = e => {
        let { form: {
            email,
            role
        } } = this.state;

        e.preventDefault();

        this.props.addUser({
            email,
            role_id: role.id
        });

        return false;
    }

    handleChange = (e, id) => {
        let targetId = id;

        if(!targetId) {
            targetId = e.target.id;
        }

        this.setState({
            form: {
                ...this.state.form,
                [targetId]: id ? e : getEventVal(e)
            }
        });
    }

    handleRadioChange = (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    }

    render() {
        let {
            loggedIn,
            fetching,
            saving,
        } = this.props;

        let {
            form: {
                email,
                role
            },
        } = this.state;

        let requiredPopulated = email && role.id;

        if(!loggedIn) return <Redirect to='/login' />

        return(
            <form onSubmit={this.handleSubmit}>
                <FormGroup
                    title={"User details"}
                    border="all"
                    background="all"
                    padChildren={true}
                    padBottom={true}
                    id={"customer-details"}
                >
                    <ErrorBoundary render={() => <Error overlay={true} />}>
                        <FormItemGroup labelWidth={"36px"}>
                            <FormItem
                                label={"Email"}
                                id={'email'}
                                required={true}
                            >
                                <Input
                                    value={email}
                                    id={'email'}
                                    type={"email"}
                                    onChange={this.handleChange}
                                />
                            </FormItem>

                            <FormItem
                                label={"Role"}
                                id={'role'}
                                align={'top'}
                                required={true}
                            >
                                <UserRolesContainer
                                    value={role}
                                    onChange={data => this.handleChange(data, 'role')}
                                    id={'role'}
                                />
                            </FormItem>
                        </FormItemGroup>

                        <Button
                            small={true}
                            blue={true}
                            disabled={saving || !requiredPopulated}
                        >Save</Button>

                        { saving && <Loading overlay={true} /> }
                    </ErrorBoundary>
                </FormGroup>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        fetching: state.users.fetching,
        saving: state.users.saving,
        error: state.users.error,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ addUser }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NewUserContainer));