import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AnchorNav } from '../../components/Nav';
import { hasPermission } from '../../utils/validation';

const addItemsBasedOnSource = (items, { raids, site_visits, test_purchases }, self) => {
    let newItems = [...items];

    let addedItems = [];

    const siteVisitsPermission = hasPermission(self, 'site-visits:read');
    const testPurchasesPermission = hasPermission(self, 'test-purchases:read');
    const raidsPermission = hasPermission(self, 'raids:read');

    if(site_visits && siteVisitsPermission) {
        addedItems.push({
            label: 'Site Visits',
            id: '#site-visits',
        });
    }

    if(test_purchases && testPurchasesPermission) {
        addedItems.push({
            label: 'Test Purchases',
            id: '#test-purchases',
        });
    }

    if(raids && raidsPermission) {
        addedItems.push({
            label: 'Raids',
            id: '#raids',
        });
    }

    newItems.splice(5, 0, ...addedItems);

    return newItems;
}

const changeArchiveText = (items, status, self) => {
    let label = (status === 'closed' || status === 'invoiced') ? 'Unarchive Case' : 'Archive Case';

    if(!hasPermission(self, 'cases:resolve')) return items;

    return [...items, {
        label,
        id: '#resolve-case',
    }];
}

class CaseMenuContainer extends Component {
    constructor(props) {
        super(props);

        let menuItems = [
            {
                label: 'Case Details',
                id: '#case-details',
            },
            {
                label: 'Case Status',
                id: '#case-status',
            },
            {
                label: 'Activity Log',
                id: '#activity-log',
            },
            {
                label: 'Case Traders',
                id: '#case-traders',
            },
            {
                label: 'Case Vehicles',
                id: '#case-vehicles',
            },
        ]

        // If case details is populated at this point, add menu items based on source.
        if(props.details) {
            menuItems = addItemsBasedOnSource(menuItems, props.details.source, props.self);
        }

        this.state = { menuItems };
    }

    componentDidUpdate(prevProps) {
        let menuItems;
        let { details, documents, self } = this.props;

        // Add/remove documents to the menu depending on if there are any documents
        if(prevProps.documents.length !== documents.length) {

            if(prevProps.documents.length && !documents.length) {
                menuItems = this.state.menuItems.filter(item => item.id !== '#related-documents');
            }

            if(!prevProps.documents.length && documents.length) {
                menuItems = [...this.state.menuItems];
                menuItems.splice(3, 0, {
                    label: 'Related Documents',
                    id: '#related-documents',
                });
            }
        }

        // If case details is populated at this point, add menu items based on source and add unarchive case/archive case to end
        if(!prevProps.details && details) {
            menuItems = addItemsBasedOnSource([...this.state.menuItems], details.source, self);
            menuItems = changeArchiveText(menuItems, details.status, self);
        }

        if(menuItems && menuItems.length) {
            this.setState({ menuItems });
        }
    }

    render() {
        return <AnchorNav items={this.state.menuItems} />
    }
}

const mapStateToProps = state => {
    return {
        self: state.user.self,
        documents: state.case.data.documents.data,
        details: state.case.data.details.data,
    }
}

export default connect(
    mapStateToProps
)(CaseMenuContainer);