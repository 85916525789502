// Styled component container for main DataTables component
import styled from 'styled-components';

import { table, greenTable } from '../../../../style/tables';

const DataTableWrap = styled.div`
    position: relative;
    
    .ReactTable {
        border: 0;
        
        .rt-thead.rt-thead {
            border: 0;
            box-shadow: none;
            
        }

		.rt-tr.rt-tr {
			align-items: ${props => props.rowAlign};
		}
        
        .rt-th.rt-th,
        .rt-td.rt-td {
            display: flex;
            align-items: center;
            padding: 10px 16px;
            border: 0;

			&:last-of-type {
				margin-left: ${({ rightAlignLastCol }) => rightAlignLastCol ? 'auto' : 0};
			}
        }
        
        .rt-th.rt-th {
            height: 50px;
            border: 0;
            box-shadow: none;
            font-weight: 500;
            
            &.-sort-desc,
            &.-sort-asc {
                box-shadow: none;
                
                &:after {
                    display: block;
                }
            }
            
            &.-sort-asc {
                &:after {
                    transform: rotate(180deg) translateY(50%);
                }
            }
            
            &:after {
                content: '';
                display: none;
                position: absolute;
                width: 14px;
                height: 14px;
                top: 50%;
                right: 12px;
                transform: translateY(-50%);
                background: url('/svg/icon-chevron.svg') no-repeat center/100%;
                transition: ${({ theme }) => theme.duration.quickest} transform;
            }
        }
        
        .rt-td.rt-td {
            position: relative;
            height: ${({ noCellHeight }) => noCellHeight ? 'auto' : '50px'};
            min-height: 50px;
            
            >a {
                display: flex;
                align-items: center;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 10px 16px;
                font-weight: 500;
                color: ${({ theme }) => theme.darkText};
                text-decoration: none;
            }
        }
        
        .rt-resizer.rt-resizer {
            right: -3px;
            width: 6px;
        }
        
        ${({ theme, styleType }) => styleType === 'weekCalendarHeader' && `
            .rt-table {
                background: ${theme.greyLightest};
            }
            
            .rt-tbody {
                background: white;
                
                .rt-td.rt-td {
                    &:not(:first-of-type) {
                        justify-content: center;
                    }
                }
            }
            
            .rt-thead.rt-thead {
                .rt-th {
                    text-transform: uppercase;
                    text-align: center;
                }
            
                &.-headerGroups {
                    .rt-th {
                        height: 28px;
                        
                        &:nth-of-type(2) {
                            flex: 1 0 0!important;
                            justify-content: center;
                        }
                    }
                }
                
                &.-headerGroups,
                &.-headerGroups .rt-th {
                    min-width: 0!important;
                }
                
                &.-header {
                    .rt-th {
                        height: 23px;
                        font-size: ${theme.fontSize.bodySm};
                        
                        &:not(:first-of-type) {
                            justify-content: center;
                            border-top: 1px solid ${theme.greyBorder};
                        }
                        
                        &:not(:first-of-type):not(:last-of-type) {
                            border-right: 1px solid ${theme.greyBorder};
                        }
                        
                        &:first-of-type {
                            overflow: visible;
                        
                            &:before {
                                content: '';
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                width: 1px;
                                height: 51px;
                                background: ${theme.greyBorder};
                            }
                        }
                    }
                }
            }
        `}
    }
    
    ${({ styleType, noCellBorder, theme }) => styleType === 'blue' ? greenTable(theme) : table(theme, noCellBorder)}
`;

DataTableWrap.defaultProps = {
	styleType: '',
	rowAlign: 'center'
}

export default DataTableWrap;