import { handleActions } from 'redux-actions';

import { findByIdAndUpdate } from '../utils/dataMap';

const initialState = {
    data: {
        details: {
            data: null,
            fetching: true,
            saving: false,
            error: false,
        },
        activityLog: {
            data: [],
            fetching: true,
            refetching: true,
            error: false,
            comment: {
                sending: false,
                sent: false,
            }
        },
        documents: {
            data: [],
            fetching: true,
            deleting: false,
            error: false,
        },
        traders: {
            data: [],
            fetching: true,
            saving: false,
            error: false,
        },
        vehicles: {
            data: [],
            fetching: true,
            saving: false,
            error: false,
        },
        siteVisits: {
            data: [],
            fetching: true,
            saving: false,
            error: false,
        },
        raids: {
            data: [],
            fetching: true,
            saving: false,
            error: false,
        },
        testPurchases: {
            data: [],
            links: {},
            meta: {},
            page: 1,
            limit: 10,
            sort: '',
            fetching: true,
            saving: false,
            error: false,
		},
		invoice: {
			data: [],
			fetching: true,
			saving: false,
			error: false,
		}
    },
    fetching: true,
    completing: true,
    unarchiving: false,
    saving: false,
    error: false,
}

const updateItem = (state, key, vals) => {
    return {
        ...state,
        data: {
            ...state.data,
            [key]: {
                ...state.data[key],
                ...vals
            }
        },
    }
}

export default handleActions({

    FETCH_CASE: (state, action) => {
        return updateItem(state, 'details', {
            fetching: true,
            error: false,
        })
    },

    FETCH_CASE_SUCCESS: (state, action) => {
        return updateItem(state, 'details', {
            data: action.case,
            fetching: false,
        })
    },

    FETCH_CASE_FAIL: (state, action) => {
        return updateItem(state, 'details', {
            fetching: false,
            error: action.status === 404 ? 'notFound' : true
        })
    },

    ADD_CASE: (state, action) => {
        return {
            ...state,
            saving: true,
            error: false,
        }
    },

    ADD_CASE_SUCCESS: (state, action) => {
        return {
            ...state,
            saving: false,
            data: {
                ...state.data,
                details: {
                    ...state.data.details,
                    data: action.case
                }
            }
        }
    },

    ADD_CASE_FAIL: (state, action) => {
        return {
            ...state,
            saving: false,
            error: true,
        }
    },

    COMPLETE_CASE: (state, action) => {
        return {
            ...state,
            completing: true,
            error: false,
        }
    },

    COMPLETE_CASE_SUCCESS: (state, action) => {
        return {
            ...state,
            completing: false,
        }
    },

    COMPLETE_CASE_FAIL: (state, action) => {
        return {
            ...state,
            completing: false,
            error: true,
        }
    },

    UNARCHIVE_CASE: (state, action) => {
        return {
            ...state,
            unarchiving: true,
            error: false,
        }
    },

    UNARCHIVE_CASE_SUCCESS: (state, action) => {
        return {
			...state,
			data: {
				...state.data,
				details: {
					...state.data.details,
					data: {
						...state.data.details.data,
						status: 'pending'
					}
				}
			},
            unarchiving: false,
        }
    },

    UNARCHIVE_CASE_FAIL: (state, action) => {
        return {
            ...state,
            unarchiving: false,
            error: true,
        }
    },

    ADD_CASE_COMMENT: (state, action) => {
        return updateItem(state, 'activityLog', {
            comment: {
                ...state.data.activityLog.comment,
                sending: true,
                sent: false,
                error: false,
            }
        })
    },

    ADD_CASE_COMMENT_FAIL: (state, action) => {
        return updateItem(state, 'activityLog', {
            comment: {
                ...state.data.activityLog.comment,
                sending: false,
                error: true,
            }
        })
    },

    ADD_CASE_COMMENT_SUCCESS: (state, action) => {
        return updateItem(state, 'activityLog', {
            comment: {
                ...state.data.activityLog.comment,
                sending: false,
                sent: true,
            }
        })
    },

    ADD_CASE_LOG_ITEM_SUCCESS: (state, action) => {
        return updateItem(state, 'activityLog', {
            data: [action.item, ...state.data.activityLog.data],
            comment: {
                sending: false,
                fetching: false,
            }
        })
    },

    ADD_CASE_TRADER: (state, action) => {
        return updateItem(state, 'traders', {
            saving: true,
            error: false,
        })
    },

    ADD_CASE_TRADER_FAIL: (state, action) => {
        return updateItem(state, 'traders', {
            saving: false,
            error: true,
        })
    },

    ASSIGN_CASE_TRADER: (state, action) => {
        return updateItem(state, 'traders', {
            saving: true,
            error: false,
        })
    },

    ASSIGN_CASE_TRADER_SUCCESS: (state, action) => {
        return updateItem(state, 'traders', {
            data: [...state.data.traders.data, action.trader],
            saving: false,
            fetching: false,
        })
    },

    UNASSIGN_CASE_TRADER: (state, action) => {
        return updateItem(state, 'traders', {
            saving: true,
            error: false,
        })
    },

    UNASSIGN_CASE_TRADER_SUCCESS: (state, action) => {
        return updateItem(state, 'traders', {
            data: state.data.traders.data.filter(trader => trader.id !== action.id),
            saving: false,
            fetching: false,
        })
    },

    UPDATE_CASE: (state, action) => {
        return updateItem(state, 'details', {
            saving: true,
            error: false,
        })
    },

    UPDATE_CASE_SUCCESS: (state, action) => {
        return updateItem(state, 'details', {
            data: action.case,
            saving: false,
        })
    },

    UPDATE_CASE_FAIL: (state, action) => {
        return updateItem(state, 'details', {
            saving: false,
            error: true
        })
    },

    FETCH_CASE_DOCUMENTS: (state, action) => {
        return updateItem(state, 'documents', {
            fetching: true,
            error: false,
        })
    },

    FETCH_CASE_DOCUMENTS_SUCCESS: (state, action) => {
        return updateItem(state, 'documents', {
            data: action.documents,
            fetching: false,
        })
    },

    FETCH_CASE_DOCUMENTS_FAIL: (state, action) => {
        return updateItem(state, 'documents', {
            fetching: false,
            error: true,
        })
    },

    DELETE_DOCUMENT: (state, action) => {
        return updateItem(state, 'documents', {
            error: false,
            deleting: true,
        })
    },

    DELETE_DOCUMENT_SUCCESS: (state, action) => {
        return updateItem(state, 'documents', {
            data: state.data.documents.data.filter(doc => doc.id !== action.id),
            deleting: false,
        })
    },

    DELETE_DOCUMENT_FAIL: (state, action) => {
        return updateItem(state, 'documents', {
            deleting: false,
            error: true
        })
    },

    FETCH_CASE_RAIDS: (state, action) => {
        return updateItem(state, 'raids', {
            fetching: true,
            error: false,
        })
    },

    FETCH_CASE_RAIDS_SUCCESS: (state, action) => {
        return updateItem(state, 'raids', {
            data: action.raids,
            fetching: false,
        })
    },

    FETCH_CASE_RAIDS_FAIL: (state, action) => {
        return updateItem(state, 'raids', {
            fetching: false,
            error: true,
        })
    },

    ADD_CASE_RAID: (state, action) => {
        return updateItem(state, 'raids', {
            saving: true,
            error: false,
        })
    },

    ADD_CASE_RAID_FAIL: (state, action) => {
        return updateItem(state, 'raids', {
            saving: false,
            error: true
        })
    },

    ADD_CASE_RAID_SUCCESS: (state, action) => {
        return updateItem(state, 'raids', {
            data: [...state.data.raids.data, action.raid],
            saving: false,
        })
    },

    UPDATE_CASE_RAID: (state, action) => {
        return updateItem(state, 'raids', {
            saving: true,
            error: false,
        })
    },

    UPDATE_CASE_RAID_FAIL: (state, action) => {
        return updateItem(state, 'raids', {
            saving: false,
            error: true
        })
    },

    UPDATE_CASE_RAID_SUCCESS: (state, action) => {
        let data = findByIdAndUpdate(state.data.raids.data, action.raid, 'raid');

        return updateItem(state, 'raids', {
            data,
            saving: false,
        })
    },

    COMPLETE_CASE_RAID: (state, action) => {
        return updateItem(state, 'raids', {
            saving: true,
            error: false,
        })
    },

    COMPLETE_CASE_RAID_SUCCESS: (state, action) => {
        let data = findByIdAndUpdate(state.data.raids.data, action.raid, 'raid');

        return updateItem(state, 'raids', {
            saving: false,
            data
        })
    },

    COMPLETE_CASE_RAID_FAIL: (state, action) => {
        return updateItem(state, 'raids', {
            fetching: false,
            saving: false,
            error: false
        })
    },

    DELETE_CASE_RAID: (state, action) => {
        return updateItem(state, 'raids', {
            saving: true,
            error: false,
        })
    },

    DELETE_CASE_RAID_SUCCESS: (state, action) => {
        return updateItem(state, 'raids', {
            saving: false,
            data: state.data.raids.data.filter(raid => raid.raid.id !== action.id),
        })
    },

    DELETE_CASE_RAID_FAIL: (state, action) => {
        return updateItem(state, 'raids', {
            saving: false,
            error: true
        })
    },

    FETCH_CASE_SITE_VISITS: (state, action) => {
        return updateItem(state, 'siteVisits', {
            fetching: true,
            error: false,
        })
    },

    FETCH_CASE_SITE_VISITS_SUCCESS: (state, action) => {
        return updateItem(state, 'siteVisits', {
            data: action.siteVisits,
            fetching: false,
        })
    },

    FETCH_CASE_SITE_VISITS_FAIL: (state, action) => {
        return updateItem(state, 'siteVisits', {
            fetching: false,
            error: true,
        })
    },

    ADD_SITE_VISIT: (state, action) => {
        return updateItem(state, 'siteVisits', {
            saving: true,
            error: false,
        })
    },

    ADD_SITE_VISIT_FAIL: (state, action) => {
        return updateItem(state, 'siteVisits', {
            saving: false,
            error: true
        })
    },

    ADD_SITE_VISIT_SUCCESS: (state, action) => {
        return updateItem(state, 'siteVisits', {
            data: [...state.data.siteVisits.data, action.siteVisit],
            saving: false,
        })
    },

    UPDATE_CASE_SITE_VISIT: (state, action) => {
        return updateItem(state, 'siteVisits', {
            saving: true,
            error: false,
        })
    },

    UPDATE_CASE_SITE_VISIT_SUCCESS: (state, action) => {
        let data = findByIdAndUpdate(state.data.siteVisits.data, action.siteVisit, 'site_visit');

        return updateItem(state, 'siteVisits', {
            saving: false,
            data
        })
    },

    UPDATE_CASE_SITE_VISIT_FAIL: (state, action) => {
        return updateItem(state, 'siteVisits', {
            fetching: false,
            saving: false,
            error: true,
        })
    },

    COMPLETE_CASE_SITE_VISIT: (state, action) => {
        return updateItem(state, 'siteVisits', {
            saving: true,
            error: false,
        })
    },

    COMPLETE_CASE_SITE_VISIT_SUCCESS: (state, action) => {
        let data = findByIdAndUpdate(state.data.siteVisits.data, action.siteVisit, 'site_visit');

        return updateItem(state, 'siteVisits', {
            saving: false,
            data
        })
    },

    COMPLETE_CASE_SITE_VISIT_FAIL: (state, action) => {
        return updateItem(state, 'siteVisits', {
            fetching: false,
            saving: false,
            error: true,
        })
    },

    DELETE_CASE_SITE_VISIT: (state, action) => {
        return updateItem(state, 'siteVisits', {
            saving: true,
            error: false,
        })
    },

    DELETE_CASE_SITE_VISIT_SUCCESS: (state, action) => {
        return updateItem(state, 'siteVisits', {
            saving: false,
            data: state.data.siteVisits.data.filter(siteVisit => siteVisit.site_visit.id !== action.id),
        })
    },

    DELETE_CASE_SITE_VISIT_FAIL: (state, action) => {
        return updateItem(state, 'siteVisits', {
            saving: false,
            error: true
        })
    },

    FETCH_CASE_TEST_PURCHASES: (state, action) => {
        return updateItem(state, 'testPurchases', {
            fetching: true,
            error: false,
        })
    },

    FETCH_CASE_TEST_PURCHASES_SUCCESS: (state, action) => {
        return updateItem(state, 'testPurchases', {
            data: action.testPurchases.data,
            links: action.testPurchases.links,
            // meta: action.testPurchases.meta,
            // page: action.testPurchases.meta.current_page,
            // limit: action.testPurchases.meta.per_page,
            fetching: false,
        })
    },

    FETCH_CASE_TEST_PURCHASES_FAIL: (state, action) => {
        return updateItem(state, 'testPurchases', {
            fetching: true,
            error: true,
        })
    },

    ADD_CASE_TEST_PURCHASE: (state, action) => {
        return updateItem(state, 'testPurchases', {
            saving: true,
            error: false,
        })
    },

    ADD_CASE_TEST_PURCHASE_FAIL: (state, action) => {
        return updateItem(state, 'testPurchases', {
            saving: false,
            error: true
        })
    },

    ADD_CASE_TEST_PURCHASE_SUCCESS: (state, action) => {
        return updateItem(state, 'testPurchases', {
            data: [...state.data.testPurchases.data, action.testPurchase],
            saving: false,
        })
    },

    UPDATE_CASE_TEST_PURCHASE: (state, action) => {
        return updateItem(state, 'testPurchases', {
            saving: true,
            error: false,
        })
    },

    UPDATE_CASE_TEST_PURCHASE_SUCCESS: (state, action) => {
        let data = findByIdAndUpdate(state.data.testPurchases.data, action.testPurchase, 'test_purchase');

        return updateItem(state, 'testPurchases', {
            saving: false,
            data
        })
    },

    UPDATE_CASE_TEST_PURCHASE_FAIL: (state, action) => {
        return updateItem(state, 'testPurchases', {
            saving: false,
            error: true,
        })
    },

    COMPLETE_CASE_TEST_PURCHASE: (state, action) => {
        return updateItem(state, 'testPurchases', {
            saving: true,
            error: false,
        })
    },

    COMPLETE_CASE_TEST_PURCHASE_SUCCESS: (state, action) => {
        let data = findByIdAndUpdate(state.data.testPurchases.data, action.testPurchase, 'test_purchase');

        return updateItem(state, 'testPurchases', {
            saving: false,
            data
        })
    },

    COMPLETE_CASE_TEST_PURCHASE_FAIL: (state, action) => {
        return updateItem(state, 'testPurchases', {
            fetching: false,
            saving: false,
            error: true
        })
    },

    DELETE_CASE_TEST_PURCHASE: (state, action) => {
        return updateItem(state, 'testPurchases', {
            saving: true,
            error: false,
        })
    },

    DELETE_CASE_TEST_PURCHASE_SUCCESS: (state, action) => {
        return updateItem(state, 'testPurchases', {
            saving: false,
            data: state.data.testPurchases.data.filter(testPurchase => testPurchase.test_purchase.id !== action.id),
        })
    },

    DELETE_CASE_TEST_PURCHASE_FAIL: (state, action) => {
        return updateItem(state, 'testPurchases', {
            saving: false,
            error: true
        })
    },

    FETCH_CASE_TRADERS: (state, action) => {
        return updateItem(state, 'traders', {
            fetching: true,
            error: false,
        })
    },

    FETCH_CASE_TRADERS_SUCCESS: (state, action) => {
        return updateItem(state, 'traders', {
            data: action.traders,
            fetching: false,
        })
    },

    FETCH_CASE_TRADERS_FAIL: (state, action) => {
        return updateItem(state, 'traders', {
            fetching: false,
            error: true,
        })
    },

    UPDATE_CASE_TRADERS: (state, action) => {
        return updateItem(state, 'traders', {
            fetching: false,
            error: false,
        })
    },

    UPDATE_CASE_TRADERS_SUCCESS: (state, action) => {
        return updateItem(state, 'traders', {
            fetching: false,
            data: action.traders
        })
    },

    UPDATE_CASE_TRADERS_FAIL: (state, action) => {
        return updateItem(state, 'traders', {
            fetching: false,
            error: true,
        })
    },

    UPDATE_CASE_TRADER: (state, action) => {
        return updateItem(state, 'traders', {
            saving: true,
            error: false,
        })
    },

    UPDATE_CASE_TRADER_SUCCESS: (state, action) => {
        let data = findByIdAndUpdate(state.data.traders.data, action.trader);

        return updateItem(state, 'traders', {
            saving: false,
            data
        })
    },

    UPDATE_CASE_TRADER_FAIL: (state, action) => {
        return updateItem(state, 'traders', {
            saving: false,
            error: true,
        })
    },

    FETCH_CASE_VEHICLES: (state, action) => {
        return updateItem(state, 'vehicles', {
            fetching: true,
        })
    },

    FETCH_CASE_VEHICLES_SUCCESS: (state, action) => {
        return updateItem(state, 'vehicles', {
            data: action.vehicles,
            fetching: false,
        })
    },

    FETCH_CASE_VEHICLES_FAIL: (state, action) => {
        return updateItem(state, 'vehicles', {
            fetching: false,
        })
    },

    UPDATE_CASE_VEHICLES: (state, action) => {
        return updateItem(state, 'vehicles', {
            fetching: false,
            error: false,
        })
    },

    UPDATE_CASE_VEHICLES_SUCCESS: (state, action) => {
        return updateItem(state, 'vehicles', {
            fetching: false,
            data: action.vehicles
        })
    },

    UPDATE_CASE_VEHICLES_FAIL: (state, action) => {
        return updateItem(state, 'vehicles', {
            fetching: false,
            error: true,
        })
    },

    UPDATE_CASE_VEHICLE: (state, action) => {
        return updateItem(state, 'vehicles', {
            saving: true,
            error: false,
        })
    },

    UPDATE_CASE_VEHICLE_SUCCESS: (state, action) => {
        let data = findByIdAndUpdate(state.data.vehicles.data, action.vehicle);

        return updateItem(state, 'vehicles', {
            saving: false,
            data
        })
    },

    UPDATE_CASE_VEHICLE_FAIL: (state, action) => {
        return updateItem(state, 'vehicles', {
            saving: false,
            error: true,
        })
    },

    ASSIGN_CASE_VEHICLE: (state, action) => {
        return updateItem(state, 'vehicles', {
            saving: true,
            error: false,
        })
    },

    ASSIGN_CASE_VEHICLE_SUCCESS: (state, action) => {
        return updateItem(state, 'vehicles', {
            data: [...state.data.vehicles.data, action.vehicle],
            saving: false,
            fetching: false,
        })
    },

    UNASSIGN_CASE_VEHICLE: (state, action) => {
        return updateItem(state, 'vehicles', {
            saving: true,
            error: false,
        })
    },

    UNASSIGN_CASE_VEHICLE_SUCCESS: (state, action) => {
        return updateItem(state, 'vehicles', {
            data: state.data.vehicles.data.filter(vehicle => vehicle.id !== action.id),
            saving: false,
            fetching: false,
        })
    },

    FETCH_CASE_LOG: (state, action) => {
        const data = {
            error: false,
        }

        if(action.refetching) {
            data.refetching = true;
        } else {
            data.fetching = true;
        }

        return updateItem(state, 'activityLog', data);
    },

    FETCH_CASE_LOG_SUCCESS: (state, action) => {
        return updateItem(state, 'activityLog', {
            data: action.activityLog,
            fetching: false,
            refetching: false,
        })
    },

    FETCH_CASE_LOG_FAIL: (state, action) => {
        return updateItem(state, 'activityLog', {
            fetching: false,
            refetching: false,
        })
	},
	
	FETCH_INVOICE: (state, action) => {
        return updateItem(state, 'invoice', {
			error: false,
			fetching: true,
		});
    },

    FETCH_INVOICE_SUCCESS: (state, action) => {
        return updateItem(state, 'invoice', {
            data: action.invoice,
            fetching: false,
        })
    },

    FETCH_INVOICE_FAIL: (state, action) => {
        return updateItem(state, 'invoice', {
            fetching: false,
            error: action.error,
        })
    },

    RESET_CASE: (state, action) => {
        return initialState
    },

}, initialState)
