import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SVG } from '../Utils';

const IconLabelWrap = styled.div`
    display: flex;
    justify-content: center;
    font-size: ${({ theme }) => theme.fontSize.bodySm};
    
    p {
        color: ${({ theme, color }) => color ? theme[color] : 'inherit'};
    }
    
    svg {
        width: ${({ size }) => size && size === 'lg' ? '18px' : '13px'};
        height: auto;
        margin-left: 8px;
    }
    
    path {
        fill: ${({ theme }) => theme.blueLighter};
    }
`;

const IconLabel = ({ label, path, color, size }) => {
    return(
        <IconLabelWrap color={color} size={size}>
            <p>{ label }</p>
            <SVG path={path} />
        </IconLabelWrap>
    );
}

IconLabel.propTypes = {
    label: PropTypes.string,
    path: PropTypes.string,
}

IconLabel.defaultProps = {
    path: '/svg/icon-plus-square.svg',
}

export default IconLabel;