import styled from 'styled-components';

export default styled.div`
    @media screen and (max-width: 425px) {
        >* {
            max-width: none;
            
            +* {
                margin-top: 10px;
            }
        }
    }
    
    @media screen and (min-width: 426px) {
        display: flex;
        flex-wrap: wrap;
        
        >* {
            &:nth-of-type(2n+2) {
                margin-left: 10px;
                flex: 1 0 0;
            }
        }
    }
`;