import React, { Fragment } from 'react';
import styled from 'styled-components';

const ModalContentTitle = styled.h1`
    width: 100%;
    padding: 25px 15px;
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize.title};
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: ${({ theme }) => theme.blue};
    background: white;
`;

const ModalContentWrap = styled.div`
    ${({ styleType }) => styleType && styleType === 'window' && `
        position: relative;
        min-height: 200px;
        padding: 50px;
        overflow: ${({ overflow }) => overflow ? 'visible' : 'auto'};
        background: ${({ theme }) => theme.greyLightest};
        
        @media screen and (max-width: 767px) {
            padding: 35px; 
        }
        
        @media screen and (max-width: 429px) {
            padding: 20px 15px;
        }
    `}
    
    ${({ styleType }) => styleType && styleType === 'borderless' && `
        max-width: 450px;   
        padding: 15px;
    `}
`;

const ModalContent = ({ title, children, overflow, ...props }) => (
    <Fragment>
        { title && <ModalContentTitle>{ title }</ModalContentTitle> }
        <ModalContentWrap
            overflow={overflow}
            {...props}
        >{ children }</ModalContentWrap>
    </Fragment>
);

ModalContent.defaultProps = {
    styleType: 'window',
}

export default ModalContent;