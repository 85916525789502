import { get, post, put, checkAuthentication } from '../utils/api';
import { setUserToken, removeUserToken } from '../utils/cookies';
import { validatePassword, validateEmail, validateParams, dispatchErrors } from '../utils/validation';

export const loginUser = ({ email, password }) => {

    return (dispatch, getState) => {

        if( !email ) {
            dispatch({ type: 'ERROR', error: 'No email address supplied' }); return;
        }

        if( !password ) {
            dispatch({ type: 'ERROR', error: 'No password supplied' }); return;
        }

        dispatch({ type: 'LOGIN_USER' });

        return post('/auth/login', { email, password }, false)
            .then(({ access_token, expires_in }) => setUserToken(access_token, expires_in))
            .then(data => {
                dispatch({ type: 'LOGIN_USER_SUCCESS' });
                dispatch(getUser());
            })
            .catch(checkAuthentication.bind(null, dispatch))
            .catch(err => {
                dispatch({ type: 'LOGIN_USER_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

};

export const registerUser = (params) => {

    return (dispatch) => {

        let validatedParams = validateParams(['name', 'password', 'password_confirmation', 'invite_code'], params);

        let {
            name,
            password,
            password_confirmation
        } = validatedParams;

        if( !name ) {
            dispatch({ type: 'ERROR', error: 'No name supplied' }); return;
        }

        if( !password ) {
            dispatch({ type: 'ERROR', error: 'No password supplied' }); return;
        }

        if( !password_confirmation || password !== password_confirmation ) {
            dispatch({ type: 'ERROR', error: 'The passwords must match' }); return;
        }

        dispatch({ type: 'REGISTER_USER' });

        return post(`/auth/register`, validatedParams, false)
            .then(({ access_token, expires_in }) => setUserToken(access_token, expires_in))
            .then(data => {
                dispatch({ type: 'REGISTER_USER_SUCCESS', success: 'Your account has been created.' });
                dispatch(getUser());
            })
            .catch(checkAuthentication.bind(null, dispatch))
            .catch(err => {
                dispatch({ type: 'REGISTER_USER_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

};

export const getUser = () => {

    return (dispatch, getState) => {

        dispatch({ type: 'FETCH_SELF' });

        return get('/users/self', true)
            .then(({ data }) => {
                dispatch({ type: 'FETCH_SELF_SUCCESS', user: data });
            })
            .catch(checkAuthentication.bind(null, dispatch))
            .catch(err => {
                dispatch({ type: 'FETCH_SELF_FAIL' });
            })

    }

};

export const updateUser = (id, params, self = false) => {

    let validatedParams = validateParams(['name', 'old_password', 'new_password', 'new_password_confirmation'], params);

    return (dispatch) => {

        if( params.new_password && params.new_password !== params.new_password_confirmation ) {
            dispatch({ type: 'ERROR', error: 'Your password and confirmation password don\'t match' }); return;
        }

        // if( validatedParams.email && !validateEmail(validatedParams.email) ) {
        //     dispatch({ type: 'ERROR', error: 'Email address is not valid' }); return;
        // }

        dispatch({ type: 'UPDATE_USER' });

        return put(`/users/${id}`, validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then(({ data }) => {
                let success = {
                    type: 'UPDATE_USER_SUCCESS',
                }

                if(self) {
                    success.self = data;
                    success.success = 'Your settings have been updated.'
                } else {
                    success.user = data;
                    success.success = 'The user has been updated.'
                }

                dispatch(success)
            })
            .catch(err => {
                dispatch({ type: 'UPDATE_USER_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

};

export const logoutUser = () => {

    return (dispatch, getState) => {

        dispatch({ type: 'LOGOUT_USER' });

        return post('/auth/logout', {}, true)
            .then(({ data }) => {
                dispatch({ type: 'LOGOUT_USER_SUCCESS', success: 'You have been logged out.' })
                removeUserToken();
            })
            .catch(err => {
                dispatch({ type: 'LOGOUT_USER_FAIL' });
                removeUserToken();
                return dispatchErrors(err, dispatch);
            })

    }

};

export const requestPasswordReset = ({ email }) => {

    return (dispatch, getState) => {

        if( !email ) {
            dispatch({ type: 'ERROR', error: 'No email address supplied' }); return;
        } else if( !validateEmail(email) ) {
            dispatch({ type: 'ERROR', error: 'Email address is not valid' }); return;
        }
        email = email.toLowerCase();

        dispatch({ type: 'RESET_PASSWORD' });

        return post('/password/email', { email }, false)
            .then(() => {
                dispatch({ type: 'RESET_PASSWORD_SUCCESS', success: "A password reset link has been sent to " + email });
            })
            .catch(err => {
                dispatch({ type: 'RESET_PASSWORD_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

};

export const resetPassword = ({ email, password, password_confirmation, token }) => {

    return (dispatch, getState) => {

        dispatch({ type: 'UPDATE_PASSWORD' });

        if( !email ) {
            dispatch({ type: 'ERROR', error: 'No email address supplied.' }); return;
        } else if( !validateEmail(email) ) {
            dispatch({ type: 'ERROR', error: 'Email address is not valid.' }); return;
        }
        email = email.toLowerCase();

        if( password !== password_confirmation ) {
            dispatch({ type: 'ERROR', error: 'Passwords do not match.' }); return;
        }

        if( !token ) {
            dispatch({ type: 'ERROR', error: 'No reset token supplied.' }); return;
        }

        return post('/password/reset', { email, password, password_confirmation, token }, false)
            .then(() => {
                dispatch({ type: 'UPDATE_PASSWORD_SUCCESS', success: "Your password has been changed." });
            })
            .catch(err => {
                console.log(err)
                dispatch({ type: 'UPDATE_PASSWORD_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

};

// export const updateConnection = (type) => {
//
//     return (dispatch, getState) => {
//
//         dispatch({ type: 'UPDATE_CONNECTION', hasConnection: Boolean(type !== 'none') })
//
//     }
//
// }