import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUserRoles } from '../../actions/users';
import { AsyncSelect } from '../../components/Forms';

export class UserRolesContainer extends Component {
    checkForValidValue = (value) => {
        let match = this.props.userRoles.find(type => type.name === value);

        return match && match.id;
    }

    updateInput = value => {
        // If coming from onChange event it's an object.
        let data = value;

        // It's a string from onInputChange. Check if this is a valid option, by finding an ID.
        if(typeof data === 'string') {
            data = {
                name: data,
                id: this.checkForValidValue(data),
            }
        }

        // onBlur for some reason triggers an onInputChange with an empty string, so skip the change if it's an empty string.
        if(!data.name) return;

        this.props.onChange(data);
    }

    getUserRoles = () => {
        let { userRoles, getUserRoles } = this.props;

        if(!!userRoles.length) return;

        getUserRoles();
    }

    render() {
        let {
            fetching,
            userRoles,
            value,
            id
        } = this.props;

        return(
            <AsyncSelect
                value={value}
                options={userRoles}
                onMenuOpen={this.getUserRoles}
                isLoading={fetching}
                allowCreateWhileLoading={fetching}
                onChange={this.updateInput}
                onInputChange={this.updateInput}
                onSelectResetsInput={false}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                getOptionLabel={item => item.name}
                getOptionValue={item => item.name}
                id={id}
            />
        );
    }
}

UserRolesContainer.propTypes = {
    userRoles: PropTypes.array,
    exclude: PropTypes.array,
    fetching: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.object,
    getUserRoles: PropTypes.func,
    onChange: PropTypes.func,
}

const mapStateToProps = state => {
    return {
        userRoles: state.userRoles.data,
        fetching: state.userRoles.fetching,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getUserRoles }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserRolesContainer));