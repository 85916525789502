import React, { Component } from 'react';
import styled from 'styled-components';
import { DropTarget } from 'react-dnd';

const DragTargetWrap = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    
    >* {
        width: 100%;
    }
`;

const squareTarget = {
    drop(props, monitor, component) {
        return {
            index: props.index
        }
    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver()
    };
}

class DragVehiclesTarget extends Component {
    render() {
        const { connectDropTarget, isOver, children } = this.props;

        return(
            <DragTargetWrap innerRef={instance => connectDropTarget(instance)}>
                {children}
                {isOver &&
                    <div style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: '100%',
                        zIndex: 1,
                        opacity: 0.5,
                        backgroundColor: 'yellow',
                    }} />
                }
            </DragTargetWrap>
        );
    }
}

export default DropTarget('vehicleCard', squareTarget, collect)(DragVehiclesTarget);