import { css } from 'styled-components';
import theme from '../config/theme';

export const media = Object.keys(theme.size).reduce((acc, label) => {
    acc[label] = (...args) => css`
		@media (max-width: ${theme.size[label]}) {
			${css(...args)}
		}
	`

    return acc
}, {});

export const screenReader = () => {
    return `
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0,0,0,0);
		border: 0;
	`;
};

export const listPlain = () => {
    return `
        padding: 0;
        margin: 0;
        list-style: none;
    `;
}

export const darkAfter = () => {
    return `
        &:after {
            content: '';
            display: block;
            position: fixed;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.6);
        }
    `;
}

export const input = () => {
    return `
        display: flex;
        align-items: center;
        min-height: 33px;
        padding: 5px 12px;
        border: ${theme.border.main};
        border-radius: 0;
        background: ${theme.whiteish};
        font-weight: 400;
        font-size: ${theme.fontSize.bodyMd};
        color: ${theme.darkText};
    `;
}

export const option = () => {
    return `
        width: 100%;
        padding: 5px 12px;
        border: 0;
        text-align: left;
        background: none;
        transition: ${theme.duration.quick} background;
        font-size: ${theme.fontSize.bodyXs};
        line-height: normal;
        cursor: pointer;
        
        &:hover {
            background: ${theme.greyLighter};
        }
    `;
}

export const optionDropdown = () => {
    return `
        max-height: 300px;
        padding: 0;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
    `;
}

export const optionDropdownOuter = () => {
    return `
        position: absolute!important;
        width: 100%;
        top: 100%;
        left: 0;
        border: ${theme.border.main};
        border-radius: 0;
        box-shadow: none;
        z-index: 1;
        background: ${theme.greyLightest};
    `;
}

export const greyCard = (props) => {
    return`
        position: relative;
        display: ${props && props.mini ? 'inline-block' : 'block'};
        min-height: ${props && props.mini ? '0' : '65px'};
        padding: ${props && props.mini ? '5px 11px' : '10px'};
        border: ${theme.border.main};
        border-radius: ${theme.radius.small};
        background: ${theme.greyLightest};
        
        a {
            text-decoration: none;
        }
        
        ${({ mini }) => mini && `
            font-size: ${theme.fontSize.bodyXs};
            line-height: 140%;
            
            a {
                font-size: ${theme.fontSize.bodyXs};
            }
        `};
    `;
}

export const dragCard = () => {
    return `
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-size: ${theme.fontSize.bodyMd};
        cursor: pointer;
        
        >div {
            width: 65%;
            text-align: center;
            word-break: break-all;

            +div {
                margin-top: 2px;
            }
        }
        
        i {
            position: absolute;
            top: 50%;
            left: 83%;
            transform: translateY(-50%);
            color: ${theme.blueLight};
            font-size: 18px;
        }
    `;
}

export const fiveCol = () => {
    return`
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;
        
        >* {
            @media screen and (min-width: 1201px) {
                width: calc(20% - 12px);
                
                &:not(:nth-child(5n+5)) {
                    margin-right: 15px;
                }
                
                &:nth-child(n+6) {
                    margin-top: 15px;
                }
            }
            
            @media screen and (min-width: 992px) and (max-width: 1200px) {
                width: calc(25% - 11.25px);
                
                &:not(:nth-child(4n+4)) {
                    margin-right: 15px;
                }
                
                &:nth-child(n+5) {
                    margin-top: 15px;
                }
            }
            
            @media screen and (min-width: 700px) and (max-width: 991px) {
                width: calc((100% / 3) - 10px);
                
                &:not(:nth-child(3n+3)) {
                    margin-right: 15px;
                }
                
                &:nth-child(n+4) {
                    margin-top: 15px;
                }
            }
            
            @media screen and (min-width: 525px)  and (max-width: 699px) {
                width: calc(50% - 7.5px);
                
                &:nth-child(2n+1) {
                    margin-right: 15px;
                }
                
                &:nth-child(n+3) {
                    margin-top: 15px;
                }
            }
            
            @media screen and (max-width: 524px) {
                &:nth-child(n+2) {
                    margin-top: 15px;
                }
            }
        }
    `;
}

export const overlayStyle = () => {
    return`
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
        background: rgba(255, 255, 255, .65);
    `;
}

export const minWidthInput = () => {
    return`
        && {
            flex: none;
            width: 130px;
            
            >* {
                max-width: 100%;
            }
        }
    `;
}