import {
    Cases,
    Case,
    NewCase,
    Invoice,
    Customers,
    Customer,
    NewCustomer,
    Login,
    Reports,
    Settings,
    UserManagement,
    NewUser,
    NotFound,
} from '../views/index';

let routes = [
    {
        path: '/',
        exact: true,
        component: Cases,
    },
    {
        path: '/login',
        exact: true,
        component: Login
    },
    {
        path: '/reset-password',
        exact: true,
        component: Login
    },
    {
        path: '/change-password',
        exact: true,
        component: Login
    },
    {
        path: '/register',
        exact: true,
        component: Login
    },
    {
        path: '/cases',
        exact: true,
        component: Cases,
    },
    {
        path: '/cases/new',
        exact: true,
        component: NewCase,
    },
    {
        path: '/cases/:id',
        exact: true,
        component: Case,
    },
    {
        path: '/cases/:id/invoice',
        exact: true,
        component: Invoice,
    },
    {
        path: '/customers',
        exact: true,
        component: Customers,
    },
    {
        path: '/customers/new',
        exact: true,
        component: NewCustomer,
    },
    {
        path: '/customers/:id',
        exact: true,
        component: Customer,
    },
    {
        path: '/settings',
        exact: true,
        component: Settings,
    },
    {
        path: '/users',
        exact: true,
        component: UserManagement,
    },
    {
        path: '/users/new',
        exact: true,
        component: NewUser,
    },
    {
        path: '/reports',
        exact: true,
        component: Reports,
    },
    {
        path: '/*',
        component: NotFound,
    },
];

export default routes;