import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import memoizeOne from 'memoize-one';
import { format, startOfWeek, endOfWeek, addWeeks } from 'date-fns';

import {
    getUsers,
    getUserExclusions,
    addUserExclusion,
    deleteUserExclusion,
    deleteUser,
    resetUsers,
} from '../../actions/users';
import { WeekCalendar } from '../../components/Calendars/index';
import { SearchBar } from '../../components/Forms';
import { ResponsiveTableWrap } from '../../components/Tables/index';
import { Loading, ErrorBoundary, Error } from '../../components/Utils/index';
import { hasPermission } from '../../utils/validation';

const dateOptions = {
    weekStartsOn: 1
}

const memoizedFilter = (arr, search) => {
    return arr.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
}

const memoizedMapExclusions = (users, exclusions) => {
    return users.map(u => ({
        ...u,
        exclusions: exclusions.data.filter(e => e.user.id === u.id)
    }));
}

export class UserManagementContainer extends Component {
    constructor(props) {
        super(props);

        let date = props.location.search ? props.location.search.split('date=')[1] : new Date();

        this.state = {
            mapping: false,
            users: null,
            search: '',
            date: startOfWeek(date, dateOptions),
            clicked: {
                startDate: null,
                userId: null,
                deleting: false
            },
            hovered: {
                date: null,
                userId: null
            }
        }
    }

    componentDidMount() {
        this.props.getUsers({
            limit: 100
        });
        this.getUserExclusions();

        window.addEventListener('keydown', this.handleKeyDown);
        window.addEventListener('click', this.handleClick);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('click', this.handleClick);
    }

    componentDidUpdate(prevProps, prevState) {
        let { exclusions, fetching } = this.props;

        // Map user exclusions when users/excluions have been fetched
        if(
            (prevProps.exclusions.fetching && !exclusions.fetching && !fetching) ||
            (prevProps.fetching && !fetching && !exclusions.fetching)
        ) {
            this.mapExclusions();
        }

        // Reset clicked state if saving has occured
        if(prevProps.exclusions.saving && !exclusions.saving) {
            this.clearClicked();
        }

        // Re-fetch exclusions after deleting has occured
        if(!prevProps.exclusions.deleted && exclusions.deleted) {
            this.getUserExclusions();
        }

        // Re-fetch exclusions if the date has changed
        if(prevState.date !== this.state.date) {
            this.getUserExclusions();
        }
    }

    mapExclusions = () => {
        let users = memoizedMapExclusions(this.props.users.filter(({ role }) => role === 'Team Member' || role === 'Admin'), this.props.exclusions);

        this.setState({
            users,
            mapping: false
        });
    }

    getUserExclusions = () => {
        this.props.getUserExclusions({
            limit: 100,
            from: format(this.state.date, 'YYYY-MM-DD') + ' 00:00:00',
            to: format(this.memoizedEndOfWeek(this.state.date, dateOptions), 'YYYY-MM-DD') + ' 23:59:59'
        });
    }

    memoizedEndOfWeek = memoizeOne(endOfWeek);

    handleKeyDown = (e) => {
        // Clear any date picks if escape is pushed
        if(e.keyCode === 27) {
            this.clearClicked();
        }
    }

    handleClick = (e) => {
        // Clear any date picks if anything but time slot button is clicked
        if(e.target.innerText === 'AM' || e.target.innerText === 'PM') return;

        this.clearClicked();
    }

    clearClicked = () => {
        if(!this.state.clicked.startDate) return;

        this.setState({
            clicked: {
                startDate: null,
                userId: null,
                deleting: false
            }
        });
    }

    addWeeks = (modifier) => {
        this.setState({
            mapping: true,
            date: addWeeks(this.state.date, modifier),
            clicked: {
                startDate: null,
                userId: null,
                deleting: false
            }
        });
    }

    bookOff = (endDate) => {
        let { clicked: { startDate, userId, deleting } } = this.state;

        // Replace am/pm with actual times.
        startDate = startDate.replace('am', '00:00:00').replace('pm', '12:00:00');
        endDate = endDate.replace('am', '11:59:59').replace('pm', '23:59:59');

        if(deleting) {
            this.props.deleteUserExclusion(userId, {
                from: startDate,
                to: endDate
            });
        } else {
            this.props.addUserExclusion({
                start_date: startDate,
                end_date: endDate,
                user_id: userId
            });
        }

        this.setState({
            mapping: true,
        });
    }

    handleTimeSlotClick = (clicked) => {
        let { clicked: { startDate, userId, deleting } } = this.state;

        // If the start date is already set, is against the same user as this click, and the action type(deleting or not) is the same, book off time.
        if(startDate && userId === clicked.userId && clicked.date && deleting === clicked.deleting) {
            this.bookOff(clicked.date);
        } else {
            this.setState({
                clicked: {
                    startDate: clicked.date,
                    userId: clicked.userId,
                    deleting: clicked.deleting
                }
            });
        }
    }

    handleTimeSlotHover = ({ date, userId }) => {
        // Save the hovered slot to state so the in-between slots can be calculated
        if(!this.state.clicked.startDate) return;

        this.setState({
            hovered: {
                date,
                userId
            }
        });
    }

    handleDelete = (id) => {
        this.props.deleteUser(id);
    }

    render() {
        let {
            fetching,
            self,
        } = this.props;

        let {
            users,
            date,
            clicked,
            hovered,
            search,
            mapping,
        } = this.state;

        users = users ? memoizedFilter(users, search) : null;

        const userHasPermission = hasPermission(self, 'users:write');

        return(
            <ErrorBoundary render={() => <Error />}>
                <SearchBar
                    value={search}
                    placeholder={'search users'}
                    submitOnChange={true}
                    onChange={e => this.setState({ search: e })}
                />

                <ResponsiveTableWrap>
                    { fetching && <Loading overlay={true} /> }
                    <WeekCalendar
                        date={date}
                        users={users}
                        clicked={clicked}
                        hovered={hovered}
                        onPrevClick={() => this.addWeeks(-1)}
                        onNextClick={() => this.addWeeks(1)}
                        onTimeSlotClick={this.handleTimeSlotClick}
                        onTimeSlotHover={this.handleTimeSlotHover}
                        onDelete={this.handleDelete}
                        mapping={mapping}
                        self={self && self.id}
                        canEdit={userHasPermission}
                    />
                </ResponsiveTableWrap>
            </ErrorBoundary>
        );
    }
}

const mapStateToProps = state => {
    return {
        users: state.users.data,
        self: state.user.self,
        exclusions: state.users.exclusions,
        fetching: state.users.fetching,
        deleting: state.users.deleting,
        reset: state.users.reset,
        error: state.users.error,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getUsers, getUserExclusions, addUserExclusion, deleteUserExclusion, resetUsers, deleteUser }, dispatch);

UserManagementContainer.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object),
    exclusions: PropTypes.object,
    self: PropTypes.object,
    fetching: PropTypes.bool,
    reset: PropTypes.bool,
    error: PropTypes.bool,
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(UserManagementContainer));