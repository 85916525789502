import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SVG } from '../Utils';

export const StyledButton = styled.button`
    padding: 0;
    border: 0;
    background: none;
    font-size: ${({ theme }) => theme.fontSize.bodyMd};
    color: ${({ theme }) => theme.darkText};
    cursor: pointer;
`;

export const HiddenInputWrap = styled.div`
    display: inline-flex;
    align-items: center;
`;

export const HiddenInputClose = styled.button`
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    
    svg {
        width: 17px;
        height: auto;
    }
    
    polygon {
        fill: ${({ theme }) => theme.darkText};
        transform: rotate(45deg);
        transform-origin: 50%;
    }
`;

export const HiddenInputInner = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    
    ${HiddenInputClose} {
        margin-left: 10px;
    }
`;

class HiddenInput extends Component {
    state = {
        showChildren: false,
    }

    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate(prevProps, prevState) {
        let { showChildren } = this.state,
            { onHide, onShow } = this.props;

        if(prevState.showChildren === showChildren) return;

        if(showChildren) {
            if(onShow) onShow();
        }

        if(!showChildren) {
            if(onHide) onHide();
        }
    }

    handleKeyDown = e => {
        // Hide if esc key is pressed
        if(e.which === 27) {
            this.toggleShowChildren(false);
        }
    }
    
    toggleShowChildren = showChildren => {
        this.setState({ showChildren });
    }

    render() {
        let { showChildren } = this.state,
            { showText, children } = this.props;

        return(
            <HiddenInputWrap>
                { !showChildren && <StyledButton onClick={() => this.toggleShowChildren(true)}>{ showText }</StyledButton> }
                { showChildren &&
                    <HiddenInputInner>
                        { children }
                        <HiddenInputClose onClick={() => this.toggleShowChildren(false)}>
                            <SVG path={'/svg/icon-plus.svg'} />
                        </HiddenInputClose>
                    </HiddenInputInner>
                }
            </HiddenInputWrap>
        );
    }
}

HiddenInput.propTypes = {
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    showText: PropTypes.string,
    children: PropTypes.any,
}

export default HiddenInput;