import React from 'react';
import system from 'system-components';
import styled from 'styled-components';

import { screenReader } from '../../utils/style';

// Allow is prop to change HTML element. Default to span.
const SROnlyEl = system({
    is: 'span'
});

const SROnlyWrap = styled(SROnlyEl)`
    ${screenReader()}
`;

const SROnly = ({ children, is }) => {
    return(
        <SROnlyWrap is={is}>{ children }</SROnlyWrap>
    );
}


export default SROnly;