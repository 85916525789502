import React from 'react';
import styled from 'styled-components';

import { SROnly, SVG } from '../Utils';

import { input } from '../../utils/style';

const ButtonListItemWrap = styled.div`
    position: relative;
    ${input()};
    display: grid;
    padding-right: 22px;
    font-weight: 400;
    cursor: default;
    background: ${({ disabled, theme }) => disabled ? theme.greyLighter : theme.whiteish};
    
    button {
        position: absolute;
        top: 50%;
        right: 6px;
        transform: translateY(-50%);
        padding: 0;
        border: 0;
        background: none;
        cursor: pointer;
    }
    
    svg {
        width: 15px;
        height: auto;
    }
    
    >span {
        span {
            +span {
                margin-left: 10px;
            }
        }
    }
    
    path {
        fill: ${({ theme }) => theme.blueLight};
    }
    
    ${({ noWrap }) => noWrap && `
        >span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    `}
    
    ${({ large }) => large && `
        padding: 10px 12px;
    `}
`;

const ButtonListItem = ({ children, title, onClick, disabled, onRemove, showControls, large, noWrap, ...props }) => (
    <ButtonListItemWrap
        disabled={disabled}
        large={large}
        noWrap={noWrap}
        {...props}
    >
        <span title={title}>
            { children }
        </span>
        { showControls &&
            <button onClick={onRemove}>
                <SROnly>Remove item</SROnly>
                <SVG path={'/svg/icon-remove.svg'} />
            </button>
        }
    </ButtonListItemWrap>
);

ButtonListItem.defaultProps = {
    noWrap: true,
}

export default ButtonListItem;