export const table = (theme, noCellBorder) => {
    return`
        .ReactTable {
            .rt-table.rt-table {
                border: ${theme.border.main};
            }
            
            .rt-thead.rt-thead {
                background: ${theme.greyLightest};
            }
            
            .rt-td.rt-td {
                &:first-of-type {
					${!noCellBorder ? `
						&:after {
							content: '';
							display: block;
							position: absolute;
							width: 1px;
							height: 100%;
							top: 0;
							right: 0;
							background: ${theme.greyBorder};
						}
					` : ``}
                    
                    &[style*="opacity"]:not([style*="opacity: 1"]) {
                        opacity: 1!important;
                        
                        >* {
                            opacity: .6;
                        }
                        
                        &:after {
                            opacity: 1;
                        }
                    }
                }
                
                strong {
                    font-weight: 500;
                }
            }
            
            .-pagination.-pagination {
                border-top: 0;
                box-shadow: none;
            }
            
            .rt-tr-group.rt-tr-group {
                border-top: ${theme.border.main};
                border-bottom: 0;
            }
            
            .pagination-top {
                margin-bottom: 8px;
                .rt-pagination__pp {
                    display: none;
                }
            }
            
            .pagination-bottom {
                margin-top: 8px;
            }
            
            .rt-pagination {
                display: flex;
                justify-content: space-between;
                align-items: center;
                
                >*:last-child {
                    margin-left: auto;
                }
            }
        }
    `;
}

export const greenTable = (theme) => {
    return`
        .ReactTable {
            font-size: ${theme.fontSize.bodyMd};
        
            .rt-th.rt-th {
                display: flex;
                align-items: center;
                height: 50px;
                
                * {
                    color: ${theme.white};
                }
                
                &:after {
                    background-image: url(/svg/icon-chevron-white.svg);
                }
            }
            
            .rt-td.rt-td {
                display: flex;
                align-items: center;
                min-height: 65px;
                
                &:last-of-type {
                    padding: 0;
                }
            }
            
            .rt-tbody {
                margin-top: 15px;
            }
            
            .rt-tr-group {
                background: ${theme.white};
                
                &:nth-of-type(even) {
                    background: ${theme.greyLighter};
                }
            }
        }
    `;
}