import React from 'react';

import { CasesContainer } from '../containers/Case';
import { UserHasPermissionContainer } from '../containers/User';

import { PageWithMainNav } from '../components/Layout';
import { PageTitle } from '../components/Text';
import { CornerButton } from '../components/Buttons';
import { MetaData, ScrollToTopOnMount } from '../components/Utils';

const Cases = () => {
    return(
        <PageWithMainNav>
            <ScrollToTopOnMount />
            <MetaData vars={{ title: 'Cases' }} />

            <PageTitle>Cases</PageTitle>
            <CasesContainer />
            <UserHasPermissionContainer permissions={'cases:write'}>
                <CornerButton
                    icon={'/svg/icon-plus.svg'}
                    to={'/cases/new'}
                />
            </UserHasPermissionContainer>
        </PageWithMainNav>
    );
}

export default Cases;