import React, { Component } from 'react';
import { DragSource } from 'react-dnd';
import styled from 'styled-components';

import { GreyCard } from '../../../Cards';
import { SVG, SROnly } from '../../../Utils';
import { dragCard } from '../../../../utils/style';

const StyledDragCard = styled(GreyCard)`
    ${dragCard()};
    flex-wrap: wrap;
    
    button {
        display: none;
        position: absolute;
        top: 5px;
        right: 5px;
        padding: 4px;
        border: 0;
        background: none;
        cursor: pointer;
        
        svg {
            width: 7px;
        }
        
        polygon {
            fill: ${({ theme }) => theme.darkText};
        }
    }
    
    &:hover button {
        display: block;
    }
`;

const cardSource = {
    beginDrag(props) {
        return {
            cardId: props.id
        };
    }
};

function collect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging(),
        newIndex: monitor.getDropResult(),
        item: monitor.getItem(),
        type: 'vehicle'
    }
}

class DragVehiclesCard extends Component {
    componentDidUpdate() {
        // If the card being dragged is this one and the indexes are different, let the parent know so that the order can be changed.
        let { id, index, newIndex, item, type, onIndexChange } = this.props;

        if(item && newIndex && item.cardId === id && newIndex.index !== index && type === 'vehicle') {
            onIndexChange(item.cardId, newIndex.index);
        }
    }

    handleDelete = (e) => {
        e.stopPropagation();

        this.props.onDelete(this.props.id);

        return false;
    }

    render() {
        let { id, colour, make, model, number_plate, index, connectDragSource, onClick } = this.props;

        return(
            <StyledDragCard innerRef={instance => connectDragSource(instance)} onClick={() => onClick(id)}>
                { colour && <div>{colour}</div> }
                { make && <div><strong>{make} {model}</strong></div> }
                <div>Reg: {number_plate || 'Unknown'}</div>
                { index === 0 && <i className="icon-crown" /> }
                <button onClick={this.handleDelete}>
                    <SROnly>Remove vehicle from case</SROnly>
                    <SVG path={"/svg/icon-close.svg"} />
                </button>
            </StyledDragCard>
        );
    }
}

export default DragSource('vehicleCard', cardSource, collect)(DragVehiclesCard);