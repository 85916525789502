import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';

import { registerUser, getUser } from '../../actions/user';
import { Input, ChunkyForm } from '../../components/Forms';
import { Button } from '../../components/Buttons';
import { Small } from '../../components/Text';

class RegisterContainer extends Component {
    state = {
        email: '',
        name: '',
        password: '',
        password_confirmation: '',
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let { name, password, password_confirmation } = this.state;

        let params = this.props.location.search;
        let { id } = queryString.parse(params);

        this.props.registerUser({
            name,
            password,
            password_confirmation,
            invite_code: id,
        });
    }

    handleChange = e => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }

    render() {
        let { name, password, password_confirmation } = this.state,
            { loggedIn, self, loggingIn } = this.props;

        let params = this.props.location.search;
        let { email } = queryString.parse(params);

        if(loggedIn && self || !email) return <Redirect to='/cases' />

        const requiredPopulated = email && name && password && password_confirmation;

        return(
            <ChunkyForm onSubmit={this.handleSubmit}>
                <Input
                    value={email}
                    id={'email'}
                    placeholder={"Email"}
                    srLabel={'Email'}
                    disabled={true}
                    chunky={true}
                />
                <Input
                    value={name}
                    onChange={this.handleChange}
                    placeholder={"Name"}
                    id={'name'}
                    srLabel={'Name'}
                    chunky={true}
                />
                <Input
                    type="password"
                    value={password}
                    placeholder={"Password"}
                    srLabel={'Password'}
                    id={'password'}
                    chunky={true}
                    onChange={this.handleChange}
                />
                <Input
                    type="password"
                    value={password_confirmation}
                    placeholder={"Confirm password"}
                    srLabel={'Confirm password'}
                    id={'password_confirmation'}
                    chunky={true}
                    onChange={this.handleChange}
                />
                <Small>Passwords must be at least 6 characters long and contain at least one number, one letter and one special character.</Small>
                <Button
                    chunky={true}
                    blue={true}
                    disabled={!requiredPopulated || loggingIn}
                >Register</Button>
            </ChunkyForm>
        );
    }
}

const mapStateToProps = state => {
    return {
        self: state.user.self,
        loggedIn: state.user.loggedIn,
        loggingIn: state.user.loggingIn,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ registerUser, getUser }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(RegisterContainer));