import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { addDays, format } from 'date-fns';

import { Table, TableRow, WeekControls, UserWrap, UserWeek } from './components';
import { TH, TD } from './components/TableCell';
import { SVG, SROnly } from '../../Utils';

class WeekCalendar extends Component {
    renderHeader = (startOfWeek) => {
        let days = [];

        for(let i = 0; i < 7; i++) {
            let day = format(addDays(startOfWeek, i), 'ddd D');

            days.push(
                <TH key={`thead${day}`} size={'sm'}>{ day }</TH>
            );
        }

        return(
            <thead>
                <TableRow>
                    <TH noPadding={true} />
                    <TH
                        colSpan="7"
                        noPadding={true}
          t          >
                        <Table>
                            <thead>
                                <TableRow>
                                    <TH colSpan="7" lightText={true}>
                                        <WeekControls
                                            date={ format(startOfWeek, 'MMMM YYYY') }
                                            onPrevClick={this.props.onPrevClick}
                                            onNextClick={this.props.onNextClick}
                                        />
                                    </TH>
                                </TableRow>

                                <TableRow>
                                    { days }
                                </TableRow>
                            </thead>
                        </Table>
                    </TH>
                </TableRow>
            </thead>
        );
    }

    renderUsers = (startOfWeek, users) => {
        let {
            mapping,
            clicked,
            hovered,
            canEdit,
            self,
        } = this.props;

        if(!users || !users.length) {
            return(
                <tbody>
                    <TableRow>
                        <TD colSpan={'8'} />
                    </TableRow>
                </tbody>
            );
        }

        return(
            <tbody>
                { users.map(user => (
                    <TableRow
                        key={`row${user.email}`}
                        startOfWeek={startOfWeek}
                        data-testid={'userRow'}
                        style={ !canEdit ? self === user.id ? null : { pointerEvents: 'none' } : null }
                    >
                        <TD>
                            <UserWrap>
                                <SVG path={'/svg/icon-user.svg'} />
                                <div>
                                    <p>{ user.name }</p>
                                    <p>{ user.role }</p>
                                    <p>{ user.email }</p>
                                    {/*{ self && self !== user.id &&*/}
                                        {/*<Link*/}
                                            {/*el={'button'}*/}
                                            {/*red={true}*/}
                                            {/*small={true}*/}
                                            {/*onClick={() => this.props.onDelete(user.id)}*/}
                                        {/*>Delete</Link>*/}
                                    {/*}*/}
                                </div>
                            </UserWrap>
                        </TD>

                        <UserWeek
                            clicked={clicked}
                            hovered={hovered}
                            user={user}
                            mapping={mapping}
                            startOfWeek={startOfWeek}
                            onTimeSlotClick={this.props.onTimeSlotClick}
                            onTimeSlotHover={this.props.onTimeSlotHover}
                        />
                    </TableRow>
                )) }
            </tbody>
        );
    }


    render() {
        let {
            date,
            users,
        } = this.props;

        return(
            <Table>
                { this.renderHeader(date) }
                { this.renderUsers(date, users) }
            </Table>
        );
    }
}

WeekCalendar.propTypes = {
    date: PropTypes.instanceOf(Date),
    users: PropTypes.arrayOf(PropTypes.object),
    exclusions: PropTypes.arrayOf(PropTypes.object),
    self: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ])
}

export default WeekCalendar;