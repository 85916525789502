import { keys } from 'underscore';

import settings from '../config/settings';
import { getUserToken, removeUserToken } from '../utils/cookies';

const toQueryString = (obj) => {
    return keys(obj).map( i => {
        let value = obj[i];

        return (encodeURIComponent(i) + "=" + encodeURIComponent(value))
    }).join("&");
};

const request = ({ method, url, data }, token = null) => {

    // Set the headers if there is a token
    const headers = {
        // 'Content-Type': 'multipart/form-data'
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    };
    if( token ) headers.Authorization = "Bearer " + token;

    // Pass the data through
    let options = { method, headers };
    url = settings.api + url;
    if( method === 'GET' || method === 'DELETE' ) {
        url += '?' + toQueryString(data);
    } else {
        options.body = JSON.stringify(data);
    }

    // options.mode = 'no-cors';

    return fetch( url, options )
        .then( async res => {
            // TODO: Find a better way and noit with statusText. Maybe bodyUsed. or return a {} from the server
            let body = res.status === 204 ? {} : await res.json() || {};
            return { body, res };
        } )
        .then( ({ body, res }) => {
            if( !res.ok ) throw { body, status: res.status };
            return body;
        });

}

export const fetchDownload = ({ method, url, data }, token = null) => {
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }

    if( token ) headers.Authorization = "Bearer " + token;

    // Pass the data through
    let options = { method, headers };
    url = settings.api + url;
    if( method === 'GET' || method === 'DELETE' ) {
        url += '?' + toQueryString(data);
    } else {
        options.body = JSON.stringify(data);
    }

    // options.mode = 'no-cors';

    return fetch( url, options )
        .then( async res => {
            let body = await res.blob();

            return { body, res };
        } )
        .then( ({ body, res }) => {
            //console.log( body, res )
            if( !res.ok ) throw { body, status: res.status };
            return body;
        });
}

const requestWithToken = (params) => {
    const token = getUserToken();
    return request(params, token);
}

export const get = (url, data, withToken = true) => {
    const params = { method: 'GET', url, data };
    return withToken ? requestWithToken(params) : request(params);
};

export const post = (url, data, withToken = true) => {
    const params = { method: 'POST', url, data };
    return withToken ? requestWithToken(params) : request(params);
};

export const put = (url, data, withToken = true) => {
    const params = { method: 'PUT', url, data };
    return withToken ? requestWithToken(params) : request(params);
};

export const remove = (url, data, withToken = true) => {
    const params = { method: 'DELETE', url, data };
    return withToken ? requestWithToken(params) : request(params);
};

export const getDownload = (url, data, withToken = true) => {
    const params = { method: 'GET', url, data };

    const token = getUserToken();

    return withToken ? fetchDownload(params, token) : fetchDownload(params);
};

export const checkAuthentication = (dispatch, err) => {

    const returnError = {
        ...err,
        message: 'body' in err && 'message' in err.body ? err.body.message : 'An error occured.'
    }

    // Check if the response code is present and that it's 401 (unauthenticated)
    if( err && 'status' in err && err.status === 401 ) {
        removeUserToken();

        const error = 'message' in err ? err.message : err.body.message;
        //if( 'devMessage' in err.body && process.env.NODE_ENV === 'production' ) console.log( err.body.devMessage )
        if( error ) dispatch({ type: 'FETCH_TOKEN_FAIL', error })
        throw returnError;
    }

    // If there are validation errors, change the error message to be the first validation error.
    if( err && 'body' in err && 'validation_errors' in err.body && err.status === 400 ) {
        let firstErr = Object.keys(err.body.validation_errors)[0];
        returnError.message = err.body.validation_errors[firstErr][0];
    }

    // Return the current error to be processed
    throw returnError;

}