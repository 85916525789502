import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { loginUser, getUser } from '../../actions/user';
import { Input, ChunkyForm } from '../../components/Forms';
import { Button } from '../../components/Buttons';

class LoginContainer extends Component {
    state = {
        email: '',
        password: '',
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let { email, password } = this.state;

        this.props.loginUser({
            email,
            password
        });

        return false;
    }

    handleChange = e => {
        this.setState({
            [e.target.id]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
    }

    render() {
        let { email, password } = this.state,
            { loggedIn, self, loggingIn } = this.props;

        if(loggedIn && self) return <Redirect to='/cases' />

        return(
            <ChunkyForm onSubmit={this.handleSubmit}>
                <Input
                    type="email"
                    value={email}
                    onChange={this.handleChange}
                    placeholder={"Email address"}
                    id={'email'}
                    srLabel={'Email address'}
                    chunky={true}
                />
                <Input
                    type="password"
                    value={password}
                    placeholder={"Password"}
                    srLabel={'Password'}
                    id={'password'}
                    chunky={true}
                    onChange={this.handleChange}
                />
                <Button
                    chunky={true}
                    blue={true}
                    disabled={loggingIn}
                    data-testid={'login-btn'}
                >Login</Button>
            </ChunkyForm>
        );
    }
}

const mapStateToProps = state => {
    return {
        self: state.user.self,
        loggedIn: state.user.loggedIn,
        loggingIn: state.user.fetching,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ loginUser, getUser }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginContainer));