import styled from 'styled-components';

import PageTitle from '../Text/PageTitle';

export default styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 25px;
    
    ${PageTitle} {
        margin: 0;
    }
    
    @media screen and (max-width: 700px) {
        flex-wrap: wrap;
        
        ${PageTitle} {
            width: 100%;
            
            +* {
                order: -1;
                margin-bottom: 25px;
            }
            
            +button {
                span:first-child:last-child {
                    margin-left: 0;
                }
            }
        }
    }
`;