import styled from 'styled-components';
import { Flex } from 'grid-styled';

// import { flexCols } from '../../utils/style';

const FlexCols = styled(Flex)`
    ${({ cols }) => cols === 2 && `
        justify-content: space-between;

        >* {
            width: calc(50% - 5px);

            &:nth-of-type(n+3) {
                margin-top: 10px;
            }
        }
    `};

    ${({ cols }) => cols === 3 && `
        >* {
            width: calc((100% / 3) - (20px / 3));

            &:nth-of-type(3n+1),
            &:nth-of-type(3n+2) {
                margin-right: 10px;
            }

            &:nth-of-type(n+4) {
                margin-top: 10px;
            }
        }
    `};

    ${({ cols }) => cols === 4 && `
        >* {
            width: calc(25% - (30px / 4));

            &:nth-of-type(4n+1),
            &:nth-of-type(4n+2),
            &:nth-of-type(4n+3) {
                margin-right: 10px;
            }

            &:nth-of-type(n+5) {
                margin-top: 10px;
            }
        }
    `};

    ${({ cols }) => cols === 5 && `
        >* {
            width: calc(20% - (40px / 5));

            &:nth-of-type(5n+1),
            &:nth-of-type(5n+2),
            &:nth-of-type(5n+3),
            &:nth-of-type(5n+4) {
                margin-right: 10px;
            }

            &:nth-of-type(n+6) {
                margin-top: 10px;
            }
        }
    `};
`;

FlexCols.defaultProps = {
    flexWrap: 'wrap'
}

export default FlexCols;