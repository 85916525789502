import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { getValue } from '../../utils/dataMap';

const TableCardWrap = styled.div`
    width: 100%;
    border: ${({ theme }) => theme.border.main};
    background: ${({ theme }) => theme.whiteish};
`;

const TableCardRow = styled.div`
    position: relative;
    display: flex;
    height: 40px;
    padding: 5px 10px;
    
    ${({ isTitle, theme }) => isTitle && `
        background: ${theme.greyLightest};
        font-weight: 700;
    `};
    
    >* {
        display: flex;
        align-items: center;
    
        &:first-child {
            width: ${({ labelWidth }) => labelWidth ? labelWidth : '110px'};
        }
        
        &:last-child {
            flex: 1 0 0;
        }
    }
    
    +div {
        border-top: ${({ theme }) => theme.border.main}
    }
`;

const Label = styled.strong``;

const Val = styled.div`
    ${({ isTitle }) => !isTitle && `
        text-align: right;
        justify-content: flex-end;
    `}
    
    a {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 5px 10px;
        text-decoration: none;
        
        ${({isTitle}) => !isTitle && `
            text-align: right;
            justify-content: flex-end;
        `}
    }
    
    strong {
        font-weight: 400;
    }
`;

const TableCard = ({ data, columns, firstColTitle, cardLink, labelWidth }) => {
    return(
        <TableCardWrap>
            { columns.map((col, i) => {
                let { MobileCell, Header, accessor } = col;

                let row = {
                    value: getValue(data, accessor),
                    original: {
                        ...data
                    },
                }

                let showLabel = firstColTitle ? i !== 0 : true;

                return(
                    <TableCardRow
                        isTitle={!showLabel}
                        key={`row${data.id}${i}`}
                        labelWidth={labelWidth}
                    >
                        { showLabel && <Label>{ Header }</Label> }
                        <Val isTitle={!showLabel}>
                            { MobileCell ? <MobileCell {...row} /> : row.value }
                        </Val>
                    </TableCardRow>
                );
            }) }
        </TableCardWrap>
    );
}

export default TableCard;