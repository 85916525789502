import React, { Component, Fragment } from 'react';
import SVG from 'react-svg';
import PropTypes from 'prop-types';

import { UserDropdownContainer } from '../../containers/User';

import { DateTimeInput, FormGroup, FormItem, FormItemGroup, Input, TimeInput } from './';
import { Button } from '../Buttons';
import { Modal, ModalButtons, ModalContent } from '../Modals';
import { Link } from '../Text';
import { ErrorBoundary, Error } from '../Utils';
import { HiddenInputInner, HiddenInputWrap, HiddenInputClose, StyledButton } from './HiddenInput';

class AssignLoggedTime extends Component {
    constructor(props) {
        super(props);

        this.timeInput = React.createRef();

        this.state = {
            modalOpen: false,
            calculatedTime: null,
            date: null,
            user: null,
            time: {
                value: '',
                valid: false
            },
        }
    }

    handleSave = () => {
        let time = {
            ...this.state.time,
        }

        if(this.state.user) {
            time.user_id = this.state.user.id;
        }

        if(this.state.date) {
            time.created_at = this.state.date;
        }

        this.props.onChange(time);

        this.setState({
            modalOpen: false,
            date: null,
            user: null,
            time: {
                value: '',
                valid: false
            },
        });
    }

    render() {
        return(
            <Fragment>
                { this.props.value ? (
                    <HiddenInputWrap>
                        <HiddenInputInner>
                            <TimeInput
                                value={this.props.value}
                                id={this.props.id}
                                disabled
                            />

                            <HiddenInputClose
                                onClick={() => this.props.onChange({
                                    value: '',
                                    valid: true
                                })}
                                type={'button'}
                            >
                                <SVG path={'/svg/icon-plus.svg'} />
                            </HiddenInputClose>
                        </HiddenInputInner>
                    </HiddenInputWrap>
                ) : (
                    <StyledButton
                        onClick={() => this.setState({ modalOpen: true })}
                        type={'button'}
                        style={{
                            display: 'inline-flex',
                            flex: 1,
                        }}
                    >Log time</StyledButton>
                ) }

                <Modal
                    isOpen={this.state.modalOpen}
                    onRequestClose={this.closeModal}
                    overflow={true}
                >
                    <ModalContent
                        title={'Add Logged Time'}
                        overflow={true}
                    >
                        <ErrorBoundary
                            render={() => <Error overlay={true} />}
                        >
                            <FormGroup center={true}>
                                <FormItemGroup
                                    labelWidth={"150px"}
                                    wide={true}
                                >
                                    <FormItem
                                        label={'Time'}
                                        id={'time'}
                                        minWidthInput={true}
                                    >
                                        <TimeInput
                                            value={this.state.time.value}
                                            onChange={time => this.setState({ time })}
                                            innerRef={this.timeInput}
                                        />
                                    </FormItem>

                                    <FormItem
                                        label={'User'}
                                        id={'user'}
                                        minWidthInput={true}
                                    >
                                        <UserDropdownContainer
                                            addUser={user => this.setState({ user })}
                                            value={this.state.user}
                                            addOnType={true}
                                            defaultToSelf
                                        />
                                    </FormItem>

                                    <FormItem
                                        label={'Date'}
                                        id={'date'}
                                    >
                                        <DateTimeInput
                                            handleChange={date => this.setState({ date }) }
                                            value={this.state.date}
                                        />
                                    </FormItem>
                                </FormItemGroup>
                            </FormGroup>

                            <ModalButtons
                                pl={'150px'}
                                wide={true}
                            >
                                <Button
                                    blue={true}
                                    small={true}
                                    onClick={() => this.handleSave(false)}
                                    disabled={!this.state.time.valid}
                                >Confirm</Button>

                                <Link
                                    el={'button'}
                                    onClick={() => this.setState({ modalOpen: false })}
                                >Cancel</Link>
                            </ModalButtons>
                        </ErrorBoundary>
                    </ModalContent>
                </Modal>
            </Fragment>
        );
    }
}

AssignLoggedTime.propTypes = {};

export default AssignLoggedTime;
