import React, { Component } from 'react';
import styled from 'styled-components'
import Autosuggest from 'react-autosuggest';

import { Input } from './';
import { listPlain, optionDropdown, optionDropdownOuter, option } from '../../utils/style';

const AutosuggestWrap = styled.div`
    .react-autosuggest__container--open {
        position: relative;
    }
    
    .react-autosuggest__suggestions-container {
        display: none;
        ${optionDropdownOuter()}
        
        &--open {
            display: block;
        }
    }
    
    ul {
        ${listPlain()};
        ${optionDropdown()};
    }
`;

const Suggestion = styled.button`
    ${option()};
`;

class InputSuggest extends Component {
    handleChange = (e, { newValue }) => {
        this.props.onChange(newValue);
    }

    loadSuggestions = ({ value }) => {
        this.props.fetchSuggestions(value);
    }

    handleKeyboardSelect = e => {
        // Stops propagation - not sure why stopPropagation() doesn't work here.
        e.preventDefault();
    }

    renderSuggestion = (result) => (
        <Suggestion>{ result.name }</Suggestion>
    )

    renderInput = inputProps => (
        <Input inputProps={inputProps} />
    )

    render() {
        let { suggestions, value } = this.props;

        return(
            <AutosuggestWrap>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.loadSuggestions}
                    getSuggestionValue={result => result.name}
                    onSuggestionSelected={this.handleKeyboardSelect}
                    onSuggestionsClearRequested={() => this.props.fetchSuggestions('')}
                    renderSuggestion={this.renderSuggestion}
                    renderInputComponent={this.renderInput}
                    inputProps={{
                        value,
                        onChange: this.handleChange,
                    }}
                />
            </AutosuggestWrap>
        );
    }
}

export default InputSuggest;