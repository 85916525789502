export const toSlug = (title) => {
    return title
        .toLowerCase()
        .replace(' & ', '-')
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'');
}

export const toTitle = slug => {
    return toTitleCase(slug.replace(/(-)|(_)/g, ' '));
}

const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase() );
}

export const isUpperCase = str => {
    return str === str.toUpperCase();
}

export const getSizeInitial = str => {
    // If the string is all caps, it's likely already in desired format
    if(isUpperCase(str)) {
        return str;
    } else {
        // Else take the first letter and make it caps
        return str.split('')[0].toUpperCase();
    }
}

export const lineBreaksFromArray = (array) => {
    if(!array) return '';
    if(typeof array === 'string') return array;

    return array.join('\n');
}

export const moneyString = string => {
    if(!string) string = '0';

    return '£' + string;
}

export const getInlineAddress = string => {
    if(!string) return '';

    return string.replace(/ \r/g, ', ');
}

export const getLoggedTimeString = (timeArray) => {
    if(!timeArray || !timeArray.length) return 'N/A';

    let string = '';

    let { hours, minutes } = timeArray.reduce((acc, curr) => {
        return {
            hours: acc.hours + curr.hours,
            minutes: acc.minutes + curr.minutes,
        }
    }, { hours: 0, minutes: 0 });

    if(hours) string += `${hours} hours `;
    if(minutes) string += `${minutes} minutes`;

    return string;
}

export const padNumber = (number) => {
    if(number > 9) return number;

    return '0' + number;
}