import React, { Component, Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { completeCase, unarchiveCase } from '../../actions/cases';

import { Button } from '../../components/Buttons';
import {
    Error,
    ErrorBoundary,
} from '../../components/Utils';
import { Modal } from '../../components/Modals';
import theme from '../../config/theme';
import { hasPermission } from '../../utils/validation';

const StyledDiv = styled.div`
    min-width: 190px;
    padding: 15px 15px;
    border: ${({ theme }) => theme.border.main};
    border-radius: ${({ theme }) => theme.radius.smaller};
    box-shadow: ${({ theme }) => theme.boxShadow.main};
    text-align: center;
`;

class CompleteCaseContainer extends Component {
    state = {
        modalOpen: false,
        confirm: false,
        reason: '',
    }

    options = [
        {
            label: 'With Trading Standards',
            action: () => this.openConfirm('trading_standards')
        },
        {
            label: 'With Client',
            action: () => this.openConfirm('client')
        },
        {
            label: 'With Border Force',
            action: () => this.openConfirm('border_force')
        },
        {
            label: 'WRi PCP',
            action: () => this.openConfirm('wri_pcp')
        },
        {
            label: 'No further action',
            action: () => this.openConfirm('no_further_action')
        },
	];

    componentDidUpdate(prevProps) {
        if((prevProps.completing && !this.props.completing) && !this.props.error) {
            this.props.history.push('/cases');
		}
    }

    completeCase = () => {
		const params = {
			outcome: this.state.reason || this.props.reason,
		}

		if(this.state.invoice) {
			params.invoice = this.state.invoice;
		}

        if(params.outcome === 'unarchive') {
			this.props.unarchiveCase(this.props.match.params.id);
			this.setState({
				modalOpen: false,
				confirm: false,
				reason: '',
			});
        } else {
			this.props.completeCase(this.props.match.params.id, params);
        }
    }

    openConfirm = (reason) => {
		if(
			(this.props.details && this.props.details.status === 'potential' && reason === 'no_further_action') || 
			this.props.reason ||
			this.props.match.path === '/cases/:id/invoice' ||
			reason === 'unarchive'
		) {
			this.setState({
				confirm: true,
				modalOpen: true,
				reason: reason || this.props.reason,
			});
		} else {
			this.props.history.push(`/cases/${this.props.match.params.id}/invoice`, { reason });
		}
    }

    render() {
        let { self, loggedIn, details, render } = this.props;
		let { modalOpen, confirm } = this.state;
		
		let options = this.options;

        let confirmTitle = 'Are you sure you want to resolve the case?',
            confirmText = 'This will place the case in an archived state.',
			confirmBtn = 'Archive Case';
			
		const completed = details && details.status && (details.status === 'closed' || details.status === 'invoiced');

        if(details && details.status && (details.status === 'closed' || details.status === 'invoiced')) {
            options = [
				{
					label: 'Unarchive Case',
					action: () => this.openConfirm('unarchive')
				}
			];

            confirmTitle = 'Are you sure you want to unarchive this case?';
            confirmText = '';
            confirmBtn = 'Unarchive Case';
		}

        if(!loggedIn) return <Redirect to='/login' />

        const userHasPermission = hasPermission(self, 'cases:resolve');

		if(!userHasPermission) return null;

        return(
            <Fragment>
                { render({
					completed,
					complete: (invoice) => {
						this.setState({
							invoice
						});

						this.openConfirm(this.props.reason);
					},
					openModal: (invoice) => {
						this.setState({ 
							modalOpen: true,
							invoice
						});
					},
					unarchive: () => this.openConfirm('unarchive'),
				}) }

                <Modal
                    isOpen={modalOpen}
                    onRequestClose={() => this.setState({ modalOpen: false, confirm: false })}
                    borderless={true}
                    narrow={true}
                >
                    <ErrorBoundary render={() => <Error overlay={true} />}>
                        { confirm ?
                            <Fragment>
                                <StyledDiv>
                                    <p><strong>{ confirmTitle }</strong></p>
                                    { confirmText && <p style={{ color: theme.lightText, marginTop: '5px' }}>{ confirmText }</p> }
                                </StyledDiv>

                                <Button
                                    onClick={this.completeCase}
                                    minWidth={true}
                                    red={true}
                                    small={true}
                                    style={{
                                        display: 'table',
                                        width: '120px',
                                        margin: '20px auto 0'
                                    }}
                                >{ confirmBtn }</Button>
                                <Button
                                    onClick={() => {
										if(this.props.reason) {
											this.setState({
												modalOpen: false
											});
										} else {
											this.setState({ confirm: false })
										}
									}}
                                    minWidth={true}
                                    grey={true}
                                    small={true}
                                    style={{
                                        display: 'table',
                                        width: '110px',
                                        margin: '10px auto 0'
                                    }}
                                >Cancel</Button>
                            </Fragment>
                            :
                            <Fragment>
                                <p style={{
                                    margin: '0 0 10px',
                                    textAlign: 'center',
                                    color: theme.lightText,
                                }}>Please select an outcome for the case:</p>
                                { options.map(option => (
                                    <Button
                                        onClick={option.action}
                                        chunkyish={true}
                                        block={true}
                                        full={true}
                                        shadow={true}
                                        key={`resolve${option.label}`}
                                    >{ option.label }</Button>
                                )) }

                                <Button
                                    onClick={() => this.setState({ modalOpen: false })}
                                    minWidth={true}
                                    grey={true}
                                    small={true}
                                    style={{
                                        display: 'table',
                                        margin: '20px auto 0'
                                    }}
                                >Cancel</Button>
                            </Fragment>
                        }
                    </ErrorBoundary>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
		self: state.user.self,
        loggedIn: state.user.loggedIn,
        unarchiving: state.case.unarchiving,
        completing: state.case.completing,
        details: state.case.data.details.data,
        error: state.case.error,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ 
	completeCase, 
	unarchiveCase
}, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CompleteCaseContainer));