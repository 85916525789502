import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { withAlert } from 'react-alert';
import ReactTimeout from 'react-timeout';

import { getUser } from '../actions/user';
import { removeAlerts } from '../actions/alerts';
import { Loading } from '../components/Utils';

const alert = (Component) => {
    class AlertComponent extends Component {
        componentDidMount() {
            this.props.getUser();
        }

        componentDidUpdate(prevProps, prevState) {
            if(!this.props.alerts || !this.props.alerts.message) return;
            if(prevProps.alerts.message === this.props.alerts.message) return;

            this.flashMessage();
        }

        flashMessage = () => {
            this.props.alert.show(this.props.alerts);

            this.props.setTimeout(this.props.removeAlerts, 5300);
        }

        render() {
            let { fetching, loggedIn } = this.props;

            // TODO: Move this
            let isAuthPage = ['/login', '/reset-password', '/change-password', '/register'].includes(window.location.pathname);

            if(fetching) return <Loading overlay={true} />;
            if(!loggedIn && !isAuthPage) return <Redirect to={'/login'} />

            return <Component />
        }
    }

    const mapStateToProps = state => {
        return {
            alerts: state.alerts,
            loggedIn: state.user.loggedIn,
            settings: state.user.data,
            fetching: state.user.fetching,
            saving: state.user.saving,
        };
    };

    const mapDispatchToProps = dispatch => bindActionCreators({ removeAlerts, getUser }, dispatch);

    return connect(
        mapStateToProps,
        mapDispatchToProps,
    )(withAlert(ReactTimeout(AlertComponent)));
}

export default alert;