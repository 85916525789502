import React from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';

import { settings } from '../../../../config';

import { IconLabel } from '../../../Icons';

const StyledDropzone = styled(Dropzone)`
    //width: 100%;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${({ theme }) => theme.border.main};
    border-radius: ${({ theme }) => theme.radius.small};
    cursor: pointer;
    
    button {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
        border: 0;
        background: none;
        cursor: pointer;
        
        span {
            &:first-of-type {
                width: 100%;
                margin-bottom: 5px;
                font-size: ${({ theme }) => theme.fontSize.bodySm};
                color: ${({ theme }) => theme.darkText};
            }
        
            &:last-of-type {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 0;
                height: 0;
                padding: 9px;
                color: ${({ theme }) => theme.blueLighter};
                border: 1px solid ${({ theme }) => theme.blueLighter};
                border-radius: ${({ theme }) => theme.radius.smaller};
                
                &:after {
                    content: '+';
                    position: absolute;
                    top: 0px;
                    left: 4px;
                    font-size: 16px;
                }
            }
        }
        
        &:focus {
            outline: 0;
        }
    }
`;

const NewFile = ({ handleDrop }) => {
    return(
        <StyledDropzone
            onDrop={handleDrop}
            accept={settings.acceptedFileTypes}
            maxSize={settings.maxFileSize}
        >
            <button>
                <IconLabel label={"Add a file"} />
            </button>
        </StyledDropzone>
    );
}

export default NewFile;