import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Log, LogEntry, LogInput } from './components';
import { Loading } from '../Utils';
import { getTimeObjectFromString } from '../../utils/dates';

class ActivityLog extends Component {
    constructor(props) {
        super(props);

        this.logRef = React.createRef();

        this.state = {
            text: '',
            time: {
                value: '',
                valid: true,
            },
            documents: [],
        }
    }

    componentDidUpdate(prevProps) {
        this.logScrollCheck(prevProps);
        this.commentSentCheck(prevProps);
    }

    logScrollCheck = (prevProps) => {
        if(!this.props.entries) return;

        if(prevProps.entries.length !== this.props.entries.length) {
            this.logRef.current.scrollTop = 0;
        }
    }

    addFiles = (e) => {
        let input = e.target,
            files = [...input.files];

        files.forEach(file => {
            let reader = new FileReader();

            reader.onload = () => {
                this.setState({
                    documents: [...this.state.documents, {
                        file: reader.result,
                        name: file.name,
                        type: file.type
                    }]
                });
            }
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');

            reader.readAsDataURL(input.files[0]);
        });
    }

    removeFile = (index) => {
        let documents = [...this.state.documents];

        documents.splice(index, 1);

        this.setState({ documents });
    }

    commentSentCheck = (prevProps) => {
        if(prevProps.sent === this.props.sent) return;

        if(this.props.sent) {
            this.setState({
                text: '',
                documents: [],
                time: {
                    value: '',
                    valid: true,
                },
            });
        }
    }

    updateTime = (time) => {
        this.setState({ time });
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let { text, time, documents } = this.state;

        let timeObject = getTimeObjectFromString(time.value, true);

        if(time.created_at) {
            timeObject.created_at = time.created_at;
        }

        if(time.user_id) {
            timeObject.user_id = time.user_id;
        }

        let data = {
            comment: {
                text
            },
            logged_time: [timeObject],
        };

        if(documents && documents.length) {
            data.documents = documents;
        }

        this.props.onSubmit(data);

        return false
    }

    render() {
        let {
            entries,
            sending,
            fetching,
            disableComments,
        } = this.props;

        let {
            text,
            time,
            documents
        } = this.state;

        let disabled = !text && !documents.length;

        if(time.value) {
            disabled = !time.valid;
        }

        return(
            <Fragment>
                <Log ref={this.logRef}>
                    { (!entries || !entries.length) && <p style={{ marginBottom: '15px' }}>There are currently no entries.</p> }
                    { entries && !!entries.length && entries.map((entry, i) => (
                        <LogEntry
                            {...entry}
                            index={i}
                            key={`logEntry${i}`}
                        />
                    )) }
                    { fetching && <Loading overlay={true} /> }
                </Log>

                { !disableComments &&
                    <LogInput
                        text={text}
                        time={time.value}
                        files={documents}
                        onTimeChange={this.updateTime}
                        onCommentChange={(e) => this.setState({ text: e.target.value })}
                        onSubmit={this.handleSubmit}
                        onFilesAdd={this.addFiles}
                        onFileRemove={this.removeFile}
                        disabled={disabled}
                        sending={sending}
                    />
                }
            </Fragment>
        );
    }
}

ActivityLog.defaultProps = {
    entries: []
}

ActivityLog.propTypes = {
    entries: PropTypes.array,
    fetching: PropTypes.bool,
    sending: PropTypes.bool,
    sent: PropTypes.bool,
    disableComments: PropTypes.bool,
    onSubmit: PropTypes.func,
}

export default ActivityLog;