import React, { Component } from 'react';
import { addDays, format, isWithinRange, isBefore } from 'date-fns';

import { getAvailability } from '../../../../utils/dates';
import UserDay from './UserDay';

const memoizedStartDateReplace = (string) => {
    return string.replace('am', '00:00:00').replace('pm', '12:00:00');
}
const memoizedEndDateReplace = (string) => {
    return string.replace('am', '11:59:59').replace('pm', '23:59:59');
}

class UserWeek extends Component {
    shouldComponentUpdate(nextProps) {
        if(this.props.clicked.userId !== nextProps.clicked.userId) return true;
        if(nextProps.clicked.userId === this.props.user.id) return true;
        if(nextProps.startOfWeek !== this.props.startOfWeek) return true;
        if(nextProps.mapping !== this.props.mapping) return true;

        return false;
    }

    checkClicked = (date, id) => {
        let { clicked } = this.props;

        if(!clicked || !clicked.userId) return false;

        return {
            am: `${clicked.userId} ${clicked.startDate}` === `${id} ${date} am`,
            pm: `${clicked.userId} ${clicked.startDate}` === `${id} ${date} pm`,
        }
    }

    checkBetweenClickedHovered = (date, id) => {
        // Check if this day's slots are between the clicked and hovered slots
        let { clicked, hovered } = this.props;

        if(!clicked || !clicked.startDate || !hovered.date || id !== clicked.userId || id !== hovered.userId) return false;

        let clickedStartDate = memoizedStartDateReplace(clicked.startDate),
            hoveredDate = memoizedEndDateReplace(hovered.date);

        if(isBefore(hoveredDate, clickedStartDate)) return false;

        return {
            am: isWithinRange(`${date} 00:00:01`, clickedStartDate, hoveredDate),
            pm: isWithinRange(`${date} 12:00:01`, clickedStartDate, hoveredDate)
        }
    }

    checkIfBeforeClicked = (date) => {
        // Check if this day's slots are before the clicked slot
        let { clicked } = this.props;

        if(!clicked || !clicked.startDate) return false;

        let clickedStartDate = memoizedStartDateReplace(clicked.startDate);

        return {
            am: isBefore(`${date} 00:00:00`, clickedStartDate),
            pm: isBefore(`${date} 23:59:59`, clickedStartDate)
        }
    }

    render() {
        let {
            startOfWeek,
            user,
        } = this.props;

        return Array.from(Array(7)).map((e, i) => {
            let day = format(addDays(startOfWeek, i), 'YYYY-MM-DD');

            // Check if this day is inside the user's exclusions
            let unavailable = getAvailability(day, user.exclusions);

            // Check if this slot has been clicked for this user.
            let clicked = this.checkClicked(day, user.id);

            // Check if this slot is in between the slot that's been clicked and any slot we're hovering over
            let betweenClickedHovered = this.checkBetweenClickedHovered(day, user.id);

            // Check if this slot is in between the slot that's been clicked and any slot we're hovering over
            let beforeClicked = this.checkIfBeforeClicked(day, user.id);

            // This is just here to provide a shallow check for our PureComponent UserDay to prevent unnecessary renders
            let userIsClicked = this.props.clicked && user.id === this.props.clicked.userId;

            return(
                <UserDay
                    user={user}
                    day={day}
                    onTimeSlotHover={this.props.onTimeSlotHover}
                    onTimeSlotClick={this.props.onTimeSlotClick}
                    unavailable={unavailable}
                    clicked={clicked}
                    beforeClicked={beforeClicked}
                    betweenClickedHovered={betweenClickedHovered}
                    userIsClicked={userIsClicked}
                    key={'col' + user.email + i}
                />
            );
        })
    }
}

export default UserWeek;