import { handleActions } from 'redux-actions';

const initialState = {
    data: [],
    links: {},
    meta: {},
    page: 1,
    limit: 10,
    sort: '',
    sortBy: '',
    searchQuery: '',
    fetching: true,
    error: false,
}

export default handleActions({

    FETCH_TRADERS: (state, action) => {
        return {
            ...state,
            fetching: true,
            error: false,
        }
    },

    FETCH_TRADERS_SUCCESS: (state, action) => {
        return {
            ...state,
            fetching: false,
            data: action.traders.data,
            links: action.traders.links,
            meta: action.traders.meta,
            page: action.traders.meta.current_page,
            limit: action.traders.meta.per_page,
            ...action.params
        }
    },

    FETCH_TRADERS_FAIL: (state, action) => {
        return {
            ...state,
            fetching: false,
            error: true
        }
    },

    RESET_TRADERS: (state, action) => {
        return initialState;
    }

}, initialState)