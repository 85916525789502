import Cookies from 'js-cookie';
import { addHours } from 'date-fns';

import settings from '../config/settings';

export const getUserToken = () => {
    return Cookies.get( settings.userCookieName ) || null;
};

export const setUserToken = (token, expiry) => {
    let expires = addHours(new Date(), expiry);
    return Cookies.set( settings.userCookieName, token, { expires } );
}

export const removeUserToken = () => {
    return Cookies.remove( settings.userCookieName )
};

export const getCookies = () => {
    return Cookies.getJSON();
}