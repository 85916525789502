import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';

import { resetPassword } from '../../actions/user';

import { Input, ChunkyForm } from '../../components/Forms';
import { Button } from '../../components/Buttons';
import { Small } from '../../components/Text';

class ChangePasswordContainer extends Component {
    state = {
        password: '',
        password_confirmation: '',
    }

    componentDidUpdate(prevProps) {
        if(prevProps.saving && !this.props.saving && !this.props.error) {
            this.props.history.push('/login');
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        let { password, password_confirmation } = this.state;
        let params = this.props.location.search;
        let { email, id } = queryString.parse(params);

        this.props.resetPassword({
            email,
            password,
            password_confirmation,
            token: id,
        });

        return false;
    }

    handleChange = e => {
        this.setState({
            [e.target.id]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
    }

    render() {
        let { password, password_confirmation } = this.state,
            { location, saving } = this.props;

        let params = location.search;
        let { email } = queryString.parse(params);

        return(
            <ChunkyForm onSubmit={this.handleSubmit}>
                <Input
                    type="email"
                    value={email || ''}
                    placeholder={"Email address"}
                    id={'email'}
                    srLabel={'Email address'}
                    chunky={true}
                    disabled={true}
                />
                <Input
                    type="password"
                    value={password}
                    onChange={this.handleChange}
                    placeholder={"New password"}
                    id={'password'}
                    srLabel={'New password'}
                    chunky={true}
                />
                <Input
                    type="password"
                    value={password_confirmation}
                    onChange={this.handleChange}
                    placeholder={"Confirm new password"}
                    id={'password_confirmation'}
                    srLabel={'Confirm new password'}
                    chunky={true}
                />
                <Small>Passwords must be at least 6 characters long and contain at least one number, one letter and one special character.</Small>
                <Button
                    chunky={true}
                    blue={true}
                    disabled={saving}
                >Submit</Button>
            </ChunkyForm>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        saving: state.user.saving,
        error: state.user.error,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ resetPassword }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePasswordContainer));