import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
    max-width: 100%;
    padding: 6px 9px;
    border: ${({ theme }) => theme.border.main};
    border-radius:${({ theme }) => theme.radius.small};
    background: ${({ theme }) => theme.whiteish};
    font-size: ${({ theme }) => theme.fontSize.bodyMd};
    cursor: pointer;
    transition-duration: ${({ theme }) => theme.duration.quick};
    transition-property: background, color, border-color;
    
    &:disabled,
    &:disabled:hover {
        background: ${({ theme }) => theme.greyLight};
        border-color: ${({ theme }) => theme.greyLight};
        cursor: default;
    }
    
    &:hover {
        background: ${({ theme }) => theme.greyLightest};
    }
    
    ${({ small, theme }) => small && `
        padding: 3px 9px;
        font-weight: 300;
    `}
    
    ${({ chunky, theme }) => chunky && `
        width: 100%;
        height: 53px;
        text-align: center;
        letter-spacing: 1.1px;
        font-size: 16.5px;
        line-height: 19px;
        text-transform: uppercase;
        border-radius: ${theme.radius.smaller};
    `}

    ${({ chunkyish, theme }) => chunkyish && `
        height: auto;
        line-height: 54px;
        // width: 190px;
        padding: 0 15px;
        border-radius: ${theme.radius.smaller};
    `}
    
    ${({ minWidth, theme }) => minWidth && `
        min-width: 80px;
    `};

    ${({ blue, theme }) => blue && `
        border-color: ${theme.blue};
        background: ${theme.blue};
        color: ${theme.white};
        
        &:hover {
            background: ${theme.blueLight};
            color: ${theme.white};
        }
    `};

    ${({ green, theme }) => green && `
        border-color: ${theme.greenDark};
        background: ${theme.greenDark};
        color: ${theme.white};
        
        &:hover {
            background: ${theme.greenDarkLight};
            color: ${theme.white};
        }
    `};

    ${({ blueLight, hollow, theme }) => blueLight && `
        border-color: ${theme.blueLight};
        background: ${hollow ? 'none' : theme.blueLight};
        color: ${hollow ? theme.blueLight : theme.white};
        
        &:hover {
            background: ${hollow ? theme.blueLight : theme.blueLighter};
            color: ${theme.white};
        }
    `};

    ${({ grey, theme }) => grey && `
        border-color: ${theme.lightText};
        background: ${theme.lightText};
        color: ${theme.white};
        
        &:hover {
            background: ${theme.greyLight};
            color: ${theme.white};
        }
    `};

    ${({ red, theme }) => red && `
        border-color: ${theme.red};
        background: ${theme.red};
        color: ${theme.white};
        
        &:hover {
            background: ${theme.redLight};
            color: ${theme.white};
        }
    `};
    
    ${({ full, theme }) => full && `
        width: 100%;
    `}
    
    ${({ shadow, theme }) => shadow && `
        box-shadow: ${theme.boxShadow.main};
        color: ${theme.blue};
        
        &:hover {
            background: ${theme.blue};
            border-color: ${theme.blue};
            color: ${theme.white};
        }
    `}
    
    ${({ block, theme }) => block && `
        margin-top: 10px;
    `}
    
    ${({ fetching, theme }) => fetching && `
        /* TODO: Fetching styles. Delay, similar to Loading? */
    `}
`;

const Button = ({ disabled, ...props }) => (
    <StyledButton
		{...props}
        disabled={props.fetching || disabled}
    >{ props.children }</StyledButton>
)

export default Button;