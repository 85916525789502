import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCustomers } from '../../actions/customers';

import { InputSuggest } from '../../components/Forms';

class CustomerInputContainer extends Component {
    state = {
        name: '',
        id: null,
        customerSuggestions: [],
    }

    componentDidMount() {
        this.props.getCustomers();
    }

    componentDidUpdate(prevProps, prevState) {
        let { updateCustomerId } = this.props;

        if(prevState.id !== this.state.id) {
            updateCustomerId(this.state.id);
        }
    }

    updateCustomer = (name) => {
        let { customers } = this.props;

        let match = customers.find(c => c.name === name);

        this.setState({
            name,
            id: match ? match.id : null
        });
    }

    fetchCustomerSuggestions = (search) => {
        let { customers } = this.props;

        this.setState({
            customerSuggestions: !search ? [] : customers.filter(c => c.name.includes(search))
        });
    }

    handleChange = e => {
        this.setState({
            name: e.target.value
        });
    }

    render() {
        let {
            name,
            customerSuggestions,
        } = this.state;

        return(
            <InputSuggest
                fetchSuggestions={this.fetchCustomerSuggestions}
                suggestions={customerSuggestions}
                onChange={this.updateCustomer}
                value={name}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        customers: state.customers.data,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getCustomers }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerInputContainer));