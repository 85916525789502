import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Input } from './';
import { validateTime } from '../../utils/validation';

const StyledInput = styled(Input)`
    width: 75px;
    
    &:focus[value=""] {
        outline: ${({ theme }) => `1px outset ${theme.red}!important`};
    }

    ${({ valid, theme }) => !valid && `
        &:not([value=""]),
        &:focus {
            outline: 1px outset ${theme.red}!important};
        }
    `}
`;

class TimeInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valid: !!validateTime(props.value),
        }
    }

    // Send value to parent component, but handle validation here.
    handleChange = e => {
        let { value } = e.target,
            valid = !!validateTime(value);

        this.setState({ valid });

        this.props.onChange({ value, valid });
    }

    render() {
        let {
            value,
            innerRef,
            onChange,
            ...props
        } = this.props;

        return(
            <StyledInput
                value={this.props.value}
                onChange={this.handleChange}
                placeholder={'0:00'}
                valid={this.state.valid ? 1 : 0}
                innerRef={this.props.innerRef}
                type={"text"}
                data-testid={'input'}
                {...props}
            />
        );
    }
}

TimeInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    innerRef: PropTypes.object,
}

TimeInput.defaultProps = {
    value: ''
}

export default TimeInput;