import styled from 'styled-components';

export default styled.table`
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    border: ${({ theme }) => theme.border.main};
    
    table {
        border: 0;
    }
`;