import theme from '../config/theme';

export default `
    html {
        box-sizing: border-box;
    }
    *, *:before, *:after {
        box-sizing: inherit;
    }
    
    body {
        display: flex;
        min-height: 100vh;
        margin: 0;
        padding: 0;
        color: ${theme.darkText};
        font-family: 'Roboto', sans-serif;
        font-size: ${theme.fontSize.body};
        line-height: 175%;
        
		* {
            color: inherit;
            font-family: inherit;
        }
    }
    
    #root {
        display: flex;
        width: 100%;
        
        >* {
            width: 100%;
        }
	}
	
	.sr-only {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0,0,0,0);
		border: 0;
	}
    
    svg {
        max-width: 100%;
        height: auto;
    }
    
    body:not(.user-is-tabbing) *:focus {
        outline: 0;
    }
    
    a {
        text-decoration: none;
    }
`;