import React, { Fragment } from 'react';
import MediaQuery from 'react-responsive';

import { CardSlider } from '../../components/Cards';
import {
    DataTable,
    TableCard,
} from '../../components/Tables';

import { isCaseClosed } from '../../utils/validation';
import theme from '../../config/theme';

const CasesTable = ({ cases, columns, meta, page, fetching, noDataButtonLink, onPageChange, onPageSizeChange, onSortChange }) => (
    <Fragment>
        <MediaQuery query={'(max-width: 700px)'}>
            <CardSlider
                total={cases.length}
                emptyText={"There are no cases."}
            >
                { cases.map(c => (
                    <TableCard
                        data={c}
                        columns={columns}
                        firstColTitle={true}
                        key={`tableCard${c.id}`}
                    />
                )) }
            </CardSlider>
        </MediaQuery>

        <MediaQuery query={'(min-width: 701px)'}>
            <DataTable
                data={cases}
                columns={columns}
                total={meta.total}
                pages={meta.last_page}
                page={page - 1}
                limit={Number(meta.per_page)}
                viewingFrom={meta.from}
                viewingTo={meta.to}
                manual={true}
                getTdProps={(state, rowInfo) => {
                    if(!rowInfo) return {}

                    return{
                        style: {
                            opacity: isCaseClosed(rowInfo.original.status) ? theme.opacity.inactive : '1'
                        }
                    }
                }}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                onSortChange={onSortChange}
                fetching={fetching}
                noDataText={'There are no cases.'}
                noDataButtonText={'add a new case'}
                noDataButtonLink={noDataButtonLink}
            />
        </MediaQuery>
    </Fragment>
);

export default CasesTable;
