import React from 'react';
import styled from 'styled-components';

import { media } from '../../../utils/style';

const LogWrap = styled.div`
    position: relative;
    max-height: 370px;
    padding: 15px 72px 0 36px;
    overflow-y: auto;
    
    ${media.medium`
        padding: 0;
    `}
`;

const Log = React.forwardRef(({ children }, ref) => (
    <LogWrap innerRef={ref}>{ children }</LogWrap>
));

export default Log;