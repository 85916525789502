const defaultVal = {
    message: '',
    type: ''
}

export default (state = defaultVal, action = {}) => {

    const { type, success, alert, error } = action;

    if (type === 'RESET_ALERT') {
        return defaultVal;
    } else if (success) {
        return { message: success, type: 'success' };
    } else if (alert) {
        return { message: alert, type: '' };
    } else if (error) {
        return { message: error, type: 'danger' };
    }

    return state;

}