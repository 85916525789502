import React from 'react';
import styled from 'styled-components';

const IconCircleWrap = styled.div`
    position: relative;
    width: 0;
    height: 0;
    padding: ${({ size }) => size === 'lg' ? '20px' : '11px'};
    border-radius: 50%;
    border: 1px solid ${({ styleType, theme, disabled }) => styleType === 'b' && !disabled ? theme.white : theme.blue };
    background: ${({ theme }) => theme.blue};
    transition: ${({ theme }) => theme.duration.quick};
    
    svg {
        position: absolute;
        width: 100%;
        max-width: 60%;
        max-height: 60%;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
    }
    
    path {
        fill: ${({ theme }) => theme.white};
    }

    ${({ inactive, theme, styleType }) => {
        if(inactive) {
            if(styleType === 'b') {
                return`
                    &:not(:hover) {
                        border-color: transparent;
                    }
                `;
            } else {
                return`
                    opacity: .25;
                    background: ${theme.white};

                    path {
                        fill: ${theme.blue}!important;
                    }
                `;
            }
        }
    }}
    
    ${({ inactive, styleType }) => inactive && styleType === 'b' && `
        &:not(:hover) {
            border-color: transparent;
        }
    `}
    
    ${({ disabled, theme }) => disabled && `
        path {
            fill: ${theme.greyLighter}!important;
            opacity: .4;
        }
    `}
    
    ${({ pending, theme }) => pending && `
        background: ${theme.blueLightest};
        border-color: ${theme.blueLightest};
    `}
`;

const IconCircle = ({ children, ...props }) => {
    return(
        <IconCircleWrap {...props}>
            { children }
        </IconCircleWrap>
    );
}

export default IconCircle;