export const removeAlerts = () => {
    return (dispatch, getState) => {
        dispatch({
            type: 'RESET_ALERT'
        });
    }
}

export const flashAlert = (message, type) => {
    return ( dispatch ) => {
        dispatch({ type: 'FLASH_ALERT', [type]: message });
    }
}