import { get, checkAuthentication } from '../utils/api';
import { validateParams, dispatchErrors } from '../utils/validation';

export const getVehicles = (params) => {

    // Remove any invalid parameters
    let validatedParams = validateParams(['searchQuery', 'sort', 'sortBy', 'page', 'limit'], params);

    return (dispatch) => {

        dispatch({type: 'FETCH_VEHICLES'});

        return get('/vehicles', validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then((data) => {
                dispatch({type: 'FETCH_VEHICLES_SUCCESS', vehicles: data, params: validatedParams})
            })
            .catch(err => {
                dispatch({type: 'FETCH_VEHICLES_FAIL'});
                return dispatchErrors(err, dispatch);
            })

    }

}