import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { requestPasswordReset } from '../../actions/user';
import { Input, ChunkyForm } from '../../components/Forms';
import { Button } from '../../components/Buttons';

class ResetPasswordContainer extends Component {
    state = {
        email: '',
    }

    componentDidUpdate(prevProps) {
        if((prevProps.saving && !this.props.saving) && !this.props.error) {
            this.props.history.push('/login');
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();

        this.props.requestPasswordReset(this.state);

        return false;
    }

    handleChange = e => {
        this.setState({
            [e.target.id]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
        });
    }

    render() {
        let { email } = this.state,
            { saving } = this.props;

        return(
            <ChunkyForm onSubmit={this.handleSubmit}>
                <p>Enter your email address and we will send you an email with further instructions for resetting your password.</p>
                <Input
                    type="email"
                    value={email}
                    onChange={this.handleChange}
                    placeholder={"Email address"}
                    id={'email'}
                    srLabel={'Email address'}
                    chunky={true}
                />
                <Button
                    chunky={true}
                    blue={true}
                    disabled={saving}
                >Submit</Button>
            </ChunkyForm>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        saving: state.user.saving,
        error: state.user.error,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ requestPasswordReset }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPasswordContainer));