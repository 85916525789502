import { get, checkAuthentication } from '../utils/api';
import { validateParams, dispatchErrors } from '../utils/validation';

export const getPaymentMethods = (params = {}) => {

    // Remove any invalid parameters
    let validatedParams = validateParams(['limit', 'page', 'sort'], params);

    return (dispatch) => {

        dispatch({ type: 'FETCH_PAYMENT_METHODS' });

        return get('/payment-methods', validatedParams, true)
            .catch(checkAuthentication.bind(null, dispatch))
            .then((data) => {
                dispatch({ type: 'FETCH_PAYMENT_METHODS_SUCCESS', paymentMethods: data, params: validatedParams })
            })
            .catch(err => {
                dispatch({ type: 'FETCH_PAYMENT_METHODS_FAIL' });
                return dispatchErrors(err, dispatch);
            })

    }

}