import React from 'react';
import styled from 'styled-components';
import { withRouter, Link } from 'react-router-dom';

import { PageWithCenteredContent } from '../components/Layout';
import { PageTitle } from '../components/Text';
import {
    ErrorBoundary,
    Error
} from '../components/Utils';

import {
    LoginContainer,
    ResetPasswordContainer,
    ChangePasswordContainer,
    RegisterContainer,
} from '../containers/Auth';

const LoginWrap = styled.div`
    ${PageTitle} {
        text-align: center;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: 1.65px;
        color: ${({ theme }) => theme.greyDark};
        
        @media screen and (max-width: 700px) {
            margin-bottom: 15px;
            font-size: 18px;
            letter-spacing: 0;
        }
    }
    
    >a {
        &:last-child {
            display: table;
            margin: 15px auto 0;
            text-decoration: none; 
            color: ${({ theme }) => theme.lightText};
        }
    }
`;

const Login = ({ history, location: { pathname } }) => {

    // Set content based on route.
    let title;

    let Container = LoginContainer;

    let link = {
        label: 'Back to login',
        path: '/login'
    };

    if(pathname === '/login') {
        title = 'Login to your account';

        link = {
            label: 'Forgot your password?',
            path: '/reset-password'
        }

    } else if (pathname === '/register') {
        title = 'Create an account';
        Container = RegisterContainer;
    } else if (pathname === '/reset-password') {
        title = 'Reset your password';
        Container = ResetPasswordContainer;
    } else {
        title = 'Change your password';
        Container = ChangePasswordContainer;
    }

    return(
        <PageWithCenteredContent>
            <ErrorBoundary render={() => <Error overlay={true} />}>
                <LoginWrap>
                    <PageTitle>{ title }</PageTitle>

                    <Container />

                    <Link to={link.path}>{ link.label }</Link>
                </LoginWrap>
            </ErrorBoundary>
        </PageWithCenteredContent>
    );
}

export default withRouter(Login);