import { handleActions } from 'redux-actions';

import { denestUsers } from '../utils/dataMap';

const initialState = {
    data: [],
    links: {},
    meta: {},
    page: 1,
    limit: 10,
    sort: '',
    name: '',
    available_from: null,
    available_to: null,
    role_id: null,
    fetching: true,
    deleting: false,
    saving: false,
    error: false,
    reset: false,
    exclusions: {
        data: [],
        deleted: false,
        fetching: true,
        saving: false,
        error: false,
    }
}

export default handleActions({

    FETCH_USERS: (state, action) => {
        return {
            ...state,
            fetching: true,
            error: false,
            reset: false,
        }
    },

    FETCH_USERS_SUCCESS: (state, action) => {
        return {
            ...state,
            data: action.users,
            fetching: false,
            params: action.params,
            reset: false,
        }
    },

    FETCH_USERS_FAIL: (state, action) => {
        return {
            ...state,
            fetching: false,
            error: true,
            reset: false,
        }
    },

    FETCH_USER_EXCLUSIONS: (state, action) => {
        return {
            ...state,
            exclusions: {
                ...state.exclusions,
                fetching: true,
                deleted: false,
                error: false,
            }
        }
    },

    FETCH_USER_EXCLUSIONS_SUCCESS: (state, action) => {
        return {
            ...state,
            exclusions: {
                ...state.exclusions,
                data: action.exclusions.data,
                fetching: false,
                error: false,
            }
        }
    },

    FETCH_USER_EXCLUSIONS_FAIL: (state, action) => {
        return {
            ...state,
            exclusions: {
                ...state.exclusions,
                fetching: false,
                error: true,
            }
        }
    },

    ADD_USER_EXCLUSION: (state, action) => {
        return {
            ...state,
            exclusions: {
                ...state.exclusions,
                saving: true,
                fetching: true,
                error: false,
            }
        }
    },

    ADD_USER_EXCLUSION_SUCCESS: (state, action) => {
        return {
            ...state,
            exclusions: {
                ...state.exclusions,
                data: [...state.exclusions.data, action.exclusion],
                saving: false,
                fetching: false,
                error: false,
            }
        }
    },

    ADD_USER_EXCLUSION_FAIL: (state, action) => {
        return {
            ...state,
            exclusions: {
                ...state.exclusions,
                saving: false,
                fetching: false,
                error: true,
            }
        }
    },

    DELETE_USER_EXCLUSION: (state, action) => {
        return {
            ...state,
            exclusions: {
                ...state.exclusions,
                deleted: false,
                saving: true,
                error: false,
            }
        }
    },

    DELETE_USER_EXCLUSION_SUCCESS: (state, action) => {
        return {
            ...state,
            exclusions: {
                ...state.exclusions,
                deleted: true,
                saving: false,
                error: false,
            }
        }
    },

    DELETE_USER_EXCLUSION_FAIL: (state, action) => {
        return {
            ...state,
            exclusions: {
                ...state.exclusions,
                deleted: false,
                saving: false,
                error: true,
            }
        }
    },

    ADD_USER: (state, action) => {
        return {
            ...state,
            saving: true,
            error: false,
        }
    },

    ADD_USER_SUCCESS: (state, action) => {
        return {
            ...state,
            saving: false,
        }
    },

    ADD_USER_FAIL: (state, action) => {
        return {
            ...state,
            saving: false,
            error: true,
        }
    },

    DELETE_USER: (state, action) => {
        return {
            ...state,
            deleting: action.id,
            error: false,
        }
    },

    DELETE_USER_SUCCESS: (state, action) => {
        return {
            ...state,
            deleting: false,
            data: state.data.filter(user => user.id !== action.id)
        }
    },

    DELETE_USER_FAIL: (state, action) => {
        return {
            ...state,
            deleting: false,
            error: true,
        }
    },

    REMOVE_USER_FROM_ACTION: (state, action) => {
        return {
            ...state,
            reset: true,
        }
    },

    RESET_USERS: (state, action) => {
        return {
            ...state,
            data: [],
            fetching: true,
            reset: true,
        }
    },

}, initialState)