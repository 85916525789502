const theme = {
    green: '#5fe9a5',
    greenDark: '#739B66',
    greenDarkLight: '#80A873',
    blue: '#297FCA',
    blueLight: '#3FA2F7',
    blueLighter: '#6CBBFF',
    blueLightest: '#C1E2FF',
    blueFaint: 'rgba(41,127,202, .8)',
    blueAlt: '#96CCFA',
    red: '#a22d39',
    redAlt: '#C3423F',
    redLight: '#B7313E',
    orange: '#FFBC42',
    greyDark: '#444444',
    grey: '#8a8a8a',
    greyLight: '#a9a9a9',
    greyLighter: '#e5e5e5',
    greyLightest: '#f4f4f4',
    whiteish: '#fcfcfc',
    white: '#ffffff',
    darkText: 'rgba(74, 74, 74, .8)',
    faintText: 'rgba(208, 208, 208, .4)',
    lightText: '#9b9b9b',
    lightTextAlt: '#787E8C',
    blueText: '#6B7897',
    greyBorder: 'rgba(201, 201, 201, .9)',
    greyBorderFaint: 'rgba(208,208,208,0.5)',
    error: '#a22d39',
    size: {
        xs: '479px',
        small: '40em',
        medium: '52em',
        large: '64em',
    },
    duration: {
        quickest: '.125s',
        quick: '.3s',
    },
    fontSize: {
        bodyXs: '11px',
        bodySm: '12px',
        body: '13px',
        bodyMd: '14px',
        bodyLg: '15px',
        titleSm: '16px',
        title: '18px',
    },
    opacity: {
        inactive: '.6'
    },
    radius: {
        smaller: '2.2px',
        small: '3px',
        medium: '5px',
    },
    border: {
        main: `1px solid rgba(201, 201, 201, .8)`,
        faint: `1px solid rgba(208, 208, 208, .4)`,
    },
    boxShadow: {
        main: '0 2px 4px 0 rgba(0,0,0,0.5)'
    }
}

export default theme;