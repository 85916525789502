import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCustomers, resetCustomers } from '../../actions/customers';
import { AsyncSelect } from '../../components/Forms';

class CustomerDropdownContainer extends Component {
    componentDidMount() {
        this.props.resetCustomers();
    }

    checkForValidValue = (value) => {
        let match = this.props.customers.find(type => type.name === value);

        return match && match.id;
    }

    updateInput = value => {
        // If coming from onChange event it's an object.
        let data = value;

        // It's a string from onInputChange. Check if this is a valid option, by finding an ID.
        if(typeof data === 'string') {
            data = {
                name: data,
                id: this.checkForValidValue(data),
            }
        }

        // onBlur for some reason triggers an onInputChange with an empty string, so skip the change if it's an empty string.
        if(!data.name) return;

        this.props.onChange(data);
    }

    getCustomers = () => {
        let { customers, getCustomers, params } = this.props;

        if(!!customers.length) return;

        getCustomers(params);
    }

    render() {
        let { fetching, customers, value } = this.props;

        return(
            <AsyncSelect
                value={value}
                options={customers}
                onMenuOpen={this.getCustomers}
                isLoading={fetching}
                allowCreateWhileLoading={fetching}
                onChange={this.updateInput}
                onInputChange={this.updateInput}
                onSelectResetsInput={false}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                getOptionLabel={item => item.name}
                getOptionValue={item => item.name}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        customers: state.customers.data,
        fetching: state.customers.fetching,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getCustomers, resetCustomers }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerDropdownContainer));