import { handleActions } from 'redux-actions';

import { updateItem } from '../utils/dataMap';

const initialState = {
    data: {
        details: {
            data: {},
            fetching: true,
            saving: false,
            error: '',
        },
        cases: {
            data: [],
            links: {},
            meta: {},
            page: 1,
            limit: 10,
            sort: '',
            sortBy: '',
            searchQuery: '',
            fetching: true,
            archived: false,
            potential: false,
            mine_only: false,
            error: '',
        },
        documents: {
            data: [],
            fetching: true,
            saving: false,
            deleting: false,
            error: '',
        },
        contacts: {
            data: [],
            fetching: true,
            saving: false,
            error: '',
        },
    },
}

export default handleActions({

    FETCH_CUSTOMER: (state, action) => {
        return updateItem(state, 'details', {
            fetching: true,
            error: false,
        })
    },

    FETCH_CUSTOMER_SUCCESS: (state, action) => {
        return updateItem(state, 'details', {
            data: action.customer,
            fetching: false,
        })
    },

    FETCH_CUSTOMER_FAIL: (state, action) => {
        return updateItem(state, 'details', {
            fetching: false,
            error: action.status === 404 ? 'notFound' : true
        })
    },

    ADD_CUSTOMER: (state, action) => {
        return updateItem(state, 'details', {
            fetching: true,
            error: false,
        })
    },

    ADD_CUSTOMER_SUCCESS: (state, action) => {
        return updateItem(state, 'details', {
            data: action.customer,
            fetching: false,
        })
    },

    ADD_CUSTOMER_FAIL: (state, action) => {
        return updateItem(state, 'details', {
            fetching: false,
            error: true,
        })
    },

    UPDATE_CUSTOMER: (state, action) => {
        return updateItem(state, 'details', {
            saving: true,
            error: false,
        })
    },

    UPDATE_CUSTOMER_SUCCESS: (state, action) => {
        return updateItem(state, 'details', {
            data: action.customer,
            saving: false,
        })
    },

    UPDATE_CUSTOMER_FAIL: (state, action) => {
        return updateItem(state, 'details', {
            saving: false,
            error: true,
        })
    },

    FETCH_CUSTOMER_CASES: (state, action) => {
        return updateItem(state, 'cases', {
            fetching: true,
            error: false,
        })
    },

    FETCH_CUSTOMER_CASES_SUCCESS: (state, action) => {
        let {
            status,
            ...params
        } = action.params;

        let newState = {
            fetching: false,
            data: action.cases.data,
            links: action.cases.links,
            meta: action.cases.meta,
            page: action.cases.meta.current_page,
            limit: action.cases.meta.per_page,
            archived: status && status.includes('closed,invoiced'),
            potential: status && status.includes('potential'),
            ...params
        }

        // If there's no searchQuery provided, reset it to an empty string, in case an old value is still in the store.
        if(!('searchQuery' in action.params)) {
            newState.searchQuery = '';
        }

        return updateItem(state, 'cases', {
            ...newState
        })
    },

    FETCH_CUSTOMER_CASES_FAIL: (state, action) => {
        return updateItem(state, 'cases', {
            fetching: false,
            error: true,
        })
    },

    FETCH_CUSTOMER_DOCUMENTS: (state, action) => {
        return updateItem(state, 'documents', {
            fetching: true,
            error: false,
        })
    },

    FETCH_CUSTOMER_DOCUMENTS_SUCCESS: (state, action) => {
        return updateItem(state, 'documents', {
            data: action.documents,
            fetching: false,
        })
    },

    FETCH_CUSTOMER_DOCUMENTS_FAIL: (state, action) => {
        return updateItem(state, 'documents', {
            fetching: false,
            error: true,
        })
    },

    ADD_CUSTOMER_DOCUMENTS: (state, action) => {
        return updateItem(state, 'documents', {
            saving: true,
            error: false,
        })
    },

    ADD_CUSTOMER_DOCUMENTS_SUCCESS: (state, action) => {
        return updateItem(state, 'documents', {
            data: action.documents,
            saving: false,
        })
    },

    ADD_CUSTOMER_DOCUMENTS_FAIL: (state, action) => {
        return updateItem(state, 'documents', {
            saving: false,
            error: true,
        })
    },

    DELETE_DOCUMENT: (state, action) => {
        return updateItem(state, 'documents', {
            deleting: true,
            error: false,
        })
    },

    DELETE_DOCUMENT_SUCCESS: (state, action) => {
        return updateItem(state, 'documents', {
            data: state.data.documents.data.filter(doc => doc.id !== action.id),
            deleting: false,
        })
    },

    DELETE_DOCUMENT_FAIL: (state, action) => {
        return updateItem(state, 'documents', {
            deleting: false,
            error: true
        })
    },

    FETCH_CUSTOMER_CONTACTS: (state, action) => {
        return updateItem(state, 'contacts', {
            fetching: true,
            error: false,
        })
    },

    FETCH_CUSTOMER_CONTACTS_SUCCESS: (state, action) => {
        return updateItem(state, 'contacts', {
            data: action.contacts,
            fetching: false,
        })
    },

    FETCH_CUSTOMER_CONTACTS_FAIL: (state, action) => {
        return updateItem(state, 'contacts', {
            fetching: false,
            error: true,
        })
    },

    ADD_CUSTOMER_CONTACT: (state, action) => {
        return updateItem(state, 'contacts', {
            saving: true,
            error: false,
        })
    },

    ADD_CUSTOMER_CONTACT_SUCCESS: (state, action) => {
        return updateItem(state, 'contacts', {
            data: [...state.data.contacts.data, action.contact],
            fetching: false,
            saving: false,
        })
    },

    ADD_CUSTOMER_CONTACT_FAIL: (state, action) => {
        return updateItem(state, 'contacts', {
            saving: false,
            error: true,
        })
    },

    // DELETE_DOCUMENT: (state, action) => {
    //     return updateItem(state, 'documents', {
    //         fetching: true,
    //     })
    // },

    DELETE_CUSTOMER_CONTACT_SUCCESS: (state, action) => {
        return updateItem(state, 'contacts', {
            data: state.data.contacts.data.filter(c => c.id !== action.id),
            fetching: false,
        })
    },

    DELETE_CUSTOMER_CONTACT_FAIL: (state, action) => {
        return updateItem(state, 'contacts', {
            fetching: false,
            error: true
        })
    },

    RESET_CUSTOMER: (state, action) => {
        return initialState;
    },

}, initialState)