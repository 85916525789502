import React from 'react';

import { PageWithMainNav, PageTitleWrap } from '../components/Layout';
import { PageTitle } from '../components/Text';
import { FormGroup } from '../components/Forms';
import {
    Error,
    ErrorBoundary,
    ScrollToTopOnMount,
} from '../components/Utils';
import { BackButton } from '../components/Buttons';
import {
    CustomerDetailsContainer,
    CustomerContactsContainer,
    CustomerDocumentsContainer,
} from '../containers/Customer';
import { CasesContainer } from '../containers/Case';

const Customer = () => {
    return(
        <PageWithMainNav>
            <ScrollToTopOnMount />

            <PageTitleWrap>
                <PageTitle>Edit customer</PageTitle>
                <BackButton
                    to={'/customers'}
                    small={true}
                    showLabel={true}
                    label={'Return to customers'}
                />
            </PageTitleWrap>

            <ErrorBoundary render={() => <Error overlay={false} />}>
                <CustomerDetailsContainer />
            </ErrorBoundary>

            <ErrorBoundary render={() => <Error overlay={false} />}>
                <CustomerDocumentsContainer />
            </ErrorBoundary>

            <ErrorBoundary render={() => <Error overlay={false} />}>
                <CustomerContactsContainer />
            </ErrorBoundary>

            <FormGroup
                title={'Cases'}
                border={'top'}
            >
                <CasesContainer isCustomer={true} />
            </FormGroup>
        </PageWithMainNav>
    );
}

export default Customer;