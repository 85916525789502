import styled from 'styled-components';

export default styled.table`
    width: 100%;
    border-collapse: collapse;
    
    td,
    th {
        padding: 7px 0;
        text-align: left;
    }

    tr {
        td {
            border-top: 1px solid ${({ theme }) => theme.greyLighter};
        }
       
    }
    
    thead {
        //border-top: 1px solid ${({ theme }) => theme.greyLighter};
    }
    
    tbody {
        //border-bottom: 1px solid ${({ theme }) => theme.greyLighter};
    }
    
    .small-row {
        td {
            padding: 4px 0;
            font-size: 12px;
            
            &:first-of-type {
                padding-left: 15px;
            }
        }
    }
`;
