import React, { Component, Fragment } from 'react';

import {
    FormGroup,
    FormItemGroup,
    FormItem,
    Input,
} from '../Forms';
import { ModalButtons } from '../Modals';
import { Button } from '../Buttons';
import { Link } from '../Text';

import { getEventKey, getEventVal } from '../../utils/dataMap';
import { allTrue } from '../../utils/validation';

const updateContactState = (data) => {
    return {
        name: data['name'] || '',
        email: data['email'] || '',
    }
}

class ContactForm extends Component {
    constructor(props) {
        super(props);

        let {
            id,
            onSave,
            onCancel,
            ...fields
        } = props;

        this.state = {
            hasChanged: false,
            id: props.id || null,
            editing: !props.id,
            form: updateContactState(fields),
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // If the data was saved, with no errors, clear the form.
        if((prevProps.saving && !this.props.saving) && !this.props.error) {
            this.clearForm();
        }
    }

    handleChange = (e, k) => {
        let key = k || getEventKey(e);

        this.setState({
            hasChanged: true,
            form: {
                ...this.state.form,
                [key]: getEventVal(e)
            },
        });
    }

    handleSave = (stayOpen) => {
        let { id, form } = this.state;

        this.props.onSave(id, form, stayOpen);
    }

    clearForm = () => {
        this.setState({
            id: null,
            hasChanged: false,
            form: updateContactState({})
        });
    }

    render() {
        let {
            hasChanged,
            form: {
                name,
                email,
            }
        } = this.state;

        let { onCancel, saving } = this.props;

        let requiredPopulated = allTrue([name, email]);

        return(
            <Fragment>
                <FormGroup center={true}>
                    <FormItemGroup labelWidth={"120px"} wide={true}>
                        <FormItem
                            label={"Name"}
                            id={'name'}
                            minWidthInput={true}
                            required={true}
                        >
                            <Input
                                value={name}
                                id={'name'}
                                type={"text"}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem
                            label={"Email"}
                            id={'email'}
                            minWidthInput={true}
                            required={true}
                        >
                            <Input
                                value={email}
                                id={'email'}
                                type={"email"}
                                onChange={this.handleChange}
                            />
                        </FormItem>
                    </FormItemGroup>
                </FormGroup>

                <ModalButtons pl={'120px'} wide={true}>
                    <Button
                        small={true}
                        blue={true}
                        onClick={() => this.handleSave(false)}
                        disabled={saving || !hasChanged || !requiredPopulated}
                    >Save</Button>
                    <Button
                        small={true}
                        blue={true}
                        onClick={() => this.handleSave(true)}
                        disabled={saving || !hasChanged || !requiredPopulated}
                    >Save and add another</Button>
                    <Link el={'button'} onClick={onCancel}>Cancel</Link>
                </ModalButtons>
            </Fragment>
        );
    }
}

export default ContactForm;