import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getCaseLog, addCaseComment } from '../../actions/cases';

import ActivityLog from '../../components/ActivityLog';
import { FormGroup } from '../../components/Forms';
import {
    Loading,
    Error,
    ErrorBoundary,
} from '../../components/Utils';

class CaseActivityLogContainer extends Component {
    componentDidMount() {
        this.props.getCaseLog(this.props.match.params.id);
    }

    handleSubmit = (data) => {
        this.props.addCaseComment(this.props.match.params.id, data);
    }

    render() {
        let {
            loggedIn,
            details,
            activityLog,
            fetching,
            sending,
            sent,
            error,
        } = this.props;

        if(!loggedIn) return <Redirect to='/login' />

        if(fetching) return <Loading />

        let allowComments = details && details.source.comments;

        return(
            <FormGroup
                title={"Activity Log"}
                background={'title'}
                border={'all'}
                id="activity-log"
            >
                <ErrorBoundary render={() => <Error withIcon={true} />}>
                    <ActivityLog
                        onSubmit={this.handleSubmit}
                        entries={activityLog}
                        sending={sending}
                        sent={sent}
                        error={error}
                        disableComments={!allowComments}
                    />
                </ErrorBoundary>
            </FormGroup>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        details: state.case.data.details.data,
        activityLog: state.case.data.activityLog.data,
        fetching: state.case.data.activityLog.fetching,
        sending: state.case.data.activityLog.comment.sending,
        sent: state.case.data.activityLog.comment.sent,
        error: state.case.data.activityLog.error,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getCaseLog, addCaseComment }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseActivityLogContainer));