import { handleActions } from 'redux-actions';

const initialState = {
    data: [],
    links: {},
    meta: {},
    page: 1,
    limit: 10,
    sort: '',
    sortBy: '',
    mine_only: true,
    archived: false,
    potential: false,
    searchQuery: '',
    fetching: true,
    saving: false,
    error: false,
}

export default handleActions({

    FETCH_CASES: (state, action) => {
        return {
            ...state,
            fetching: true,
            error: false,
        }
    },

    FETCH_CASES_SUCCESS: (state, action) => {
        let {
            status,
            ...params
        } = action.params;

        let newState = {
            ...state,
            fetching: false,
            data: action.cases.data,
            links: action.cases.links,
            meta: action.cases.meta,
            page: action.cases.meta.current_page,
            limit: action.cases.meta.per_page,
            archived: status && status.includes('closed,invoiced'),
            potential: status && status.includes('potential'),
            ...params
        }

        // If there's no searchQuery provided, reset it to an empty string, in case an old value is still in the store.
        if(!('searchQuery' in action.params)) {
            newState.searchQuery = '';
        }

        return newState;
    },

    FETCH_CASES_FAIL: (state, action) => {
        return {
            ...state,
            fetching: false,
            error: true
        }
    },

}, initialState)
