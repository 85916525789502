import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTimeout from 'react-timeout';
import styled from 'styled-components'
import ReactLoading from 'react-loading';

import theme from '../../config/theme';
import { overlayStyle } from '../../utils/style';

const LoadingOverlay = styled.div`
    >div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 25px auto;
    }
    
    svg {
        width: 30px;
    }

    ${({ overlay, align }) => overlay && `
        ${overlayStyle()};
        
        >div {
            position: absolute;
            left: 50%;
            top: ${align === 'top' ? '15%' : '50%'};
            transform: translateX(-50%) ${align === 'top' ? '' : 'translateY(-50%)'};
            margin: 0;
        }
    `};
    
`;

// Delay loading state. Looks better for quick-loading stuff.
class Loading extends Component {
    state = {
        show: false
    }

    componentDidMount() {
        this.props.setTimeout(() => {
            this.setState({
                show: true
            });
        }, 350);
    }

    render() {
        if(!this.state.show && this.props.delay) return null;

        return(
            <LoadingOverlay align={this.props.align} overlay={this.props.overlay}>
                <ReactLoading type={"spin"} color={theme.blue} />
            </LoadingOverlay>
        );
    }
}

Loading.propTypes = {
    // Use this in situations where showing nothing is better UX, rather than a quick Loading flash.
    delay: PropTypes.bool,
    overlay: PropTypes.bool,
    align: PropTypes.oneOf(['top', 'center']),
}

Loading.defaultProps = {
    align: 'center',
    delay: true,
}

export default ReactTimeout(Loading);