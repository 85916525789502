import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { addCase } from '../../actions/cases';
import { getCustomers } from '../../actions/customers';
import { CustomerDropdownContainer } from '../Customer';
import { SourcesDropdownContainer } from './';

import {
    Input,
    FormItem,
    FormItemGroup,
    FormGroup,
    ToggleSwitch,
} from '../../components/Forms';
import { Loading, Error, ErrorBoundary } from '../../components/Utils';
import { Button } from '../../components/Buttons';

import { getEventVal } from '../../utils/dataMap';

class NewCaseContainer extends Component {
    constructor(props) {
        super(props);

        let { state } = props.location;

        let customer = {
            name: (state && state.customer && state.customer.name) || '',
            id: (state && state.customer && state.customer.id) || null
        }

        this.state = {
            form: {
                customer,
                opTitle: '',
                source: {},
                is_potential: false,
            },
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { details, history } = this.props;

        if(details && details.id) {
            history.push(`/cases/${details.id}`);
        }
    }

    handleSubmit = e => {
        let { form: { customer, opTitle, source, is_potential } } = this.state;

        e.preventDefault();

        this.props.addCase(customer.id, {
            title: opTitle,
            source_id: source.id,
            is_potential
        });

        return false;
    }

    handleChange = (e, id) => {
        let targetId = id;

        if(!targetId) {
            targetId = e.target.id;
        }

        this.setState({
            form: {
                ...this.state.form,
                [targetId]: id ? e : getEventVal(e)
            }
        });
    }

    render() {
        let {
            loggedIn,
            saving,
        } = this.props;

        let {
            form: {
                customer,
                opTitle,
                source,
                is_potential
            },
        } = this.state;

        if(!loggedIn) return <Redirect to='/login' />

        return(
            <form onSubmit={this.handleSubmit}>
                <FormGroup
                    title={"Case details"}
                    border="all"
                    background="all"
                    padChildren={true}
                    padBottom={true}
                    id={"case-details"}
                >
                    <ErrorBoundary render={() => <Error overlay={true} />}>
                        <FormItemGroup labelWidth={"135px"}>
                            <FormItem label={"Customer"} id={'customer'} required={true}>
                                <CustomerDropdownContainer
                                    value={customer}
                                    onChange={data => this.handleChange(data, 'customer')}
                                    params={{ limit: 100 }}
                                />
                            </FormItem>

                            <FormItem label={"WRi Operation Title"} id={'opTitle'} required={true}>
                                <Input
                                    value={opTitle}
                                    id={'opTitle'}
                                    name={'opTitle'}
                                    type={"text"}
                                    onChange={this.handleChange}
                                />
                            </FormItem>

                            <FormItem label={"Source"} id={'source'} required={true}>
                                <SourcesDropdownContainer
                                    value={source}
                                    id={'source'}
                                    onChange={e => this.handleChange(e, 'source')}
                                />
                            </FormItem>

                            <FormItem label={"Potential Case"} id={'is_potential'}>
                                <ToggleSwitch
                                    checked={is_potential}
                                    id={"is_potential"}
                                    onChange={this.handleChange}
                                />
                            </FormItem>
                        </FormItemGroup>

                        <Button
                            small={true}
                            blue={true}
                            disabled={!customer.id || !opTitle || saving}
                        >Save</Button>

                        { saving && <Loading overlay={true} /> }
                    </ErrorBoundary>
                </FormGroup>
            </form>
        );

    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        customers: state.customers.data,
        fetching: state.customers.fetching,
        details: state.case.data.details.data,
        saving: state.case.saving,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ addCase, getCustomers }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(NewCaseContainer));