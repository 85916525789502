import React from 'react';
import styled from 'styled-components';

const Filters = ({ children }) => {
    return(
        <FiltersWrap>
            { children }
        </FiltersWrap>
    );
}

const FiltersWrap = styled.nav`
    +* {
        margin-top: 13px;
    }
    
    .filters__bottom {
        display: flex;
        align-items: center;
    }
    
    .filters__download {
        margin: 0 0 0 auto;
        
        button {
            display: flex;
            align-items: center;
        }
        
        svg {
            width: 11px;
            margin-left: 8px;
            fill: ${({ theme }) => theme.white};
        }
    }
    
    @media screen and (max-width: 700px) {
        +* {
            margin-top: 30px;
        }
        
        .filters__bottom {
            display: flex;
            //justify-content: flex-end;
            
            strong {
                margin-right: auto;
            }
        }
    }
    
    @media screen and (max-width: 539px) {
        .filters__bottom {
            align-items: flex-end;
            flex-wrap: wrap;
        }
        
        .filters__download {
            width: 100%;
            margin: 15px 0 0 0;
        }
    
        .filters__toggles {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 20px;
            order: -1;
            
            >div {
                //flex-basis: 100%;
                justify-content: flex-end;
                
                +div {
                    //margin-top: 15px;
                }
            }
        }
    }
`;

export default Filters;