import React, { Component, Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    getCustomerContacts,
    addCustomerContact,
    updateCustomerContacts,
    updateCustomerContact,
    deleteCustomerContact,
} from '../../actions/customers';

import { UserHasPermissionContainer } from '../User';
import { FormGroup } from '../../components/Forms';
import {
    Modal,
    ModalContent,
} from '../../components/Modals';
import {
    DragContacts,
    ContactForm,
} from '../../components/Contacts';
import {
    Error,
    ErrorBoundary,
    Loading,
} from '../../components/Utils';

class CaseContactsContainer extends Component {
    state = {
        modalOpen: false,
        editing: null,
        stayOpen: false,
    }

    componentDidMount() {
        this.props.getCustomerContacts(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        // If we have saved successfully with no errors and 'add another' hasn't been chosen, close the modal.
        if((prevProps.saving && !this.props.saving) && !this.props.error && !this.state.stayOpen) {
            this.setState({
                modalOpen: false,
                editing: null,
                stayOpen: false,
            });
        }
    }

    updateContacts = (newItems) => {
        this.props.updateCustomerContacts(this.props.match.params.id, newItems.map(item => item.id));
    }

    removeContact = (id) => {
        if(window.confirm('Are you sure you want to delete this contact?')) {
            this.props.deleteCustomerContact(this.props.match.params.id, id);
        }
    }

    saveContact = (id, params, stayOpen = false) => {
        let { match } = this.props;

        if(id) {
            this.props.updateCustomerContact(id, params);
        } else {
            // Contact needs to be created.
            this.props.addCustomerContact(match.params.id, params);
        }

        this.setState({ stayOpen });
    }

    openEditContactModal = (id) => {
        this.setState({
            modalOpen: true,
            editing: this.props.contacts.find(item => item.id === id)
        });
    }

    openNewContactModal = () => {
        this.setState({
            modalOpen: true,
            editing: null
        });
    }

    closeModal = () => {
        this.setState({
            modalOpen: false,
            editing: false,
        });
    }

    render() {
        let {
            loggedIn,
            contacts,
            fetching,
            saving,
            error,
            self,
        } = this.props;

        let { modalOpen, editing } = this.state;

        let contactIds = contacts.map(contact => contact.id);

        if(!loggedIn) return <Redirect to='/login' />

        return(
            <UserHasPermissionContainer
                self={self}
                permissions={'customers:write'}
            >
                <FormGroup
                    title={"Associated Contacts"}
                    id={"case-contacts"}
                    saving={saving}
                    border={'top'}
                    noMinHeight={true}
                >
                    { fetching ? <Loading /> :
                        <ErrorBoundary render={() => <Error overlay={true} />}>
                            <DragContacts
                                items={contacts}
                                onClick={this.openEditContactModal}
                                onAddNew={this.openNewContactModal}
                                onMove={this.updateContacts}
                                onDelete={this.removeContact}
                            />
                        </ErrorBoundary>
                    }
                </FormGroup>

                <Modal
                    isOpen={modalOpen}
                    onRequestClose={this.closeModal}
                >
                    <ModalContent title={`${editing ? 'Edit' : 'Add'} Associated Contact`}>
                        <ErrorBoundary render={() => <Error overlay={true} />}>
                            <ContactForm
                                {...editing}
                                onSave={this.saveContact}
                                onCancel={this.closeModal}
                                exclude={contactIds}
                                saving={saving}
                                error={error}
                            />
                        </ErrorBoundary>
                    </ModalContent>
                </Modal>
            </UserHasPermissionContainer>
        );
    }
}

const mapStateToProps = state => {
    return {
        self: state.user.self,
        loggedIn: state.user.loggedIn,
        contacts: state.customer.data.contacts.data,
        fetching: state.customer.data.contacts.fetching,
        saving: state.customer.data.contacts.saving,
        error: state.customer.data.contacts.error,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    getCustomerContacts,
    addCustomerContact,
    updateCustomerContacts,
    updateCustomerContact,
    deleteCustomerContact
}, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseContactsContainer));