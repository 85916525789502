import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { logoutUser } from '../../actions/user';

const LogoutContainer = ({ children, render, logoutUser }) => {
    return render(logoutUser);
}

const mapDispatchToProps = dispatch => bindActionCreators({ logoutUser }, dispatch);

export default connect(
    null,
    mapDispatchToProps
)(LogoutContainer);