import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import {
    AssignLoggedTime,
    FileUploader,
    FormGroup,
    FormItemGroup,
    FormItem,
    Input,
    ToggleSwitch,
    TextArea,
} from '../Forms';
import { ModalButtons } from '../Modals';
import { Button } from '../Buttons';
import { Link } from '../Text';

import {
    getEventKey,
    getEventVal,
    addFileToState,
    removeFileFromState,
    filterDocumentsAndAddToObject,
} from '../../utils/dataMap';
import {
    convertLoggedTimeToArray,
    getLoggedTime
} from '../../utils/dates';

class CompleteSiteVisitForm extends Component {
    constructor(props) {
        super(props);

        const logged_time = getLoggedTime(props.logged_time);

        this.state = {
            id: (props.site_visit && props.site_visit.id) || null,
            form: {
                mileage: (props.site_visit && props.site_visit.mileage) || '',
                notes: (props.site_visit && props.site_visit.notes) || '',
                documents: props.documents || [],
                products_present: false,
                ...logged_time
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // If the data was saved, with no errors, clear the form.
        if((prevProps.saving && !this.props.saving) && !this.props.error) {
            this.clearForm();
        }
    }

    clearForm = () => {
        this.setState({
            id: null,
            form: {
                mileage: '',
                notes: '',
                documents: [],
                products_present: false,
                logged_time: '',
                additional_logged_time: ''
            }
        })
    }

    handleSave = (stayOpen) => {
        let {
            id,
            form,
        } = this.state;

        let data = {
            site_visit: {
                notes: form.notes,
                mileage: form.mileage,
                products_present: form.products_present,
            },
            logged_time: convertLoggedTimeToArray(form),
        };

        // If there are already documents assigned to the case, make sure we don't try post them again.
        data = filterDocumentsAndAddToObject(form.documents, this.props.documents, data);

        this.props.onSave(id, data, stayOpen);
    }

    handleChange = (e, k) => {
        let key = k || getEventKey(e);

        let val = k && k.includes('logged_time') ? e : getEventVal(e);

        this.setState({
            form: {
                ...this.state.form,
                [key]: val
            }
        });
    }

    removeFile = (index) => {
        let document = this.state.form.documents[index];

        this.setState(state => removeFileFromState(state, index));

        // If the document has an ID, then it's already been uploaded, so we need to actually delete it.
        if(document && document.id) {
            this.props.onFileRemove(document.id);
        }
    }

    render() {
        let {
            form: {
                documents,
                notes,
                mileage,
                logged_time,
                additional_logged_time,
                products_present,
            } 
        } = this.state;

        let {
            onCancel,
            saving,
            handleRejectedFiles
        } = this.props;

        let requiredPopulated = mileage;

        return(
            <Fragment>
                <FormGroup center={true}>
                    <FormItemGroup labelWidth={"150px"} wide={true}>
                        <FormItem label={"Products Present"} id={'products_present'}>
                            <ToggleSwitch
                                checked={products_present}
                                id={'products_present'}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem label={"Logged Time"} id={'logged_time'}>
                            <AssignLoggedTime
                                value={logged_time.value}
                                id={'logged_time'}
                                onChange={e => this.handleChange(e, 'logged_time')}
                            />
                        </FormItem>

                        <FormItem label={"Additional Logged Time"} id={'additional_logged_time'}>
                            <AssignLoggedTime
                                value={additional_logged_time.value}
                                id={'additional_logged_time'}
                                onChange={e => this.handleChange(e, 'additional_logged_time')}
                            />
                        </FormItem>

                        <FormItem
                            label={"Logged Mileage"}
                            id={'mileage'}
                            required={true}
                        >
                            <Input
                                value={mileage}
                                id={'mileage'}
                                onChange={e => this.handleChange(e, 'mileage')}
                                minWidth={true}
                                type={'number'}
                            />
                        </FormItem>

                        <FormItem label={"Notes"} id={'notes'} align={'top'}>
                            <TextArea
                                value={notes}
                                id={'notes'}
                                onChange={this.handleChange}
                            />
                        </FormItem>

                        <FormItem label={"Associated files"} id={'files'} align={'top'}>
                            <FileUploader
                                files={documents}
                                handleAcceptedFiles={file => this.setState(state => addFileToState(state, file))}
                                handleRejectedFiles={handleRejectedFiles}
                                onRemove={this.removeFile}
                                cols={2}
                            />
                        </FormItem>
                    </FormItemGroup>
                </FormGroup>

                <ModalButtons pl={'150px'} wide={true}>
                    <Button
                        blue={true}
                        small={true}
                        onClick={() => this.handleSave(false)}
                        disabled={saving || !requiredPopulated}
                    >Save</Button>
                    <Button
                        small={true}
                        blue={true}
                        onClick={() => this.handleSave(true)}
                        disabled={saving || !requiredPopulated}
                    >Save and add another</Button>
                    { this.state.id &&
                        <Button
                            red={true}
                            small={true}
                            onClick={() => this.props.onDelete(this.state.id)}
                        >Delete</Button>
                    }
                    <Link el={'button'} onClick={onCancel}>Cancel</Link>
                </ModalButtons>
            </Fragment>
        );
    }
}

CompleteSiteVisitForm.propTypes = {
    onSave: PropTypes.func
}

export default CompleteSiteVisitForm;