import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getTraders, resetTraders } from '../../actions/traders';
import { AsyncSelect } from '../../components/Forms';

export class TraderDropdownContainer extends Component {
    state = {
        traders: [],
        fetchingTimeout: 0
    }

    static getDerivedStateFromProps(props, state) {
        if(!!state.traders.length && !props.exclude) return null;
        if(!props.traders.length) return null;

        let { traders, exclude } = props;

        if(exclude) {
            traders = traders.filter(trader => exclude.indexOf(trader.id) === -1);
        }

        return { traders }
    }

    componentWillUnmount() {
        this.props.resetTraders();
    }

    updateInput = value => {
        // If coming from onChange event it's an object.
        let data = value;

        // It's a string from onInputChange. Check if this is a valid option, by finding an ID.
        if(typeof data === 'string') {
            data = {
                contact_name: data
            }
        }

        // onBlur for some reason triggers an onInputChange with an empty string, so skip the change if it's an empty string.
        if(!data || (!data.contact_name && !data.business_name)) return;

        this.props.onChange(data);
    }

    getTraders = () => {
        let { traders, getTraders } = this.props;

        if(!!traders.length) return;

        if(this.state.fetchingTimeout) {
            clearTimeout(this.state.fetchingTimeout);
        }

        this.setState({
            fetchingTimeout: setTimeout(() => getTraders({ limit: 300 }), 300)
        })
    }

    render() {
        let { fetching, value, disabled } = this.props;

        let { traders } = this.state;

        return(
            <AsyncSelect
                value={value}
                options={traders}
                placeholder={value}
                onMenuOpen={this.getTraders}
                isLoading={fetching}
                allowCreateWhileLoading={fetching}
                isDisabled={disabled}
                onChange={this.updateInput}
                onInputChange={this.updateInput}
                onSelectResetsInput={false}
                onBlurResetsInput={false}
                onCloseResetsInput={false}
                getOptionLabel={item => item.contact_name || item.business_name}
                getOptionValue={item => item.contact_name}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        traders: state.traders.data,
        fetching: state.traders.fetching,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getTraders, resetTraders }, dispatch);

TraderDropdownContainer.propTypes = {
    fetching: PropTypes.bool,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onChange: PropTypes.func,
    traders: PropTypes.arrayOf(
        PropTypes.object
    ),
    exclude: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number
        ])
    ),
}

TraderDropdownContainer.defaultProps = {
    traders: []
}

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(TraderDropdownContainer));