import React, { Component } from 'react';

import { PageWithMainNav, PageTitleWrap } from '../components/Layout';
import { PageTitle } from '../components/Text';
import { BackButton } from '../components/Buttons';
import { ScrollToTopOnMount, MetaData } from '../components/Utils';

import { NewCaseContainer } from '../containers/Case';

class NewCase extends Component {
    render() {
        return(
            <PageWithMainNav>
                <ScrollToTopOnMount />
                <MetaData vars={{ title: 'New Case' }} />

                <PageTitleWrap>
                    <PageTitle>New Case</PageTitle>
                    <BackButton
                        to={'/cases'}
                        small={true}
                        showLabel={true}
                        label={'Discard and return to cases'}
                    />
                </PageTitleWrap>

                <NewCaseContainer />
            </PageWithMainNav>
        );
    }
}

export default NewCase;