import React, { Component, Fragment } from 'react';
import MediaQuery from 'react-responsive';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    getCaseTestPurchases,
    updateCaseTestPurchase,
    addCaseTestPurchase,
    completeCaseTestPurchase,
    deleteCaseTestPurchase
} from '../../actions/cases';
import { removeUserFromAction } from '../../actions/users';
import { deleteDocument } from '../../actions/documents';
import { flashAlert } from '../../actions/alerts';

import { IconLabel } from '../../components/Icons';
import { FormGroup } from '../../components/Forms';
import { Grid } from '../../components/Layout';
import { CardButton } from '../../components/Cards';
import {
    DataTable,
    GreenTableWrap,
    TableRowButton,
} from '../../components/Tables';
import {
    Loading,
    Error,
    ErrorBoundary,
} from '../../components/Utils';
import {
    Modal,
    ModalContent,
} from '../../components/Modals';
import {
    ViewTestPurchaseForm,
    EditTestPurchaseForm,
    CompleteTestPurchaseForm,
    TestPurchaseCard
} from '../../components/TestPurchases';

import {
    BlockyDateCell,
    DocumentsCell,
    FullCell,
} from '../../components/Tables/DataTable/components/DataTableCells';

import { validateSort, isCaseClosed } from '../../utils/validation';
import { moneyString } from '../../utils/textTransforms';
import theme from '../../config/theme';

class CaseTestPurchasesContainer extends Component {
    state = {
        editing: null,
        completing: null,
        viewing: null,
        modalOpen: false,
        stayOpen: false,
    }

    columns = [
        {
            Header: 'Date',
            accessor: 'test_purchase.start_date',
            Cell: ({ original, value }) => (
                <FullCell onClick={() => this.openModal(original.test_purchase)}>
                    <BlockyDateCell value={value} />
                </FullCell>
            ),
            maxWidth: 75,
            sortable: false,
        },
        {
            Header: "Location",
            accessor: 'test_purchase.address',
            Cell: ({ original }) => <FullCell onClick={() => this.openModal(original.test_purchase)}>{ original.test_purchase.address || original.test_purchase.website }</FullCell>,
            maxWidth: 125,
            sortable: false,
        },
        {
            Header: "Products",
            accessor: 'test_purchase.item',
            Cell: ({ original, value }) => <FullCell onClick={() => this.openModal(original.test_purchase)}>{ value }</FullCell>,
            width: 100,
            sortable: false,
        },
        {
            Header: "Cost",
            accessor: 'test_purchase.cost',
            Cell: ({ original, value }) => <FullCell onClick={() => this.openModal(original.test_purchase)}>{ moneyString(value) }</FullCell>,
            maxWidth: 85,
            sortable: false,
        },
        {
            Header: "Mileage",
            accessor: 'test_purchase.mileage',
            Cell: ({ original, value }) => <FullCell onClick={() => this.openModal(original.test_purchase)}>{ value }</FullCell>,
            maxWidth: 85,
            sortable: false,
        },
        {
            Header: "Associated Documents",
            accessor: 'documents',
            Cell: props => <DocumentsCell {...props} onOutcomeClick={this.openCompleteTestPurchaseModal} />,
            // maxWidth: 200,
        },
    ];

    componentDidMount() {
        this.getTestPurchases({ limit: 20 });
    }

    componentDidUpdate(prevProps) {
        // If we have saved successfully with no errors and 'add another' hasn't been chosen, close the modal.
        if((prevProps.saving && !this.props.saving) && !this.props.error && !this.state.stayOpen) {
            this.setState({
                modalOpen: false,
                editing: null,
                completing: null,
                viewing: null,
                stayOpen: false,
            });
        }
    }

    openModal = ({ id, status }) => {
        if(status === 'completed') {
            this.openViewTestPurchaseModal(id);
        } else {
            this.openEditTestPurchaseModal(id);
        }
    }

    saveTestPurchase = (id, params, stayOpen = false) => {
        let { match } = this.props,
            { completing, editing } = this.state;

        if(id) {
            if(completing) {
                this.props.completeCaseTestPurchase(match.params.id, id, params);
            } else if(editing) {
                this.props.updateCaseTestPurchase(match.params.id, id, params);
            }
        } else {
            this.props.addCaseTestPurchase(match.params.id, params);
        }

        this.setState({ stayOpen });
    }

    deleteTestPurchase = (id) => {
        if(window.confirm('Are you sure you want to delete this test purchase?')) {
            this.props.deleteCaseTestPurchase(this.props.match.params.id, id);
        }
    }

    openEditTestPurchaseModal = (id) => {
        this.setState({
            editing: this.props.testPurchases.find(item => item.test_purchase.id === id),
            completing: null,
            modalOpen: true,
        });
    }

    openViewTestPurchaseModal = (id) => {
        this.setState({
            viewing: this.props.testPurchases.find(item => item.test_purchase.id === id),
            completing: null,
            editing: null,
            modalOpen: true,
        });
    }

    openCompleteTestPurchaseModal = (id) => {
        this.setState({
            completing: this.props.testPurchases.find(item => item.test_purchase.id === id),
            editing: null,
            viewing: null,
            modalOpen: true,
        });
    }

    openNewTestPurchaseModal = () => {
        this.setState({
            editing: null,
            completing: null,
            viewing: null,
            modalOpen: true,
        });
    }

    closeModal = () => {
        this.setState({
            editing: null,
            completing: null,
            viewing: null,
            modalOpen: false,
        });
    }

    getTestPurchases = (newParam = {}) => {
        this.props.getCaseTestPurchases(this.props.match.params.id, {
            ...this.props,
            ...newParam,
        });
    }

    handleSortChange = (sortArray) => {
        // Only allow sorting by the following keys.
        let sortParams = validateSort(['title', 'date', 'deadline'], sortArray);

        if(!sortParams.length) return;

        // Query the server with the provided sort params and reset to page 1.
        this.getCases({
            sortBy: sortParams[0]['id'],
            sort: sortParams[0]['desc'] ? 'DESC' : 'ASC',
            page: 1,
        });
    }

    renderCards = (testPurchases) => {
        return testPurchases.map(item => (
            <TestPurchaseCard
                onEdit={this.openEditTestPurchaseModal}
                onComplete={this.openCompleteTestPurchaseModal}
                onView={this.openViewTestPurchaseModal}
                key={`tp${item.test_purchase.id}`}
                {...item}
            />
        ));
    }

    render() {
        let {
            testPurchases,
            details,
            page,
            fetching,
            saving,
            error,
            meta,
            loggedIn,
        } = this.props;

        let {
            editing,
            completing,
            viewing,
            modalOpen,
        } = this.state;

        let hide = details && !details.source.test_purchases;
        if(hide) return null;

        let ModalForm = EditTestPurchaseForm;
        if(completing) {
            ModalForm = CompleteTestPurchaseForm;
        } else if(viewing) {
            ModalForm = ViewTestPurchaseForm;
        }

        let modalTitle = 'Add Test Purchase';
        if(editing) {
            modalTitle = 'Edit Test Purchase';
        } else if(completing) {
            modalTitle = 'Complete Test Purchase';
        } else if(viewing) {
            modalTitle = 'View Test Purchase';
        }

        if(!loggedIn) return <Redirect to='/login' />

        return(
            <Fragment>
                { fetching && !testPurchases.length ? <Loading /> :
                    <FormGroup
                        title={"Test Purchases"}
                        id={"test-purchases"}
                        border={"top"}
                    >
                        <ErrorBoundary render={() => <Error overlay={true} />}>
                            <MediaQuery query={'(max-width: 991px)'}>
                                <Grid cols={[ [1, 0], [2, 480], [3, 901], [4, 1201], [5, 1380] ]}>
                                    { this.renderCards(testPurchases) }
                                    <CardButton
                                        label={'Add a test purchase'}
                                        onClick={this.openNewTestPurchaseModal}
                                    />
                                </Grid>
                            </MediaQuery>
                            <MediaQuery query={'(min-width: 992px)'}>
                                <GreenTableWrap>
                                    <DataTable
                                        data={testPurchases}
                                        columns={this.columns}
                                        total={meta.total}
                                        pages={meta.last_page}
                                        page={page - 1}
                                        limit={meta.per_page}
                                        viewingFrom={meta.from}
                                        viewingTo={meta.to}
                                        manual={true}
                                        getTdProps={(state, rowInfo) => {
                                            if(!rowInfo) return {}

                                            return{
                                                style: {
                                                    opacity: isCaseClosed(rowInfo.original.status) ? theme.opacity.inactive : '1'
                                                }
                                            }
                                        }}
                                        onPageChange={page => this.getCases({ page: page + 1 })}
                                        onPageSizeChange={size => this.getCases({ limit: size, page: 1 })}
                                        onSortChange={this.handleSortChange}
                                        fetching={fetching}
                                        noDataText={'There are no test purchases yet.'}
                                        noDataButtonText={'add a new test purchase'}
                                        noDataButtonOnClick={this.openNewTestPurchaseModal}
                                        styleType={'blue'}
                                        loadInside={false}
                                    />
                                    <TableRowButton onClick={this.openNewTestPurchaseModal}>
                                        <IconLabel label={"Add a test purchase"} />
                                    </TableRowButton>
                                </GreenTableWrap>
                            </MediaQuery>
                        </ErrorBoundary>
                    </FormGroup>
                }

                <Modal
                    isOpen={modalOpen}
                    onRequestClose={this.closeModal}
                >
                    <ModalContent title={modalTitle}>
                        <ErrorBoundary render={() => <Error overlay={true} />}>
                            <ModalForm
                                {...editing}
                                {...completing}
                                {...viewing}
                                onSave={this.saveTestPurchase}
                                onDelete={this.deleteTestPurchase}
                                onCancel={this.closeModal}
                                onStaffRemove={(id, userId) => this.props.removeUserFromAction(this.props.match.params.id, id, userId, 'test-purchases')}
                                onFileRemove={id => this.props.deleteDocument(id)}
                                handleRejectedFiles={msg => this.props.flashAlert(msg, 'error')}
                                saving={saving}
                                error={error}
                            />
                        </ErrorBoundary>
                    </ModalContent>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        details: state.case.data.details.data,
        testPurchases: state.case.data.testPurchases.data,
        links: state.case.data.testPurchases.links,
        meta: state.case.data.testPurchases.meta,
        page: state.case.data.testPurchases.page,
        limit: state.case.data.testPurchases.limit,
        sort: state.case.data.testPurchases.sort,
        fetching: state.case.data.testPurchases.fetching,
        saving: state.case.data.testPurchases.saving,
        error: state.case.data.testPurchases.error,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({
    getCaseTestPurchases,
    updateCaseTestPurchase,
    completeCaseTestPurchase,
    addCaseTestPurchase,
    removeUserFromAction,
    deleteDocument,
    deleteCaseTestPurchase,
    flashAlert
}, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseTestPurchasesContainer));