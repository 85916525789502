import React, { Component, Fragment } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    getCaseTraders,
    addCaseTraderAndAssign,
    updateCaseTraders,
    updateCaseTrader,
    assignCaseTrader,
    unassignCaseTrader,
} from '../../actions/cases';

import { FormGroup } from '../../components/Forms';
import {
    Modal,
    ModalContent,
} from '../../components/Modals';
import {
    DragTraders,
    TraderForm,
} from '../../components/Traders';
import {
    Error,
    ErrorBoundary,
    Loading,
} from '../../components/Utils';
// import confirm from '../../components/Confirm/confirm';
import { hasPermission } from '../../utils/validation';

class CaseTradersContainer extends Component {
    state = {
        modalOpen: false,
        editing: null,
        stayOpen: false,
    }

    componentDidMount() {
        this.props.getCaseTraders(this.props.match.params.id);
    }

    componentDidUpdate(prevProps) {
        // If we have saved successfully with no errors and 'add another' hasn't been chosen, close the modal.
        if((prevProps.saving && !this.props.saving) && !this.props.error && !this.state.stayOpen) {
            this.setState({
                modalOpen: false,
                editing: null,
                stayOpen: false,
            });
        }
    }

    updateTraders = (newItems) => {
        this.props.updateCaseTraders(this.props.match.params.id, newItems.map(item => item.id));
    }

    removeTrader = (id) => {
        if(window.confirm('Are you sure you want to remove this trader?')) {
            this.props.unassignCaseTrader(this.props.match.params.id, id);
        }
    }

    saveTrader = (id, params, stayOpen = false) => {
        let { match } = this.props;

        if(id) {
            let traderIsPresent = this.props.traders.find(trader => trader.id === id);

            // Trader already exists, so warn the user before updating.
            if(traderIsPresent) {
                let confirmation = window.confirm('Changes made to this trader will affect all instances. Are you sure you want to do this?');

                if(confirmation) {
                    this.props.updateCaseTrader(id, params);
                }
            } else {
                // Trader exists, but needs to be assigned to this case.
                params.id = id;
                this.props.assignCaseTrader(match.params.id, params);
            }
        } else {
            // Trader needs to be created and then assigned to the case.
            this.props.addCaseTraderAndAssign(match.params.id, params);
        }

        this.setState({ stayOpen });
    }

    openEditTraderModal = (id) => {
        this.setState({
            modalOpen: true,
            editing: this.props.traders.find(item => item.id === id)
        });
    }

    openNewTraderModal = () => {
        this.setState({
            modalOpen: true,
            editing: null
        });
    }

    closeModal = () => {
        this.setState({
            modalOpen: false,
            editing: false,
        });
    }

    render() {
        let {
            loggedIn,
            traders,
            fetching,
            saving,
            error,
            self,
        } = this.props;

        let { modalOpen, editing } = this.state;

        let traderIds = traders.map(trader => trader.id);

        const userHasPermission = hasPermission(self, 'traders:read');

        if(!loggedIn) return <Redirect to='/login' />

        if(!userHasPermission) return null;

        return(
            <Fragment>
                <FormGroup
                    title={"Associated Traders"}
                    id={"case-traders"}
                    saving={saving}
                    border={'top'}
                    noMinHeight={true}
                >
                    { fetching ? <Loading /> :
                        <ErrorBoundary render={() => <Error overlay={true} />}>
                            <DragTraders
                                items={traders}
                                onClick={this.openEditTraderModal}
                                onAddNew={this.openNewTraderModal}
                                onMove={this.updateTraders}
                                onDelete={this.removeTrader}
                            />
                        </ErrorBoundary>
                    }
                </FormGroup>

                <Modal
                    isOpen={modalOpen}
                    onRequestClose={this.closeModal}
                >
                    <ModalContent title={`${editing ? 'Edit' : 'Add'} Associated Trader`}>
                        <ErrorBoundary render={() => <Error overlay={true} />}>
                            <TraderForm
                                {...editing}
                                onSave={this.saveTrader}
                                onCancel={this.closeModal}
                                exclude={traderIds}
                                saving={saving}
                                error={error}
                            />
                        </ErrorBoundary>
                    </ModalContent>
                </Modal>
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        loggedIn: state.user.loggedIn,
        self: state.user.self,
        traders: state.case.data.traders.data,
        fetching: state.case.data.traders.fetching,
        saving: state.case.data.traders.saving,
        error: state.case.data.traders.error,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators({ getCaseTraders, updateCaseTraders, updateCaseTrader, addCaseTraderAndAssign, assignCaseTrader, unassignCaseTrader }, dispatch);

export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(CaseTradersContainer));