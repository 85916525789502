import React  from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import settings from '../../config/settings';

let MetaData = ({ page, vars }) => {
    let { title, meta } = metadata[page](vars);
    return <Helmet title={title} meta={meta} />
};

MetaData.propTypes = {
    vars: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]),
    page: PropTypes.string
};

MetaData.defaultProps = {
    vars: {},
    page: 'universal'
};

let metadata = {

    titleTemplate: settings.title,

    universal(vars) {

        let { title, description, type } = vars;
        if( title ) title += " | ";
        if( type ) {
            if(title) {
                title += type + " | ";
            } else {
                title = type + " | ";
            }
        }
        title += metadata.titleTemplate;

        // let images = site.globalImages;
        // images.unshift(image);
        // let imageTags = images.map(image => ({"property": "og:image", "content": image}));

        return {
            title,
            meta: [
                {"property": "og:type", "content": "website"},
                {"name": "description", "content": description},
                {"property": "og:title", "content": title},
                {"property": "og:description", "content": description},
                // ...imageTags
            ]
        }
    },

    notFound() {
        return {
            title: "Sorry, this page doesn't exist" + metadata.titleTemplate
        }
    }

}

export default MetaData;
