import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { PageWithBackButton } from '../components/Layout';
import { ScrollToTopOnMount, MetaData } from '../components/Utils';

import { InvoiceContainer } from '../containers/Case';
import { UserHasPermissionContainer } from '../containers/User';

class Invoice extends Component {	
	render() {
		const { match } = this.props;

		return(
			<PageWithBackButton link={`/cases/${match.params.id}`}>
				<ScrollToTopOnMount />
				<MetaData vars={{ title: 'Client Invoice' }} />
	
				<UserHasPermissionContainer permissions={'cases:write'}>
					<InvoiceContainer 
						id={match.params.id}
					/>
				</UserHasPermissionContainer>
			</PageWithBackButton>
		);
	}
}

export default withRouter(Invoice);