import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

import { Grid } from '../../Layout';
import { Loading } from '../../Utils';
import { File, NewFile } from './components';

class FileUploader extends Component {
    state = {
        removing: false,
        dropping: false,
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.deleting && !this.props.deleting) {
            this.setState({
                removing: false,
            });
        }

        if(prevProps.saving && !this.props.saving) {
            this.setState({
                dropping: false,
            });
        }
    }

    handleDrop = (accepted, rejected) => {
        this.setState({
            dropping: true
        });

        if(accepted.length > 20) {
            this.props.handleRejectedFiles('You can only upload up to 20 files at a time.');
            accepted.splice(-1, accepted.length - 20);
        }

        if(rejected.length) {
            this.props.handleRejectedFiles('Some of your files couldn\'t be uploaded. Only documents and images up to 100MB are allowed.');
            Sentry.captureEvent({
                message: 'Rejected Files: ' + rejected.map(({ name, type }) => `Name: ${name} Type: ${type}, `)
            });
        }

        if(!accepted.length) {
            return this.setState({
                dropping: false
            });
        }

        accepted.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                let data = {
                    file: reader.result,
                    name: file.name,
                    type: file.type
                }

                if(!data.type) {
                    if(file.name.includes('.doc')) {
                        data.type = 'application/msword';
                    }
                }

                // pass the number of files to expect to the container, so we know when processing has finished
                if(this.props.uploadOnAccept) {
                    data = {
                        file: data,
                        expect: accepted.length
                    }
                }

                this.props.handleAcceptedFiles(data);
            }
            reader.onabort = () => console.log('file reading was aborted');
            reader.onerror = () => console.log('file reading has failed');

            reader.readAsDataURL(file);
        });
    }

    handleRemove = (index, id) => {
        this.props.onRemove(index, id);

        // Only set this if we're sending a DELETE request. Removing files from state will be instant so there's no need to show deleting UI state
        if(id) {
            this.setState({
                removing: index
            });
        }
    }

    render() {
        let { files, cols, saving, readOnly, canRemove, uploadOnAccept } = this.props;
        let { dropping } = this.state;

        return(
            <Grid cols={cols}>
                { files.map((file, i) => (
                    <File
                        index={i}
                        key={`file${i}`}
                        removing={i === this.state.removing}
                        onRemove={this.handleRemove}
                        canRemove={canRemove}
                        {...file}
                    />
                )) }
                { !readOnly && <NewFile handleDrop={this.handleDrop} /> }
                { (saving || (dropping && uploadOnAccept)) && <Loading overlay={true} /> }
            </Grid>
        );
    }
}

FileUploader.propTypes = {
    handleAcceptedFiles: PropTypes.func,
    cols: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.number
    ]),
    files: PropTypes.array,
    readOnly: PropTypes.bool,
    deleting: PropTypes.bool,
    // If true, this will pass the number of accepted files, along with each file, to the parent container
    uploadOnAccept: PropTypes.bool,
}

FileUploader.defaultProps = {
    cols: [ [2, 0], [3, 480], [4, 665], [5, 1301] ],
    files: [],
    canRemove: true,
    handleAcceptedFiles: () => {},
    handleRejectedFiles: () => {},
}

export default FileUploader;