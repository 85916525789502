import styled from 'styled-components';

import { media } from '../../utils/style';

const ModalButtons = styled.div`
    width: 100%;
    max-width: ${({ wide }) => wide ? '530px' : '410px'};
    padding-left: ${({ pl }) => `calc(${pl} + 18px)`};
    margin-left: auto;
    margin-right: auto;
    
    >button {
        +* {
            margin-left: 10px;
        }
    }
    
    ${({ alignRight }) => alignRight && `
        text-align: right;
    `};
    
    @media screen and (max-width: 649px) {
        padding-left: 0;
        text-align: right;
    }
    
    @media screen and (max-width: 479px) {
        >button {
            display: table;
            margin-left: auto;
            
            +* {
                margin: 10px 0 0 auto;
            }
        }
    }
`;

ModalButtons.defaultProps = {
    pl: '120px'
}

export default ModalButtons;