import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
    TextArea,
    HiddenInput,
    TimeInput,
    FileInput,
} from '../../Forms';
import AssignLoggedTime from '../../Forms/AssignLoggedTime';
import { File } from '../../Forms/FileUploader/components';
import { Loading } from '../../Utils';
import { Button } from '../../Buttons';
import { Grid } from '../../Layout';

const LogInputWrap = styled.form`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: 38px 72px 38px 256px;
    background: ${({ theme }) => theme.greyLightest};
    
    textarea {
        padding: 16px 33px!important;
    }
    
    @media screen and (max-width: 832px) {
        padding-left: 36px;
    }
    @media screen and (max-width: 479px) {
        padding: 25px 15px;
    }
`;

const LogInputBottom = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
    
    @media screen and (max-width: 1284px) {
        flex-wrap: wrap;
    }
`;

const LogInputDocs = styled.div`
    flex: 1 0 0;
    padding-right: 15px;
    
    @media screen and (max-width: 1284px) {
        width: 100%;
        padding: 15px 0 0;
        order: 2;
        flex: none;
    }
`;

const LogInputControls = styled.div`
    input[type="file"] {
        position: absolute;
    }
    
    @media screen and (max-width: 1284px) {
        order: 1;
    }
`;

class LogInput extends Component {
    constructor(props) {
        super(props);

        this.timeInput = React.createRef();
    }

    render() {
        let {
            text,
            time,
            files,
            onCommentChange,
            onTimeChange,
            onSubmit,
            onFilesAdd,
            onFileRemove,
            disabled,
            sending,
        } = this.props;

        return(
            <LogInputWrap onSubmit={onSubmit}>
                <TextArea
                    placeholder={"Add an update"}
                    srLabel={"Add an update"}
                    id={'text'}
                    value={text}
                    onChange={onCommentChange}
                    borderless={true}
                />

                <LogInputBottom>
                    { !!files.length &&
                        <LogInputDocs>
                            <Grid cols={[ [1, 0], [2, 360], [3, 526], [4, 1135] ]}>
                                { files.map((file, i) => <File {...file} key={file.name} onRemove={() => onFileRemove(i)} canRemove={true} />) }
                            </Grid>
                        </LogInputDocs>
                    }

                    <LogInputControls>
                        <FileInput onChange={onFilesAdd} />&nbsp;/&nbsp;
                        <AssignLoggedTime
                            value={time}
                            onChange={onTimeChange}
                        />

                        <Button
                            small={true}
                            blue={true}
                            disabled={disabled || sending}
                            style={{ marginLeft: '25px' }}
                        >Post</Button>
                    </LogInputControls>
                </LogInputBottom>

                { sending && <Loading overlay={true} /> }
            </LogInputWrap>
        );
    }
}

LogInput.propTypes = {
    text: PropTypes.string,
    time: PropTypes.object,
    onCommentChange: PropTypes.func,
    onTimeChange: PropTypes.func,
    onSubmit: PropTypes.func,
    timeInvalid: PropTypes.bool,
    disabled: PropTypes.bool,
    sending: PropTypes.bool,
}

export default LogInput;