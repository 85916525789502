import styled from 'styled-components';

export default styled.div`
    display: flex;
    align-items: flex-start;
    padding: 0 5px;
    
    >div:first-of-type {
        width: 22px;
    }
    
    >div:last-of-type {
        width: calc(100% - 22px);
        padding-left: 10px;
        overflow-x: auto;
    }
    
    p {
        font-size: ${({ theme }) => theme.fontSize.bodySm};
        line-height: 155%;
        
        &:first-of-type {
            color: ${({ theme }) => theme.blueLight};
            font-weight: 700;
        }
    }
    
    svg {
        width: 100%;
    }
    
    path {
        fill: ${({ theme }) => theme.blueLight};
    }
`;